import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  BackAndConfirmButtons,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  IconEnum,
  NotificationHard,
  NotificationIconTypeEnum,
  OperationsHeader,
  useTranslation,
  FundSheetModal,
  FundSheetModalDataType,
  OperationsSubHeaderAdvantageListItemType,
  OperationsHeaderDetailsItemType,
  ExpendableGreen,
  BankAccountInfosCard,
  ChequeInfosCard
} from '@components/index';
import { PlanFamillyList } from '@constants/common';
import {
  ProfitSharingIncentivePaymentModeTypeEnum,
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/index';
import { locator } from '@constants/locator';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { AccountState } from '@modules/dashboard/types';
import { ErrorItemState } from '@modules/error/types';
import {
  ProfitSharingIncentiveAssigmentAmountsType,
  ProfitSharingIncentiveInitDataState,
  ProfitSharingIncentiveRepartitionState
} from '@modules/profit-sharing-incentive/types';
import { ApplicationSettingsState } from '@modules/settings/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { constructStepperItems } from '@pages/Common/utils';

import {
  FiletStepperCardDestination,
  FiletStepperCardExternalAccounts,
  FiletStepperCardOrigin,
  ProfitSharingIncentiveHeader,
  ProfitSharingIncentiveDestinationCardType,
  ProfitSharingIncentiveDestinationSubCardType
} from '../components';
import { label } from './label';
import { useStyles } from './useStyles';

interface ProfitSharingIncentiveSynthesisPageProps {
  isSubmitLoading: boolean;
  submitError?: ErrorItemState;
  onSubmitRequest(): void;
  repartition: ProfitSharingIncentiveRepartitionState | null;
  initData: ProfitSharingIncentiveInitDataState | null;
  assigmentAmounts: ProfitSharingIncentiveAssigmentAmountsType | null;
  navigation: StackNavigationProp<AppNavigatorInterface>;
  selectedCompany: AccountState | undefined;
  applicationSettings: ApplicationSettingsState;
}

export const ProfitSharingIncentiveSynthesisPageComponent: React.FC<
  React.PropsWithChildren<ProfitSharingIncentiveSynthesisPageProps>
> = ({
  isSubmitLoading,
  submitError,
  onSubmitRequest,
  repartition,
  initData,
  assigmentAmounts,
  navigation,
  selectedCompany,
  applicationSettings
}) => {
  const styles = useStyles();
  const { formatMessage, formatCurrencyNumber, getMessageRaw } = useTranslation();
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>();
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  const isRefund = (assigmentAmounts?.refundAmount || 0) > 0;
  const isReinvest = (assigmentAmounts?.reinvestAmount || 0) > 0;
  const isContributionAdvantage = (repartition?.formValues?.contributionAmount || 0) > 0;
  const hasSimulationInPlans = !!repartition?.plans.find(
    item => !!item.advancedProperties?.HasSimulation === true
  );
  const isFiscalityAdvantage = (assigmentAmounts?.fiscalityAmount || 0) > 0;

  const getFiscalityLabels = useMemo(() => {
    const {
      type = ProfitSharingIncentiveTypeEnum.incentive,
      legalFramework = ProfitSharingIncentiveLegalFrameworkEnum.normal
    } = initData ?? {};

    const isPPV =
      type === ProfitSharingIncentiveTypeEnum.incentive &&
      legalFramework === ProfitSharingIncentiveLegalFrameworkEnum.ppv;

    if (isPPV) {
      return formatMessage({ id: 'PIChoixInvestPaimentPPVCardInfo' });
    }

    if (!isFiscalityAdvantage) {
      return formatMessage({ id: 'DetailOperationPIFiscaliteToutInvesti' });
    }

    switch (initData?.paymentMethod) {
      case ProfitSharingIncentivePaymentModeTypeEnum.RIB:
      case ProfitSharingIncentivePaymentModeTypeEnum.CHEQUE:
        return formatMessage({ id: 'DetailOperationPIFiscalitePartiellementInvesti' });
      case ProfitSharingIncentivePaymentModeTypeEnum.COMPANY:
        return formatMessage({ id: 'DetailOperationPIFiscalitePartiellementInvestiEnPayeuse' });
      default:
        break;
    }
  }, [formatMessage, initData, isFiscalityAdvantage]);

  const advantageList = useMemo(() => {
    const list: OperationsSubHeaderAdvantageListItemType[] = [];

    if (hasSimulationInPlans && isContributionAdvantage) {
      list.push({
        icon: IconEnum.CONTRIBUTION,
        title: `${formatMessage({ id: 'PIValidationChoixPercIvestCardAbondementTitre' })} `,
        titleDetail: formatCurrencyNumber({
          value: repartition?.formValues?.contributionAmount || 0
        }),
        description: formatMessage({ id: 'PIValidationChoixPercIvestCardAbondementDescription' })
      });
    }
    list.push({
      icon: IconEnum.FISCALITE,
      title: formatMessage({ id: 'PIValidationChoixPercIvestCardFiscaliteTitre' }),
      description: getFiscalityLabels
    });

    return list;
  }, [
    isContributionAdvantage,
    formatMessage,
    getFiscalityLabels,
    formatCurrencyNumber,
    repartition?.formValues?.contributionAmount
  ]);

  const headerDetails = useMemo(() => {
    const list: OperationsHeaderDetailsItemType[] = [];
    // uncomment to use Mock
    // list = mockProfitSharingIncentiveOperationDetails
    return list;
  }, []);

  const stepperItemsHeader = constructStepperItems({
    size: 3,
    direction: DirectionEnum.ROW,
    activeStep: 3
  });

  const handleOnBackPress = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleOnSubmitPress = useCallback(() => {
    onSubmitRequest();
  }, [onSubmitRequest]);

  const handleHideFundSheet = useCallback(() => {
    setDisplayFundSheetModal(false);
    setFundSheetModalData(null);
  }, []);

  const renderActions = useCallback(() => {
    return (
      <BackAndConfirmButtons
        confirmButtonLabel={formatMessage({ id: label.button.next })}
        containerStyle={styles.bottomButtons}
        isLoading={isSubmitLoading}
        onBack={handleOnBackPress}
        onValidate={handleOnSubmitPress}
        testId={locator._pi._validate_affectation}
      />
    );
  }, [styles, isSubmitLoading, handleOnSubmitPress, handleOnBackPress, formatMessage]);

  const renderSubHeader = useCallback(() => {
    const {
      type = ProfitSharingIncentiveTypeEnum.incentive,
      legalFramework = ProfitSharingIncentiveLegalFrameworkEnum.normal
    } = initData ?? {};

    return (
      <OperationsHeader
        advantageList={advantageList}
        amount={initData?.netShareAmount || 0}
        details={headerDetails}
        title={formatMessage({
          id: label.title[type]?.[legalFramework] || label.title[type]?.default
        })}
      />
    );
  }, [advantageList, initData, headerDetails, formatMessage]);

  const renderHeader = useCallback(() => {
    const {
      type = ProfitSharingIncentiveTypeEnum.incentive,
      legalFramework = ProfitSharingIncentiveLegalFrameworkEnum.normal
    } = initData ?? {};

    return (
      <ProfitSharingIncentiveHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({
          id:
            label.header.help.content[type]?.[legalFramework] ||
            label.header.help.content[type]?.default
        })}
        helpPointModalTitle={formatMessage({
          id: label.header.help.title
        })}
        helpPointPosition="left"
        stepperItems={stepperItemsHeader}
        title={formatMessage({
          id: label.header.title
        })}
      />
    );
  }, [formatMessage, getMessageRaw, initData, stepperItemsHeader]);

  const renderOrigin = useCallback(() => {
    const {
      type = ProfitSharingIncentiveTypeEnum.incentive,
      legalFramework = ProfitSharingIncentiveLegalFrameworkEnum.normal
    } = initData ?? {};

    return (
      <FiletStepperCardOrigin
        amount={initData?.netShareAmount || 0}
        description={selectedCompany?.companyName}
        title={formatMessage({
          id: label.origin.title[type]?.[legalFramework] || label.title[type]?.default
        })}
      />
    );
  }, [selectedCompany, initData, formatMessage]);

  const renderDestination = useCallback(() => {
    if (!isReinvest) {
      return null;
    }
    const constructedDestinationCards: ProfitSharingIncentiveDestinationCardType[] = [];
    repartition?.plans.forEach((plan, index) => {
      const planAmount = plan.formValues?.amount || 0;
      if (!planAmount) return;
      const isMergedExternal = !!plan?.advancedProperties?.IsMergedExternal;
      const constructedDestinationManagements: ProfitSharingIncentiveDestinationCardType['managements'] =
        [];
      const HasSimulation = !!plan?.advancedProperties.HasSimulation;
      plan.compartments.forEach(compartment => {
        const isMultipleManagement = compartment.managements.length > 1;
        compartment.managements.forEach(management => {
          const isValidManagement =
            !management?.advancedProperties?.IsTechnicalManagement &&
            (isMergedExternal || !!management.formValues?.amount) &&
            plan.planType !== 'EXT';

          if (!isValidManagement) return;

          let constructedDestinationSubCards: ProfitSharingIncentiveDestinationSubCardType[] = [];
          if (isMergedExternal || management.isFree) {
            constructedDestinationSubCards = management.supports.reduce<
              ProfitSharingIncentiveDestinationSubCardType[]
            >((prevConstructedDestinationSubCards, investedSupport) => {
              const isValidSupport =
                !investedSupport?.advancedProperties?.IsTechnicalFund &&
                !!investedSupport?.formValues?.amountPercent;

              if (!isValidSupport) return prevConstructedDestinationSubCards;

              const supportPourcentage =
                investedSupport?.advancedProperties?.PourcentageRepartition || 0;

              const investedSupportAmount = isMergedExternal
                ? (planAmount * supportPourcentage) / 100
                : investedSupport?.formValues?.amount;

              const investedSupportPercentage = isMergedExternal
                ? supportPourcentage
                : investedSupport?.formValues?.amountPercent;

              const constructedDestinationSubCard = {
                title: investedSupport.supportName,
                amount: investedSupportAmount,
                percentage: investedSupportPercentage,
                hasContribution: HasSimulation && investedSupport?.hasContribution,
                contribution: investedSupport.formValues?.contributionAmount,
                onPress: () => {
                  setDisplayFundSheetModal(true);
                  setFundSheetModalData({
                    id: investedSupport.supportIsin,
                    title: investedSupport.supportName || ''
                  });
                }
              };

              return [...prevConstructedDestinationSubCards, constructedDestinationSubCard];
            }, []);
          }

          const managementPourcentage = management?.advancedProperties?.PourcentageRepartition || 0;

          const managementAmount = isMergedExternal
            ? (planAmount * managementPourcentage) / 100
            : management.formValues?.amount;

          const managementPercentage = isMergedExternal
            ? managementPourcentage
            : management.formValues?.amountPercent;

          constructedDestinationManagements.push({
            title: management.label,
            isFree: management.isFree,
            hideManagementAmount: management.isFree,
            hideManagementRow: !isMultipleManagement,
            amount: managementAmount,
            percentage: managementPercentage,
            hasContribution: HasSimulation && management.advancedProperties.HasContribution,
            contribution: management.formValues?.contributionAmount,
            showMoreSupportData: plan.planFamily === PlanFamillyList.ES,
            subCards: constructedDestinationSubCards
          });
        });
      });

      constructedDestinationCards.push({
        filetTitle: (index === 0 && formatMessage({ id: label.destination.title1 })) || undefined,
        planName: plan.isCustomPlan ? plan.planFullName : plan.shortLabel,
        planType: plan.planType,
        amount: planAmount,
        managements: constructedDestinationManagements
      });
    });
    return (
      <FiletStepperCardDestination
        cards={constructedDestinationCards}
        disableLine={!isRefund}
      />
    );
  }, [isRefund, isReinvest, formatMessage, repartition?.plans]);

  const renderExternalAccounts = useCallback(() => {
    if (!isRefund) {
      return null;
    }
    let paymentDetails: undefined | React.ReactNode;
    const { paymentMethod = ProfitSharingIncentivePaymentModeTypeEnum.UNKNOWN } = initData ?? {};

    switch (initData?.paymentMethod) {
      case ProfitSharingIncentivePaymentModeTypeEnum.CHEQUE:
        const fullAddress = initData?.saverDetails?.fullAddress;
        paymentDetails = (
          <View style={styles.bankAccountContainer}>
            <ExpendableGreen
              title={formatMessage({
                id: 'Remboursement_virementRecap_masquerCondPost'
              })}>
              <ChequeInfosCard
                appSettings={applicationSettings}
                chequeInfoData={{
                  ...fullAddress,
                  additionalAddress_2: fullAddress.additionalAddress_2 || '',
                  additionalAddress_3: fullAddress.additionalAddress_3 || '',
                  street: fullAddress.street || '',
                  recipient: fullAddress.recipient || ''
                }}
              />
            </ExpendableGreen>
          </View>
        );

        break;
      case ProfitSharingIncentivePaymentModeTypeEnum.RIB:
        const PIDelaiTraitementGlobal = applicationSettings.PIDelaiTraitementGlobal
          ? Number(applicationSettings.PIDelaiTraitementGlobal)
          : 0;
        const endDate = dayjs(initData?.deadlineDate);
        endDate.add(PIDelaiTraitementGlobal, 'day');

        const bankAccountDetail = initData?.saverDetails?.bankAccountDetail;
        paymentDetails = (
          <View style={styles.bankAccountContainer}>
            <ExpendableGreen
              title={formatMessage({ id: 'PIValidationChoixPercIvestCardCompteBancaireLien' })}>
              <BankAccountInfosCard
                AccountInfoData={{
                  ...bankAccountDetail,
                  domiciliation: bankAccountDetail?.domiciliation || undefined,
                  titulaire: bankAccountDetail?.titulaire || undefined
                }}
                NoDisplayPaymentChoice
              />
            </ExpendableGreen>
          </View>
        );
        break;
      default:
        break;
    }

    return (
      <FiletStepperCardExternalAccounts
        amount={assigmentAmounts?.refundAmount || 0}
        description={formatMessage({ id: label.payment[paymentMethod]?.description })}
        stepTitle={formatMessage({ id: label.destination[!isReinvest ? 'title1' : 'title2'] })}
        title={formatMessage({ id: label.payment[paymentMethod]?.title })}>
        {paymentDetails}
      </FiletStepperCardExternalAccounts>
    );
  }, [
    assigmentAmounts,
    isRefund,
    isReinvest,
    formatMessage,
    initData,
    applicationSettings,
    styles
  ]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasColumnCenter={false}
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
        renderTopContent={renderSubHeader}>
        <View style={styles.contentContainer}>
          <View style={styles.content}>
            {renderOrigin()}
            {renderDestination()}
            {renderExternalAccounts()}
            {!!submitError?.message && (
              <NotificationHard
                containerStyle={styles.notifications}
                text={submitError.message}
                type={NotificationIconTypeEnum.WARNING}
              />
            )}
            {renderActions()}
          </View>
        </View>
        {!!fundSheetModalData && (
          <FundSheetModal
            id={fundSheetModalData?.id}
            modalIsVisible={displayFundSheetModal}
            onCloseModal={handleHideFundSheet}
            title={fundSheetModalData?.title}
          />
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
