import { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';

export const useDictionary = (category: string | undefined, descriptionKey: string | undefined) => {
  const { formatMessage, getMessageRaw } = useTranslation();

  const dictionary = useMemo(() => {
    return {
      contactPageTitle: formatMessage({ id: 'ContactPageTitle' }),
      linkDescription: formatMessage({ id: 'ContactPhonePageInformationLinkLabel' }),
      contactPhonePageInformationLink: formatMessage({ id: 'ContactPhonePageInformationLink' }),
      titlePhone: formatMessage({ id: 'ContactPhonePageTitle' }),
      descriptionPhone: getMessageRaw({ id: descriptionKey }),
      contactPhoneInformation: formatMessage({ id: 'ContactPhonePageInformation' }),
      contactPhoneNotice: formatMessage({ id: category }),
      notice: formatMessage({ id: 'ContactPhonePageNumber' }),
      titleCourrierES: formatMessage({ id: 'ContactMailingAddressPageTitleES' }),
      subTitleCourrierES: formatMessage({ id: 'ContactMailingAddressPageDescriptionES' }),
      addressES: getMessageRaw({ id: 'ContactMailingAdressPageAddressES' }),
      titleCourrierRC: formatMessage({ id: 'ContactMailingAddressPageTitleRC' }),
      subTitleCourrierRC: formatMessage({ id: 'ContactMailingAddressPageDescriptionRC' }),
      addressRC: getMessageRaw({ id: 'ContactMailingAdressPageAddressRC' }),
      mailRcContactMail: formatMessage({ id: 'ContactMailAddressRC' })
    };
  }, [category, formatMessage, getMessageRaw]);

  return dictionary;
};
