import React from 'react';
import { Linking, Pressable, View } from 'react-native';

import { Text, usePhoneNumberUtils } from '@components/index';
import { useScreenSizes } from '@ere-uilib/styles';

import { useStyles } from './useStyles';

interface Props {
  phoneNumber: string;
  description: string;
}

export const PhoneContactComponent: React.FC<Props> = ({ phoneNumber, description }) => {
  const { getNumberInfos } = usePhoneNumberUtils();
  const number = getNumberInfos(phoneNumber).formatedPhoneNumber;
  const styles = useStyles();
  const { isMobile } = useScreenSizes();
  return (
    <Pressable
      disabled={!isMobile}
      onPress={() => Linking.openURL(`tel:${phoneNumber}`)}
      role="button"
      style={styles.container}>
      <View style={styles.triangle} />
      <View style={styles.leftContainer}>
        <Text style={styles.phoneText}>{number}</Text>
      </View>
      <View style={styles.rightContainer}>
        <Text
          style={styles.descriptionContainer}
          weight="light">
          {description}
        </Text>
      </View>
    </Pressable>
  );
};
