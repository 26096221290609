import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isTablet } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        scrollViewMainContainer: {
          gap: theme.metrics.spacing.xm,

          paddingHorizontal: theme.metrics.spacing.s
        },
        titleContainer: { gap: theme.metrics.spacing.s },
        sendButton: {
          padding: theme.metrics.spacing.xm,
          width: isDesktop || isTablet ? undefined : '100%'
        },
        sendButtonContainer: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        },
        textAreaContainer: {}
      }),
    [isDesktop, isTablet, theme]
  );

  return styles;
}
