import {
    ProfitSharingIncentiveCompaignOperationTypeEnum,
    ProfitSharingIncentiveLegalFrameworkEnum,
    ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

export const compaignOperationType = {
    [ProfitSharingIncentiveTypeEnum.incentive]: {
        default: ProfitSharingIncentiveCompaignOperationTypeEnum.incentiveNoPPV,
        [ProfitSharingIncentiveLegalFrameworkEnum.ppv]:
        ProfitSharingIncentiveCompaignOperationTypeEnum.incentiveWithPPV
    },
    [ProfitSharingIncentiveTypeEnum.profitsharing]: {
        default: ProfitSharingIncentiveCompaignOperationTypeEnum.profitsharing
    }
};
