import { useRoute } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  LoaderPlaceholderView,
  NavigationHeader,
  RetryActionPlaceHolder,
  SimpleButton,
  useScreenSizes,
  useTranslation,
  CircleLoader,
  OperationsHeader,
  Text,
  OperationsHistoryStatusEnum
} from '@components/index';
import { OperationFamilyEnum } from '@constants/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';

import { OperationDetailsContent } from './components';
import { useOperationDetailsHeaderData } from './hooks';
import { OperationDetailsProps } from './interface';
import { label } from './label';
import { useStyles } from './style';
import { findTrueExternalPlan } from './utils';

export const OperationDetailsPageComponent: React.FC<OperationDetailsProps> = ({
  onGetOperationDetails,
  operationDetails,
  isLoading,
  error
}) => {
  const navigation = useNavigation();
  const { isDesktop } = useScreenSizes();
  const { formatMessage, getMessageRaw } = useTranslation();

  const styles = useStyles();
  const route = useRoute();
  const operationId = (route.params as { id: string }).id;
  const operationFamily = operationDetails?.family;

  const isArbitration = operationFamily === OperationFamilyEnum.ARBITRATION;

  const isRefund = operationFamily === OperationFamilyEnum.REFUND;

  const isAmountHidden = operationDetails?.brutAmount === 0;

  const errorArbitration =
    operationDetails?.source?.plans?.length === 0 ||
    operationDetails?.destination?.plans?.length === 0;

  const errorRefund =
    operationDetails?.source?.plans?.length === 0 ||
    operationDetails?.destination?.payment === null;

  const isRetryActionPlaceholderVisible =
    !!error?.message ||
    (operationDetails?.statusCode !== OperationsHistoryStatusEnum.ANNULE &&
      ((isRefund && errorRefund) || (isArbitration && errorArbitration)));

  const { operationDetailsHeaderDetailList, operationDetailsHeaderAdvantageList } =
    useOperationDetailsHeaderData({
      operationDetails
    });

  const handleCancelOperation = useCallback(() => {
    navigation.navigate(RouteNames.OperationCancel);
  }, [navigation]);

  const handleModifyOperation = useCallback(() => {
    navigation.navigate(RouteNames.OperationUpdate);
  }, [navigation]);

  const renderLoader = useCallback(
    () => (
      <View style={styles.loaderContainer}>
        <CircleLoader diameter={36} />
        <LoaderPlaceholderView style={styles.loader} />
      </View>
    ),
    [styles]
  );

  const renderHeader = useCallback(() => {
    const { type, frame } = operationDetails?.details?.optionsBulletin ?? {};
    const hasTrueExternalPlan = findTrueExternalPlan(operationDetails?.destination?.plans);

    const helpPointTitleId =
      (hasTrueExternalPlan && label.header.help.title.external) ||
      (type && frame && frame && label.header.help.title[type]?.[frame]) ||
      (type && label.header.help.title[type]?.default) ||
      label.header.help.title.default;

    const helpPointContentId =
      (hasTrueExternalPlan && label.header.help.content.external) ||
      (type && frame && label.header.help.content[type]?.[frame]) ||
      (type && label.header.help.content[type]?.default) ||
      label.header.help.content.default;

    return (
      <NavigationHeader
        displayBackButton={true}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: helpPointContentId })}
        helpPointModalTitle={formatMessage({ id: helpPointTitleId })}
        helpPointPosition={'right'}
        onGoBack={() => navigation.goBack()}
        title={formatMessage({ id: label.header.title })}
      />
    );
  }, [formatMessage, getMessageRaw, navigation, operationDetails]);

  const renderOperationHeader = useCallback(() => {
    if (isLoading || !!error?.message || !operationDetails) return;
    const { brutAmount, creationDate, details, netAmount, statusCode, statusLabel } =
      operationDetails;

    const creationDateAsDate = !!creationDate ? new Date(creationDate) : undefined;

    const { type, frame } = details?.optionsBulletin ?? {};
    const labelId = type && frame && label.title[type]?.[frame];

    const originTitleLabelCode =
      (labelId && formatMessage({ id: labelId })) ||
      operationDetails?.label ||
      operationDetails?.type;

    return (
      <OperationsHeader
        advantageList={operationDetailsHeaderAdvantageList}
        amount={netAmount}
        creationDate={creationDateAsDate}
        details={operationDetailsHeaderDetailList}
        hideAmount={isAmountHidden}
        statusLabel={statusLabel}
        statusType={statusCode}
        title={originTitleLabelCode}
      />
    );
  }, [
    isLoading,
    error?.message,
    operationDetails,
    operationDetailsHeaderAdvantageList,
    operationDetailsHeaderDetailList,
    isAmountHidden,
    formatMessage
  ]);

  const renderBottomActions = useCallback(() => {
    if (!operationDetails?.cancelable || isLoading) return;

    const description = formatMessage({ id: 'RetirementJourneyOperationDetailsInfoText' });

    return (
      <View style={styles.bottomActions}>
        {!!description && (
          <Text
            style={styles.bottomActionsText}
            variant="t3"
            weight="light">
            {description}
          </Text>
        )}
        <View style={styles.buttonsContainer}>
          <SimpleButton
            design="outlined"
            onPress={handleCancelOperation}
            testId={locator._operation_details._cancel_button}
            title={formatMessage({ id: 'DetailOperationAnnulerBouton' })}
          />
          <SimpleButton
            design="solid"
            onPress={handleModifyOperation}
            testId={locator._operation_details._modify_button}
            title={formatMessage({ id: 'DetailOperationModifierBouton' })}
          />
        </View>
      </View>
    );
  }, [
    styles,
    formatMessage,
    operationDetails?.cancelable,
    isLoading,
    handleCancelOperation,
    handleModifyOperation
  ]);

  const renderContent = useCallback(() => {
    if (isRetryActionPlaceholderVisible)
      return <RetryActionPlaceHolder retryAction={() => onGetOperationDetails(operationId)} />;

    return <OperationDetailsContent />;
  }, [isRetryActionPlaceholderVisible, operationId, onGetOperationDetails]);

  useEffect(() => {
    onGetOperationDetails(operationId);
  }, [onGetOperationDetails, operationId]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.contentContainer}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderBottomActions}
        renderTopContent={renderOperationHeader}>
        {isLoading && renderLoader()}
        {!isLoading && !!operationFamily && renderContent()}
        {isDesktop && renderBottomActions?.()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
