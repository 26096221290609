import { FundSheetTabsEnum } from '@constants/savings';
import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { AccountsAction } from '@modules/dashboard/actions/dashboardActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { DownloadResultTypeEnum, FundsState } from '@modules/funds/types';

import { FundsAction, FundsActionsType } from './actions/fundsActionsTypes';
import { arrangeDocumentsFundList } from './utils';

export const initialState: FundsState = {
  funds: undefined,
  fundsPerformance: undefined,
  fundsDocumentsList: undefined,
  multiFundsOverviews: undefined,
  multiFundsPerformances: undefined,
  multiFundsRepartitionSupports: undefined,
  compareMode: false,
  selectedNavigation: '',
  innerNavigations: Object.values(FundSheetTabsEnum),
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  downloadResult: undefined
};

export default function FundsReducer(
  state: FundsState = initialState,
  action: FundsAction | AuthenticateAction | AccountsAction
): FundsState {
  switch (action?.type) {
    case FundsActionsType.GET_FUND_OVERVIEW_REQUEST:
    case FundsActionsType.GET_FUND_PERFORMANCE_REQUEST:
    case FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_REQUEST:
      return initialState;
    case FundsActionsType.GET_FUND_OVERVIEW_SUCCESS:
      return {
        ...state,
        funds: action.fundOverview,
        // add 3 tabs when the funds call fail the first time
        innerNavigations: action.fundOverview
          ? initialState.innerNavigations
          : [FundSheetTabsEnum.DOCUMENTS]
      };
    case FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_SUCCESS:
      return {
        ...state,
        fundsRepartitionSupport: action.fundRepartitionSupport
      };
    case FundsActionsType.GET_FUND_OVERVIEW_FAILURE:
      return {
        ...state,
        innerNavigations: [FundSheetTabsEnum.DOCUMENTS]
      };
    case FundsActionsType.GET_FUND_PERFORMANCE_SUCCESS:
      return {
        ...state,
        fundsPerformance: action.fundPerformance,
        funds: state.funds
          ? {
              ...state.funds,
              sharePriceHisto: action.fundPerformance?.sharePriceHisto
            }
          : undefined
      };
    case FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        fundsDocumentsList: arrangeDocumentsFundList({
          list: action.fundsDocumentsList,
          typeOrderList: action.fundDocTypeOrderList || []
        })
      };

    case FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_SUCCESS:
      return {
        ...state,
        multiFundsOverviews: action.multiFundsOverviews
      };
    case FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_SUCCESS:
      return {
        ...state,
        multiFundsPerformances: action.multiFundsPerformances
      };
    case FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS:
      return {
        ...state,
        multiFundsRepartitionSupports: action.multiFundsRepartitionSupports
      };
    case FundsActionsType.SET_COMPARE_MODE:
      return {
        ...state,
        compareMode: action.compareMode
      };

    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        downloadResult: undefined,
        error: initialState.error
      };
    case FundsActionsType.POST_DOWNLOAD_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST:
      return {
        ...state,
        downloadResult: undefined
      };
    case FundsActionsType.POST_DOWNLOAD_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS:
      return {
        ...state,
        downloadResult: DownloadResultTypeEnum.SUCCESS
      };
    case FundsActionsType.POST_DOWNLOAD_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE:
      return {
        ...state,
        downloadResult:
          action.isNativeProblem === true
            ? DownloadResultTypeEnum.TECHNICAL_ERROR
            : DownloadResultTypeEnum.API_ERROR
      };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
