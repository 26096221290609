import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';

import {
  CardWithLeftThread,
  ManagementWrapper,
  useTheme,
  useTranslation
} from '@components/ERE-UILib';
import { ProfitSharingIncentiveAmountFieldModalConnectedRefType } from '@components/ERE360Components';
import { SavingRepartitionCardWithFundSheet } from '@components/templates';
import { RouteNames } from '@constants/navigation';
import { PlanFamillyList } from '@ere-uilib/constants';
import { PlanCompartmentsType } from '@modules/common/types';
import {
  ProfitSharingIncentiveAmountValidationParamsType,
  ProfitSharingIncentiveInitDataState
} from '@modules/profit-sharing-incentive/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { RootNavigation } from '@navigation/RootNavigation';
import {
  CompartmentCardHeader,
  CompartmentCardRepartition,
  ExpandableProvider
} from '@pages/ProfitSharingIncentive/components';
import { useProfitSharringRepartitionContext } from '@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context';
import { label } from '@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/label';
import { useStyles } from '@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/styles';
import { getTechnicalFund } from '@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/utils';
import { roundNumber } from '@utils/numberRound';

type PlansListProps = {
  plans?: PlanCompartmentsType[];
  initData: ProfitSharingIncentiveInitDataState;
  setProfitSharingIncentiveRepartitionAmount: (
    params: ProfitSharingIncentiveAmountValidationParamsType
  ) => void;
  isContributionError?: boolean;
  navigation: StackNavigationProp<AppNavigatorInterface>;
  amountModalRef: React.RefObject<ProfitSharingIncentiveAmountFieldModalConnectedRefType>;
};

const PlansList: React.FC<PlansListProps> = ({
  plans,
  amountModalRef,
  navigation,
  initData,
  setProfitSharingIncentiveRepartitionAmount,
  isContributionError
}) => {
  const {
    hasStartedInvest,
    remainingInvestedPercent,
    remainingInvestedAmount,
    isPercentage,
    isformFilled,
    isRealExternalPlan
  } = useProfitSharringRepartitionContext();
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();

  const linkUnderfieldLabel = formatMessage({
    id: label.plan.link[hasStartedInvest ? 'amountRemaining' : 'amountAll']
  });

  const handleLinkButtonPress = useCallback(() => {
    RootNavigation.replace(RouteNames.RedirectOperationPage, {
      provider: PlanFamillyList.ES,
      operationType: initData?.type || ''
    });
  }, [initData]);

  return (
    <>
      {plans?.map((plan, planIndex) => {
        const planColors = theme.colors.basics.primary;
        const planId = plan?.planId;
        const contributionPotentiel =
          (plan.advancedProperties.ContributionLimit || 0) -
          (plan.advancedProperties.ContributionConsumed || 0);
        const isContributionPotentiel = contributionPotentiel > 0;
        const isMergedExternal = !!plan?.advancedProperties?.IsMergedExternal;
        const HasSimulation = !!plan?.advancedProperties.HasSimulation;

        const isContributionDisplayed =
          isMergedExternal || (plan?.advancedProperties.HasContribution && HasSimulation);

        const technicalFund = getTechnicalFund(plan);

        const technicalFundIdSet = {
          planId,
          compartmentCode: technicalFund?.compartmentCode || '',
          managementId: technicalFund?.managementId || '',
          fundId: technicalFund?.supportIsin || ''
        };

        const remainingTechnicalFundInvestedPercent =
          remainingInvestedPercent + (technicalFund?.formValues?.amountPercent || 0);
        const remainingTechnicalFundInvestedAmount =
          remainingInvestedAmount + (technicalFund?.formValues?.amount || 0);
        const planTitle = plan.isCustomPlan
          ? plan.planFullName
          : `${formatMessage({ id: label.plan.name })} ${plan?.shortLabel}`;
        const allAmountValue = isPercentage
          ? remainingTechnicalFundInvestedPercent
          : remainingTechnicalFundInvestedAmount;

        const renderCompartmentCard = (areCompartmentsExpanded = true) => (
          <CardWithLeftThread
            addShadow
            backgroundColor={planColors?.c500}
            borderLeftColor={planColors?.c500}
            containerStyle={styles.planCardContainer}
            key={planIndex + planId}>
            <CompartmentCardHeader
              backGroundColorCustom={planColors?.c500}
              contributionEstimation={plan.formValues?.contributionAmount}
              contributionPotentiel={contributionPotentiel}
              dispositifTitle={planTitle}
              editable={isMergedExternal || isRealExternalPlan(plan)}
              fieldAmount={technicalFund?.formValues?.amount}
              fieldPercent={technicalFund?.formValues?.amountPercent}
              hasDarkBackground
              isContributionDisplayed={isContributionDisplayed}
              isFieldAmountPercentage={isPercentage}
              isLinkUnderField={!isformFilled}
              isPercentagePlusAmount
              linkUnderfieldLabel={linkUnderfieldLabel}
              onEditAmountPress={() => {
                const amountParameters = isPercentage
                  ? {
                      reinvestablePercentage: remainingTechnicalFundInvestedPercent,
                      maxPercent: remainingTechnicalFundInvestedPercent
                    }
                  : {
                      reInvestAmount: remainingTechnicalFundInvestedAmount
                    };
                if (allAmountValue > 0) {
                  amountModalRef?.current?.openWithData({
                    displayData: {
                      title: planTitle,
                      initialAmount: isPercentage
                        ? technicalFund?.formValues?.amountPercent || 0
                        : technicalFund?.formValues?.amount || 0,
                      amountParameters: amountParameters,
                      allAmountValue: allAmountValue,
                      allAmountLabel: linkUnderfieldLabel
                    },
                    isPercentage,
                    ...technicalFundIdSet
                  });
                }
              }}
              onLinkUnderFieldPress={() => {
                setProfitSharingIncentiveRepartitionAmount({
                  amount: remainingTechnicalFundInvestedAmount,
                  amountPercent: remainingTechnicalFundInvestedPercent,
                  contribution: null,
                  ...technicalFundIdSet
                });
              }}
              onResetAmountPress={() => {
                setProfitSharingIncentiveRepartitionAmount({
                  amount: 0,
                  amountPercent: 0,
                  contribution: null,
                  ...technicalFundIdSet
                });
              }}
              underFieldLinkStyle={styles.planUnderFieldLink}
            />
            {isMergedExternal && <CompartmentCardRepartition />}
            {areCompartmentsExpanded &&
              !isRealExternalPlan(plan) &&
              plan?.compartments.map(compartment => {
                const compartmentId = compartment.code;
                const isMultipleManagements = compartment.managements.length > 1;
                return compartment.managements.map((management, managementIndex) => {
                  if (management?.advancedProperties?.IsTechnicalManagement) return;

                  const managementId = management.id;
                  const masterSupport = management.supports.find(support => support.isMaster);
                  const masterSupportAmount: number = masterSupport?.formValues?.amountPercent || 0;
                  const fieldAmountpercent = !!masterSupport
                    ? masterSupportAmount
                    : management?.investedFormValues?.amountPercent;
                  const managementContribution = !!masterSupport
                    ? masterSupport.formValues?.contributionAmount
                    : management?.formValues?.contributionAmount;

                  const isVisibleManagementHeader = isMultipleManagements;
                  const isFreeManagement = management.isFree;
                  const isExpandable = isVisibleManagementHeader && !isMergedExternal;
                  const isFundsDisabled = isMergedExternal;
                  const isSupportDisabled = isMergedExternal || !isFreeManagement;
                  const isSupportEditable = !isMergedExternal && management.isFree;
                  const isManagementHasContribution =
                    isContributionPotentiel &&
                    management.advancedProperties.HasContribution &&
                    HasSimulation;
                  const isDisplayingExtraManagement = !isMergedExternal && !isFreeManagement;
                  const managementAllAmountValue = isPercentage
                    ? remainingInvestedPercent + (management.formValues?.amountPercent || 0)
                    : roundNumber(remainingInvestedAmount + (management.formValues?.amount || 0));

                  const remainingManagementInvestedPercent =
                    remainingInvestedPercent + (fieldAmountpercent || 0);

                  const fieldAmountPercent = isMergedExternal
                    ? management?.advancedProperties?.PourcentageRepartition
                    : management?.formValues?.amountPercent;

                  return (
                    <ManagementWrapper
                      backGroundColorCustom={planColors?.c100}
                      contribution={managementContribution}
                      fieldAmount={management.formValues?.amount}
                      fieldAmountPercent={fieldAmountPercent}
                      hasIncorrectDrivingData={
                        management.advancedProperties.HasIncorrectDrivingData
                      }
                      isContributionDisplayed={isManagementHasContribution}
                      isContributionError={isContributionError}
                      isContributionLast
                      isDisplayingExtraManagement={isDisplayingExtraManagement}
                      isExpandable={isExpandable}
                      isExtraManagementEditable={!isFreeManagement}
                      isFieldAmountPercentage={isPercentage}
                      isFieldPercentagePlusAmount
                      isFundsDisabled={isFundsDisabled}
                      isLinkUnderField={!isformFilled}
                      key={managementIndex + management.id}
                      linkUnderfieldLabel={linkUnderfieldLabel}
                      onEditMasterAmountPress={() => {
                        const initialAmount = isPercentage
                          ? technicalFund?.formValues?.amountPercent || 0
                          : technicalFund?.formValues?.amount || 0;
                        const amountParameters = isPercentage
                          ? {
                              reinvestablePercentage: managementAllAmountValue,
                              maxPercent: managementAllAmountValue
                            }
                          : {
                              reInvestAmount: managementAllAmountValue
                            };
                        if (managementAllAmountValue > 0) {
                          amountModalRef?.current?.openWithData({
                            displayData: {
                              title: planTitle,
                              subTitle: isRealExternalPlan(plan) ? undefined : management.label,
                              initialAmount,
                              amountParameters,
                              allAmountValue: managementAllAmountValue,
                              allAmountLabel: linkUnderfieldLabel,
                              isContributionActive: isManagementHasContribution
                            },
                            isPercentage: isPercentage,
                            planId: plan.planId,
                            compartmentCode: compartment.code,
                            managementId: managementId,
                            isManagementDirectContributionAmounts: !masterSupport,
                            isManagementDirectAmounts: !masterSupport
                          });
                        }
                      }}
                      onLinkButtonPress={handleLinkButtonPress}
                      onLinkUnderFieldPress={() => {
                        setProfitSharingIncentiveRepartitionAmount({
                          planId,
                          compartmentCode: compartmentId || '',
                          managementId: managementId,
                          fundId: masterSupport?.supportIsin,
                          amount: remainingInvestedAmount,
                          amountPercent: remainingManagementInvestedPercent,
                          contribution: null
                        });
                      }}
                      onResetAmountPress={() => {
                        setProfitSharingIncentiveRepartitionAmount({
                          planId,
                          compartmentCode: compartmentId || '',
                          managementId: managementId,
                          fundId: masterSupport?.supportIsin,
                          amount: 0,
                          amountPercent: 0,
                          contribution: null
                        });
                      }}
                      operationType={initData?.type}
                      title={management.label}
                      totalAmount={management.totalAmount}
                      visibleHeader={isVisibleManagementHeader}
                      visibleInvestedAmount>
                      {management.supports.map((support, supportIndex) => {
                        const remainingSupportInvestedPercent =
                          remainingInvestedPercent + (support.formValues?.amountPercent || 0);
                        const remainingSupportAmount = roundNumber(
                          remainingInvestedAmount + (support.formValues?.amount || 0)
                        );
                        const fieldPercent = isMergedExternal
                          ? support?.advancedProperties?.PourcentageRepartition
                          : support?.formValues?.amountPercent;

                        if (support?.advancedProperties?.IsTechnicalFund) return;

                        const isSupportContributionDisplayed =
                          isContributionPotentiel && support.hasContribution;
                        return (
                          <SavingRepartitionCardWithFundSheet
                            contribution={support.formValues?.contributionAmount}
                            disabled={isSupportDisabled}
                            editable={isSupportEditable}
                            fieldAmount={support.formValues?.amount}
                            fieldPercent={fieldPercent}
                            hasNoFundSheet={support?.hasNoFundSheet}
                            investedAmount={support.amount?.amount}
                            isContributionDisplayed={isSupportContributionDisplayed}
                            isContributionError={isContributionError}
                            isContributionLast
                            isFieldAmountPercentage={isPercentage}
                            isFieldPercentagePlusAmount
                            isLinkUnderField={!isformFilled}
                            isRiskLevelSRI={!!support.riskLevelSRI}
                            key={supportIndex + support.supportIsin}
                            legalStatus={support.legalStatus}
                            linkUnderfieldLabel={linkUnderfieldLabel}
                            navigation={navigation}
                            onEditAmountPress={() => {
                              const initialAmount = isPercentage
                                ? technicalFund?.formValues?.amountPercent || 0
                                : technicalFund?.formValues?.amount || 0;
                              const supportAmountParameters = isPercentage
                                ? {
                                    reinvestablePercentage: remainingSupportInvestedPercent,
                                    maxPercent: remainingSupportInvestedPercent
                                  }
                                : {
                                    reInvestAmount: remainingSupportAmount
                                  };
                              const allAmountValue = isPercentage
                                ? remainingSupportInvestedPercent
                                : remainingSupportAmount;
                              if (allAmountValue > 0) {
                                amountModalRef?.current?.openWithData({
                                  displayData: {
                                    title: planTitle,
                                    subTitle: isRealExternalPlan(plan)
                                      ? undefined
                                      : support.supportName,
                                    initialAmount: initialAmount,
                                    amountParameters: supportAmountParameters,
                                    allAmountValue: allAmountValue,
                                    allAmountLabel: linkUnderfieldLabel,
                                    isContributionActive:
                                      isSupportContributionDisplayed && HasSimulation
                                  },
                                  isPercentage: isPercentage,
                                  planId: plan.planId,
                                  compartmentCode: compartment.code,
                                  managementId: managementId,
                                  fundId: support.supportIsin
                                });
                              }
                            }}
                            onLinkUnderFieldPress={() => {
                              setProfitSharingIncentiveRepartitionAmount({
                                planId,
                                compartmentCode: compartmentId || '',
                                managementId: managementId,
                                fundId: support.supportIsin,
                                amount: remainingSupportAmount,
                                amountPercent: remainingSupportInvestedPercent,
                                contribution: null
                              });
                            }}
                            onResetAmountPress={() => {
                              setProfitSharingIncentiveRepartitionAmount({
                                planId,
                                compartmentCode: compartmentId || '',
                                managementId: managementId,
                                fundId: support.supportIsin,
                                amount: 0,
                                amountPercent: 0,
                                contribution: null
                              });
                            }}
                            riskLevel={support.riskLevelSRI || support.riskLevelSRRI}
                            source={support?.source}
                            supportIsin={support.supportIsin}
                            supportName={support.supportName}
                            testId={support.supportName}
                          />
                        );
                      })}
                    </ManagementWrapper>
                  );
                });
              })}
          </CardWithLeftThread>
        );

        return (
          <ExpandableProvider isDefaultExpanded={!isMergedExternal}>
            {areCompartmentsExpanded => renderCompartmentCard(areCompartmentsExpanded)}
          </ExpandableProvider>
        );
      })}
    </>
  );
};

export default PlansList;
