import React from 'react';
import { View } from 'react-native';

import { Title, Text, HtmlRenderer } from '@components/index';

import { useStyles } from './useStyles';
import { ContactCard } from '../ContactCard';

interface Props {
  title: string;
  subTitle: string;
  address: string;
  testId: string;
}

export const CourierCardContent: React.FC<Props> = ({ title, subTitle, address, testId }) => {
  const styles = useStyles();

  return (
    <ContactCard>
      <View>
        <Title
          aria-level={3}
          role="heading"
          style={styles.textCenteredStyle}
          testID={testId}
          variant={'t6'}
          weight="bold">
          {title}
        </Title>
        <Text
          style={[styles.textCenteredStyle, styles.textDescriptionStyle]}
          variant={'t3'}
          weight={'light'}>
          {subTitle}
        </Text>
      </View>
      <View style={styles.separator} />
      <HtmlRenderer html={address} />
    </ContactCard>
  );
};
