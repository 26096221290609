import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        copyIconContainer: {
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: theme.metrics.spacing.s
        },
        copyIcon: {
          minHeight: 20,
          width: 20,
          height: 20
        },
        toolTip: {
          position: 'absolute',
          top: 25,
          textAlign: 'center',
          minWidth: 70
        }
      }),
    [theme]
  );

  return styles;
}
