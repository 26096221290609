import React, { useCallback } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { SynthesisFundsRow, SynthesisManagementRow } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './styles';
import { SimpleCardProps, ThresholdDataProps } from './types';

export function FiletStepperSimpleCard({
  title,
  index,
  amount,
  headerColor,
  hideManagementAmount,
  isFlowReallocation,
  subCards,
  hideManagementRow,
  percentage,
  hasContribution,
  contribution,
  showMoreSupportData,
  conditionalOrder,
  statusCode,
  renderTriggerTreshold
}: SimpleCardProps) {
  const { formatMessage } = useTranslation();

  const styles = useStyles();

  const onTriggerThresholdRender = useCallback(
    (data: ThresholdDataProps) => {
      if (!renderTriggerTreshold) return;

      return renderTriggerTreshold(data);
    },
    [renderTriggerTreshold]
  );

  const renderEcheanceValue = useCallback(
    (echeanceValue: string | undefined) => {
      if (!echeanceValue) return null;

      return (
        <View style={styles.echeanceContainerRow}>
          <Text
            variant="t3"
            weight="light">
            {`${formatMessage({ id: 'MaturityArbitrageFeatureChosenMaturity' })}`}
          </Text>
          <Text
            variant="t3"
            weight="light">
            {echeanceValue}
          </Text>
        </View>
      );
    },
    [formatMessage]
  );
  return (
    <React.Fragment key={`synthesis_row_${index}`}>
      {!hideManagementRow && (
        <SynthesisManagementRow
          amount={!isFlowReallocation ? amount : undefined}
          containerStyle={{
            backgroundColor: headerColor
          }}
          contribution={contribution}
          hasContribution={hasContribution}
          hideManagementAmount={hideManagementAmount}
          hideManagementOnlyAmount={isFlowReallocation}
          percentage={percentage}
          title={title}
        />
      )}
      {subCards?.map((subCard, i) => {
        return (
          <React.Fragment key={`synthesis_fund_row_${i}`}>
            <SynthesisFundsRow
              amount={!isFlowReallocation ? subCard.amount : undefined}
              availabilityItems={subCard.availabilities}
              conditionalOrder={conditionalOrder}
              contribution={subCard.contribution}
              hasContribution={subCard.hasContribution}
              key={i}
              netAssetValue={subCard.netAssetValue}
              numberOfUnits={subCard.numberOfUnits}
              onTitlePress={subCard.onPress}
              percentage={subCard.percentage}
              showMoreSupportData={showMoreSupportData}
              statusCode={statusCode}
              title={subCard.title}
            />
            {subCard.triggerThreshold && onTriggerThresholdRender(subCard.triggerThreshold)}
            {renderEcheanceValue(subCard.echeanceValue)}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
