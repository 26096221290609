import { ReactChild, ReactChildren } from 'react';

import { AllocationsEnum } from '@constants/arbitrations';
import { InstallmentDecisionPropertiesEnum } from '@constants/installment';
import { RefundDecisionPropertiesEnum } from '@constants/refunds';
import { BankAccountInfoType } from '@ere-uilib/index';
import { ArbitrationDecisionPropertiesEnum } from '@modules/arbitration/constants';
import { DueDateOption } from '@modules/arbitration/types';
import { TriggerThresholdProperties } from '@modules/refund/available-refund/types';
import {
  RefundUnavailabilityDecisionTreeType,
  UnavailableRefundDispositifItemState
} from '@modules/refund/unavailable-refund/types';
import { DueDateProps } from '@pages/Arbitration/components/Support/components/DueDateCard/interface';

export interface OptionsState {
  name: string;
  value: string;
  labelCode: string;
}

export type StandardPropertyFilterState = { name: string; value: boolean | string };

export type StandardAdvancedPropertiesState = {
  HasRepaymentOrArbitrationTransactionInProgress: boolean;
};

export interface AddressDetail {
  additionalAddress_1: string | null;
  additionalAddress_2: string | null;
  additionalAddress_3: string | null;
  street: string | null;
  country: string | null;
  zipcode: string | null;
  city: string | null;
  countryCode?: string | null;
  recipient: string | null;
}

export type ResponseType = {
  descriptionLabel?: string;
  selectedOption?: OptionsState;
  respondedValue?: string;
  respondedTitle?: string;
  title?: string;
  value?: string;
  text?: string;
  respondedPropertyFilter?: StandardPropertyFilterState;
  propertyFilter?: StandardPropertyFilterState;
  advancedProperties?: StandardAdvancedPropertiesState;
  options?: OptionsState[];
  type?: string;
  amount?: number;
  headerAmount?: number;
  renderCustomResponses?: () => ReactChild | ReactChildren;
  renderInnerResponse?: () => ReactChild | ReactChildren;
  isRenderInnerResponse?: boolean;
  isRenderChartResponse?: boolean;
  icon?: string;
  progress?: number;
  chartItems?: {
    value: number;
    title: string;
    key: AllocationsEnum;
  }[];
  disabled?: boolean;
  disabledMessage?: string;
  validationButtonTitle?: string;
  validationButtonLarge?: boolean;
  data?: RefundUnavailabilityDecisionTreeType | UnavailableRefundDispositifItemState[] | null;
};

export type SaverDetailsType = {
  fullAddress: AddressDetail;
  bankAccountDetail?: BankAccountInfoType | null;
  creditCardPaymentReferenceDetail?: string | null;
  type?: string | null;
};

export type QuestionsState = {
  implicitResponseEnabled?: boolean;
  key?: string;
  title: string;
  respondedTitle?: string;
  description?: string;
  text: string;
  isRequired?: boolean;
  tooltip: string;
  color?: string;
  responded?: ResponseType | undefined;
  showEligibility: boolean;
  responses: ResponseType[];
  canNotBeAsked?: boolean;
  type?: string;
  displayType?: string;
  titleNonFormatted?: boolean;
  saverDetails?: SaverDetailsType;
};

export interface BankAccountDetails {
  domiciliation: string | null;
  titulaire: string | null;
  rum: string;
  ics: string;
  iban: string;
  bic: string;
  type: string;
}

export interface BankInformationsState {
  fullAddress: AddressDetail;
  bankAccountDetail: BankAccountDetails;
}

export type AribitrationFilterPropertiesType = {
  FlowReallocation: boolean;
  PartialReallocation: boolean;
  StockReallocation: boolean;
  ThresholdTrigger: boolean;
};

export type RefundRepartitionDataSupportFilterType = undefined | 'Invested' | 'Responsible';

export type FilterPropertiesType = {
  [InstallmentDecisionPropertiesEnum.FLOW_REALLOCATION]?: boolean;
  [InstallmentDecisionPropertiesEnum.PARTIAL_REALLOCATION]?: boolean;
  [InstallmentDecisionPropertiesEnum.STOCK_REALLOCATION]?: boolean;
  [InstallmentDecisionPropertiesEnum.THRESHOLD_TRIGGER]?: boolean;
  [InstallmentDecisionPropertiesEnum.RETIREMENT_ONLY]?: boolean;
  [InstallmentDecisionPropertiesEnum.TAX_OUT]?: boolean;
  [InstallmentDecisionPropertiesEnum.HIDE_INFORMATION_VV_INF_25]?: boolean;
  [InstallmentDecisionPropertiesEnum.TAX_IN]?: boolean;
  [InstallmentDecisionPropertiesEnum.ABONDEMENT]?: boolean;
  [InstallmentDecisionPropertiesEnum.VVL]?: boolean;
  [InstallmentDecisionPropertiesEnum.IMMEDIATE_ASSET]?: boolean;
  [InstallmentDecisionPropertiesEnum.IMMEDIATE_PENSION]?: boolean;
  // [InstallmentDecisionPropertiesEnum.VVP]?: InstallmentDecisionVVPFrequencyEnum | boolean
  [InstallmentDecisionPropertiesEnum.VVP]?: any; // @TODO: mutualise VVp property
  [InstallmentDecisionPropertiesEnum.COMPANY_CONTRIBUTION]?: boolean;
  [InstallmentDecisionPropertiesEnum.SUPPORT_FILTER]?: RefundRepartitionDataSupportFilterType;
  [InstallmentDecisionPropertiesEnum.INVESTED]?: boolean;
  [InstallmentDecisionPropertiesEnum.CONTRIBUTION]?: boolean;
  [InstallmentDecisionPropertiesEnum.OAD_REALLOCATION]?: boolean;

  // Arbitration decision
  [ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION]?: boolean;
  [ArbitrationDecisionPropertiesEnum.PLAN_ID]?: string;
  [ArbitrationDecisionPropertiesEnum.COMPARTMENT_CODE]?: string;

  // Refund indispo
  [RefundDecisionPropertiesEnum.REPAYMENT_UNAVAILABLE]?: boolean;
  [RefundDecisionPropertiesEnum.REPAYMENT_AVAILABLE]?: boolean;
}; // @TODO: prepare filterProperties Object type for refund as it will be different from installmentOne

export interface AdvancedProperties {
  EarlyReleaseAmount?: number;
  AvailableAmount?: number;
  ImmediateAmount?: number;
  HasIncorrectDrivingData?: boolean;
  HasThresholdTrigger?: boolean;
  ThresholdTriggerValidityDate?: string;
  ThresholdTriggerValidityDuration?: number;
  CategorieClassification?: string;
  HasContribution?: boolean;
  HasSimulation?: boolean;
  ContributionConsumed?: number;
  ContributionLimit?: number;
  DefaultAmount?: number;
  OadReallocationAmount?: number;
  HasDrivenSavings?: boolean;
  AmountNet?: number;
  ContributionAmountBrut?: number;
  ContributionAmountNet?: number;
  AvailabilityDate?: string;
  IsAvailable?: boolean;
  AvailabilityLabel?: string;
  HasRepaymentOrArbitrationTransactionInProgress?: boolean;
  ThresholdTriggerType?: string;
  ThresholdTriggerTitle?: string;
  ThresholdTriggerValueDate?: string;
  ThresholdTriggerValueAmount?: number;
  Offres?: string;
  PourcentageRepartition?: number;
  IsMergedExternal?: boolean;
  IsExternal?: boolean;
  IsTechnicalFund?: boolean;
  IsTechnicalManagement?: boolean;
  Availabilities?: AvailabilityItem[];
}
export type AvailabilityItem = {
  numberOfUnits: number;
  datetime?: string;
  useAvailabilityLabel?: boolean;
  availabilityLabel: string;
  amount?: number;
};

export type DispositifState = {
  id: string;
  name: string;
  encours: number;
  filterProperties: FilterPropertiesType;
  hasOnGoingRcVvp: boolean;
  planFamily: string;
  advancedProperties: AdvancedProperties;
  blocagesCodes?: string[];
  isEligible?: boolean;
  isCustom?: boolean;
};

export interface OperationDecisionState {
  availableRepaymentBlockages?: string[];
  unavailableRepaymentBlockages?: string[];
  canPerformAvailableRepayment?: boolean;
  canPerformUnavailableRepayment?: boolean;
  questions: QuestionsState[];
  dispositifs: DispositifState[];
  elligibleDispositifs: DispositifState[];
  selectedDispositif?: DispositifState[];
  selectedCompartment?: CompartmentType;
  saverDetails?: BankInformationsState | null;
  planCompartments?: PlanCompartmentsType;
  isVisibleSubmitModal?: boolean;
  divestedManagementSource?: DivestedManagementSource;
}

export interface DivestedManagementSource {
  managementId: string | undefined;
  supportIsinCode: string | undefined;
  amount: number | undefined;
  executionTriggerValue?: number | undefined;
  dueDateOption?: DueDateOption | undefined;
}

export type PlanType = {
  planName: string;
  totalInvestedAmount: number;
  totalNumberOfUnits: number;
  hasContribution: boolean;
  hasVersement: boolean;
  hasInteressement: boolean;
  hasParticipation: boolean;
};

export type AmountType = {
  amount: number;
  addedValue: number;
  numberOfUnits: number;
};

export type NetAssetValueType = {
  amount: number;
  currency: string;
  dateValue: string;
};

export type NextAvailabilityType = {
  amount: number;
  datetime: string;
};

interface SupportFormValues {
  amount?: number;
  isSelected?: boolean;
  isDisabled?: boolean;
  amountPercent?: number;
  contributionAmount?: number;
  triggerThreshold?: TriggerThresholdProperties;
  dueDateOption?: DueDateOption;
}

export interface ManagementFormValues {
  amount?: number;
  amountPercent?: number;
  contributionAmount?: number;
  isSelected?: boolean;
  selectedMasterSupportId?: string;
}
export interface CompartmentFormValues {
  amount?: number;
  amountPercent?: number;
  contributionAmount?: number;
  isSelected?: boolean;
  selectedMasterSupportId?: string;
}
export interface PlanFormValues {
  amount?: number;
  amountPercent?: number;
  contributionAmount?: number;
  isSelected?: boolean;
  selectedMasterSupportId?: string;
}

export type SupportType = {
  supportIsin: string;
  supportName: string;
  supportType: string;
  supportIdNoee: string;
  riskLevelSRRI: string;
  riskLevelSRI: string;
  performanceValue: number;
  amount: AmountType;
  supportDetailsUrl: string | null;
  legalStatus: string;
  isMaster: boolean;
  hasContribution: boolean;
  hasNoFundSheet: boolean;
  source: string;
  netAssetValue: NetAssetValueType;
  plans: PlanType[] | null;
  hasVersement: boolean;
  hasInteressement: boolean;
  hasParticipation: boolean;
  nextNavDate: string;
  advancedProperties: AdvancedProperties;
  isISR: boolean;
  formValues?: SupportFormValues;
  filterProperties?: FilterPropertiesType;
  investedFormValues?: SupportFormValues;
  rate: number | null;
  schedules?: DueDateProps[] | undefined;
};

export interface ReallocationDivestmentState {
  planId: number;
  planName: string;
  planType: string;
  compartments: CompartmentType[];
  filterProperties: FilterPropertiesType;
  advancedProperties: AdvancedProperties;
  colors: string[];
  planFamily: string;
  label: string;
  shortLabel: string;
  horizon: string;
  formValues?: PlanFormValues;
  isCustomPlan: boolean;
  planFullName: string;
}

export type ManagmentType = {
  id: string;
  code: string;
  label: string;
  totalAmount: number;
  supports: SupportType[];
  order: number;
  isFree: boolean;
  filterProperties: FilterPropertiesType;
  advancedProperties: AdvancedProperties;
  formValues?: ManagementFormValues;
  investedFormValues?: ManagementFormValues;
  isFictive: boolean;
};

export type CompartmentType = {
  code?: string | undefined;
  descriptionLabel?: string;
  label?: string | undefined | null;
  managements: ManagmentType[];
  order?: number | undefined;
  isFictive?: boolean;
  formValues?: CompartmentFormValues;
  filterProperties?: FilterPropertiesType;
  advancedProperties?: AdvancedProperties;
  totalAmount?: number;
};

export type PlanCompartmentsType = {
  planId: number;
  planName: string;
  planFullName: string;
  planType: string;
  compartments: CompartmentType[];
  filterProperties: FilterPropertiesType;
  advancedProperties: AdvancedProperties;
  colors: string[];
  planFamily: string;
  label: string;
  shortLabel: string;
  horizon: string;
  formValues?: PlanFormValues;
  isCustomPlan: boolean;
};

export interface FileData {
  name: string;
  type: string;
  size: number;
  base64: string;
}

export enum PlatformOS {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
  NATIVE = 'native'
}
