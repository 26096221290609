import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@components/index';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        contentContainer: {
          alignSelf: 'center',
          width: '100%',
          maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth
        },
        loaderContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: theme?.metrics.spacing.xs
        },
        loader: {
          flex: 1,
          marginLeft: theme?.metrics.spacing.xm
        },
        bottomActions: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: theme.metrics.spacing.xm,
          ...(isMobile && {
            flexWrap: 'wrap'
          })
        },
        bottomActionsButtonContainer: {
          flexGrow: 1
        },
        contributionLoadingLayer: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0.7
        },
        modalDescriptionPargraphStyle: {
          marginBottom: theme.metrics.spacing.l
        },
        bottomActionsText: {
          textAlign: 'center',
          padding: theme.metrics.spacing.s
        },
        buttonsContainer: {
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: theme.metrics.spacing.m,
          flex: 1,
          justifyContent: 'center'
        }
      }),
    [theme, isMobile]
  );
  return styles;
};
