import { connect } from 'react-redux';

import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { getEditorialContentRequest } from '@modules/profile/actions/profileActions';
import { getEditorialContent } from '@modules/profile/selectors';
import { getProfitSharingIncentiveInitDataRequest } from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import { ProfitSharingIncentiveActionsType } from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
import { getProfitSharingIncentiveInitDataState } from '@modules/profit-sharing-incentive/selectors';
import { AppState } from '@modules/reducers';

import { ProfitSharingIncentiveDescriptionPageComponent } from './ProfitSharingIncentiveDescription.component';

const mapStateToProps = (state: AppState) => {
  return {
    isLoading:
        state.isLoading[
            ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST
            ],
    isLoadingCampaignData:
        state.isLoading[DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_REQUEST],
    error:
        state.error[
            ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST
            ],
    errorCampaignData:
        state.error[DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE],
    initData: getProfitSharingIncentiveInitDataState(state),
    campaignData: getEditorialContent(state)
  };
};

const mapDispatchToProps = {
  getProfitSharingIncentiveInitData: getProfitSharingIncentiveInitDataRequest,
  getProfitSharingIncentiveCompaignData: getEditorialContentRequest
};

export const ProfitSharingIncentiveDescription = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfitSharingIncentiveDescriptionPageComponent);
