import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  useScreenSizes,
  DashboardPageWrapperConnected,
  Paragraph,
  useTranslation,
  Title,
  useTheme,
  NavigationHeader,
  NotificationHard,
  ColumnsContainer,
  NotificationIconTypeEnum,
  PhoneFieldCheck,
  SimpleButton,
  PhoneFieldCheckOnChangeParamsInterface,
  InformativePopin,
  IconEnum,
} from '@components/index';

import { useStyles } from './styles';

import { RouteNames } from '@constants/navigation';
import { UserInfoTypeEnum } from '@constants/profile';

import { ProfileEditPhonePageProps } from './interfaces';
import { changeEmailOrPasswordErrorKinds } from '../constants';
import { locator } from '@constants/locator';

export const ProfileEditPhonePageComponent: React.FC<React.PropsWithChildren<ProfileEditPhonePageProps>> = ({
  profileUserInfos,
  navigation,
  oncheckEmailEdit,
  editPhoneNumberIsEnable,
  error,
  otpError,
  onSetProfileUserInfoChange,
}) => {
  useEffect(() => {
    oncheckEmailEdit()
  }, [oncheckEmailEdit])
  const { formatMessage } = useTranslation();

  const { isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const { styles } = useStyles();

  const [isValidFormat, setIsValidFormat] = useState<boolean>(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
  const has24hBlockingError = error?.code === changeEmailOrPasswordErrorKinds.ERR_PHO_02;
  const hasServerError = (otpError?.innerMessage !== '' || error.message !== '') && !has24hBlockingError
  const [showServerErrorPopin, setShowServerErrorPopin] = useState(hasServerError)

  useEffect(() => {
    setShowServerErrorPopin(hasServerError)
  }, [hasServerError])

  const userCurrentPhone = useMemo(() => (
    profileUserInfos?.phone || ''
  ), [profileUserInfos]);

  const isInputEnabled = useMemo(() => {
    return editPhoneNumberIsEnable && !has24hBlockingError;
  }, [
    editPhoneNumberIsEnable,
    has24hBlockingError,
  ])

  const isButtonDisabled = useMemo(() => {
    return isValidFormat && isInputEnabled
  }, [
    isInputEnabled,
    isValidFormat
  ]);

  const handlePhoneFieldChange = useCallback(({
    isValid,
    internationalPhoneNumber,
  }: PhoneFieldCheckOnChangeParamsInterface) => {
    setIsValidFormat(isValid);
    setNewPhoneNumber(internationalPhoneNumber!);
  }, []);

  const RenderHeader = useCallback(() => {
    return (
      <NavigationHeader
        displayBackButton={true}
        onGoBack={() => navigation.replace(
          RouteNames.BottomTabNavigator,
          {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.EditProfileInformations,
            },
          }
        )}
        title={formatMessage({ id: 'Profil_telephone_title' })}
      />
    );
  }, [RouteNames, formatMessage]);

  const renderActionButton = useCallback(() => {
    return (
      <View style={styles.buttonContainer}>
        <SimpleButton
          disabled={!isButtonDisabled}
          title={formatMessage({ id: 'Profil_phone_edit_save_button' })}
          onPress={() => {
            onSetProfileUserInfoChange(UserInfoTypeEnum.PHONE, newPhoneNumber);
          }}
          testId={locator._profile._personal_details._phone_edit_save}
        />
      </View>
    )
  }, [
    styles,
    isButtonDisabled,
    newPhoneNumber,
    formatMessage,
    onSetProfileUserInfoChange,
  ]);
  const renderInformativePopin = useCallback(() => {
    return <InformativePopin
    description={formatMessage({ id: "Profil_telephone_technical_error_content" })}
    iconHeight={60}
    iconWidth={60}
    iconName={IconEnum.WARNING_WITH_BG}
    isModalVisible={showServerErrorPopin}
    cancelButtonTitle={formatMessage({ id: 'Profil_telephone_technical_error_leavebutton' })}
    onCancel={() => {
      setShowServerErrorPopin(false)
      return navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.ProfileStack,
        params: {
          screen: RouteNames.Profile,
        },
      })
    }}
    onClose={() => { setShowServerErrorPopin(false) }}
    title={formatMessage({ id: 'Profil_telephone_technical_error_title' })}
  />
  }, [showServerErrorPopin, setShowServerErrorPopin]) 
  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
        renderStickyMobileBottom={renderActionButton}
        leftColumnStyle={{ paddingVertical: theme.metrics.spacing.xm }}
      >
        <>
          <View style={styles.contentStyle}>
            {has24hBlockingError && (
              <NotificationHard
                containerStyle={styles.notificationHardStyle}
                type={NotificationIconTypeEnum.WARNING}
                text={formatMessage({ id: 'Profil_telephone_notification_deadline24h_tomodify' })}
              />)
            }

            <Title
              variant="t6"
              weight="bold"
            >
              {formatMessage({ id: 'Profil_telephone_numero' })}
            </Title>
            <Paragraph
              variant="t4"
              weight="light"
            >
              {formatMessage({ id: 'Profil_telephone_content' })}
            </Paragraph>

            <PhoneFieldCheck
              initialValue={userCurrentPhone}
              isDisabled={!isInputEnabled}
              onChange={handlePhoneFieldChange}
              placeholder={formatMessage({ id: 'Profil_telephone_numero' })}
              style={styles.phoneInputStyle}
              testId={locator._profile._personal_details._phone_edit_field}
            />
            {renderInformativePopin()}
            {(!isMobile && !isTablet) &&
              renderActionButton()
            }
          </View>
        </>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
