import { cloneDeep } from 'lodash';

import {
  PlanFamillyList
} from '@constants/index';

import {
  ProfitSharingIncentiveRepartitionState,
  ProfitSharingIncentiveCalculatedContributionState,
  ProfitSharingIncentiveAmountValidationParamsType
} from '../types'
import {roundNumber} from "@utils/numberRound";

export interface UpdateProfitSharingIncentiveRepartitionAmountsDataProps {
  previousRepartitionData: ProfitSharingIncentiveRepartitionState | null
  calculatedContribution?: ProfitSharingIncentiveCalculatedContributionState[] | null
  ProfitSharingIncentiveAmountValidationParams: ProfitSharingIncentiveAmountValidationParamsType
}

export const updateProfitSharingIncentiveRepartitionAmountsData = ({
  previousRepartitionData,
  calculatedContribution,
  ProfitSharingIncentiveAmountValidationParams,
}: UpdateProfitSharingIncentiveRepartitionAmountsDataProps
): ProfitSharingIncentiveRepartitionState | null => {
  if (previousRepartitionData) {

    let newRepartitionData: ProfitSharingIncentiveRepartitionState | null = cloneDeep(previousRepartitionData);

    let newRepartitionTotalAmount = 0;
    let newRepartitionAmountPercent = 0;
    let newRepartitionTotalContribution = 0;

    const newPlans = newRepartitionData.plans.map(plan => {
      let newPlan = plan
      const isRC = newPlan.planFamily === PlanFamillyList.RC;
      let newPlanTotalAmount = 0;
      let newPlanTotalAmountPercent = 0;
      let newPlanTotalContribution = 0;

      const newCompartments = plan.compartments.map(compartment => {
        let newCompartment = compartment
        let newCompartmentTotalAmount = 0;
        let newCompartmentTotalAmountPercent = 0;
        let newCompartmentTotalContribution = 0;

        const newManagements = newCompartment?.managements?.map(management => {
          const calculatedContributionManagement = calculatedContribution?.find(item => item?.planId === plan.planId.toString())?.distributions?.find(item => item.managementId === management.id);
          const isChangedManagement = management.id === ProfitSharingIncentiveAmountValidationParams.managementId;
          const isManagementContributable = management.advancedProperties.HasContribution;

          // check if isManagementDirectContributionCalcul : management is free RC contributable with no support contributable

          const isFreeManagement = management.isFree;
          const isManagementDirectAmounts = isRC && !isFreeManagement;
          const isManagementDirectContributionAmounts = isRC;
          // update management
          let newManagementTotalAmount = 0;
          let newManagementTotalAmountPercent = 0;
          let newManagementTotalContribution = 0;
          let newManagement = management;

          // update management supports
          const newSupports = newManagement?.supports?.map(support => {

            const calculatedContributionSupport = calculatedContributionManagement?.supports?.find(item => item.supportId === support.supportIsin);
            const isChangedSupport = support.supportIsin === ProfitSharingIncentiveAmountValidationParams?.fundId;
            const isSupportContributable = support.hasContribution;
            const newContribution = calculatedContributionSupport?.contributionAmount ?? 0
            // update support
            let supportAmount = 0
            let supportPercentageAmount = 0

            if (isChangedManagement && isChangedSupport) {
              supportAmount = ProfitSharingIncentiveAmountValidationParams.amount
              supportPercentageAmount = ProfitSharingIncentiveAmountValidationParams.amountPercent
            } else {
              supportAmount = support.formValues?.amount || 0
              supportPercentageAmount = support.formValues?.amountPercent || 0
            }
            const newSupport = {
              ...support,
              formValues: {
                amount: supportAmount || 0,
                amountPercent: supportPercentageAmount || 0,
                ...((isSupportContributable || support.isMaster) ? { contributionAmount: newContribution } : {}),
              },
            };

            // update management Totals
            newManagementTotalAmount = roundNumber(newManagementTotalAmount + newSupport?.formValues?.amount || 0);
            newManagementTotalAmountPercent += newSupport?.formValues?.amountPercent
            newManagementTotalContribution = roundNumber(newManagementTotalContribution + (newSupport?.formValues?.contributionAmount || 0));
            return newSupport;
          });

          // manage free RC non free management ( they don't have master support )
          if (isManagementDirectAmounts) {
            // set management amount total from installmentAmountValidationParams amount instead of supports totals
            newManagementTotalAmount = isChangedManagement ?
              ProfitSharingIncentiveAmountValidationParams.amount
              : management.formValues?.amount || 0;
            newManagementTotalAmountPercent = isChangedManagement ?
              ProfitSharingIncentiveAmountValidationParams.amountPercent
              : management.formValues?.amountPercent || 0;
          }
          // manage free RC management contributable direct contributtion calcul
          if (isManagementDirectContributionAmounts) {
            // set management contribution total from calculated contribution instead of supports totals
            newManagementTotalContribution = calculatedContributionManagement?.contributionAmount ?? 0
          }

          // finalize new management with updated supports and management totals
          newManagement = {
            ...newManagement,
            supports: newSupports,
            formValues: {
              amount: newManagementTotalAmount,
              amountPercent: newManagementTotalAmountPercent,
              ...(isManagementContributable ? { contributionAmount: newManagementTotalContribution } : {}),
            },
          };

          // update plan totals
          newCompartmentTotalAmount += newManagementTotalAmount;
          newCompartmentTotalAmountPercent += newManagementTotalAmountPercent
          newCompartmentTotalContribution += newManagementTotalContribution;

          return newManagement;
        });

        newCompartment = {
          ...newCompartment,
          managements: newManagements,
          formValues: {
            amount: newCompartmentTotalAmount,
            amountPercent: newCompartmentTotalAmountPercent,
            contributionAmount: newCompartmentTotalContribution,
          },
        };

        // update compartment totals
        newPlanTotalAmount += newCompartmentTotalAmount;
        newPlanTotalAmountPercent += newCompartmentTotalAmountPercent
        newPlanTotalContribution += newCompartmentTotalContribution;

        return newCompartment
      })

      // finalise repartition data with updated management and plan totals
      newPlan = {
        ...newPlan,
        compartments: newCompartments,
        formValues: {
          amount: newPlanTotalAmount,
          amountPercent: newPlanTotalAmountPercent,
          contributionAmount: newPlanTotalContribution,
        },
      };

      // update compartment totals
      newRepartitionTotalAmount = roundNumber(newRepartitionTotalAmount + newPlanTotalAmount);
      newRepartitionAmountPercent += newPlanTotalAmountPercent;
      newRepartitionTotalContribution = roundNumber(newRepartitionTotalContribution + newPlanTotalContribution);
      return newPlan
    })

    newRepartitionData = {
      ...newRepartitionData,
      plans: newPlans,
      formValues: {
        amount: newRepartitionTotalAmount,
        amountPercent: newRepartitionAmountPercent,
        contributionAmount: newRepartitionTotalContribution,
      },
    };

    return newRepartitionData;
  }

  return previousRepartitionData;
};
