import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from '@components/ERE-UILib';
import { PlanFamillyList } from '@constants/common';
import { RouteNames } from '@constants/navigation';
import { AddressStatusEnum } from '@constants/profile';
import { StatusTagTypesEnum } from '@ere-uilib/molecules/tags/StatusTag/sharedInterfaces';
import { setProfileAddressPlanFamilyRequest } from '@modules/profile/actions/profileActions';
import { AddressItem } from '@modules/profile/types';
import { generateAddress } from '@pages/Dashboard/ProfilePage/utils';

import { CardChildInfo } from './types';

const label = {
  title: {
    [PlanFamillyList.ES]: 'PersonalDetailsPagePostalAdressDescriptionES',
    [PlanFamillyList.RC]: 'PersonalDetailsPagePostalAdressDescriptionRC'
  },
  notification: {
    [PlanFamillyList.ES]: '',
    [PlanFamillyList.RC]:
      'PersonalDetailsPagePostalAdressInformationMessageWithLastModificationDateRC'
  },
  footer: {
    [PlanFamillyList.ES]: '',
    [PlanFamillyList.RC]: 'PersonalDetailsPagePostalAdressInformationMessageRC'
  },
  status: {
    [PlanFamillyList.ES]: {
      [AddressStatusEnum.IN_PROGRESS]: 'PersonalDetailsPagePostalAdressModificationInProgressES',
      [AddressStatusEnum.INVALID]: 'PersonalDetailsPagePostalAdressToBeCompleted'
    },
    [PlanFamillyList.RC]: {
      [AddressStatusEnum.IN_PROGRESS]: 'PersonalDetailsPagePostalAdressTreatmentInProgressRC',
      [AddressStatusEnum.INVALID]: 'PersonalDetailsPagePostalAdressToBeCompleted'
    }
  }
};

export const useAddressChildInfos = (addresses: AddressItem[] | null): CardChildInfo[] => {
  const { formatMessage, formatDate } = useTranslation();
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const handleEditPress = useCallback(
    (planFamily: PlanFamillyList) => () => {
      dispatch(setProfileAddressPlanFamilyRequest(planFamily));
      navigation.navigate(RouteNames.ProfileEditAddress);
    },
    [dispatch, navigation]
  );

  const cards = useMemo(
    () =>
      (addresses || []).map(item => {
        const { address, type, status, lastDemandDate = '', isDisplayLastDemandDate } = item ?? {};

        const isEditable = type === PlanFamillyList.RC || status !== AddressStatusEnum.IN_PROGRESS; // Be careful

        const descriptions = [
          {
            value: (status !== AddressStatusEnum.INVALID && !!address && generateAddress(address)) || '',
            isDisabled: status === AddressStatusEnum.IN_PROGRESS
          },
          {
            value: formatMessage({ id: label.footer[type], defaultMessage: '' }),
            weight: 'light' as const
          }
        ];

        if (isDisplayLastDemandDate)
          descriptions.unshift({
            value: formatMessage({
              id: label.notification[type],
              defaultMessage: '',
              values: { date: formatDate({ value: lastDemandDate || '' }) }
            }),
            weight: 'light' as const
          });

        const tag = {
          type:
            (status === AddressStatusEnum.IN_PROGRESS && StatusTagTypesEnum.IN_PROGRESS) ||
            ((status === AddressStatusEnum.INVALID || !address) && StatusTagTypesEnum.TO_INFORM) ||
            undefined,
          label: formatMessage({ id: label.status[type]?.[status], defaultMessage: '' }),
          testId: ''
        };

        return {
          title: formatMessage({ id: label.title[type], defaultMessage: '' }),
          descriptions,
          isEditable,
          tag,
          onEditPress: handleEditPress(type)
        };
      }),
    [addresses, formatDate, formatMessage, handleEditPress]
  );

  return cards;
};
