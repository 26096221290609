import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ModalFrame,
  SimpleButton,
  Title,
  NotificationHard,
  NotificationIconTypeEnum,
  NotificationIcon
} from '@components/index';

import { OperationModifyPageProps } from './interface';
import { useStyles } from './style';
import { useData } from '../OperationModifyPage/hooks/useData';

export const OperationModifyPage: React.FC<React.PropsWithChildren<OperationModifyPageProps>> = ({
  navigation
}) => {
  const { onModifyOperation, isModifyLoading, modifyError, dictionary } = useData();
  const styles = useStyles();

  const handleModalClose = useCallback(() => {
    navigation.pop();
  }, [navigation]);

  const handleModalValidation = () => {
    onModifyOperation();
  };

  const renderActions = () => {
    return (
      <View style={styles.buttonContainerStyle}>
        <SimpleButton
          containerStyle={styles.buttonStyle}
          loading={isModifyLoading}
          onPress={handleModalValidation}
          title={dictionary.yes}
        />
        <SimpleButton
          containerStyle={[styles.buttonStyle, styles.lastButtonStyle]}
          design={'outlined'}
          disabled={isModifyLoading}
          onPress={handleModalClose}
          title={dictionary.no}
        />
      </View>
    );
  };
  return (
    <View style={styles.containerStyle}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom">
        <View style={styles.modalContainer}>
          <NotificationIcon type={NotificationIconTypeEnum.WARNING} />
          <Title
            style={styles.titleStyle}
            variant="t6">
            {dictionary.title}
          </Title>
          {!!modifyError?.message && modifyError?.message !== '' && (
            <NotificationHard
              text={modifyError?.message}
              type={NotificationIconTypeEnum.WARNING}
            />
          )}
          {renderActions()}
        </View>
      </ModalFrame>
    </View>
  );
};
