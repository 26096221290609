import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { locator } from '@constants/locator';
import { Link, Title } from '@ere-uilib/atoms';
import { ClipboardButton } from '@ere-uilib/molecules';

import { EmailCardContent } from './EmailCardContent';
import { useDictionary } from './useDictionary';
import { useStyles } from './useStyles';
import { useHelpContactContext } from '../../HelpContactsPageProvider';
interface Props {
  isRC?: boolean;
  onPress: () => void;
}

export const EmailCardContentRc: React.FC<Props> = ({ isRC = false, onPress }) => {
  const { email } = useHelpContactContext();
  const styles = useStyles();
  const dictionary = useDictionary();

  return (
    <>
      {isRC && (
        <EmailCardContent
          subTitle={dictionary.mailRcContactSubTitle}
          testId={locator._profile._help_contact._email_rc_card}
          title={dictionary.mailRcContactTitle}>
          <View style={styles.mailContactRcLinkCard}>
            <Title variant="t6">{dictionary.mailRcContactAddress}</Title>

            <View style={styles.mailContainer}>
              <TouchableOpacity onPress={onPress}>
                <Link textVariant="t4">{email}</Link>
              </TouchableOpacity>

              <ClipboardButton
                copiedTooltip={dictionary.copied}
                copyTooltip={dictionary.copyMail}
                text={email}
              />
            </View>
          </View>
        </EmailCardContent>
      )}
    </>
  );
};
