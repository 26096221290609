export enum InputMode {
  DATA = 'DATA',
  // UPLOAD = "UPLOAD",
  DATA_AND_UPLOAD = 'DATA_AND_UPLOAD',
  SERVICE_RH = 'SERVICE_RH',
  TSA = 'TSA',
  TSA_OR_UPLOAD = 'TSA_OR_UPLOAD',
  CORRESPONDANT = 'CORRESPONDANT'
}

export const estimatedNetBeforeTaxPlansTypes = ['PERECO', 'PERECOI', 'PERECOG'];
