import React from 'react';

import { LoaderPlaceholderView } from '@ere-uilib/atoms';
import { LoaderCard } from '@ere-uilib/molecules';

import { useStyles } from './useStyles';

export const CompaignBlockLoader: React.FC = () => {
  const styles = useStyles();
  return (
    <LoaderCard loaderContainerStyle={styles.loaderContainerStyle}>
      <LoaderPlaceholderView style={styles.loaderPlaceholderViewOne} />
      <LoaderPlaceholderView style={styles.loaderPlaceholderViewTwo} />
    </LoaderCard>
  );
};
