import React, { useEffect, useMemo, useState } from 'react';

import { AmountFieldModal360, useTranslation } from '@components/index';

import {
  AmountValidationParamsType,
  CalculContributionParamsType,
  ModalData,
  ProfitSharingIncentiveAmountFieldModalConnectedProps
} from './interfaces';
import { ProfitSharingIncentiveAmountFieldModalConnectedRefType } from './sharedInterfaces';

export const ProfitSharingIncentiveAmountFieldModalConnectedComponent = React.forwardRef<
  ProfitSharingIncentiveAmountFieldModalConnectedRefType,
  ProfitSharingIncentiveAmountFieldModalConnectedProps
>(
  (
    {
      visible,
      calculatedContribution,
      onSetProfitSharingIncentiveRepartitionAmount,
      onCalculContribution,
      onInitCalculatedContribution,
      onSetProfitSharingIncentiveRepartitionAmountModalVisibleState,
      isCalculContributionLoading,
      isSetProfitSharingIncentiveAmountLoading,
      calculatedContributionError,
      profitSharingIncentiveRepartitionData,
      assigmentAmounts
    },
    ref
  ) => {
    const { formatMessage } = useTranslation();
    const baseReinvestAmount = assigmentAmounts?.reinvestAmount || 0;
    const defaultData: ModalData = {
      displayData: {
        title: '',
        initialAmount: undefined
      },
      planId: undefined,
      managementId: undefined,
      compartmentCode: undefined,
      fundId: undefined,
      isManagementDirectAmounts: undefined,
      isManagementDirectContributionAmounts: undefined,
      isPercentage: true
    };

    const [data, setData] = useState<ModalData>(defaultData);
    const formError = useMemo(() => {
      const isContributionError =
        data.displayData.isContributionActive &&
        calculatedContributionError?.message &&
        calculatedContributionError?.message !== '';
      if (isContributionError) {
        return formatMessage({ id: 'VV_repartition_contribution_error_label' });
      }
      return undefined;
    }, [
      formatMessage,
      calculatedContributionError?.message,
      data.displayData.isContributionActive
    ]);
    const targetedPlan = profitSharingIncentiveRepartitionData?.plans?.find(
      plan => plan.planId === data.planId
    );
    const targetedCompartment = targetedPlan?.compartments?.find(
      compartment => compartment.code === data.compartmentCode
    );
    const targetedManagement = targetedCompartment?.managements?.find(
      management => management.id === data.managementId
    );
    const targetedManagementId = data.managementId ? data.managementId : targetedManagement?.id;
    const targetedFundId = data.fundId
      ? data.fundId
      : targetedManagement?.supports?.find(support => support.isMaster === true)?.supportIsin;

    const contribution = calculatedContribution
      ?.find((item: any) => item?.planId === targetedPlan?.planId.toString())
      ?.distributions?.find(management => targetedManagementId === management.managementId)
      ?.supports?.find(support => targetedFundId === support.supportId)?.contributionAmount;

    // allow method via ref
    React.useImperativeHandle(ref, () => ({
      openWithData: receivedData => {
        onSetProfitSharingIncentiveRepartitionAmountModalVisibleState(true);
        setData(receivedData);
      }
    }));

    useEffect(() => {
      if (!visible) {
        setData(defaultData);
      }
    }, [visible]);

    const onCancelPressed = () => {
      onSetProfitSharingIncentiveRepartitionAmountModalVisibleState(false);
      setData(defaultData);
    };

    const percentageRatio = baseReinvestAmount / 100;
    const getFinalAmount = (amount?: number | null): number => {
      let finalAmount = amount;
      if (amount && data.isPercentage) {
        finalAmount = Math.round(amount * percentageRatio * 100) / 100;
      }
      return finalAmount || 0;
    };
    const getFinalPercentageAmount = (amount?: number | null): number => {
      let finalPercentageAmount = amount;
      if (amount && !data.isPercentage) {
        finalPercentageAmount = Math.round((amount / percentageRatio) * 100) / 100;
      }
      return finalPercentageAmount || 0;
    };
    const onValidPressed = (amount?: number | null) => {
      if (
        data.planId &&
        data.compartmentCode &&
        data.managementId &&
        profitSharingIncentiveRepartitionData &&
        (data.isManagementDirectContributionAmounts || targetedFundId)
      ) {
        const params: AmountValidationParamsType = {
          planId: data.planId,
          compartmentCode: data.compartmentCode,
          managementId: data.managementId,
          fundId: targetedFundId,
          amount: getFinalAmount(amount),
          amountPercent: getFinalPercentageAmount(amount),
          contribution: calculatedContribution,
          isContributionActive: data?.displayData.isContributionActive
        };
        onSetProfitSharingIncentiveRepartitionAmount(params);
      }
    };
    const onCalculContributionPressed = (amount?: number | null) => {
      if (
        data.planId &&
        data.compartmentCode &&
        data.managementId &&
        profitSharingIncentiveRepartitionData &&
        targetedFundId
      ) {
        const params: CalculContributionParamsType = {
          planId: data.planId,
          compartmentCode: data.compartmentCode,
          managementId: data.managementId,
          fundId: targetedFundId,
          amount: getFinalAmount(amount)
        };
        onCalculContribution(params);
      }
    };
    return (
      <AmountFieldModal360
        calculatedContribution={contribution}
        data={data.displayData}
        formError={formError}
        isAmountPercentage={data.isPercentage}
        isCalculContributionLoading={isCalculContributionLoading}
        isValidButtonLoading={isSetProfitSharingIncentiveAmountLoading}
        onCalculContribution={onCalculContributionPressed}
        onCancel={onCancelPressed}
        onResetCalculatedContribution={onInitCalculatedContribution}
        onValid={onValidPressed}
        visible={visible}
      />
    );
  }
);
