import { PlanFamillyList } from '@constants/common';
import { InputMode } from '@modules/common/constants';
import { GetAddressesDtoResponse, PostAddressDtoResponse } from '@modules/profile/service/types';
import {
  AddressState,
  AddressUpdateStatus,
  ProfileAddressUpdateStatusState
} from '@modules/profile/types';

export const mapGetAddressesResponseToState = (response?: GetAddressesDtoResponse): AddressState =>
  ({
    ...response,
    addresses: response?.addresses?.map(item => ({
      ...item,
      ...(item.type === PlanFamillyList.RC && { inputMode: InputMode.DATA_AND_UPLOAD })
    }))
  }) as AddressState;

export const mapPostAddressResponseToState = ({
  isSucceeded
}: PostAddressDtoResponse): ProfileAddressUpdateStatusState => ({
  addressUpdateStatus: AddressUpdateStatus[isSucceeded ? 'SUCCESS' : 'ERROR']
});
