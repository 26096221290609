
import { LegalMenuFooterStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: LegalMenuFooterStyles
): LegalMenuFooterStyles => {
  const textColor = context?.isWhite ?
    context?.theme.colors.basics.white
    : context?.theme.colors.basics.grey.c400;
  return {
    listContainerStyle: [
      {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: context?.theme.metrics.spacing.s,
      },
      style?.listContainerStyle,
    ],
    listItemTextStyle: [
      {
        fontSize: context?.theme.fonts.fontSize.paragraph.t4,
        color: textColor
      },
      style?.listItemTextStyle,
    ],
    listItemTextSeparatorStyle: [
      {
        marginHorizontal: context?.theme.metrics.spacing.s,
        color: textColor
      },
      style?.listItemTextSeparatorStyle,
    ],
  };
};
