import { useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export interface GlobalSavingsCardStyles {
  cardRowStyle?: StyleProp<ViewStyle>;
  cardFirstRowStyle?: StyleProp<ViewStyle>;
  labelRowStyle?: StyleProp<ViewStyle>;
  labelDotStyle?: StyleProp<ViewStyle>;
  detailRowStyle?: StyleProp<ViewStyle>;
  detailContentStyle?: StyleProp<ViewStyle>;
  amountStyle?: StyleProp<TextStyle>;
  valueStyle?: StyleProp<TextStyle>;
  chevronStyle?: StyleProp<TextStyle>;
}

export const useStyles = (style: GlobalSavingsCardStyles) => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        cardColumnStyle: {
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          paddingVertical: theme.metrics.spacing.xm,
          borderTopWidth: 1,
          borderTopColor: theme.colors.basics.white,
          ...StyleSheet.flatten(style?.cardRowStyle)
        },
        cardRowStyle: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flex: 1,
          width: '100%'
        },
        planTitleCard: {
          flexDirection: 'row',
          alignItems: 'flex-start',
          flex: 1
        },
        cardFirstColumnStyle: {
          borderTopWidth: 0,
          ...StyleSheet.flatten(style?.cardFirstRowStyle)
        },

        columnContainer: {
          paddingTop: 0,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flex: 1
        },
        labelRowStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          ...StyleSheet.flatten(style.labelRowStyle)
        },

        labelDotStyle: {
          width: 13,
          height: 13,
          backgroundColor: theme.colors.basics.white,
          borderRadius: 50,
          marginTop: 4,
          marginRight: theme.metrics.spacing.s,
          ...StyleSheet.flatten(style.labelDotStyle)
        },

        detailRowStyle: {
          minWidth: 100,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          flex: 1,
          alignItems: 'center',
          ...StyleSheet.flatten(style.detailRowStyle)
        },

        detailContentStyle: {
          flexDirection: 'column',
          alignItems: 'flex-end',
          flex: 1,
          ...StyleSheet.flatten(style.detailContentStyle)
        },

        amountStyle: {
          fontSize: theme.fonts.fontSize.paragraph.t2,
          fontFamily: theme.fonts.fontFamily.bold,
          textAlign: 'right',
          ...StyleSheet.flatten(style.amountStyle)
        },

        valueStyle: {
          color: theme.colors.basics.primary.c500,
          fontSize: theme.fonts.fontSize.paragraph.t4,
          fontFamily: theme.fonts.fontFamily.bold,
          marginTop: theme.metrics.spacing.xs,
          textAlign: 'right',
          ...StyleSheet.flatten(style.valueStyle)
        },
        estimatedNetAmount: {
          color: theme.colors.basics.black,
          fontSize: theme.fonts.fontSize.paragraph.t3,
          fontFamily: theme.fonts.fontFamily.regular,
          marginTop: theme.metrics.spacing.xs,
          textAlign: 'right'
        },

        chevronStyle: {
          fontSize: theme.metrics.iconSizes.xxm,
          color: theme.colors.text.primary.c500,
          paddingLeft: theme.metrics.spacing.xm,
          fontWeight: 'bold',
          alignSelf: 'center',
          ...StyleSheet.flatten(style.chevronStyle)
        }
      }),
    [theme, style]
  );

  return styles;
};
