import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        iconSuccessStyle: {
          width: 241,
          height: 140,
          alignSelf: 'center'
        },
        textContainerStyle: {
          alignItems: 'center',
          marginTop: theme.metrics.spacing.l,
          marginBottom: theme.metrics.spacing.l
        },

        subTitle: { marginTop: 10 },
        backToMenuButton: { marginBottom: theme.metrics.spacing.xm }
      }),
    [theme]
  );

  return styles;
};
