/**
 * Round half away from zero ('commercial' rounding)
 * Uses correction to offset floating-point inaccuracies.
 * Works symmetrically for positive and negative numbers.
 */

export const roundNumber =(value: number, decimalPlaces = 2) => {
    const p = Math.pow(10, decimalPlaces);
    const n = (value * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
}
