export enum PlanTypeEnum {
    PERU = 'PERU',
    PERECO = 'PERECO',
    PERECOG = 'PERECOG',
    PERECOI = 'PERECOI',

    PERO = 'PERO',
    PERCO = 'PERCO',
    PERCOG = 'PERCOG',
    PERCOI = 'PERCOI',

    PEE = 'PEE',
    PEG = 'PEG',
    PEI = 'PEI',
    A39 = 'A39',
    EXT = 'EXT'
}
