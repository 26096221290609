import { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';

export const useDictionary = () => {
  const { formatMessage, getMessageRaw } = useTranslation();

  const dictionary = useMemo(() => {
    return {
      defaultTitleSelect: formatMessage({ id: 'ContactFormPageReason' }),
      contactFormPageHeader: formatMessage({ id: 'ContactFormPageHeader' }),
      contactFormPageTitle1: formatMessage({ id: 'ContactFormPageTitle1' }),
      contactFormPageTitle: formatMessage({ id: 'ContactFormPageTitle' }),
      contactFormPageDescriptionES: formatMessage({ id: 'ContactFormPageDescriptionES' }),
      contactFormPageMandatoryFields: formatMessage({ id: 'ContactFormPageMandatoryFields' }),
      contactFormPageReason: formatMessage({ id: 'ContactFormPageReason' }),
      contactFormPageValidationButton: formatMessage({ id: 'ContactFormPageValidationButton' }),
      relDemandeMessage: formatMessage({ id: 'ContactFormPageMessageStatements' }),
      placeHolderSelect: formatMessage({ id: 'ContactFormPageReason' }),
      placeHolderInput: formatMessage({ id: 'ContactFormPageMessage' }),
      errorMessage: formatMessage({ id: 'ContactFormPageMessageExceedingCharacters' }),
      popinConfirmButton: formatMessage({ id: 'ContactFormPagePopinExitButtonLeave' }),
      popinCancelButton: formatMessage({ id: 'ContactFormPagePopinExitButtonStay' }),
      popinHeaderTitle: formatMessage({ id: 'ContactFormPagePopinExitMessageTitle' }),
      popinDescriptionText: formatMessage({ id: 'ContactFormPagePopinExitMessage' }),
      contactFormPagePopinProfilePageButton: formatMessage({
        id: 'ContactFormPagePopinProfilePageButton'
      }),
      contactFormPagePopinConfirmationMessage: formatMessage({
        id: 'ContactFormPagePopinConfirmationMessage'
      }),
      contactFormPagePopinConfirmationTitle: formatMessage({
        id: 'ContactFormPagePopinConfirmationTitle'
      })
    };
  }, [formatMessage, getMessageRaw]);

  return dictionary;
};
