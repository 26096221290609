import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        cardContainerStyle: {
          borderRadius: theme.metrics.borderRadius.xs,
          paddingVertical: theme.metrics.spacing.xm,
          paddingHorizontal: theme.metrics.spacing.s,
          shadowColor: 'rgba(96, 97, 112, 0.2)',
          shadowOffset: {
            width: 0,
            height: 6
          },
          shadowOpacity: 1,
          shadowRadius: 15,
          elevation: 6
        },
        textCenteredStyle: {
          textAlign: 'center'
        },
        separator: {
          borderBottomWidth: 1,
          width: 50,
          borderColor: theme.colors.basics.grey.c200
        },
        textDescriptionStyle: {
          fontWeight: '400',
          fontSize: theme.fonts.fontSize.paragraph.t3
        },
        containerStyle: {
          gap: theme.metrics.spacing.xm
        },
        mailContactRcLinkCard: {
          justifyContent: 'center',
          alignItems: 'center'
        },
        mailContainer: {
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center'
        }
      }),
    [theme]
  );

  return styles;
}
