import { createStackNavigator } from '@react-navigation/stack';
import { Formik } from 'formik';
import React from 'react';

import { RouteNames } from '@constants/navigation';
import { InputMode } from '@modules/common/constants';
import { ProfileEditAddressStackNavigatorInterface } from '@navigation/Interfaces';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  ProfileEditAddressDocumentPage,
  ProfileEditAddressForm,
  ProfileEditAddressInformationPage,
  ProfileEditAddressPopin,
  useProfileEditAddressData
} from '@pages/Dashboard';

const mapping = {
  [RouteNames.ProfileEditAddressForm]: {
    [InputMode.DATA_AND_UPLOAD]: true,
    [InputMode.DATA]: true
  },
  [RouteNames.ProfileEditAddressDocument]: {
    [InputMode.DATA_AND_UPLOAD]: true,
    [InputMode.TSA_OR_UPLOAD]: true
  },
  [RouteNames.ProfileEditAddressInformation]: {
    [InputMode.TSA_OR_UPLOAD]: true,
    [InputMode.TSA]: true,
    [InputMode.SERVICE_RH]: true,
    [InputMode.CORRESPONDANT]: true
  }
};

const ProfileEditAddressStack = createStackNavigator<ProfileEditAddressStackNavigatorInterface>();

export const ProfileEditAddressStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  const { planFamily, address, inputMode } = useProfileEditAddressData();
  const { onSubmit } = ProfileEditAddressForm.useData();

  return (
    <Formik
      initialValues={ProfileEditAddressForm.getInitialValuesFrom(address)}
      onSubmit={onSubmit}
      validationSchema={ProfileEditAddressForm.getValidationSchemaBy(planFamily)}>
      <>
        <ProfileEditAddressStack.Navigator
          screenOptions={{
            gestureEnabled: false,
            headerShown: false
          }}>
          {mapping[RouteNames.ProfileEditAddressForm][inputMode] && (
            <ProfileEditAddressStack.Screen
              component={ProfileEditAddressForm.ProfileEditAddressFormPage}
              name={RouteNames.ProfileEditAddressForm}
              options={defaultScreensOptions.ProfileEditAddressForm}
            />
          )}
          {mapping[RouteNames.ProfileEditAddressInformation][inputMode] && (
            <ProfileEditAddressStack.Screen
              component={ProfileEditAddressInformationPage}
              name={RouteNames.ProfileEditAddressInformation}
              options={defaultScreensOptions.ProfileEditAddressInformation}
            />
          )}
          {mapping[RouteNames.ProfileEditAddressDocument][inputMode] && (
            <ProfileEditAddressStack.Screen
              component={ProfileEditAddressDocumentPage}
              name={RouteNames.ProfileEditAddressDocument}
              options={defaultScreensOptions.ProfileEditAddressDocument}
            />
          )}
        </ProfileEditAddressStack.Navigator>
        <ProfileEditAddressPopin />
      </>
    </Formik>
  );
};
