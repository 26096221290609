import { FundsActionsType } from '@modules/funds/actions/fundsActionsTypes';
import { AppState } from '@modules/reducers';

export const getFundsRepartitionSupports = (state: AppState) =>
  state.funds?.fundsRepartitionSupport;
export const getFundsOverview = (state: AppState) => state.funds?.funds;
export const getInnerNavigations = (state: AppState) => state.funds?.innerNavigations;
export const getFundsPerformance = (state: AppState) => state.funds?.fundsPerformance;
export const getFundsDocumentsList = (state: AppState) => state.funds?.fundsDocumentsList;
export const getFundsPerformanceSharePriceHisto = (state: AppState) =>
  state.funds?.fundsPerformance?.sharePriceHisto;
export const getMultiFundsOverviews = (state: AppState) => state.funds?.multiFundsOverviews;
export const getMultiFundsPerformances = (state: AppState) => state.funds?.multiFundsPerformances;
export const getMultiFundsRepartitionSupports = (state: AppState) =>
  state.funds?.multiFundsRepartitionSupports;
export const getCompareMode = (state: AppState) => state.funds?.compareMode;
export const getLoadingState = (valueLoading: string) => (state: AppState) =>
  state.isLoading[valueLoading];
export const getGlobalError = (errorValue: string) => (state: AppState) => state.error[errorValue];
export const getIsDownloading = (state: AppState) =>
  state.isLoading[FundsActionsType.POST_DOWNLOAD_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST];
export const getDownloadResult = (state: AppState) => state.funds?.downloadResult;
