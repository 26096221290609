import { OtpChanelTypeEnum, PlanFamillyList, UserInfoTypeEnum } from '@constants/index';
import { FileData } from '@modules/common/types';
import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';

import { AddressDetailDto } from '../service/types';
import {
  ConsentsState,
  EditorialContentState,
  EditorialErrorState,
  OtpErrorState,
  OtpParametersState,
  ProfileErrorState,
  ProfileMenuState,
  ProfileUserInfosState,
  ProfileAddressUpdateStatusState,
  emailInfosType,
  phoneInfosType,
  postalInfosType,
  AddressState
} from '../types';

export enum ProfileActionsType {
  LOCK_BIOMETRIC_USER_FROM_PROFILE_MENU = 'LOCK_BIOMETRIC_USER_FROM_PROFILE_MENU',
  GET_CONSENTS_REQUEST = 'GET_CONSENTS_REQUEST',
  GET_CONSENTS_SUCCESS = 'GET_CONSENTS_SUCCESS',
  GET_CONSENTS_FAILURE = 'GET_CONSENTS_FAILURE',
  GET_EDITORIAL_CONTENT_REQUEST = 'GET_EDITORIAL_CONTENT_REQUEST',
  GET_EDITORIAL_CONTENT_SUCCESS = 'GET_EDITORIAL_CONTENT_SUCCESS',
  GET_EDITORIAL_CONTENT_FAILURE = 'GET_EDITORIAL_CONTENT_FAILURE',
  ACCEPT_CONSENTS_REQUEST = 'ACCEPT_CONSENTS_REQUEST',
  ACCEPT_CONSENTS_SUCCESS = 'ACCEPT_CONSENTS_SUCCESS',
  ACCEPT_CONSENTS_FAILURE = 'ACCEPT_CONSENTS_FAILURE',
  PROFILE_INIT_ERROR = 'PROFILE_INIT_ERROR',
  GET_PROFILE_MENU_REQUEST = 'GET_PROFILE_MENU_REQUEST',
  GET_PROFILE_MENU_SUCCESS = 'GET_PROFILE_MENU_SUCCESS',
  GET_PROFILE_MENU_FAILURE = 'GET_PROFILE_MENU_FAILURE',
  GET_PROFILE_USER_INFOS_REQUEST = 'GET_PROFILE_USER_INFOS_REQUEST',
  GET_PROFILE_USER_INFOS_SUCCESS = 'GET_PROFILE_USER_INFOS_SUCCESS',
  GET_PROFILE_USER_INFOS_FAILURE = 'GET_PROFILE_USER_INFOS_FAILURE',
  SEND_PROFILE_USER_INFO_CHANGE_REQUEST = 'SEND_PROFILE_USER_INFO_CHANGE_REQUEST',
  SEND_PROFILE_USER_INFO_CHANGE_SUCCESS = 'SEND_PROFILE_USER_INFO_CHANGE_SUCCESS',
  SEND_PROFILE_USER_INFO_CHANGE_FAILURE = 'SEND_PROFILE_USER_INFO_CHANGE_FAILURE',
  VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST = 'VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST',
  VALIDATE_PROFILE_USER_INFO_CHANGE_SUCCESS = 'VALIDATE_PROFILE_USER_INFO_CHANGE_SUCCESS',
  VALIDATE_PROFILE_USER_INFO_CHANGE_FAILURE = 'VALIDATE_PROFILE_USER_INFO_CHANGE_FAILURE',
  OTP_FLOW_START_REQUEST = 'OTP_FLOW_START_REQUEST',
  OTP_FLOW_START_SUCCESS = 'OTP_FLOW_START_SUCCESS',
  OTP_FLOW_START_FAILURE = 'OTP_FLOW_START_FAILURE',
  ASK_NAVIGATION_TO_URL = 'ASK_NAVIGATION_TO_URL',
  EDIT_PASSWORD_RESET = 'EDIT_PASSWORD_RESET',
  EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST',
  EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS',
  EDIT_PASSWORD_FAILURE = 'EDIT_PASSWORD_FAILURE',
  OTP_FLOW_INITIALIZE_CHANEL_REQUEST = 'OTP_FLOW_INITIALIZE_CHANEL_REQUEST',
  OTP_FLOW_INITIALIZE_CHANEL_SUCCESS = 'OTP_FLOW_INITIALIZE_CHANEL_SUCCESS',
  OTP_FLOW_INITIALIZE_CHANEL_FAILURE = 'OTP_FLOW_INITIALIZE_CHANEL_FAILURE',
  OTP_FLOW_ASK_CODE_REQUEST = 'OTP_FLOW_ASK_CODE_REQUEST',
  OTP_FLOW_ASK_CODE_SUCCESS = 'OTP_FLOW_ASK_CODE_SUCCESS',
  OTP_FLOW_ASK_CODE_FAILURE = 'OTP_FLOW_ASK_CODE_FAILURE',
  OTP_FLOW_ASK_CODE_AGAIN_REQUEST = 'OTP_FLOW_ASK_CODE_AGAIN_REQUEST',
  OTP_FLOW_ASK_CODE_AGAIN_SUCCESS = 'OTP_FLOW_ASK_CODE_AGAIN_SUCCESS',
  OTP_FLOW_ASK_CODE_AGAIN_FAILURE = 'OTP_FLOW_ASK_CODE_AGAIN_FAILURE',
  OTP_FLOW_SEND_CODE_REQUEST = 'OTP_FLOW_SEND_CODE_REQUEST',
  OTP_FLOW_SEND_CODE_SUCCESS = 'OTP_FLOW_SEND_CODE_SUCCESS',
  OTP_FLOW_SEND_CODE_FAILURE = 'OTP_FLOW_SEND_CODE_FAILURE',
  OTP_FLOW_COMPLETED_VALIDATION_REQUEST = 'OTP_FLOW_COMPLETED_VALIDATION_REQUEST',
  OTP_FLOW_COMPLETED_VALIDATION_SUCCESS = 'OTP_FLOW_COMPLETED_VALIDATION_SUCCESS',
  OTP_FLOW_COMPLETED_VALIDATION_FAILURE = 'OTP_FLOW_COMPLETED_VALIDATION_FAILURE',
  UPDATE_PROFILE_OTP_PARAMETERS = 'UPDATE_PROFILE_OTP_PARAMETERS',
  RESET_PROFILE_OTP_PARAMETERS = 'RESET_PROFILE_OTP_PARAMETERS',
  RESET_PROFILE_OTP_FLOW = 'RESET_PROFILE_OTP_FLOW',
  INIT_PROFILE_OTP_ERROR = 'INIT_PROFILE_OTP_ERROR',
  UPDATE_CONTACT_PREFERENCES_REQUEST = 'UPDATE_CONTACT_PREFERENCES_REQUEST',
  UPDATE_CONTACT_PREFERENCES_SUCCESS = 'UPDATE_CONTACT_PREFERENCES_SUCCESS',
  UPDATE_CONTACT_PREFERENCES_FAILURE = 'UPDATE_CONTACT_PREFERENCES_FAILURE',
  UPDATE_CONTACT_PREFERENCES_SUCCESS_RESET = 'UPDATE_CONTACT_PREFERENCES_SUCCESS_RESET',
  GET_CONTACT_PREFERENCES_REQUEST = 'GET_CONTACT_PREFERENCES_REQUEST',
  GET_CONTACT_PREFERENCES_SUCCESS = 'GET_CONTACT_PREFERENCES_SUCCESS',
  GET_CONTACT_PREFERENCES_FAILURE = 'GET_CONTACT_PREFERENCES_FAILURE',
  SET_CONTACT_PREFERENCES_OPT_IN_EMAIL = 'SET_CONTACT_PREFERENCES_OPT_IN_EMAIL',
  SET_CONTACT_PREFERENCES_OPT_IN_SMS = 'SET_CONTACT_PREFERENCES_OPT_IN_SMS',
  SET_CONTACT_PREFERENCES_HAS_NOTIFICATIONS_ACTIVATED = 'SET_CONTACT_PREFERENCES_HAS_NOTIFICATIONS_ACTIVATED',
  GET_CONTACT_PREFERENCES_OPTIN_REQUEST = 'GET_CONTACT_PREFERENCES_OPTIN_REQUEST',
  GET_CONTACT_PREFERENCES_OPTIN_SUCCESS = 'GET_CONTACT_PREFERENCES_OPTIN_SUCCESS',
  GET_CONTACT_PREFERENCES_OPTIN_FAILURE = 'GET_CONTACT_PREFERENCES_OPTIN_FAILURE',
  SET_CONTACT_PREFERENCES_OPTIN_REQUEST = 'SET_CONTACT_PREFERENCES_OPTIN_REQUEST',
  SET_CONTACT_PREFERENCES_OPTIN_SUCCESS = 'SET_CONTACT_PREFERENCES_OPTIN_SUCCESS',
  SET_CONTACT_PREFERENCES_OPTIN_FAILURE = 'SET_CONTACT_PREFERENCES_OPTIN_FAILURE',
  SET_CONTACT_PREFERENCES_OPTIN_RESET = 'SET_CONTACT_PREFERENCES_OPTIN_RESET',
  CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_REQUEST = 'CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_REQUEST',
  CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_SUCCESS = 'CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_SUCCESS',
  CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_FAILURE = 'CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_FAILURE',
  CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_REQUEST = 'CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_REQUEST',
  CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_SUCCESS = 'CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_SUCCESS',
  CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_FAILURE = 'CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_FAILURE',
  GET_PROFILE_ADDRESSES_REQUEST = 'GET_PROFILE_ADDRESSES_REQUEST',
  GET_PROFILE_ADDRESSES_FAILURE = 'GET_PROFILE_ADDRESSES_FAILURE',
  GET_PROFILE_ADDRESSES_SUCCESS = 'GET_PROFILE_ADDRESSES_SUCCESS',
  UPDATE_PROFILE_ADDRESS_REQUEST = 'UPDATE_PROFILE_ADDRESS_REQUEST',
  UPDATE_PROFILE_ADDRESS_FAILURE = 'UPDATE_PROFILE_ADDRESS_FAILURE',
  UPDATE_PROFILE_ADDRESS_SUCCESS = 'UPDATE_PROFILE_ADDRESS_SUCCESS',
  RESET_PROFILE_ADDRESS_STATUS = 'RESET_PROFILE_ADDRESS_STATUS',
  SET_PROFILE_ADDRESS_PLAN_FAMILY = 'SET_PROFILE_ADDRESS_PLAN_FAMILY',
  GET_CONTACT_INFOS_REQUEST = 'GET_CONTACT_INFOS_REQUEST',
  GET_CONTACT_INFOS_SUCCESS = 'GET_CONTACT_INFOS_SUCCESS',
  GET_CONTACT_INFOS_FAILURE = 'GET_CONTACT_INFOS_FAILURE',
  POST_CONTACT_FORM_REQUEST = 'POST_CONTACT_FORM_REQUEST',
  POST_CONTACT_FORM_SUCCESS = 'POST_CONTACT_FORM_SUCCESS',
  POST_CONTACT_FORM_FAILURE = 'POST_CONTACT_FORM_FAILURE',
  RESET_CONTACT_FORM_STATE = 'RESET_CONTACT_FORM_STATE'
}

// biometric

export interface SetBiometricLockingUserFromProfileMenuAction {
  type: ProfileActionsType.LOCK_BIOMETRIC_USER_FROM_PROFILE_MENU;
  isLocked: boolean;
}

//get Consents

export interface GetConsentsRequestAction {
  type: ProfileActionsType.GET_CONSENTS_REQUEST;
}

export interface GetConsentsSuccessAction {
  type: ProfileActionsType.GET_CONSENTS_SUCCESS;
  consents: ConsentsState[];
}

export interface GetConsentsFailureAction {
  type: ProfileActionsType.GET_CONSENTS_FAILURE;
  error: ProfileErrorState;
}

// Editorial content

export interface GetEditorialContentRequestAction {
  type: ProfileActionsType.GET_EDITORIAL_CONTENT_REQUEST;
  code: string;
}

export interface GetEditorialContentSuccessAction {
  type: ProfileActionsType.GET_EDITORIAL_CONTENT_SUCCESS;
  content: EditorialContentState;
}

export interface GetEditorialContentFailureAction {
  type: ProfileActionsType.GET_EDITORIAL_CONTENT_FAILURE;
  error: EditorialErrorState;
}

// Accept consent
export interface AcceptConsentsRequestAction {
  type: ProfileActionsType.ACCEPT_CONSENTS_REQUEST;
  code: string;
}

export interface AcceptConsentsSuccessAction {
  type: ProfileActionsType.ACCEPT_CONSENTS_SUCCESS;
  code: string;
  isAccepted: boolean;
}

export interface AcceptConsentsFailureAction {
  type: ProfileActionsType.ACCEPT_CONSENTS_FAILURE;
  error: ProfileErrorState;
}

//get Profile Menu

export interface GetProfileMenuRequestAction {
  type: ProfileActionsType.GET_PROFILE_MENU_REQUEST;
}

export interface GetProfileMenuSuccessAction {
  type: ProfileActionsType.GET_PROFILE_MENU_SUCCESS;
  profileMenu: ProfileMenuState;
}

export interface GetProfileMenuFailureAction {
  type: ProfileActionsType.GET_PROFILE_MENU_FAILURE;
  error: ProfileErrorState;
}

export interface GetProfileAddressesRequestAction {
  type: ProfileActionsType.GET_PROFILE_ADDRESSES_REQUEST;
}

export interface GetProfileUserInfosSuccessAction {
  type: ProfileActionsType.GET_PROFILE_USER_INFOS_SUCCESS;
  userInfos: ProfileUserInfosState;
}

export interface GetProfileAddressesSuccessAction extends AddressState {
  type: ProfileActionsType.GET_PROFILE_ADDRESSES_SUCCESS;
}

export interface UpdateProfileAddressRequestAction {
  type: ProfileActionsType.UPDATE_PROFILE_ADDRESS_REQUEST;
  planFamily: PlanFamillyList;
  address: AddressDetailDto | null;
  files: FileData[];
}

export interface ResetProfileAddressStatusAction {
  type: ProfileActionsType.RESET_PROFILE_ADDRESS_STATUS;
}

export interface SetProfileAddressPlanFamilyAction {
  type: ProfileActionsType.SET_PROFILE_ADDRESS_PLAN_FAMILY;
  planFamily: PlanFamillyList;
}

export interface UpdateProfileAddressFailureAction {
  type: ProfileActionsType.UPDATE_PROFILE_ADDRESS_FAILURE;
  error: ProfileErrorState;
}

export interface UpdateProfileAddressSuccessAction extends ProfileAddressUpdateStatusState {
  type: ProfileActionsType.UPDATE_PROFILE_ADDRESS_SUCCESS;
}

export interface GetProfileUserInfosFailureAction {
  type: ProfileActionsType.GET_PROFILE_USER_INFOS_FAILURE;
  error: ProfileErrorState;
}

//get Profile User Infos

export interface GetProfileUserInfosRequestAction {
  type: ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST;
}

//send user info change

export interface SendProfileUserInfoChangeRequestAction {
  type: ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_REQUEST;
  userInfoType: UserInfoTypeEnum;
  value: string;
}

export interface SendProfileUserInfoChangeSuccessAction {
  type: ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_SUCCESS;
}

export interface SendProfileUserInfoChangeFailureAction {
  type: ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_FAILURE;
  error: ProfileErrorState;
}

//validate user info change

export interface ValidateProfileUserInfoChangeRequestAction {
  type: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST;
}

export interface ValidateProfileUserInfoChangeSuccessAction {
  type: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_SUCCESS;
}

export interface ValidateProfileUserInfoChangeFailureAction {
  type: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_FAILURE;
  error: ProfileErrorState;
}

// Profile OTP Management
// start otp flow

export interface OtpFlowStartRequestAction {
  type: ProfileActionsType.OTP_FLOW_START_REQUEST;
  otpParameters: OtpParametersState;
}

export interface OtpFlowStartSuccessAction {
  type: ProfileActionsType.OTP_FLOW_START_SUCCESS;
}

export interface OtpFlowStartFailureAction {
  type: ProfileActionsType.OTP_FLOW_START_FAILURE;
  error: OtpErrorState;
}

export interface AskNavigationToUrlAction {
  type: ProfileActionsType.ASK_NAVIGATION_TO_URL;
  url: string;
}

// edit password
export interface EditPasswordResetAction {
  type: ProfileActionsType.EDIT_PASSWORD_RESET;
}

export interface EditPasswordRequestAction {
  type: ProfileActionsType.EDIT_PASSWORD_REQUEST;
  currentPassword: string;
  newPassword: string;
}

export interface EditPasswordSuccessAction {
  type: ProfileActionsType.EDIT_PASSWORD_SUCCESS;
}

export interface EditPasswordFailureAction {
  type: ProfileActionsType.EDIT_PASSWORD_FAILURE;
  error: ProfileErrorState;
}

// otp flow initialize chanel

export interface OtpFlowInitializeChanelRequestAction {
  type: ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_REQUEST;
  chanelType: OtpChanelTypeEnum;
}

export interface OtpFlowInitializeChanelSuccessAction {
  type: ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_SUCCESS;
}

export interface OtpFlowInitializeChanelFailureAction {
  type: ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_FAILURE;
  error: OtpErrorState;
}

// otp flow ask code

export interface OtpFlowAskCodeRequestAction {
  type: ProfileActionsType.OTP_FLOW_ASK_CODE_REQUEST;
  chanelType?: OtpChanelTypeEnum;
}

export interface OtpFlowAskCodeSuccessAction {
  type: ProfileActionsType.OTP_FLOW_ASK_CODE_SUCCESS;
}

export interface OtpFlowAskCodeFailureAction {
  type: ProfileActionsType.OTP_FLOW_ASK_CODE_FAILURE;
  error: OtpErrorState;
}

// otp flow ask code again

export interface OtpFlowAskCodeAgainRequestAction {
  type: ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_REQUEST;
}

export interface OtpFlowAskCodeAgainSuccessAction {
  type: ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_SUCCESS;
}

export interface OtpFlowAskCodeAgainFailureAction {
  type: ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_FAILURE;
  error: OtpErrorState;
}

// otp flow send code

export interface OtpFlowSendCodeRequestAction {
  type: ProfileActionsType.OTP_FLOW_SEND_CODE_REQUEST;
  otpCode: string;
}

export interface OtpFlowSendCodeSuccessAction {
  type: ProfileActionsType.OTP_FLOW_SEND_CODE_SUCCESS;
}

export interface OtpFlowSendCodeFailureAction {
  type: ProfileActionsType.OTP_FLOW_SEND_CODE_FAILURE;
  error: OtpErrorState;
}

// otp flow success validation

export interface OtpFlowCompletedValidationRequestAction {
  type: ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_REQUEST;
}

export interface OtpFlowCompletedValidationSuccessAction {
  type: ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_SUCCESS;
}

export interface OtpFlowCompletedValidationFailureAction {
  type: ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_FAILURE;
  error: OtpErrorState;
}

// update otp parameters

export interface UpdateProfileOtpParametersAction {
  type: ProfileActionsType.UPDATE_PROFILE_OTP_PARAMETERS;
  otpParameters: OtpParametersState;
}
// reset otp parameters

export interface ResetProfileOtpParametersAction {
  type: ProfileActionsType.RESET_PROFILE_OTP_PARAMETERS;
}
// reset otp flow

export interface ResetProfileOtpFlowAction {
  type: ProfileActionsType.RESET_PROFILE_OTP_FLOW;
}

// RESET otp error

export interface InitProfileOtpErrorAction {
  type: ProfileActionsType.INIT_PROFILE_OTP_ERROR;
}

// InitProfile Error
export interface ProfileInitErrorAction {
  type: ProfileActionsType.PROFILE_INIT_ERROR;
}

// Contact preferences

export interface UpdateContactPreferenceRequestAction {
  type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_REQUEST;
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
  toDoPostSuccessActionType?: string;
  demandId?: string | null;
}

export interface UpdateContactPreferenceSuccessAction {
  type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_SUCCESS;
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
}

export interface UpdateContactPreferencesFailureAction {
  type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_FAILURE;
}

export interface UpdateContactPreferenceSuccessResetAction {
  type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_SUCCESS_RESET;
}

export interface GetContactPreferenceRequestAction {
  type: ProfileActionsType.GET_CONTACT_PREFERENCES_REQUEST;
}

export interface GetContactPreferenceSuccessAction {
  type: ProfileActionsType.GET_CONTACT_PREFERENCES_SUCCESS;
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
}

export interface GetContactPreferencesFailureAction {
  type: ProfileActionsType.GET_CONTACT_PREFERENCES_FAILURE;
}

export interface SetContactPreferencesOptInEmailAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPT_IN_EMAIL;
  emailOptIn: boolean;
}

export interface SetContactPreferencesOptInSmsAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPT_IN_SMS;
  smsOptIn: boolean;
}

export interface SetContactPreferencesHasNotificationsActivatedAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_HAS_NOTIFICATIONS_ACTIVATED;
  hasNotificationsActivated: boolean;
}

/// optin

export interface GetContactPreferenceOptinRequestAction {
  type: ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_REQUEST;
}

export interface GetContactPreferenceOptinSuccessAction {
  type: ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_SUCCESS;
  acceptGroupEmail: boolean;
  acceptPartnerEmail: boolean;
}

export interface GetContactPreferencesOptinFailureAction {
  type: ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_FAILURE;
  error: ProfileErrorState;
}

//setOptin

export interface SetContactPreferencesOptInRequestAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_REQUEST;
  demandId?: string | null;
  acceptGroupEmail: boolean;
  acceptPartnerEmail: boolean;
  toDoPostSuccessActionType?: string;
}

export interface SetContactPreferencesOptInSuccessAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_SUCCESS;
}

export interface SetContactPreferencesOptinfailureAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_FAILURE;
  error: ProfileErrorState;
}

export interface SetContactPreferencesOptInResetAction {
  type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_RESET;
}

export interface CheckEditMailAuthorizeIsEnableRequestAction {
  type: ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_REQUEST;
}

export interface CheckEditMailAuthorizeIsEnableSuccessAction {
  type: ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_SUCCESS;
  editMailIsEnable: boolean;
}

export interface CheckEditMailAuthorizeIsEnableFailureAction {
  type: ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_FAILURE;
  error: ProfileErrorState;
}

export interface CheckEditPhoneNumberAuthorizeIsEnableRequestAction {
  type: ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_REQUEST;
}

export interface CheckEditPhoneNumberAuthorizeIsEnableSuccessAction {
  type: ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_SUCCESS;
  editPhoneNumberIsEnable: boolean;
}

export interface CheckEditPhoneNumberAuthorizeIsEnableFailureAction {
  type: ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_FAILURE;
  error: ProfileErrorState;
}

//help contact
export interface GetContactInfosRequestAction {
  type: ProfileActionsType.GET_CONTACT_INFOS_REQUEST;
}

export interface GetContactInfosSuccessAction {
  type: ProfileActionsType.GET_CONTACT_INFOS_SUCCESS;
  email: emailInfosType;
  phone: phoneInfosType;
  postalRc: postalInfosType;
  postalEs: postalInfosType;
}

export interface GetContactInfosFailureAction {
  type: ProfileActionsType.GET_CONTACT_INFOS_FAILURE;
}

export interface PostContactFormRequestAction {
  type: ProfileActionsType.POST_CONTACT_FORM_REQUEST;
  message: string;
  object: string;
}

export interface PostContactFormRequestFailureAction {
  type: ProfileActionsType.POST_CONTACT_FORM_FAILURE;
  error: ProfileErrorState;
}

export interface PostContactFormRequestSuccessAction {
  type: ProfileActionsType.POST_CONTACT_FORM_SUCCESS;
}

export interface PostContactFormRequestSuccessAction {
  type: ProfileActionsType.POST_CONTACT_FORM_SUCCESS;
}

export interface ResetContactFormStateAction {
  type: ProfileActionsType.RESET_CONTACT_FORM_STATE;
}

export type ProfileAction =
  | GetConsentsRequestAction
  | GetConsentsSuccessAction
  | GetConsentsFailureAction
  | AcceptConsentsFailureAction
  | AcceptConsentsRequestAction
  | AcceptConsentsSuccessAction
  | GetProfileMenuRequestAction
  | GetProfileMenuSuccessAction
  | GetProfileMenuFailureAction
  | GetProfileUserInfosRequestAction
  | GetProfileUserInfosSuccessAction
  | GetProfileUserInfosFailureAction
  | SendProfileUserInfoChangeRequestAction
  | SendProfileUserInfoChangeSuccessAction
  | SendProfileUserInfoChangeFailureAction
  | ValidateProfileUserInfoChangeRequestAction
  | ValidateProfileUserInfoChangeSuccessAction
  | ValidateProfileUserInfoChangeFailureAction
  | EditPasswordResetAction
  | EditPasswordRequestAction
  | EditPasswordSuccessAction
  | EditPasswordFailureAction
  | OtpFlowStartRequestAction
  | OtpFlowStartSuccessAction
  | OtpFlowStartFailureAction
  | OtpFlowInitializeChanelRequestAction
  | OtpFlowInitializeChanelFailureAction
  | OtpFlowInitializeChanelSuccessAction
  | AskNavigationToUrlAction
  | OtpFlowAskCodeRequestAction
  | OtpFlowAskCodeFailureAction
  | OtpFlowAskCodeSuccessAction
  | OtpFlowAskCodeAgainRequestAction
  | OtpFlowAskCodeAgainFailureAction
  | OtpFlowAskCodeAgainSuccessAction
  | OtpFlowSendCodeRequestAction
  | OtpFlowSendCodeSuccessAction
  | OtpFlowSendCodeFailureAction
  | OtpFlowCompletedValidationRequestAction
  | OtpFlowCompletedValidationSuccessAction
  | OtpFlowCompletedValidationFailureAction
  | UpdateProfileOtpParametersAction
  | ResetProfileOtpParametersAction
  | ResetProfileOtpFlowAction
  | InitProfileOtpErrorAction
  | ProfileInitErrorAction
  | InitAllErrorsAction
  | UpdateContactPreferenceRequestAction
  | UpdateContactPreferenceSuccessAction
  | UpdateContactPreferenceSuccessResetAction
  | UpdateContactPreferencesFailureAction
  | GetContactPreferenceRequestAction
  | GetContactPreferenceSuccessAction
  | GetContactPreferencesFailureAction
  | SetContactPreferencesOptInEmailAction
  | SetContactPreferencesOptInSmsAction
  | SetContactPreferencesHasNotificationsActivatedAction
  | GetEditorialContentRequestAction
  | GetEditorialContentSuccessAction
  | GetEditorialContentFailureAction
  | GetContactPreferenceOptinRequestAction
  | GetContactPreferenceOptinSuccessAction
  | GetContactPreferencesOptinFailureAction
  | SetContactPreferencesOptInRequestAction
  | SetContactPreferencesOptInSuccessAction
  | SetContactPreferencesOptinfailureAction
  | SetContactPreferencesOptInResetAction
  | SetBiometricLockingUserFromProfileMenuAction
  | CheckEditMailAuthorizeIsEnableRequestAction
  | CheckEditMailAuthorizeIsEnableSuccessAction
  | CheckEditMailAuthorizeIsEnableFailureAction
  | CheckEditPhoneNumberAuthorizeIsEnableRequestAction
  | CheckEditPhoneNumberAuthorizeIsEnableSuccessAction
  | CheckEditPhoneNumberAuthorizeIsEnableFailureAction
  | GetProfileAddressesSuccessAction
  | GetProfileUserInfosFailureAction
  | UpdateProfileAddressRequestAction
  | UpdateProfileAddressFailureAction
  | UpdateProfileAddressSuccessAction
  | ResetProfileAddressStatusAction
  | SetProfileAddressPlanFamilyAction
  | GetContactInfosRequestAction
  | GetContactInfosSuccessAction
  | GetContactInfosFailureAction
  | PostContactFormRequestAction
  | PostContactFormRequestFailureAction
  | PostContactFormRequestSuccessAction
  | ResetContactFormStateAction;
