import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { locator } from '@constants/locator';
import { CardWithLeftThread, Text } from '@ere-uilib/atoms';
import { FiletStepper, HeaderResponse } from '@ere-uilib/molecules';

import { StepProps } from './types';
import { useStyles } from './useStyles';

export function FiletStepperCardWrapper({
  title,
  planName,
  isRealExternalPlan = true,
  borderLeftColor,
  headerResponseCustomContainerStyle,
  headerResponseAmount,
  headerResponseTitle,
  showHeaderResponseDarkText,
  disableLine,
  disableIcone,
  children,
  containerStyle,
  isExpandable = false,
  isDefaultExpanded = true,
  displayFiletTitle = true,
  withoutHorizontalMargin = false
}: StepProps) {
  const styles = useStyles(displayFiletTitle, withoutHorizontalMargin);
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  const toggleExpanded = useCallback(
    () => setIsExpanded(isActuallyExpanded => !isActuallyExpanded),
    []
  );
  return (
    <FiletStepper
      containerStyle={styles.filetContainer}
      contentContainer={containerStyle}
      disableIcone={displayFiletTitle ? disableIcone : true}
      disableLine={disableLine}
      filetContainerStyle={styles.filetSubContainer}>
      <View
        style={styles.fromContent}
        testID={locator._pi._plan_container + planName}>
        {!!title && displayFiletTitle && (
          <Text
            style={styles.filetText}
            testId={title}>
            {title}
          </Text>
        )}
        <CardWithLeftThread
          addShadow={true}
          borderLeftColor={borderLeftColor}>
          {!!headerResponseTitle && (
            <HeaderResponse
              amount={headerResponseAmount}
              customContainerStyle={[
                styles.header,
                isExpanded && styles.headerExpanded,
                headerResponseCustomContainerStyle
              ]}
              darkText={showHeaderResponseDarkText}
              disabled={!isExpandable}
              hideAmount={!isRealExternalPlan}
              onPress={toggleExpanded}
              title={headerResponseTitle}
            />
          )}
          {isExpanded && children}
        </CardWithLeftThread>
      </View>
    </FiletStepper>
  );
}
