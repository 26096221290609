import React from 'react';
import { View } from 'react-native';

import { Modal, ModalFrame, SimpleButton, SVGLocalLoader, Text } from '@components/index';
import { ContactFormPopinContentTypeEnum } from '@modules/profile/types';

import { useDictionary } from './useDictionary';
import { useErrorAndSucessPopPin } from './useErrorAndSuccessPopin';
import { useStyles } from './useStyles';

interface Props {
  onPressReturnToProfilButton: () => void;
  onPressReturnToFormButton: () => void;
  description: string;
  popinFormType: ContactFormPopinContentTypeEnum | undefined;
}

export const ContactFormErrorAndSuccessPopin = ({
  popinFormType,
  onPressReturnToProfilButton,
  onPressReturnToFormButton,
  description
}: Props) => {
  const styles = useStyles();
  const dictionary = useDictionary();
  const PopinContentTypeInfos = useErrorAndSucessPopPin(
    onPressReturnToFormButton,
    onPressReturnToProfilButton
  );
  const currentStateInfos = PopinContentTypeInfos[popinFormType || 'DEFAULT'];

  return (
    <Modal visible={!!popinFormType}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom">
        <View>
          <View style={styles.iconSuccessStyle}>
            <SVGLocalLoader name={currentStateInfos.loaderName} />
          </View>
          <View style={styles.textContainerStyle}>
            <Text variant="t2">{currentStateInfos.title}</Text>

            <Text
              style={styles.subTitle}
              variant="t2"
              weight="light">
              {currentStateInfos.subTitle}
            </Text>
            {description && popinFormType === ContactFormPopinContentTypeEnum.SUCCESS && (
              <Text
                style={styles.subTitle}
                variant="t3"
                weight="bold">
                {description}
              </Text>
            )}
          </View>
          {popinFormType === ContactFormPopinContentTypeEnum.ERROR && (
            <SimpleButton
              containerStyle={styles.backToMenuButton}
              design="outlined"
              onPress={onPressReturnToProfilButton}
              testId={dictionary.contactFormPagePopinProfilePageButton}
              title={dictionary.contactFormPagePopinProfilePageButton}
            />
          )}
          <SimpleButton
            onPress={currentStateInfos.returnAction}
            testId={currentStateInfos.returnActionLabel}
            title={currentStateInfos.returnActionLabel}
          />
        </View>
      </ModalFrame>
    </Modal>
  );
};
