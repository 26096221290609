import React from 'react';

import { useTranslation } from '@components/ERE-UILib';
import { NotificationIconTypeEnum, Paragraph } from '@ere-uilib/atoms';
import { NotificationHard } from '@ere-uilib/molecules';

export const NotificationExternalPlan = () => {
  const { formatMessage } = useTranslation();

  return (
    <NotificationHard
      title={formatMessage({ id: 'DetailOperationWarningTitreDispoExterne' })}
      type={NotificationIconTypeEnum.INFO}>
      <Paragraph
        variant="t3"
        weight="light">
        {formatMessage({ id: 'DetailOperationWarningDispoExterne' })}
      </Paragraph>
    </NotificationHard>
  );
};
