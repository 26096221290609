import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
  childrenStyle?: StyleProp<ViewStyle>;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  childrenStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {theme: ThemeType};

export const Card: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  childrenStyle,
  containerStyle
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle, childrenStyle });

  return (
    <View style={styles.containerStyle}>
      <View style={styles.childrenStyle}>{children}</View>
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      borderRadius: context?.theme.metrics.borderRadius.xs
    },
    style?.containerStyle
  ],
  childrenStyle: [{}, style?.childrenStyle]
});

const useStyles = createUseStyles(getStyles);
