import React, { useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle, TouchableOpacity, Pressable } from 'react-native';

import { locator } from '@constants/locator';
import { FontIcon, Text } from '@ere-uilib/atoms';
import { OperationsHistoryStatusEnum } from '@ere-uilib/enums';
import { EstimatedContribution } from '@ere-uilib/organisms';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { ConditionalOrderType, NetAssetValueType } from '@ere-uilib/types';
import { AvailabilityItem } from '@modules/common/types';

import { SynthetisFundInnerColumns } from '../SynthetisFundInnerColumns';
import { Styles, getStyles } from './styles';

interface Props extends Styles {
  title?: string | null;
  amount?: number;
  hasContribution?: boolean;
  contribution?: number;
  onTitlePress?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  hasContributionError?: boolean;
  percentage?: number;
  numberOfUnits?: number;
  netAssetValue?: NetAssetValueType;
  showMoreSupportData?: boolean;
  conditionalOrder?: ConditionalOrderType;
  statusCode?: string;
  availabilityItems?: AvailabilityItem[];
}
const contentStyles = createUseStyles(getStyles);

export const SynthesisFundsRow: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  title,
  amount,
  hasContribution,
  contribution,
  onTitlePress,
  hasContributionError,
  percentage,
  numberOfUnits,
  netAssetValue,
  showMoreSupportData = false,
  conditionalOrder,
  statusCode,
  availabilityItems
}) => {
  const theme = useTheme();
  const isTitlePressable = !!onTitlePress;
  const isBottomCard = showMoreSupportData;
  const isMobile = useScreenSizes().isMobile;
  const styles = contentStyles(
    { isMobile, theme, isTitlePressable, isBottomCard },
    { containerStyle }
  );
  const {
    formatMessage,
    formatDate,
    formatCurrencyNumberWithPlusMinus,
    formatPercentNumberWithPlusMinus
  } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <View
        style={styles.containerStyle}
        testID={locator._pi._plan_children}>
        <TouchableOpacity
          disabled={!isTitlePressable}
          onPress={onTitlePress}
          style={styles.titleContainerStyle}>
          <Text
            numberOfLines={2}
            style={styles.titleTextStyle}
            variant="t3">
            {title}
          </Text>
        </TouchableOpacity>
        <View style={styles.rowLabelsContainerStyle}>
          {amount !== undefined && (
            <Text variant="t3">{formatCurrencyNumberWithPlusMinus({ value: amount || 0 })}</Text>
          )}
          {percentage !== undefined && (
            <Text
              style={styles.percentageText}
              variant="t3">
              {formatPercentNumberWithPlusMinus({ value: percentage || 0 })}
            </Text>
          )}
          {hasContribution && (contribution || 0) > 0 && (
            <EstimatedContribution contribution={contribution} />
          )}
        </View>
      </View>
      {conditionalOrder?.thresholdType && (
        <>
          <View style={styles.rowStyle}>
            <Text>
              <Text
                variant="t3"
                weight="light">
                {formatMessage({
                  id: `DetailOperationSeuilSousJacent${conditionalOrder?.thresholdType}Description`,
                  values: {
                    valeur_seuil: ''
                  }
                })}
              </Text>
              <Text
                variant="t3"
                weight="regular">
                {`${conditionalOrder?.threshold} €`}
              </Text>
            </Text>
          </View>
          <View style={styles.rowStyle}>
            <Text>
              <Text
                variant="t3"
                weight="light">
                {formatMessage({
                  id: `DetailOperationSeuilLast${conditionalOrder?.thresholdType}Description`,
                  values: {
                    date_valeur: formatDate({
                      value: conditionalOrder?.netAssetDate,
                      options: {
                        dateStyle: 'short'
                      }
                    }),
                    cours: ''
                  }
                })}
              </Text>
              <Text
                variant="t3"
                weight="regular">
                {`${conditionalOrder?.netAssetValue} €`}
              </Text>
            </Text>
          </View>
          {statusCode && statusCode === OperationsHistoryStatusEnum.IN_PROGRESS && (
            <View style={styles.rowStyle}>
              <Text>
                <Text
                  variant="t3"
                  weight="light">
                  {formatMessage({
                    id: 'DetailOperationSeuilAnnulationConditionsDescription',
                    values: {
                      date_fin_validite: ''
                    }
                  })}
                </Text>
                <Text
                  variant="t3"
                  weight="regular">
                  {formatDate({
                    value: conditionalOrder?.expirationDate,
                    options: {
                      dateStyle: 'short'
                    }
                  })}
                </Text>
              </Text>
            </View>
          )}
        </>
      )}

      <View style={styles.detailsContainerStyle}>
        {/* TODO: remove condition once the back sends the needed values */}
        {showMoreSupportData && (
          <>
            <View>
              <Pressable
                disabled={false}
                onPress={() => setExpanded(!expanded)}>
                <View style={styles.checkBoxContainerStyle}>
                  <Text
                    style={styles.subTitleStyle}
                    variant="t3">
                    {formatMessage({ id: 'DetailOperationDetailLabel' })}
                  </Text>
                  <FontIcon
                    color={theme.colors.text.link.c500}
                    name={expanded ? 'chevron-haut' : 'chevron-bas'}
                    size={theme.metrics.iconSizes.xm}
                    style={styles.chevronStyle}
                  />
                </View>
              </Pressable>
            </View>
            {expanded && (
              <SynthetisFundInnerColumns
                details={availabilityItems}
                netAssetValue={netAssetValue}
                numberOfUnits={numberOfUnits}
                statusCode={statusCode}></SynthetisFundInnerColumns>
            )}
          </>
        )}
      </View>
    </>
  );
};
