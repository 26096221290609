import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { View } from 'react-native';

import { useTranslation, Text, Title } from '@components/index';
import { locator } from '@constants/locator';
import { SVGLocalLoader } from '@ere-uilib/atoms';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { PrimeAmountCardProps } from './interfaces';
export interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}
import { useStyles } from './useStyles';
import { label } from '../../label';

export const PrimeAmountCard: React.FC<React.PropsWithChildren<PrimeAmountCardProps>> = ({
  startDate,
  endDate,
  primeAmount,
  companyAmount,
  mediumGross,
  derogatoryAmount,
  style
}) => {
  const styles = useStyles({ style });
  const { formatMessage, formatDate, formatCurrencyNumber } = useTranslation();

  return (
    <View style={styles.premiumAmountCard}>
      <View>
        <View style={styles.AmountCardHeader}>
          <View style={styles.AmountCardHeaderDetail}>
            <Text weight="light">
              {formatMessage({
                id: label.prime.title,
                values: {
                  date_debut: formatDate({ value: startDate }),
                  date_fin: formatDate({ value: endDate })
                }
              })}
            </Text>
            <Text>{formatMessage({ id: label.prime.subtitle })}</Text>
            <Title
              style={styles.companyAmount}
              variant={'t3'}
              weight="bold">
              {formatCurrencyNumber({ value: primeAmount })}
            </Title>
          </View>
          <View style={styles.AmountCardImage}>
            <SVGLocalLoader name="prime" />
          </View>
        </View>
        {!!derogatoryAmount && (
          <View style={styles.infoSet}>
            <Text weight="light">
              {formatMessage({ id: label.prime.derogatoryAmount })}{' '}
              {formatCurrencyNumber({ value: derogatoryAmount })}
            </Text>
          </View>
        )}
        {!!companyAmount && (
          <View
            style={styles.infoSet}
            testID={locator._pi._company_amount}>
            <Text weight="light">
              {formatMessage({ id: label.prime.companyAmount })}{' '}
              {formatCurrencyNumber({ value: companyAmount })}
            </Text>
          </View>
        )}
        {!!mediumGross && (
          <View style={styles.infoSet}>
            <Text weight="light">
              {formatMessage({ id: label.prime.mediumGross })}{' '}
              {formatCurrencyNumber({ value: mediumGross })}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
