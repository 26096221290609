import { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';

export const useDictionary = () => {
  const { formatMessage } = useTranslation();

  const dictionary = useMemo(() => {
    return {
      mailEsContactTitle: formatMessage({ id: 'ContactMailPageTitleES' }),
      mailEsContactSubTitle: formatMessage({ id: 'ContactMailPageDescriptionES' }),
      mailRcContactTitle: formatMessage({ id: 'ContactMailPageTitleRC' }),
      mailRcContactSubTitle: formatMessage({ id: 'ContactMailPageDescriptionRC' }),
      mailRcContactAddress: formatMessage({ id: 'ContactMailAddressTitleRC' }),
      mailRcContactMail: formatMessage({ id: 'ContactMailAddressRC' }),
      contactMailPageButtonES: formatMessage({ id: 'ContactMailPageButtonES' }),
      copyMail: formatMessage({ id: 'ContactMailPageCopyEmailRC' }),
      copied: formatMessage({ id: 'ContactMailPageCopiedEmailRC' })
    };
  }, [formatMessage]);

  return dictionary;
};
