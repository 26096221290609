import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '@ere-uilib/translations';
import { AppState } from '@modules/reducers';
import { modifyOperationRequest } from '@modules/savings/actions/savingsActions';
import { SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';

export const useData = () => {
  const isModifyLoading = useSelector(
    (state: AppState) => state.isLoading[SavingsActionsType.MODIFY_OPERATION_REQUEST]
  );
  const modifyError = useSelector(
    (state: AppState) => state.error[SavingsActionsType.MODIFY_OPERATION_REQUEST]
  );
  const { formatMessage } = useTranslation();
  const dictionary = {
    yes: formatMessage({ id: 'PIAnnulationOuiBouton' }),
    no: formatMessage({ id: 'PIAnnulationNonBouton' }),
    title: formatMessage({ id: 'PIModificationTitre' })
  };
  const dispatch = useDispatch();

  const onModifyOperation = useCallback(() => {
    dispatch(modifyOperationRequest());
  }, [dispatch]);

  return {
    onModifyOperation,
    isModifyLoading,
    modifyError,
    dictionary
  };
};
