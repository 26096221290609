import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/index';

import {
  ContributionInput,
  ProfitSharingIncentiveAmountValidationParamsType,
  ProfitSharingIncentiveAssigmentAmountsType,
  ProfitSharingIncentiveCalculatedContributionState,
  ProfitSharingIncentiveCalculContributionParamsType,
  ProfitSharingIncentiveErrorState,
  ProfitSharingIncentiveInitDataState,
  ProfitSharingIncentiveRepartitionPlansState
} from '../types';

export enum ProfitSharingIncentiveActionsType {
  GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST = 'GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST',
  GET_PARTICIPATION_PROFITSHARING_INIT_DATA_SUCCESS = 'GET_PARTICIPATION_PROFITSHARING_INIT_DATA_SUCCESS',
  GET_PARTICIPATION_PROFITSHARING_INIT_DATA_FAILURE = 'GET_PARTICIPATION_PROFITSHARING_INIT_DATA_FAILURE',
  SET_PARTICIPATION_PROFITSHARING_ASSIGMENT_ALLREFUND_AMOUNTS =
  'SET_PARTICIPATION_PROFITSHARING_ASSIGMENT_ALLREFUND_AMOUNTS',
  GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST = 'GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST',
  GET_PARTICIPATION_PROFITSHARING_REPARTITION_SUCCESS = 'GET_PARTICIPATION_PROFITSHARING_REPARTITION_SUCCESS',
  GET_PARTICIPATION_PROFITSHARING_REPARTITION_FAILURE = 'GET_PARTICIPATION_PROFITSHARING_REPARTITION_FAILURE',
  SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE =
  'SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE',
  SET_PARTICIPATION_PROFITSHARING_REPARTITION_RETIREMENT_VALIDATION_MODAL_VISIBLE_STATE =
  'SET_PARTICIPATION_PROFITSHARING_REPARTITION_RETIREMENT_VALIDATION_MODAL_VISIBLE_STATE',
  PARTICIPATION_PROFITSHARING_VALIDATE_RETIREMENT_AMOUNT_MODAL =
  'PARTICIPATION_PROFITSHARING_VALIDATE_RETIREMENT_AMOUNT_MODAL',
  PARTICIPATION_PROFITSHARING_CANCEL_RETIREMENT_AMOUNT_MODAL =
  'PARTICIPATION_PROFITSHARING_CANCEL_RETIREMENT_AMOUNT_MODAL',
  SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST =
  'SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST',
  SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_SUCCESS =
  'SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_SUCCESS',
  SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_FAILURE =
  'SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_FAILURE',
  GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST =
  'GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST',
  GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_SUCCESS =
  'GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_SUCCESS',
  GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_FAILURE =
  'GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_FAILURE',
  RESET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION =
  'RESET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION',
  SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST =
  'SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST',
  SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_SUCCESS =
  'SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_SUCCESS',
  SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_FAILURE =
  'SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_FAILURE',
  CLEAR_PROFIT_SHARING_INCENTIVE_DATA = 'CLEAR_PROFIT_SHARING_INCENTIVE_DATA',
  SET_ESTIMATE_CONTRIBUTION_INPUT = 'SET_ESTIMATE_CONTRIBUTION_INPUT',
}

export interface GetProfitSharingIncentiveInitDataRequestAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST;
  profitSharingIncentiveType: ProfitSharingIncentiveTypeEnum
  profitSharingIncentiveLegalFramework: ProfitSharingIncentiveLegalFrameworkEnum
  transactionId?: string
}

export interface GetProfitSharingIncentiveInitDataSuccessAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_SUCCESS;
  initData: ProfitSharingIncentiveInitDataState
}

export interface GetProfitSharingIncentiveInitDataFailureAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_FAILURE;
  error: ProfitSharingIncentiveErrorState;
}

export interface SetProfitSharingIncentiveAssigmentAllRefundAmountsAction {
  type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_ASSIGMENT_ALLREFUND_AMOUNTS;
  amounts: ProfitSharingIncentiveAssigmentAmountsType
}

export interface GetProfitSharingIncentiveRepartitionRequestAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST;
  amounts: ProfitSharingIncentiveAssigmentAmountsType
}

export interface GetProfitSharingIncentiveRepartitionSuccessAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_SUCCESS;
  plans: ProfitSharingIncentiveRepartitionPlansState[]
  amounts: ProfitSharingIncentiveAssigmentAmountsType
}

export interface GetProfitSharingIncentiveRepartitionFailureAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_FAILURE;
  error: ProfitSharingIncentiveErrorState;
}

// set repartition amount field modal visible
export interface SetProfitSharingIncentiveRepartitionAmountModalVisibleStateAction {
  type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE;
  state: boolean;
}

// set repartition retirement validation modal visible
export interface SetProfitSharingIncentiveRepartitionRetirementValidationModalVisibleStateAction {
  type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_RETIREMENT_VALIDATION_MODAL_VISIBLE_STATE;
  state: boolean;
}
export interface ValidateProfitSharingIncentiveRetirementAmountModalAction {
  type: ProfitSharingIncentiveActionsType.PARTICIPATION_PROFITSHARING_VALIDATE_RETIREMENT_AMOUNT_MODAL;
}

export interface CancelProfitSharingIncentiveRetirementAmountModalAction {
  type: ProfitSharingIncentiveActionsType.PARTICIPATION_PROFITSHARING_CANCEL_RETIREMENT_AMOUNT_MODAL;
}

// set repartition amount
export interface SetProfitSharingIncentiveRepartitionAmountRequestAction {
  type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST;
  params: ProfitSharingIncentiveAmountValidationParamsType
}

export interface SetProfitSharingIncentiveRepartitionAmountSuccessAction {
  type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_SUCCESS;
  calculatedContribution?: ProfitSharingIncentiveCalculatedContributionState[] | null
  profitSharingIncentiveAmountValidationParams: ProfitSharingIncentiveAmountValidationParamsType | undefined
}

export interface SetProfitSharingIncentiveRepartitionAmountFailureAction {
  type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_FAILURE;
  error: ProfitSharingIncentiveErrorState;
  params?: ProfitSharingIncentiveAmountValidationParamsType
}

// get calculated contribution
export interface GetProfitSharingIncentiveCalculatedContributionRequestAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST;
  params: ProfitSharingIncentiveCalculContributionParamsType
}

export interface GetProfitSharingIncentiveCalculatedContributionSuccessAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_SUCCESS;
  contributionData: ProfitSharingIncentiveCalculatedContributionState[];
  params: ProfitSharingIncentiveCalculContributionParamsType
}

export interface GetProfitSharingIncentiveCalculatedContributionFailureAction {
  type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_FAILURE;
  error: ProfitSharingIncentiveErrorState;
}
export interface ResetProfitSharingIncentiveCalculatedContributionAction {
  type: ProfitSharingIncentiveActionsType.RESET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION;
}

// Submit operation
export interface SendProfitSharingIncentiveSubmitAllocationRequestAction {
  type: ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST;
}

export interface SendProfitSharingIncentiveSubmitAllocationSuccessAction {
  type: ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_SUCCESS;
}

export interface SendProfitSharingIncentiveSubmitAllocationFailureAction {
  type: ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_FAILURE;
  error: ProfitSharingIncentiveErrorState | string;
}

export interface ClearProfitSharingIncentiveDataAction {
  type: ProfitSharingIncentiveActionsType.CLEAR_PROFIT_SHARING_INCENTIVE_DATA
}
export interface SetEstimateContributionInputAction {
  type: ProfitSharingIncentiveActionsType.SET_ESTIMATE_CONTRIBUTION_INPUT;
  payload: ContributionInput[]
}

export type ProfitSharingIncentiveAction =
  | GetProfitSharingIncentiveInitDataRequestAction
  | GetProfitSharingIncentiveInitDataSuccessAction
  | GetProfitSharingIncentiveInitDataFailureAction
  | SetProfitSharingIncentiveAssigmentAllRefundAmountsAction
  | GetProfitSharingIncentiveRepartitionRequestAction
  | GetProfitSharingIncentiveRepartitionSuccessAction
  | GetProfitSharingIncentiveRepartitionFailureAction
  | SetProfitSharingIncentiveRepartitionAmountModalVisibleStateAction
  | SetProfitSharingIncentiveRepartitionRetirementValidationModalVisibleStateAction
  | ValidateProfitSharingIncentiveRetirementAmountModalAction
  | CancelProfitSharingIncentiveRetirementAmountModalAction
  | SetProfitSharingIncentiveRepartitionAmountRequestAction
  | SetProfitSharingIncentiveRepartitionAmountSuccessAction
  | SetProfitSharingIncentiveRepartitionAmountFailureAction
  | GetProfitSharingIncentiveCalculatedContributionRequestAction
  | GetProfitSharingIncentiveCalculatedContributionSuccessAction
  | GetProfitSharingIncentiveCalculatedContributionFailureAction
  | ResetProfitSharingIncentiveCalculatedContributionAction
  | SendProfitSharingIncentiveSubmitAllocationRequestAction
  | SendProfitSharingIncentiveSubmitAllocationSuccessAction
  | SendProfitSharingIncentiveSubmitAllocationFailureAction
  | ClearProfitSharingIncentiveDataAction
  | SetEstimateContributionInputAction
