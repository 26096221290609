import axios from 'axios';

import { API_URL } from '@config/index';
import { FundsState } from '@modules/funds/types';

export async function fetchFundOverview(fundApiUrl: string, isinCode: string): Promise<FundsState> {
  return axios.get(`${fundApiUrl}/api/Overview/FromIsinCode/${isinCode}`);
}

export async function fetchFundRepartitionSupports(
  isingCode: string,
  companyId: string
): Promise<FundsState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/repartitions/supports`, {
    params: {
      isins: isingCode
    }
  });
}

export async function fetchFundPerformance(
  fundApiUrl: string,
  isinCode: string
): Promise<FundsState> {
  return axios.get(`${fundApiUrl}/api/Performances/FromIsinCode/${isinCode}`);
}

export async function fetchFundsDocumentsList(
  fundApiUrl: string,
  isinCode: string
): Promise<FundsState> {
  return axios.get(`${fundApiUrl}/api/Document/FromIsinCode/${isinCode}`);
}

export async function fetchMultiFundsOverviews(
  fundApiUrl: string,
  isinCodes: string[]
): Promise<FundsState> {
  return axios.post(`${fundApiUrl}/api/Overview/FromIsinCodes`, isinCodes);
}

export async function fetchMultiFundsPerformances(
  fundApiUrl: string,
  isinCodes: string[]
): Promise<FundsState> {
  return axios.post(`${fundApiUrl}/api/Performances/FromIsinCodes`, isinCodes);
}

export const postDownloadHistory = async (fundApiUrl: string, isinCodes: string[]) => {
  return axios.post(`${fundApiUrl}/api/History/DownloadExcelFromIsinCode`, isinCodes, {
    responseType: 'blob'
  });
};
