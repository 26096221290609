import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

export interface BankAccountInfoType {
  rum: string | null;
  ics: string | null;
  iban: string | null;
  bic: string | null;
  domiciliation?: string | null;
  titulaire?: string | null;
  type?: string | null;
}
interface Props extends Styles {
  containerStyle?: StyleProp<ViewStyle>;
  AccountInfoData: BankAccountInfoType;
  NoDisplayPaymentChoice?: boolean;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subContainerStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType };

export const BankAccountInfosCard: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  AccountInfoData,
  NoDisplayPaymentChoice
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle });
  const { formatMessage } = useTranslation();

  return (
    <View style={styles.containerStyle}>
      {!NoDisplayPaymentChoice && (
        <View style={styles.subContainerStyle}>
          <Title
            style={styles.titleStyle}
            variant="t7"
          >
            {formatMessage({ id: 'VV_paymentmode_bankaccount_label' })}
          </Title>
          <Text
            variant="t3"
            weight="light"
          >
            {formatMessage({ id: 'VV_paymentmode_mandate_label' })}
            {AccountInfoData?.rum}
          </Text>
          <Text
            variant="t3"
            weight="light"
          >
            {formatMessage({ id: 'VV_paymentmode_ics_label' })} : {AccountInfoData?.ics}
          </Text>
        </View>
      )}
      <View>
        {!!formatMessage({ id: 'operation_source_directdebit_vv_label' }).trim() && (
          <Title
            style={styles.titleStyle}
            variant="t7"
          >
            {formatMessage({ id: 'operation_source_directdebit_vv_label' })}
          </Title>
        )}
        <Text
          variant="t3"
          weight="light"
        >
          {formatMessage({ id: 'VV_paymentmode_iban_label' })}
          {` ${AccountInfoData?.iban}`}
        </Text>
        {
          AccountInfoData?.bic && <Text
                variant="t3"
                weight="light"
            >
              {formatMessage({ id: 'VV_paymentmode_bic_label' })}
              {` ${AccountInfoData?.bic}`}
            </Text>
        }
      </View>
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      flex: 1
    },
    style?.containerStyle
  ],
  titleStyle: [
    {
      marginBottom: 0
    },
    style?.titleStyle
  ],
  subContainerStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.l
    },
    style?.subContainerStyle
  ]
});

const useStyles = createUseStyles(getStyles);
