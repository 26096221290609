import { PlanFamillyList } from '@constants/common';

import { FormValues } from '../types';

export type FieldError = {
  required?: string;
  max?: string;
  regex?: string;
};

export type Field = {
  title?: string;
  error?: FieldError;
};

export type Label = Partial<{
  [planFamily in PlanFamillyList | 'default']: {
    field: Partial<{
      [key in keyof FormValues]: Field;
    }>;
    button?: {
      next?: string;
    };
  };
}>;

export const label: Label = {
  default: {
    field: {
      additionalAddress_1: {
        title: 'PostalAdressPageAddressee'
      },
      street: {
        title: 'PostalAdressPageStreetNumberAndName',
        error: {
          required: 'PostalAdressPageMissingMandatoryInputs'
        }
      },
      additionalAddress_3: {
        title: 'PostalAdressPageAdditionalAdress'
      },
      zipcode: {
        title: 'PostalAdressPagePostalCode',
        error: {
          required: 'PostalAdressPageMissingMandatoryInputs',
          max: 'PostalAdressPagePostalCodeLimitErrorMessage'
        }
      },
      city: {
        title: 'PostalAdressPageCity',
        error: {
          required: 'PostalAdressPageMissingMandatoryInputs'
        }
      },
      country: {
        title: 'PostalAdressPageCountry',
        error: {
          required: 'PostalAdressPageMissingMandatoryInputs'
        }
      }
    },
    button: {
      next: 'PostalAdressPageContinue'
    }
  },
  [PlanFamillyList.RC]: {
    field: {
      additionalAddress_1: {
        error: {
          regex: 'PostalAdressPageInvalidCharactersRC'
        }
      },
      additionalAddress_3: {
        error: {
          regex: 'PostalAdressPageInvalidCharactersRC'
        }
      },
      street: {
        error: {
          regex: 'PostalAdressPageInvalidCharactersRC'
        }
      },
      city: {
        error: {
          regex: 'PostalAdressPageInvalidCharactersRC'
        }
      }
    }
  }
};
