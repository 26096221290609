import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        buttonsContainer: {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-evenly',
          gap: theme.metrics.spacing.xm
        }
      }),
    [theme, isDesktop]
  );

  return styles;
}
