export enum ProfitSharingIncentiveTypeEnum {
  profitsharing = 'PARTICIPATION',
  incentive = 'INTERESSEMENT'
}

export enum ProfitSharingIncentiveLegalFrameworkEnum {
  complementary = 'COMPLEMENTAIRE',
  exceptional = 'EXCEPTIONNEL',
  normal = 'NORMAL',
  ppv = 'PPV',
}

export enum ProfitSharingIncentivePaymentModeTypeEnum {
  RIB = 'RIB',
  CHEQUE = 'CHEQUE',
  COMPANY = 'PAYEUSE',
  UNKNOWN = 'INCONNU',
}

export enum ProfitSharingIncentiveAllowedAffectationTypeEnum {
  REINVEST = 'SOUSCRIPTION',
  REFUND = 'REGLEMENT',
}

export enum PiProfitSharingHorizon {
  RETRAITE = "Retraite",
  PROJET = "Projet"
};

export enum ProfitSharingIncentiveCompaignOperationTypeEnum {
  profitsharing = 'PARTICIPATION_INFO_ENTREPRISE',
  incentiveNoPPV = 'INTERESSEMENT_SAUF_PPV_INFO_ENTREPRISE',
  incentiveWithPPV = 'INTERESSEMENT_PPV_INFO_ENTREPRISE'
}
