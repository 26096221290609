import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FundSheetModal,
  NotificationHard,
  NotificationIconTypeEnum,
  OperationsHeader,
  Text,
  useTranslation
} from '@components/index';
import {
  useDictionary,
  useProfitSharingController
} from "@pages/ProfitSharingIncentive";

import {
  FiletStepperCardDestination,
  FiletStepperCardOrigin,
  ProfitSharingIncentiveHeader
} from '../components';
import { useStyles } from './styles';

export const ProfitSharingIncentiveDefaultAffectationsPage: React.FC = () => {

  const { initData, isDerogatedAffectationsAvailable, renderDestinationCards, renderderogatedAffectationsCards,
    handleClose, fundSheetModalData, handleHideFundSheet } = useProfitSharingController();
  const { generatePIOriginStepperTitleLabelCode, generateDefaultAffectationsDescription, generateDefaultAffectationTitle } = useDictionary();
  const { formatMessage, getMessageRaw } = useTranslation();
  const styles = useStyles();

  const renderHeader = useCallback(
    () => (
      <ProfitSharingIncentiveHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={
          isDerogatedAffectationsAvailable
            ? getMessageRaw({ id: 'PIAffectationdefautDerogatoireAideContenuLabel' })
            : getMessageRaw({ id: 'PIAffectationdefautAideContenuLabel' })
        }
        helpPointModalTitle={formatMessage({ id: 'PIAffectationdefautAideTitreLabel' })}
        helpPointPosition="left"
        isGoBack
        onClose={handleClose}
        title={formatMessage({ id: 'PIAffectationdefautTitreLabel' })}
      />
    ),
    [getMessageRaw, formatMessage, handleClose, isDerogatedAffectationsAvailable]
  );

  const renderContent = useCallback(() => {
    const renderCards = renderDestinationCards();
    const renderAffectationCards = renderderogatedAffectationsCards();
    return (
      <View style={styles.contentContainer}>
        <View style={styles.content}>
          <NotificationHard
              containerStyle={styles.notificationContainerStyle}
              type={NotificationIconTypeEnum.WARNING}>
            <Text weight="light">
              {generateDefaultAffectationsDescription && generateDefaultAffectationsDescription}
            </Text>
          </NotificationHard>
          <View style={styles.filetStepperContainer}>
            <FiletStepperCardOrigin
                amount={initData?.netShareAmount || 0}
                title={formatMessage({ id: generatePIOriginStepperTitleLabelCode })}
            />
            {
              renderCards && <FiletStepperCardDestination
                    cards={renderCards}
                    disableLine={!isDerogatedAffectationsAvailable}
                />
            }
            {
                renderAffectationCards && <FiletStepperCardDestination
                  cards={renderAffectationCards}
                  disableLine
              />
            }
          </View>
        </View>
      </View>
    );
  }, [formatMessage, generateDefaultAffectationsDescription, generatePIOriginStepperTitleLabelCode, isDerogatedAffectationsAvailable, renderDestinationCards, renderderogatedAffectationsCards]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasColumnCenter={false}
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.leftColumnStyle}
        renderHeader={renderHeader}
        renderTopContent={() =>
          <OperationsHeader
              amount={initData?.netShareAmount || 0}
              title={generateDefaultAffectationTitle}
          />
        }>
        {renderContent()}
        {fundSheetModalData && (
          <FundSheetModal
            id={fundSheetModalData?.modalData?.id || null}
            modalIsVisible={fundSheetModalData.displayModal}
            onCloseModal={handleHideFundSheet}
            title={fundSheetModalData?.modalData?.title || null}
          />
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
