import React, { useMemo } from 'react';
import { View } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms/texts';
import { SlideButton } from '@ere-uilib/molecules/';
import { HelpPoint } from '@ere-uilib/organisms';
import { HelpPointType } from '@ere-uilib/organisms/HelpPoint/interfaces';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './styles';

interface SavingsHeaderBandProps {
  addedValue?: number;
  titleKey: string;
  amount?: number;
  swipeLeft: () => void;
  swipeRight: () => void;
  savingPlansLength: number;
  selectedPlan: number;
  helpPoint?: HelpPointType;
  estimatedNetAmount?: number;
  isEstimatedBeforeTax?: boolean;
}

export const SavingsHeaderBand = ({
  addedValue,
  titleKey,
  amount,
  swipeLeft,
  swipeRight,
  savingPlansLength,
  selectedPlan,
  helpPoint,
  estimatedNetAmount,
  isEstimatedBeforeTax
}: SavingsHeaderBandProps) => {
  const { isDesktop } = useScreenSizes();
  const { formatCurrencyNumber, formatMessage } = useTranslation();
  const styles = useStyles(addedValue);
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const isSlideButtonShown = (position: string) => {
    if (position === 'right') {
      return savingPlansLength > 1 && selectedPlan >= 0 && selectedPlan < savingPlansLength - 1;
    }
    if (position === 'left') {
      return savingPlansLength > 1 && selectedPlan > 0 && selectedPlan <= savingPlansLength - 1;
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.buttonView}>
        {isSlideButtonShown('left') && (
          <SlideButton
            direction="gauche"
            onClick={swipeLeft}
          />
        )}
      </View>

      <View style={styles.amountContainer}>
        <View style={styles.titleContainer}>
          {helpPoint && (
            <HelpPoint
              contentHtml={helpPoint?.contentHtml ?? ''}
              contentTitle={helpPoint?.contentTitle ?? ''}
              iconSize={theme.metrics.spacing.xm}
              modalTitle={helpPoint?.modalTitle ?? ''}
              style={[styles.helpPointStyle]}
            />
          )}
          <Title
            testId="my_savings_rendered_title"
            variant="t6">
            {titleKey}
          </Title>
        </View>

        <Title
          style={styles.amount}
          testId="my_savings_amount"
          variant={isDesktop ? 't2s' : 't4'}
          weight="bold">
          {formatCurrencyNumber({ value: amount || 0 })}
        </Title>

        {addedValue !== null && addedValue !== undefined && (
          <Text
            style={styles.addedValueStyle}
            testId="my_savings_potentiel_gain_loss"
            variant="t4">
            {`${formatMessage({ id: 'saving_plus-minus-value' })} ${formatCurrencyNumber({
              value: addedValue || 0
            })}`}
          </Text>
        )}
        {helpPoint && !!estimatedNetAmount && (
          <Text
            style={styles.estimatedNetAmount}
            variant="t4">
            {`${formatMessage({
              id: !!isEstimatedBeforeTax ? 'NEAvantImpotSurLeRevenuCard' : 'NENetEstiméCard'
            })}${isMobile ? '\n' : ''} ${formatCurrencyNumber({
              value: estimatedNetAmount || 0
            })}`}
          </Text>
        )}
      </View>

      <View style={styles.buttonView}>
        {isSlideButtonShown('right') && (
          <SlideButton
            direction="droite"
            onClick={swipeRight}
          />
        )}
      </View>
    </View>
  );
};
