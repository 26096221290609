import React from 'react';

import { Card } from '@components/ERE-UILib/molecules';

import { useStyles } from './useStyles';

export const ContactCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const styles = useStyles();
  return (
    <Card
      childrenStyle={styles.cardChildrenStyle}
      containerStyle={styles.cardContainerStyle}>
      {children}
    </Card>
  );
};
