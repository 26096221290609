import { useIsFocused } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';

import {
  AdvantageCard,
  BankAccountInfosCard,
  BubbleContainer,
  CardWithLeftThread,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  ExpendableGreen,
  FiletStepper,
  FundSheetModal,
  FundSheetModalDataType,
  getPlanTypeColors,
  HeaderResponse,
  InstallmentsStepper,
  OperationTotalSubHeader,
  NavigationHeader,
  NotificationHard,
  NotificationIconTypeEnum,
  SimpleButton,
  SynthesisFundsRow,
  SynthesisManagementRow,
  Text
} from '@components/index';
import {
  InstallmentDecisionVVPFrequencyEnum,
  PaymentModeList,
  PlanFamillyList
} from '@constants/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import {
  DirectionEnum,
  StepperSizeEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { constructStepperItems } from '@pages/Common/utils';
import { useIsUserCloned } from '@utils/index';

import { InstallmentSynthesisPageProps } from './interfaces';
import { getStyles } from './styles';

export const InstallmentSynthesisPageComponent: React.FC<
  React.PropsWithChildren<InstallmentSynthesisPageProps>
> = ({
  navigation,
  installmentRepartitionData,
  installmentDecisionAllResponses,
  installmentPaymentMode,
  installmentPaymentModeData,
  installmentError,
  onSendInstallmentPaymentData,
  isSendInstallmentPaymentDataLoading,
  installmentInitError,
  isEditMode,
  calculatedContributionError
}) => {
  const isFocused = useIsFocused();
  const [expanded, setExpanded] = useState(false);
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>();

  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const { formatMessage, formatCurrencyNumber, getMessageRaw } = useTranslation();
  const isUserCloned = useIsUserCloned();
  const planColors = installmentRepartitionData && theme.colors.basics.primary;
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight, expanded }, {});

  const stepperItems = constructStepperItems({
    size: isEditMode ? 2 : 4,
    direction: DirectionEnum.ROW,
    activeStep: isEditMode ? 2 : 4
  });

  const isRC = installmentRepartitionData?.planFamily === PlanFamillyList.RC;
  const HasSimulation = !!installmentRepartitionData?.advancedProperties?.HasSimulation;
  const renderAdvantageCard = () => {
    const isContributionPositive =
      !!installmentRepartitionData?.formValues?.totalContribution &&
      installmentRepartitionData?.formValues?.totalContribution > 0;
    return (
      <View style={styles.advantageCardsContainer}>
        {installmentDecisionAllResponses?.TaxOut && (
          <AdvantageCard
            advantageAmount={formatCurrencyNumber({
              value: installmentRepartitionData?.formValues?.totalAmount || 0
            })}
            advantageDescription={formatMessage({
              id: 'VV_recap_taxation_label'
            })}
            advantageTitle={formatMessage({ id: 'VV_recap_taxation_title' })}
            containerStyle={styles.advantageCard}
          />
        )}
        {installmentRepartitionData?.advancedProperties?.HasContribution &&
          isContributionPositive &&
          HasSimulation && (
            <AdvantageCard
              advantageAmount={formatCurrencyNumber({
                value: installmentRepartitionData?.formValues?.totalContribution || 0
              })}
              advantageDescription={formatMessage({
                id: 'VV_recap_contribution_label'
              })}
              advantageTitle={formatMessage({
                id: 'VV_recap_contribution_title'
              })}
              containerStyle={styles.advantageCard}
              display_netText
              hasContributionError={!!calculatedContributionError?.code}
            />
          )}
      </View>
    );
  };

  const renderSubHeader = ({ addShadow }: { addShadow: boolean }) => (
    <>
      <OperationTotalSubHeader
        addShadow={addShadow}
        mainAmount={installmentRepartitionData?.formValues?.totalAmount}
        mainTitle={formatMessage({
          id: 'VV_repartition_total_amount_label'
        })}
      />
      {!(isMobile || isTablet) && renderAdvantageCard()}
    </>
  );

  const renderHeader = () => (
    <>
      <NavigationHeader
        containerStyle={{
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.basics.grey.c200
        }}
        displayCloseButton
        headerLine={{ borderBottomWidth: 0 }}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'VV_recap_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'VV_recap_info_title' })}
        onClose={() =>
          navigation.navigate(RouteNames.InstallmentStack, {
            screen: RouteNames.InstallmentModal,
            params: {
              clearAction: InstallmentActionsType.CLEAR_INSTALLMENT_DATA
            }
          })
        }
        title={formatMessage({ id: 'VV_recap_page_title' })}>
        <View style={styles.installmentsStepperContainerStyle}>
          <InstallmentsStepper
            containerStyle={styles.stepperStyle}
            stepperItems={stepperItems}
            stepperSize={isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
          />
        </View>
      </NavigationHeader>
      {(isMobile || isTablet) && renderSubHeader({ addShadow: true })}
    </>
  );

  const renderRightDesktopColumn = () => (
    <>
      <BubbleContainer containerStyle={styles.subHeaderContainerStyle}>
        {renderSubHeader({ addShadow: false })}
      </BubbleContainer>
    </>
  );

  const renderBottomActions = () => (
    <View style={styles.bottomActionsStyle}>
      {installmentError?.message !== '' && !isSendInstallmentPaymentDataLoading && (
        <NotificationHard
          containerStyle={styles.bottomActionsErrorStyle}
          text={installmentError?.message}
          type={NotificationIconTypeEnum.WARNING}
        />
      )}
      <View style={styles.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={styles.arrowButtonStyle}
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={navigation.goBack}
          title={!(isMobile || isTablet) && formatMessage({ id: 'VV_recap_back_button' })}
        />
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          design="solid"
          disabled={isUserCloned}
          loading={isSendInstallmentPaymentDataLoading}
          onPress={onSendInstallmentPaymentData}
          size="small"
          testId={locator._payment._confirm_my_payment_synthese}
          title={formatMessage({ id: 'VV_recap_validate_button' })}
        />
      </View>
    </View>
  );

  const renderProgrammedInstallmentCard = () => {
    const frequencyNumber =
      installmentDecisionAllResponses?.VVP === InstallmentDecisionVVPFrequencyEnum.FREQUENCY_3M
        ? 3
        : installmentDecisionAllResponses?.VVP ===
            InstallmentDecisionVVPFrequencyEnum.FREQUENCY_6M && 6;
    const calendarLabel =
      installmentRepartitionData?.planFamily === PlanFamillyList.ES
        ? 'VV_recap_vp_frequency_ES_calendar_label'
        : installmentRepartitionData?.planFamily === PlanFamillyList.RC
          ? 'VV_recap_vp_frequency_RC_calendar_label'
          : undefined;

    return (
      <FiletStepper
        containerStyle={styles.filetContainer}
        disableLine={false}
        straightLine={styles.straightLineStyle}>
        <View style={styles.fromContent}>
          <Text style={styles.filetText}>{formatMessage({ id: 'VV_recap_vp_header_title' })}</Text>
          <CardWithLeftThread addShadow={true}>
            <HeaderResponse
              customContainerStyle={styles.paymentModeHeader}
              darkText
              title={
                installmentDecisionAllResponses?.VVP ===
                InstallmentDecisionVVPFrequencyEnum.FREQUENCY_1M
                  ? formatMessage({ id: 'VV_recap_vp_frequencymonthly_info' })
                  : formatMessage({
                      id: 'VV_recap_vp_frequency_info',
                      values: { frequency: frequencyNumber }
                    })
              }
              titleRight={formatMessage({ id: calendarLabel })}
            />
          </CardWithLeftThread>
        </View>
      </FiletStepper>
    );
  };

  const renderMainContent = () => {
    const selectedInstallmentPaymentModeData = installmentPaymentModeData?.filter(obj => {
      return obj.paymentMethod === installmentPaymentMode;
    });
    const planTitle = `${formatMessage({
      id: 'VV_repartition_myplan_label'
    })} ${installmentRepartitionData?.isCustomPlan ? installmentRepartitionData?.planFullName : installmentRepartitionData?.shortLabel} `;
    const bankAccountDetail = selectedInstallmentPaymentModeData?.[0]?.bankAccountDetail;
    const HasSimulation = !!installmentRepartitionData?.advancedProperties?.HasSimulation;
    return (
      <>
        {(isMobile || isTablet) && renderAdvantageCard()}
        {installmentDecisionAllResponses &&
          installmentDecisionAllResponses.VVP &&
          renderProgrammedInstallmentCard()}
        <FiletStepper
          containerStyle={styles.filetContainer}
          disableLine={false}
          straightLine={styles.straightLineStyle}>
          <View style={styles.fromContent}>
            <Text style={styles.filetText}>
              {formatMessage({ id: 'VV_recap_source_header_label' })}
            </Text>
            <CardWithLeftThread addShadow={true}>
              <HeaderResponse
                amount={
                  installmentRepartitionData?.formValues?.totalAmount &&
                  -installmentRepartitionData?.formValues?.totalAmount
                }
                customContainerStyle={styles.bankAccountHeader}
                darkText
                title={formatMessage({
                  id: 'operation_source_bankaccount_label'
                })}
              />
              <View style={styles.bankAccountContainer}>
                <Text variant="t3">
                  {installmentPaymentMode === PaymentModeList.debit
                    ? formatMessage({
                        id: 'operation_source_directdebit_label'
                      })
                    : formatMessage({ id: 'operation_source_cb_label' })}
                </Text>
                {installmentPaymentMode === PaymentModeList.debit && (
                  <ExpendableGreen
                    title={formatMessage({
                      id: 'operation_source_displayiban_label'
                    })}>
                    {bankAccountDetail && (
                      <BankAccountInfosCard
                        AccountInfoData={{
                          ...bankAccountDetail,
                          domiciliation: bankAccountDetail?.domiciliation || undefined,
                          titulaire: bankAccountDetail?.titulaire || undefined
                        }}
                      />
                    )}
                  </ExpendableGreen>
                )}
              </View>
            </CardWithLeftThread>
          </View>
        </FiletStepper>
        <FiletStepper
          containerStyle={styles.filetContainer}
          disableLine={true}>
          <View style={styles.fromContent}>
            <Text style={styles.filetText}>
              {formatMessage({ id: 'VV_recap_destination_header' })}
            </Text>
            <CardWithLeftThread
              addShadow={true}
              borderLeftColor={planColors?.c500}>
              <HeaderResponse
                amount={installmentRepartitionData?.formValues?.totalAmount}
                customContainerStyle={{ backgroundColor: planColors?.c100 }}
                darkText
                title={planTitle}
              />
              {installmentRepartitionData &&
                installmentRepartitionData?.compartments?.[0]?.managements.map(management => (
                  <>
                    {!!management.formValues?.totalAmount && (
                      <SynthesisManagementRow
                        amount={management.formValues?.totalAmount}
                        contribution={management.formValues?.totalContribution}
                        hasContribution={
                          !isRC && management?.advancedProperties?.HasContribution && HasSimulation
                        }
                        hideManagementAmount={management.isFree}
                        title={management.label || undefined}
                      />
                    )}
                    {management?.isFree &&
                      management.supports.map(support => {
                        if (support.formValues?.amount && support.formValues?.amount > 0) {
                          return (
                            <SynthesisFundsRow
                              amount={support.formValues?.amount}
                              contribution={support.formValues?.contribution}
                              hasContribution={HasSimulation && support.hasContribution}
                              onTitlePress={() => {
                                setDisplayFundSheetModal(true);
                                setFundSheetModalData({
                                  id: support?.supportIsin,
                                  title: support.supportName
                                });
                              }}
                              title={support.supportName || undefined}
                            />
                          );
                        }
                      })}
                  </>
                ))}
            </CardWithLeftThread>
          </View>
        </FiletStepper>
        {!isMobile && !isTablet && renderBottomActions()}
      </>
    );
  };

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={renderBottomActions}>
      <View style={styles.containerStyle}>
        <ColumnsContainer
          renderHeader={() => renderHeader()}
          renderRightDesktopColumn={() => renderRightDesktopColumn()}>
          {renderMainContent()}
        </ColumnsContainer>
      </View>
      {fundSheetModalData && (
        <FundSheetModal
          id={fundSheetModalData?.id}
          modalIsVisible={displayFundSheetModal}
          onCloseModal={() => {
            setDisplayFundSheetModal(false);
            setFundSheetModalData(null);
          }}
          title={fundSheetModalData?.title}
        />
      )}
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
