import { all, call, delay, fork, put, select, take } from '@redux-saga/core/effects';
import { Platform } from 'react-native';
import { takeLatest } from 'redux-saga/effects';

import { mockContactInfos } from '@__mocks__/mockContactInfos';
import { mockProfilAddresses } from '@__mocks__/mockProfilAddresses';
import { OtpChanelTypeEnum, UserInfoTypeEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { OtpRequestTypeEnum } from '@ere-uilib/enums';
import { catchApiExceptions } from '@modules/apiAuthorization';
import * as authAction from '@modules/auth/actions/authActions';
import { AuthActionType } from '@modules/auth/actions/authActionsTypes';
import { getBiometricUserAcceptionStatus } from '@modules/auth/selectors';
import { BimetricCredentialsType, SensorAvailabilityResult } from '@modules/auth/types';
import { getBankInit } from '@modules/bank-details/selectors';
import * as accountAction from '@modules/dashboard/actions/dashboardActions';
import { getUsedCompanyId } from '@modules/dashboard/selectors';
import { runManager } from '@modules/moduleManager';
import { isSensorAvailable } from '@modules/utils/biometricsMethods';
import { RootNavigation } from '@navigation/RootNavigation';

import * as actionProfile from './actions/profileActions';
import { postContactFormSuccess } from './actions/profileActions';
import * as actionProfileTypes from './actions/profileActionsTypes';
import { ProfileActionsType } from './actions/profileActionsTypes';
import { mapGetAddressesResponseToState, mapPostAddressResponseToState } from './mapping';
import { getChanelOtpIdentifier, getOtpParameters } from './selectors';
import {
  acceptConsents,
  fetchConsents,
  fetchEditorialContent,
  fetchEditPasswordRequest,
  fetchGetContactPreferences,
  fetchOtpFlowAskCode,
  fetchOtpFlowInitializeChanel,
  fetchOtpFlowSendCode,
  fetchOtpInitVerifyEmailChannel,
  fetchProfileMenu,
  fetchProfileUserInfos,
  fetchUpdateContactPreferences,
  fetchGetContactPreferencesOptin,
  fetchSetContactPreferencesOptin,
  fetchOtpInitVerifySmsChannel,
  fetchVerifPhoneUpdateIsEnable,
  fetchVerifMailUpdateIsEnable,
  fetchProfileAddresses,
  updateProfileAddress,
  fetchGetContactInfos,
  sendContactMail
} from './service';
import {
  ConsentsState,
  OtpParametersState,
  ProfileMenuItemsState,
  ProfileMenuState,
  ProfileUserInfosState
} from './types';
import * as authActions from '../auth/actions/authActions';

function* askRedirection(action: actionProfileTypes.AskNavigationToUrlAction): any {
  yield window.open(action.url, '_blank');
}

function* getConsents(): any {
  yield put(actionProfile.profileInitError());
  const response = yield call(fetchConsents);
  yield put(actionProfile.getConsentsSuccess(response.data));
  const CGUIndex = response.data.findIndex((item: ConsentsState) => item.code === 'CGU');
  const CGUisAccepted = response.data[CGUIndex].isAccepted;
  if (CGUisAccepted) {
    yield put(accountAction.getAccountsRequest());
  } else {
    RootNavigation.replace(RouteNames.CguAcceptation);
  }
}

function* onLoadEditorialPageContent(
  action: actionProfileTypes.GetEditorialContentRequestAction
): any {
  const companyId = yield select(getUsedCompanyId);
  yield put(actionProfile.profileInitError());
  const response = yield call(fetchEditorialContent, action.code, companyId);
  yield put(actionProfile.getEditorialContentSuccess(response.data));
}

function* getEditorialContentSagas(): any {
  yield takeLatest(
    actionProfileTypes.ProfileActionsType.GET_EDITORIAL_CONTENT_REQUEST,
    runManager(
      onLoadEditorialPageContent,
      actionProfileTypes.ProfileActionsType.GET_EDITORIAL_CONTENT_FAILURE
    )
  );
}

function* acceptingConsents(action: actionProfileTypes.AcceptConsentsRequestAction): any {
  const response = yield call(acceptConsents, action.code);
  yield put(actionProfile.acceptConsentsSuccess(action.code, response.data));
  return yield put(accountAction.getAccountsRequest());
}

function* getProfileMenu(): any {
  const companyId = yield select(getUsedCompanyId);

  let profileMenu: ProfileMenuState;
  yield put(actionProfile.profileInitError());
  const response = yield call(fetchProfileMenu, companyId);
  profileMenu = response.data;
  // check if biometric available
  const { available }: SensorAvailabilityResult = yield isSensorAvailable();
  const biometricCredentials: BimetricCredentialsType = yield select(
    getBiometricUserAcceptionStatus
  );
  const isBiometricAvailable = available && biometricCredentials.biometricAcceptationStatus;
  // remove biometric item when platform === web or when biometric is not available in iOS/Android devices
  if (!isBiometricAvailable || Platform.OS === 'web') {
    profileMenu = {
      ...response.data,
      menus: response.data?.menus.map((item: ProfileMenuItemsState) => {
        return {
          ...item,
          subMenus: item.subMenus.filter(subItem => subItem.url !== '/compte/connexion-biometrique')
        };
      })
    };
  }
  yield put(actionProfile.getProfileMenuSuccess(profileMenu));
}

export function* getProfileUserInfos(): any {
  const response = yield call(fetchProfileUserInfos);

  const responseMapped: { data: ProfileUserInfosState } = {
    data: {
      ereIdentifier: response?.data?.ereIdentier || null,
      mail: response?.data?.email || null,
      phone: response?.data?.phoneNumber || null,
      birthDate: response?.data?.birthDate || null,
      gender: response?.data?.gender || null
    }
  };

  yield put(actionProfile.getProfileUserInfosSuccess(responseMapped.data));
}

export function* getProfileAddresses(): any {
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchProfileAddresses, companyId);

  // Uncomment to use mock
  // const response = {
  //   data: mockProfilAddresses
  // }

  yield put(
    actionProfile.getProfileAddressesSuccess(mapGetAddressesResponseToState(response?.data))
  );
}

function* sendProfileUserInfoChange(
  action: actionProfileTypes.SendProfileUserInfoChangeRequestAction
): any {
  yield put(actionProfile.profileInitError());
  const { userInfoType, value } = action;
  let response;
  // define common otpParameters
  let otpParameters: OtpParametersState = {
    requestType: OtpRequestTypeEnum.VERIFY,
    outputActionType: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST
  };
  switch (userInfoType) {
    case UserInfoTypeEnum.MAIL:
      response = yield call(fetchOtpInitVerifyEmailChannel, { email: value });
      // define specific otpParameters
      otpParameters = {
        ...otpParameters,
        chanelType: OtpChanelTypeEnum.MAIL,
        otpIdentifierMail: response?.data?.demandId,
        chanelLabelMail: response?.data?.newEmail
      };
      break;
    case UserInfoTypeEnum.PHONE:
      response = yield call(fetchOtpInitVerifySmsChannel, { newPhoneNumber: value });

      // TODO :  remove endpoint simulation
      // yield delay(1500);
      // response = {
      //   data: {
      //     pathId: 'FakeIdentifierXXX'
      //   }
      // };
      // define specific otpParameters
      otpParameters = {
        ...otpParameters,
        chanelType: OtpChanelTypeEnum.SMS,
        // TODO: TNR on bank signature & edit phone number
        chanelLabelSms: value ?? response?.data?.newPhoneNumber,
        otpIdentifierSms: response?.data?.pathId ?? response?.data?.demandId
      };
      break;
    case UserInfoTypeEnum.ADDRESS:
      // TODO : manage address change when US is on sprint
      break;
    default:
      break;
  }
  if (response?.data) {
    yield put(actionProfile.sendProfileUserInfoChangeSuccess());
    yield put(actionProfile.otpFlowStartRequest(otpParameters));

    yield take([
      ProfileActionsType.OTP_FLOW_START_SUCCESS,
      ProfileActionsType.OTP_FLOW_START_FAILURE
    ]);
  } else {
    const error = {
      code: '',
      correlationId: '',
      message: 'Error',
      innerMessage: 'Error'
    };
    yield catchApiExceptions(ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_FAILURE, error);
  }
}

function* validateProfileUserInfoChange(): any {
  yield put(actionProfile.profileInitError());

  RootNavigation.goBack();

  yield put(actionProfile.validateProfileUserInfoChangeSuccess());
  yield put(actionProfile.getProfileUserInfosRequest()); // reload user infos to update values
}

function* otpFlowStart(action: actionProfileTypes.OtpFlowStartRequestAction): any {
  const otpParameters = action.otpParameters;
  const actionChanelType = otpParameters?.chanelType;
  yield put(actionProfile.initProfileOtpError());
  yield put(actionProfile.resetProfileOtpParameters());
  yield put(actionProfile.resetProfileOtpFlow());
  yield put(actionProfile.updateProfileOtpParameters(otpParameters));

  // if we already know channel type
  // but we don't have otpIdentifier
  // we initialize the channel to get otpIdentifier and label
  const OtpIdentifierForChanelType = yield select(getChanelOtpIdentifier);
  const isOtpIdentifierForChanelType = !!OtpIdentifierForChanelType;
  if (!isOtpIdentifierForChanelType && actionChanelType) {
    yield put(actionProfile.otpFlowInitializeChanelRequest(actionChanelType));
    yield take([
      ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_FAILURE,
      ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_SUCCESS
    ]);
  }

  // we navigate to corresponding page
  switch (action.otpParameters.requestType) {
    case OtpRequestTypeEnum.VERIFY:
      RootNavigation.navigate(RouteNames.OtpValidation, { screen: RouteNames.OtpValidationStart });

      break;
    case OtpRequestTypeEnum.SECURE:
      // if we are in secure request and we don't have channel type yet, we need user infos to display mail and phone
      if (!actionChanelType) {
        yield put(actionProfile.getProfileUserInfosRequest());
        yield take([
          ProfileActionsType.GET_PROFILE_USER_INFOS_SUCCESS,
          ProfileActionsType.GET_PROFILE_USER_INFOS_FAILURE
        ]);
      }
      RootNavigation.navigate(RouteNames.OtpSecurisation, {
        screen: RouteNames.OtpSecurisationStart
      });
      break;
    default:
      break;
  }

  yield delay(100); // needed to allow loading state to update in case of no api call in called action
  yield put(actionProfile.otpFlowStartSuccess());
}

function* otpFlowInitializeChanel(
  action: actionProfileTypes.OtpFlowInitializeChanelRequestAction
): any {
  yield put(actionProfile.initProfileOtpError());
  const otpParameters: OtpParametersState = yield select(getOtpParameters);
  const bankInfo = yield select(getBankInit);

  const { chanelType: actionChanelType } = action;
  // TODO : use otp parameters and actionChanelType to verify if we need to initialize and then to call send code
  let newOtpParameters;

  const channelValue = [OtpChanelTypeEnum.MAIL, OtpChanelTypeEnum.MAIL_DOCAPOSTE].includes(
    actionChanelType
  )
    ? otpParameters?.chanelLabelMail
    : otpParameters.chanelLabelSms;
  const response = yield call(fetchOtpFlowInitializeChanel, {
    requestType: otpParameters.requestType,
    chanelType: actionChanelType,
    demandId: otpParameters.demandId,
    channelValue
  });

  const otpIdentifier = otpParameters.demandId
    ? response?.data?.demandId
    : response?.data?.requestId;

  switch (actionChanelType) {
    case OtpChanelTypeEnum.MAIL:
      // update otp parameter with chanel recieved otpIdentifier and label
      newOtpParameters = {
        ...otpParameters,
        chanelType: OtpChanelTypeEnum.MAIL,
        otpIdentifierMail: otpIdentifier,
        chanelLabelMail: otpParameters.demandId ? response?.data?.newEmail : response?.data?.email
      };
      break;
    case OtpChanelTypeEnum.SMS:
      // update otp parameter with chanel recieved otpIdentifier and label
      newOtpParameters = {
        ...otpParameters,
        chanelType: OtpChanelTypeEnum.SMS,
        otpIdentifierSms: otpIdentifier,
        chanelLabelSms: otpParameters.demandId
          ? response?.data?.newPhoneNumber
          : response?.data?.phoneNumber
      };
      break;
    case OtpChanelTypeEnum.MAIL_DOCAPOSTE:
      // update otp parameter with chanel recieved otpIdentifier and label
      newOtpParameters = {
        ...otpParameters,
        chanelType: OtpChanelTypeEnum.MAIL_DOCAPOSTE,
        otpIdentifierMail: otpIdentifier,
        chanelLabelMail: response?.data?.email ?? bankInfo?.mail
      };
      break;
    case OtpChanelTypeEnum.SMS_DOCAPOSTE:
      // update otp parameter with chanel recieved otpIdentifier and label
      newOtpParameters = {
        ...otpParameters,
        chanelType: OtpChanelTypeEnum.SMS_DOCAPOSTE,
        otpIdentifierSms: otpIdentifier,
        chanelLabelSms: response?.data?.phoneNumber ?? bankInfo?.phoneNumber
      };
      break;
    default:
      newOtpParameters = otpParameters;
      break;
  }

  // update otpPramater with channel initialized
  yield put(actionProfile.updateProfileOtpParameters(newOtpParameters));

  yield put(actionProfile.otpFlowInitializeChanelSuccess());
}

function* otpFlowAskCode(action: actionProfileTypes.OtpFlowAskCodeRequestAction): any {
  yield put(actionProfile.initProfileOtpError());
  let otpParameters: OtpParametersState = yield select(getOtpParameters);
  const actionChanelType = action?.chanelType;

  // if we don't have otpIdentifier
  // we initialize the channel to get otpIdentifier and label
  const OtpIdentifierForChanelType = yield select(getChanelOtpIdentifier);
  const isOtpIdentifierForChanelType = !!OtpIdentifierForChanelType;
  if (!isOtpIdentifierForChanelType && actionChanelType) {
    yield put(actionProfile.otpFlowInitializeChanelRequest(actionChanelType));
    yield take([
      ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_FAILURE,
      ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_SUCCESS
    ]); // we have to handle error when init fail, actually even if init fail send ask code is called
    // refresh  otpParameters as initialization updated them
    otpParameters = yield select(getOtpParameters);
  }

  // now we are sure we are initialized and we can ask for code
  // if a value is still undefined it's api role to send error
  const otpIdentifier: string | undefined = yield select(getChanelOtpIdentifier);
  yield call(fetchOtpFlowAskCode, {
    requestType: otpParameters.requestType,
    chanelType: otpParameters.chanelType,
    otpIdentifier: otpIdentifier
  });

  // we navigate to corresponding page
  switch (otpParameters.requestType) {
    case OtpRequestTypeEnum.VERIFY:
      RootNavigation.replace(RouteNames.OtpValidation, { screen: RouteNames.OtpValidationForm });

      break;
    case OtpRequestTypeEnum.SECURE:
      RootNavigation.replace(RouteNames.OtpSecurisation, {
        screen: RouteNames.OtpSecurisationForm
      });

      break;
    default:
      break;
  }

  yield put(actionProfile.otpFlowAskCodeSuccess());
}

function* otpFlowAskCodeAgain(): any {
  yield put(actionProfile.resetProfileOtpFlow());
  yield put(actionProfile.initProfileOtpError());
  const otpParameters: OtpParametersState = yield select(getOtpParameters);

  const otpIdentifier: string | undefined = yield select(getChanelOtpIdentifier);

  yield call(fetchOtpFlowAskCode, {
    requestType: otpParameters.requestType,
    chanelType: otpParameters.chanelType,
    otpIdentifier: otpIdentifier
  });

  yield put(actionProfile.otpFlowAskCodeAgainSuccess());
}

function* otpFlowSendCode(action: actionProfileTypes.OtpFlowSendCodeRequestAction): any {
  yield put(actionProfile.initProfileOtpError());
  const otpParameters: OtpParametersState = yield select(getOtpParameters);
  const otpCode = action?.otpCode;

  const otpIdentifier: string | undefined = yield select(getChanelOtpIdentifier);

  yield call(fetchOtpFlowSendCode, {
    requestType: otpParameters.requestType,
    chanelType: otpParameters.chanelType,
    otpIdentifier: otpIdentifier,
    otpCode: otpCode
  });

  // we adapt final actions depending on requestType
  switch (otpParameters.requestType) {
    case OtpRequestTypeEnum.VERIFY:
      // on verify mode we should replace current screen by verify success screen
      RootNavigation.replace(RouteNames.OtpValidation, { screen: RouteNames.OtpValidationSuccess });
      break;
    case OtpRequestTypeEnum.SECURE:
      // on secure mode we want to use otp output action
      yield put(authAction.silentRenewTokenRequest());
      yield take([
        AuthActionType.SILENT_RENEW_TOKEN_SUCCESS,
        AuthActionType.SILENT_RENEW_TOKEN_FAILURE
      ]);
      yield put(actionProfile.otpFlowCompletedValidationRequest());
      yield take([
        ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_FAILURE,
        ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_SUCCESS
      ]);
      break;
    default:
      break;
  }

  yield put(actionProfile.otpFlowSendCodeSuccess());
  if (
    otpParameters.outputActionType === ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST
  )
    yield put(authActions.silentRenewTokenRequest());
}

function* otpFlowCompletedValidation(
  action: actionProfileTypes.OtpFlowCompletedValidationRequestAction
): any {
  yield put(actionProfile.initProfileOtpError());
  const otpParameters: OtpParametersState = yield select(getOtpParameters);
  const outputAction = {
    type: otpParameters?.outputActionType || '',
    ...(otpParameters?.outputActionParameters || {})
  };
  yield RootNavigation.goBack();
  yield put(actionProfile.resetProfileOtpParameters());

  // send output action if got one
  if (outputAction.type) {
    // verify if output action is a request and can wait action end
    const isOutputActionTypeRequest = outputAction?.type.includes('_REQUEST');
    const outputActionTypeBase = isOutputActionTypeRequest
      ? outputAction.type.replace('_REQUEST', '')
      : null;
    yield put(outputAction);
    if (outputActionTypeBase) {
      // TODO: make it clean
      if (outputActionTypeBase !== 'REGISTER_BANK_DATA')
        yield take([outputActionTypeBase + '_FAILURE', outputActionTypeBase + '_SUCCESS']);
    }
  }

  yield delay(100); // needed to allow loading state to update in case of no api call in called action

  yield put(actionProfile.otpFlowCompletedValidationSuccess());
}

function* editProfilPassword(action: actionProfileTypes.EditPasswordRequestAction): any {
  yield put(actionProfile.initProfileOtpError());
  yield call(fetchEditPasswordRequest, action.currentPassword, action.newPassword);
  yield put(actionProfile.profilEditPasswordSuccess());
}

function* getProfileMenuSagas(): any {
  yield takeLatest(
    ProfileActionsType.GET_PROFILE_MENU_REQUEST,
    runManager(getProfileMenu, ProfileActionsType.GET_PROFILE_MENU_FAILURE)
  );
}

function* getConsentsSagas(): any {
  yield takeLatest(
    ProfileActionsType.GET_CONSENTS_REQUEST,
    runManager(getConsents, ProfileActionsType.GET_CONSENTS_FAILURE)
  );
}

function* acceptingConsentsSagas(): any {
  yield takeLatest(
    ProfileActionsType.ACCEPT_CONSENTS_REQUEST,
    runManager(acceptingConsents, ProfileActionsType.ACCEPT_CONSENTS_FAILURE)
  );
}

export function* getProfileUserInfosSagas(): any {
  yield takeLatest(
    ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST,
    runManager(getProfileUserInfos, ProfileActionsType.GET_PROFILE_USER_INFOS_FAILURE)
  );
}

export function* getProfileAddressesSagas(): any {
  yield takeLatest(
    ProfileActionsType.GET_PROFILE_ADDRESSES_REQUEST,
    runManager(getProfileAddresses, ProfileActionsType.GET_PROFILE_ADDRESSES_FAILURE)
  );
}

function* sendProfileUserInfoChangeSagas(): any {
  yield takeLatest(
    ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_REQUEST,
    runManager(sendProfileUserInfoChange, ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_FAILURE)
  );
}
function* validateProfileUserInfoChangeSagas(): any {
  yield takeLatest(
    ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST,
    runManager(
      validateProfileUserInfoChange,
      ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_FAILURE
    )
  );
}

function* otpFlowStartSagas(): any {
  yield takeLatest(
    ProfileActionsType.OTP_FLOW_START_REQUEST,
    runManager(otpFlowStart, ProfileActionsType.OTP_FLOW_START_FAILURE)
  );
}
function* otpFlowInitializeChanelSagas(): any {
  yield takeLatest(
    ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_REQUEST,
    runManager(otpFlowInitializeChanel, ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_FAILURE)
  );
}
function* otpFlowAskCodeSagas(): any {
  yield takeLatest(
    ProfileActionsType.OTP_FLOW_ASK_CODE_REQUEST,
    runManager(otpFlowAskCode, ProfileActionsType.OTP_FLOW_ASK_CODE_FAILURE)
  );
}
function* otpFlowAskCodeAgainSagas(): any {
  yield takeLatest(
    ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_REQUEST,
    runManager(otpFlowAskCodeAgain, ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_FAILURE)
  );
}
function* otpFlowSendCodeSagas(): any {
  yield takeLatest(
    ProfileActionsType.OTP_FLOW_SEND_CODE_REQUEST,
    runManager(otpFlowSendCode, ProfileActionsType.OTP_FLOW_SEND_CODE_FAILURE)
  );
}
function* otpFlowCompletedValidationSagas(): any {
  yield takeLatest(
    ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_REQUEST,
    runManager(otpFlowCompletedValidation, ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_FAILURE)
  );
}

function* askRedirectionSaga(): any {
  yield takeLatest(ProfileActionsType.ASK_NAVIGATION_TO_URL, askRedirection);
}

function* editProfilPasswordSagas(): any {
  yield takeLatest(
    ProfileActionsType.EDIT_PASSWORD_REQUEST,
    runManager(editProfilPassword, ProfileActionsType.EDIT_PASSWORD_FAILURE)
  );
}

export function* updateContactPreferences(
  action: actionProfileTypes.UpdateContactPreferenceRequestAction
): any {
  const { hasNotificationsActivated, emailOptIn, smsOptIn, toDoPostSuccessActionType, demandId } =
    action;
  yield call(fetchUpdateContactPreferences, {
    demandId: demandId || null,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn
  });
  yield put(
    actionProfile.updateContactPreferencesSuccess({
      hasNotificationsActivated,
      emailOptIn,
      smsOptIn
    })
  );
  if (!toDoPostSuccessActionType) return;
  yield put({ type: toDoPostSuccessActionType });
  yield RootNavigation.goBack();
}

export function* updateContactPreferencesSaga(): any {
  yield takeLatest(
    ProfileActionsType.UPDATE_CONTACT_PREFERENCES_REQUEST,
    runManager(updateContactPreferences, ProfileActionsType.UPDATE_CONTACT_PREFERENCES_FAILURE)
  );
}

export function* getContactPreferences(): any {
  const response = yield call(fetchGetContactPreferences);

  yield put(actionProfile.getContactPreferencesSuccess(response.data || {}));
}

// optin

export function* getContactPreferencesOptin(): any {
  yield put(actionProfile.profileInitError());
  const response = yield call(fetchGetContactPreferencesOptin);
  yield put(actionProfile.getContactPreferencesOptinSuccess(response.data));
}

export function* setContactPreferencesOptin(
  action: actionProfileTypes.SetContactPreferencesOptInRequestAction
): any {
  yield put(actionProfile.profileInitError());
  yield call(fetchSetContactPreferencesOptin, {
    acceptGroupEmail: action.acceptGroupEmail,
    acceptPartnerEmail: action.acceptPartnerEmail,
    demandId: action.demandId
  });
  yield put(actionProfile.setContactPreferencesOptinSuccess());
  if (action.toDoPostSuccessActionType) {
    yield put({ type: action.toDoPostSuccessActionType });
    yield put(actionProfile.setContactPreferencesOptinReset());
    yield RootNavigation.goBack();
  }
}

export function* getContactPreferencesSaga(): any {
  yield takeLatest(
    ProfileActionsType.GET_CONTACT_PREFERENCES_REQUEST,
    runManager(getContactPreferences, ProfileActionsType.GET_CONTACT_PREFERENCES_FAILURE)
  );
}

export function* getContactInfosSaga(): any {
  yield takeLatest(
    ProfileActionsType.GET_CONTACT_INFOS_REQUEST,
    runManager(getContactInfos, ProfileActionsType.GET_CONTACT_INFOS_FAILURE)
  );
}

export function* getContactPreferencesOptinSagas(): any {
  yield takeLatest(
    ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_REQUEST,
    runManager(getContactPreferencesOptin, ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_FAILURE)
  );
}
export function* setContactPreferencesOptinSagas(): any {
  yield takeLatest(
    ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_REQUEST,
    runManager(setContactPreferencesOptin, ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_FAILURE)
  );
}

export function* checkEditMailIsEnableRequest(): any {
  yield put(actionProfile.profileInitError());
  const response = yield call(fetchVerifMailUpdateIsEnable);
  yield put(actionProfile.profileCheckEditMailAuthorizeIsEnableSuccess(response));
}
export function* checkEditMailIsEnableRequestSagas(): any {
  yield takeLatest(
    ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_REQUEST,
    runManager(
      checkEditMailIsEnableRequest,
      ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_FAILURE
    )
  );
}

export function* checkEditPhoneNumberIsEnableRequest(): any {
  yield put(actionProfile.profileInitError());
  const response = yield call(fetchVerifPhoneUpdateIsEnable);
  yield put(actionProfile.profileCheckEditPhoneNumberAuthorizeIsEnableSuccess(response));
}

export function* checkEditPhoneNumberIsEnableRequestSagas(): any {
  yield takeLatest(
    ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_REQUEST,
    runManager(
      checkEditPhoneNumberIsEnableRequest,
      ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_FAILURE
    )
  );
}

export function* updateProfileAddressRequest({
  planFamily,
  address,
  files
}: actionProfileTypes.UpdateProfileAddressRequestAction) {
  const companyId = yield select(getUsedCompanyId);
  const formData = new FormData();

  formData.append('family', planFamily);

  for (const entry in address) {
      formData.append(`address[${entry}]`, address?.[entry]);
  }

  for (let i = 0; i < files.length; i++) {
    formData.append(`attachedFiles[${i}]`, files?.[i]?.base64);
  }

  const response = yield call(updateProfileAddress, companyId, formData);

  yield put(actionProfile.updateUserAddressSuccess(mapPostAddressResponseToState(response?.data)));
}

export function* updateProfilAddressRequestSagas(): any {
  yield takeLatest(
    ProfileActionsType.UPDATE_PROFILE_ADDRESS_REQUEST,
    runManager(updateProfileAddressRequest, ProfileActionsType.UPDATE_PROFILE_ADDRESS_FAILURE)
  );
}

export function* getContactInfos(): any {
  yield put(actionProfile.profileInitError());
  const companyId = yield select(getUsedCompanyId);
  //yield delay(1500);
  //yield put(actionProfile.getContactInfosSuccess(mockContactInfos || {}));

  const response = yield call(fetchGetContactInfos, companyId);
  yield put(actionProfile.getContactInfosSuccess(response?.data || {}));
}

export function* sendContactFormRequest({
  message,
  object
}: actionProfileTypes.PostContactFormRequestAction) {
  const companyId = yield select(getUsedCompanyId);
  yield call(sendContactMail, companyId, message, object);
  yield put(postContactFormSuccess());
}

export function* sendContactFormSagas(): any {
  yield takeLatest(
    ProfileActionsType.POST_CONTACT_FORM_REQUEST,
    runManager(sendContactFormRequest, ProfileActionsType.POST_CONTACT_FORM_FAILURE)
  );
}

export function* ProfileSagas() {
  yield all([
    fork(askRedirectionSaga),
    fork(getConsentsSagas),
    fork(acceptingConsentsSagas),
    fork(getProfileMenuSagas),
    fork(getProfileUserInfosSagas),
    fork(getProfileAddressesSagas),
    fork(sendProfileUserInfoChangeSagas),
    fork(validateProfileUserInfoChangeSagas),
    fork(otpFlowStartSagas),
    fork(otpFlowInitializeChanelSagas),
    fork(otpFlowAskCodeSagas),
    fork(otpFlowAskCodeAgainSagas),
    fork(otpFlowSendCodeSagas),
    fork(otpFlowCompletedValidationSagas),
    fork(editProfilPasswordSagas),
    fork(updateContactPreferencesSaga),
    fork(getContactPreferencesSaga),
    fork(getContactInfosSaga),
    fork(getEditorialContentSagas),
    fork(getContactPreferencesOptinSagas),
    fork(setContactPreferencesOptinSagas),
    fork(checkEditMailIsEnableRequestSagas),
    fork(checkEditPhoneNumberIsEnableRequestSagas),
    fork(updateProfilAddressRequestSagas),
    fork(sendContactFormSagas)
  ]);
}
