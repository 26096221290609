import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        loaderContainerStyle: {
          justifyContent: 'space-between',
          height: theme.metrics.spacing.xxxxl
        },
        loaderPlaceholderViewOne: { width: '50%' },
        loaderPlaceholderViewTwo: { width: '100%' }
      }),
    [theme]
  );
  return styles;
};
