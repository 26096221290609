import {
  ArbitrationDecisionPropertiesEnum,
  ArbitrationQuestionTypesEnum,
} from '../constants';
import {
  ArbitrationState
} from '../types';
import {roundNumber} from "@utils/numberRound";

export const updateArbitrationDecisionCompartmentQuestion = (
  decision: ArbitrationState['decision'],
): ArbitrationState['decision'] => {
  const planCompartments: ArbitrationState['decision']['planCompartments'] = decision.planCompartments;

  const newQuestions = decision.questions.map(question => {
    let newQuestion = question;
    switch (question.type) {
      case ArbitrationQuestionTypesEnum.COMPARTMENT_CHOICE:
        // TODO: response type not right
        const planResponses: any = planCompartments?.compartments.map(compartment => {
          const headerAmount = compartment.managements.reduce((acc, value) => {
            return roundNumber(acc + value.totalAmount);
          }, 0);

          return {
            options: [],
            propertyFilter: {
              name: ArbitrationDecisionPropertiesEnum.COMPARTMENT_CODE,
              value: compartment.code || ''
            },
            text: compartment.descriptionLabel,
            title: compartment.label,
            headerAmount: headerAmount,
          };
        }) || [];
        newQuestion = {
          ...newQuestion,
          responded: undefined,
          canNotBeAsked: undefined,
          responses: planResponses,
          implicitResponseEnabled: (planCompartments?.compartments && planCompartments?.compartments.length === 1) || false
        };

        break;
      default:
        break;
    }

    return newQuestion;
  });

  return {
    ...decision,
    questions: newQuestions,
  }
}
