import React from 'react';
import { View } from 'react-native';

import { locator } from '@constants/locator';
import { ActionButton } from '@ere-uilib/molecules/buttons/ActionButton/ActionButton';

import { useDictionary } from './useDictionary';
import { useStyles } from './useStyles';
import { useHelpContactContext } from '../../HelpContactsPageProvider';
interface Props {
  phoneEnabled: boolean;
}
export const NavigationButtons: React.FC<Props> = ({ phoneEnabled }) => {
  const styles = useStyles();
  const dictionary = useDictionary();
  const { selectedIndex, selectContactInfo, isES, isRC, emailData } = useHelpContactContext();
  const buttons = [
    {
      iconName: 'contact',
      index: 0,
      testId: locator._profile._help_contact._phone_menu_button,
      title: dictionary.ContactPhoneIcon,
      isEnabled: phoneEnabled
    },
    {
      iconName: 'mail',
      index: 2,
      testId: locator._profile._help_contact._mail_menu_button,
      title: dictionary.ContactMailIcon,
      isEnabled: (isES && emailData.enabled) || isRC
    },
    {
      iconName: 'courrier',
      index: 1,
      testId: locator._profile._help_contact._postal_menu_button,
      title: dictionary.ContactMailingAddress,
      isEnabled: true
    }
  ];
  return (
    <View
      role="list"
      style={styles.buttonsContainer}>
      {buttons.map((data, i) => {
        return (
          data.isEnabled && (
            <ActionButton
              iconName={data.iconName}
              isSelected={selectedIndex === data.index}
              key={`${data.iconName}-${i}`}
              onSelect={() => {
                selectContactInfo(data.index);
              }}
              testId={data.testId}
              title={data.title}
            />
          )
        );
      })}
    </View>
  );
};
