import { useNavigation } from "@react-navigation/native";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "@components/ERE-UILib";
import { FundSheetModalDataType } from "@components/templates";
import { RouteNames } from "@constants/navigation";
import { getProfitSharingIncentiveInitDataState } from "@modules/profit-sharing-incentive/selectors";
import {
    ProfitSharingIncentiveRepartitionPlansState
} from "@modules/profit-sharing-incentive/types";
import {
    ManagementItemType,
    ProfitSharingIncentiveDestinationCardType,
    ProfitSharingIncentiveDestinationSubCardType
} from "@pages/ProfitSharingIncentive/components";
import { isRealExternalPlan } from "@pages/ProfitSharingIncentive/utils";

type ModalProps = {modalData: FundSheetModalDataType | null; displayModal: boolean };

export const useProfitSharingController = () => {
    const [fundSheetModalData, setFundSheetModalData] = useState<ModalProps>();

    const { formatMessage } = useTranslation();
    const initData = useSelector(getProfitSharingIncentiveInitDataState);
    const navigation = useNavigation();
    const { type, legalFramework } = initData ?? {};
    const handleHideFundSheet = useCallback(() => {
        setFundSheetModalData({ modalData: null, displayModal: false });
    }, []);

    const handleClose = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else if (type && legalFramework) {
            navigation.navigate(RouteNames.ProfitSharingIncentive, {
                screen: RouteNames.ProfitSharingIncentiveDescription,
                params: {
                    type,
                    cadre: legalFramework
                }
            });
        }
    }, [navigation, type, legalFramework]);

    const derogatedAffectations = useMemo(
        () => initData?.derogatoryAffectations || [],
        [initData?.derogatoryAffectations]
    );
    const isDerogatedAffectationsAvailable = useMemo(
        () => (initData?.derogatoryAmount || 0) > 0,
        [initData?.derogatoryAmount]
    );

    const buildPlanCardManagements = useCallback((plan: ProfitSharingIncentiveRepartitionPlansState) => {
            const affectionManagements: ManagementItemType[] = [];
            plan.compartments.map(compartment => {
                const hasDrivenManagement = !!compartment.managements.find(management => !management.isFree);
                const hasMultipleManagement = compartment.managements.length > 1;
                compartment.managements
                    .filter(management => !management?.advancedProperties?.IsTechnicalManagement)
                    .map(management => {
                        let supportItems: ProfitSharingIncentiveDestinationSubCardType[] = [];
                        const masterSupport = management.supports.find(support => !!support.isMaster);
                        const managementAmount =
                            masterSupport?.advancedProperties?.DefaultAmount ||
                            management?.advancedProperties?.DefaultAmount ||
                            undefined;

                        if (management.isFree) {
                            supportItems = management.supports
                                .filter(support => !support?.advancedProperties?.IsTechnicalFund)
                                .map(support => ({
                                    title: support.supportName,
                                    amount: support?.advancedProperties.DefaultAmount,
                                    percentage: undefined,
                                    onPress: () => setFundSheetModalData({ displayModal: true, modalData: {
                                        id: support.supportIsin,
                                        title: support.supportName || '' } })
                                }));
                        }

                        affectionManagements.push({
                            title: management.label,
                            isFree: management.isFree,
                            hideManagementAmount: management.isFree,
                            hideManagementRow: !hasDrivenManagement && !hasMultipleManagement,
                            amount: managementAmount,
                            percentage: undefined,
                            subCards: supportItems
                        });
                    });
            });
            return affectionManagements;
        }, []);

    const renderDestinationCards = useCallback(() => {
        const defaultAffectationsCards: ProfitSharingIncentiveDestinationCardType[] = [];
        initData?.defaultAffectations.map((plan, index) => {
            const constructedManagements: ManagementItemType[] = buildPlanCardManagements(plan);
            let realPlanAmount: number | undefined;
            const isExternalPlan = isRealExternalPlan(plan);
            if (isExternalPlan) {
                const flatMapValues = plan.compartments.flatMap(compartment => compartment.managements)
                    .flatMap(management => management.supports);
                realPlanAmount = flatMapValues.pop()?.advancedProperties?.DefaultAmount || realPlanAmount;
            }

            defaultAffectationsCards.push({
                filetTitle: index === 0 ? formatMessage({ id: 'PIAffectationdefautVersLabel' }) : undefined,
                planName: isExternalPlan ? plan.planFullName : plan.planName,
                planType: plan.planType,
                isRealExternalPlan: isExternalPlan,
                amount: realPlanAmount,
                managements: constructedManagements
            });
        });

        if (!defaultAffectationsCards || defaultAffectationsCards.length === 0) {
            return null;
        }

        return defaultAffectationsCards;

    }, [initData, formatMessage, isRealExternalPlan, isDerogatedAffectationsAvailable, buildPlanCardManagements]);

    const renderderogatedAffectationsCards = useCallback(() => {
        if (!isDerogatedAffectationsAvailable) {
            return null;
        }
        const derogatedAffectationsCards: ProfitSharingIncentiveDestinationCardType[] = [];
        derogatedAffectations.map((plan, index) => {
            const constructedManagements: ManagementItemType[] = buildPlanCardManagements(plan);
            const isExternalPlan = isRealExternalPlan(plan);

            derogatedAffectationsCards.push({
                filetTitle:
                    index === 0 ? formatMessage({ id: 'PIAffectationdefautEtVersLabel' }) : undefined,
                planName: plan.planName,
                planType: plan.planType,
                isRealExternalPlan: isExternalPlan,
                amount: undefined,
                managements: constructedManagements
            });
        });

        if (!derogatedAffectationsCards || derogatedAffectationsCards.length === 0) {
            return null;
        }
        return derogatedAffectationsCards;

    }, [buildPlanCardManagements, derogatedAffectations, formatMessage, isDerogatedAffectationsAvailable, isRealExternalPlan]);

    return {
        initData,
        isDerogatedAffectationsAvailable,
        renderderogatedAffectationsCards,
        renderDestinationCards,
        handleClose,
        fundSheetModalData,
        handleHideFundSheet
    };
}
