import { REACT_APP_AES_KEY } from '@config/index';
import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { encryptData } from '@utils/encryptDecryptData';

import { ProfileAction, ProfileActionsType } from './actions/profileActionsTypes';
import {
  ConsentsState,
  ContactFormPopinContentTypeEnum,
  ProfileState,
  AddressUpdateStatus
} from './types';

// profileEdit password

export const initialState: ProfileState = {
  consents: [],
  profileMenu: {
    lastConnectionDate: '',
    menus: []
  },
  userInfos: '',
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  otpParameters: {},
  isProfilEditPasswordSuccess: false,
  contactPreference: {
    isUpdateSuccess: false,
    hasNotificationsActivated: true,
    emailOptIn: false,
    smsOptIn: false
  },
  otpError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  otpFlow: {},
  editorialContentPage: {
    code: '',
    content: ''
  },
  editorialContentError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  contactPreferencesOptin: {
    acceptGroupEmail: false,
    acceptPartnerEmail: false
  },
  bankData: {
    esPlanList: [],
    rcPlanList: [],
    esDebitBankAccountDetails: {
      domiciliation: null,
      titulaire: null,
      rum: '',
      ics: null,
      iban: '',
      bic: '',
      type: '',
      iso: ''
    },
    esTransferBankAccountDetails: {
      domiciliation: null,
      titulaire: null,
      rum: '',
      ics: null,
      iban: '',
      bic: '',
      type: '',
      iso: ''
    },
    rcDebitBankAccountDetails: null,
    rcTransferBankAccountDetails: null
  },
  isBiometricLockedFromProfileMenu: false,
  editPasswordError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  editPhoneNumberIsEnable: true,
  editMailIsEnable: true,
  address: {
    addresses: null,
    countries: null
  },
  addressUpdateStatus: undefined,
  addressPlanFamily: undefined,
  contactInfos: {
    email: {
      enabled: false,
      email: '',
      objects: []
    },
    phone: {
      enabled: false,
      phoneNumber: '',
      description: '',
      category: ''
    },
    postalEs: {
      enabled: false
    },
    postalRc: {
      enabled: false
    }
  },
  contactFormContentType: undefined
};

const updatingConsentsLocally = (consents: ConsentsState[], code: string, isAccepted: boolean) => {
  const result = [...consents];
  const updatedConsentIndex = result.findIndex((item: ConsentsState) => item.code === code);
  if (updatedConsentIndex >= 0) {
    result[updatedConsentIndex] = {
      ...result[updatedConsentIndex],
      isAccepted
    };
  }
  return result;
};

export default function ProfileReducer(
  state: ProfileState = initialState,
  action: ProfileAction | AuthenticateAction
): ProfileState {
  switch (action?.type) {
    case ProfileActionsType.LOCK_BIOMETRIC_USER_FROM_PROFILE_MENU:
      return {
        ...state,
        isBiometricLockedFromProfileMenu: action.isLocked
      };
    case ProfileActionsType.GET_EDITORIAL_CONTENT_REQUEST:
      return {
        ...state,
        editorialContentPage: initialState.editorialContentPage
      };
    case ProfileActionsType.GET_EDITORIAL_CONTENT_SUCCESS:
      return {
        ...state,
        editorialContentPage: action.content
      };

    case ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_SUCCESS:
      return {
        ...state,
        otpFlow: {
          ...state.otpFlow,
          isAskAgainSuccess: true
        }
      };

    case ProfileActionsType.RESET_PROFILE_OTP_FLOW:
      return {
        ...state,
        otpFlow: initialState.otpFlow
      };
    case ProfileActionsType.GET_CONSENTS_SUCCESS:
      return {
        ...state,
        consents: action.consents
      };
    case ProfileActionsType.ACCEPT_CONSENTS_SUCCESS:
      return {
        ...state,
        consents: updatingConsentsLocally(state.consents, action.code, action.isAccepted)
      };
    case ProfileActionsType.GET_PROFILE_MENU_SUCCESS:
      return {
        ...state,
        profileMenu: action.profileMenu
      };
    case ProfileActionsType.GET_PROFILE_USER_INFOS_SUCCESS:
      return {
        ...state,
        userInfos: encryptData(action.userInfos, REACT_APP_AES_KEY) || initialState.userInfos
      };
    case ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_SUCCESS:
      return {
        ...state
      };
    case ProfileActionsType.EDIT_PASSWORD_SUCCESS:
      return {
        ...state,
        isProfilEditPasswordSuccess: true
      };
    case ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_SUCCESS:
      return {
        ...state,
        contactPreferencesOptin: {
          acceptGroupEmail: action.acceptGroupEmail,
          acceptPartnerEmail: action.acceptPartnerEmail
        }
      };
    case ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_SUCCESS:
      return {
        ...state,
        contactPreferencesOptin: {
          ...state.contactPreferencesOptin,
          isSuccess: true
        }
      };
    case ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_RESET:
      return {
        ...state,
        contactPreferencesOptin: {
          ...state.contactPreferencesOptin,
          isSuccess: false
        }
      };
    case ProfileActionsType.EDIT_PASSWORD_REQUEST:
    case ProfileActionsType.EDIT_PASSWORD_RESET:
      return {
        ...state,
        isProfilEditPasswordSuccess: false
      };
    case ProfileActionsType.UPDATE_PROFILE_OTP_PARAMETERS:
      return {
        ...state,
        otpParameters: {
          ...state.otpParameters,
          ...action.otpParameters
        }
      };
    case ProfileActionsType.RESET_PROFILE_OTP_PARAMETERS:
      return {
        ...state,
        otpParameters: initialState.otpParameters
      };
    case ProfileActionsType.GET_PROFILE_MENU_FAILURE:
    case ProfileActionsType.GET_CONSENTS_FAILURE:
    case ProfileActionsType.GET_EDITORIAL_CONTENT_FAILURE:
    case ProfileActionsType.GET_PROFILE_USER_INFOS_FAILURE:
    case ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_FAILURE:
    case ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_FAILURE:
    case ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_FAILURE:
    case ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_FAILURE:
    case ProfileActionsType.OTP_FLOW_START_FAILURE:
    case ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_FAILURE:
    case ProfileActionsType.OTP_FLOW_ASK_CODE_FAILURE:
    case ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_FAILURE:
    case ProfileActionsType.OTP_FLOW_SEND_CODE_FAILURE:
    case ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_FAILURE:
      return {
        ...state,
        otpError: action.error
      };
    case ProfileActionsType.EDIT_PASSWORD_FAILURE:
      return {
        ...state,
        editPasswordError: action.error
      };
    case ProfileActionsType.ACCEPT_CONSENTS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case ProfileActionsType.INIT_PROFILE_OTP_ERROR:
      return {
        ...state,
        otpError: initialState.otpError,
        editPasswordError: initialState.editPasswordError
      };
    case ProfileActionsType.PROFILE_INIT_ERROR:
      return {
        ...state,
        error: initialState.error,
        editPasswordError: initialState.editPasswordError,
        otpError: initialState.otpError
      };
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error,
        otpError: initialState.otpError,
        editPasswordError: initialState.editPasswordError
      };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return {
        ...initialState,
        isBiometricLockedFromProfileMenu: state.isBiometricLockedFromProfileMenu
      };
    case ProfileActionsType.UPDATE_CONTACT_PREFERENCES_REQUEST:
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference
        }
      };
    case ProfileActionsType.UPDATE_CONTACT_PREFERENCES_SUCCESS:
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference,
          isUpdateSuccess: true,
          hasNotificationsActivated: action.hasNotificationsActivated,
          emailOptIn: action.emailOptIn,
          smsOptIn: action.smsOptIn
        }
      };
    case ProfileActionsType.UPDATE_CONTACT_PREFERENCES_FAILURE:
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference
        }
      };
    case ProfileActionsType.UPDATE_CONTACT_PREFERENCES_SUCCESS_RESET:
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference,
          isUpdateSuccess: false
        }
      };
    case ProfileActionsType.GET_CONTACT_PREFERENCES_SUCCESS:
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference,
          hasNotificationsActivated: action.hasNotificationsActivated ?? true,
          emailOptIn: action.emailOptIn ?? false,
          smsOptIn: action.smsOptIn ?? false
        }
      };
    case ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_SUCCESS:
      return {
        ...state,
        editMailIsEnable: action.editMailIsEnable
      };
    case ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_SUCCESS:
      return {
        ...state,
        editPhoneNumberIsEnable: action.editPhoneNumberIsEnable
      };
    case ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_FAILURE:
    case ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case ProfileActionsType.GET_PROFILE_ADDRESSES_SUCCESS:
      return {
        ...state,
        address: {
          addresses: action.addresses,
          countries: action.countries
        },
        addressUpdateStatus: undefined
      };
    case ProfileActionsType.UPDATE_PROFILE_ADDRESS_SUCCESS:
      return {
        ...state,
        addressUpdateStatus: action.addressUpdateStatus
      };
    case ProfileActionsType.UPDATE_PROFILE_ADDRESS_REQUEST:
      return {
        ...state,
        addressUpdateStatus: AddressUpdateStatus.LOADING
      };
    case ProfileActionsType.UPDATE_PROFILE_ADDRESS_FAILURE:
      if (!action.error.code) return state;
      return {
        ...state,
        addressUpdateStatus: AddressUpdateStatus.ERROR
      };
    case ProfileActionsType.RESET_PROFILE_ADDRESS_STATUS:
      return {
        ...state,
        addressUpdateStatus: initialState.addressUpdateStatus
      };
    case ProfileActionsType.SET_PROFILE_ADDRESS_PLAN_FAMILY:
      return {
        ...state,
        addressPlanFamily: action.planFamily
      };
    case ProfileActionsType.GET_CONTACT_INFOS_SUCCESS:
      return {
        ...state,
        contactInfos: {
          ...state.contactInfos,
          email: { ...action.email, objects: action.email?.objects || [] },
          phone: action.phone,
          postalRc: action.postalRc,
          postalEs: action.postalEs
        }
      };
    case ProfileActionsType.GET_CONTACT_INFOS_REQUEST:
      return {
        ...state,
        contactFormContentType: undefined
      };
    case ProfileActionsType.GET_CONTACT_INFOS_FAILURE:
      return {
        ...state
      };

    case ProfileActionsType.POST_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactFormContentType: ContactFormPopinContentTypeEnum.SUCCESS
      };
    case ProfileActionsType.POST_CONTACT_FORM_REQUEST:
      return {
        ...state,
        contactFormContentType: undefined
      };
    case ProfileActionsType.POST_CONTACT_FORM_FAILURE:
      return {
        ...state,
        contactFormContentType: ContactFormPopinContentTypeEnum.ERROR
      };
    case ProfileActionsType.RESET_CONTACT_FORM_STATE:
      return {
        ...state,
        contactFormContentType: undefined
      };
    default:
      return state;
  }
}
