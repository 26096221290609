import React, { Fragment, useCallback } from 'react';
import { View } from 'react-native';

import { Card, RetryActionPlaceHolder } from '@components/ERE-UILib';

import { CompaignBlockLoader } from './components';
import { Props } from './interface';
import { useStyles } from './useStyles';
import { HtmlRenderConnectedComponent } from '../HtmlRenderConnected';

export const CompaignCard: React.FC<Props> = ({
  html,
  retryAction,
  isLoading,
  hasError
}: Props) => {
  const styles = useStyles();

  const renderItem = useCallback(() => {
    return (
      <View style={styles.CompaignCardContainer}>
        <HtmlRenderConnectedComponent html={html} />
      </View>
    );
  }, [html, styles]);

  const renderLoader = useCallback(() => {
    if (!isLoading) return null;

    return (
      <Card containerStyle={styles.compaignLoadingBlocStyle}>
        <CompaignBlockLoader />
      </Card>
    );
  }, [isLoading]);

  const renderRetryAction = useCallback(() => {
    if (!hasError) return null;

    return (
      <RetryActionPlaceHolder
        containerStyle={styles.CompaignCardContainer}
        retryAction={retryAction}
      />
    );
  }, [hasError, retryAction]);

  return (
    <Fragment>
      {renderLoader()}
      {renderRetryAction()}
      {renderItem()}
    </Fragment>
  );
};
