import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

const CARD_HEIGHT = 240;
const CARD_WIDTH = 310;

export const useStyles = () => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      CompaignCardContainer: {
        borderRadius: 5,
        overflow: 'hidden',
        maxWidth: 550,
        maxHeight: CARD_HEIGHT,
        marginBottom: 10
      },
      compaignLoadingBlocStyle: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT
      }
    });
  }, []);

  return styles;
};
