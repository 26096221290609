import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";

import { DisplayValueType } from "@ere-uilib/types";
import { PlanCompartmentsType } from "@modules/common/types";
import {
    ProfitSharingIncentiveAssigmentAmountsType,
    ProfitSharingIncentiveRepartitionState
} from "@modules/profit-sharing-incentive/types";
import { isRealExternalPlan as isExternalPlan } from "@pages/ProfitSharingIncentive/utils";

type ProfitSharingIncentiveRepartitionContextType = {
    totalInvestedAmount: number;
    hasStartedInvest: boolean;
    remainingInvestedPercent: number;
    remainingInvestedAmount: number;
    isformFilled: boolean;
    isPercentage: boolean;
    setDisplayTypeValue: (item: DisplayValueType) => void;
    displayTypeValue: DisplayValueType;
    isRealExternalPlan: (plan:  PlanCompartmentsType) => boolean;
}

type ProviderType = {
    repartition: ProfitSharingIncentiveRepartitionState | null,
    assigmentAmounts: ProfitSharingIncentiveAssigmentAmountsType | null
}

const ProfitSharringRepartitionContext = createContext<ProfitSharingIncentiveRepartitionContextType | undefined>(undefined);
const  useProfitSharringRepartitionContext = ():ProfitSharingIncentiveRepartitionContextType  => {
    const context = useContext(ProfitSharringRepartitionContext);
    if (!context) {
        throw new Error("useProfitSharringRepartitionContext must be used within an useProfitSharringRepartitionProvider");
    }
    return context;
}

const ProfitSharringRepartitionProvider: React.FC<PropsWithChildren<ProviderType>> = ({ children, assigmentAmounts, repartition }) => {

    const [isPercentage, setIsPercentage] = useState(false);

    const totalInvestedAmount = useMemo(() => assigmentAmounts?.reinvestAmount || 0, [assigmentAmounts])

    const { hasStartedInvest, remainingInvestedPercent, remainingInvestedAmount, isformFilled } = useMemo(() => {
        const investedPercent = repartition?.formValues?.amountPercent || 0;
        const investedAmount = repartition?.formValues?.amount || 0;
        const hasStartedInvest = investedPercent > 0 || investedAmount > 0;
        let remainingInvestedPercent = 100.00 - investedPercent;
        if (remainingInvestedPercent < 0) { remainingInvestedPercent = 0 }

        let remainingInvestedAmount = Number((totalInvestedAmount - investedAmount).toFixed(2));
        if (remainingInvestedAmount < 0) { remainingInvestedAmount = 0 }

        const isformFilled = isPercentage ?
            remainingInvestedPercent === 0
            : remainingInvestedAmount === 0;
        return {
            hasStartedInvest,
            remainingInvestedPercent,
            remainingInvestedAmount,
            isformFilled
        };
    }, [isPercentage, repartition?.formValues?.amount, repartition?.formValues?.amountPercent, totalInvestedAmount]);

    const setDisplayTypeValue = useCallback((newValue: DisplayValueType) => {
        setIsPercentage(newValue === DisplayValueType.PERCENT);
    }, []);

    const displayTypeValue = isPercentage ? DisplayValueType.PERCENT : DisplayValueType.EURO;

    return <ProfitSharringRepartitionContext.Provider
            value={{ isPercentage,
                    remainingInvestedPercent,
                    remainingInvestedAmount,
                    isformFilled,
                    hasStartedInvest,
                    totalInvestedAmount,
                    setDisplayTypeValue,
                    displayTypeValue,
                    isRealExternalPlan: isExternalPlan }}>
                {children}
    </ProfitSharringRepartitionContext.Provider>;
};

export { ProfitSharringRepartitionProvider, useProfitSharringRepartitionContext };
