import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import { Title, FontIcon } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { AllocationState } from '@ere-uilib/types';
import { estimatedNetBeforeTaxPlansTypes } from '@modules/common/constants';

import { GlobalSavingsCardStyles, useStyles } from './styles';

interface GlobalSavingsCardProps extends GlobalSavingsCardStyles {
  index?: number;
  plan: AllocationState;
  onPlanPress: (planId: string) => void;
}

export const GlobalSavingsCard: React.FC<React.PropsWithChildren<GlobalSavingsCardProps>> = ({
  index,
  labelRowStyle,
  detailRowStyle,
  cardRowStyle,
  labelDotStyle,
  amountStyle,
  valueStyle,
  chevronStyle,
  plan,
  onPlanPress
}) => {
  const { formatMessage, formatCurrencyNumber } = useTranslation();
  const theme = useTheme();
  const styles = useStyles({
    labelRowStyle,
    detailRowStyle,
    cardRowStyle,
    labelDotStyle,
    amountStyle,
    valueStyle,
    chevronStyle
  });

  const renderTitle = (title: string) => (
    <Title
      testId={plan.shortName}
      variant="t7"
      weight="bold">
      {title}
    </Title>
  );
  const shouldDisplayNetBeforeTax =
    !!plan.totalNetEstimatedAmount && estimatedNetBeforeTaxPlansTypes.includes(plan.type || '');
  return (
    <TouchableOpacity
      key={index}
      onPress={() => {
        onPlanPress(plan.planID);
      }}
      style={[styles.cardColumnStyle, index === 0 && styles.cardFirstColumnStyle]}>
      <View style={styles.cardRowStyle}>
        <View style={styles.planTitleCard}>
          <View style={[styles.labelDotStyle, { backgroundColor: plan.colors[0] }]} />
          <View style={styles.columnContainer}>
            {plan.isCustomPlan ? (
              <>
                {renderTitle(plan.name)}
                <Text>{plan.type}</Text>
              </>
            ) : (
              renderTitle(plan.shortName)
            )}
          </View>
        </View>
        <View style={styles.detailRowStyle}>
          <View style={styles.detailContentStyle}>
            <Text style={styles.amountStyle}>
              {formatCurrencyNumber({ value: plan.totalAmount })}
            </Text>
            {!!plan.totalNetEstimatedAmount && (
              <Text style={styles.estimatedNetAmount}>
                {`${formatMessage({
                  id: 'NENetEstiméDashboard'
                })} ${formatCurrencyNumber({ value: plan.totalNetEstimatedAmount })}`}
              </Text>
            )}
            {typeof plan.totalAddedValue === 'number' && (
              <Text
                style={[
                  styles.valueStyle,
                  {
                    color:
                      plan.totalAddedValue < 0
                        ? theme.colors.notifications.warning.c500
                        : theme.colors.basics.primary.c500
                  }
                ]}>
                {formatMessage({
                  id: 'Dashboard_CardRepartition_Item_value_label',
                  values: { variable: formatCurrencyNumber({ value: plan.totalAddedValue }) }
                })}
              </Text>
            )}
          </View>
          <FontIcon
            name="chevron-droite"
            style={styles.chevronStyle}
          />
        </View>
      </View>
      {shouldDisplayNetBeforeTax && (
        <Text style={[styles.estimatedNetAmount, { textAlign: 'left' }]}>
          {formatMessage({
            id: 'NEAvantImpotSurLeRevenuDashboard'
          })}
        </Text>
      )}
    </TouchableOpacity>
  );
};
