import {
  ProfitSharingIncentiveAllowedAffectationTypeEnum,
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentivePaymentModeTypeEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/index';
import {
  ProfitSharingIncentiveInitDataState,
  ProfitSharingIncentiveRepartitionPlansState
} from '@modules/profit-sharing-incentive/types';

export const mockProfitSharingIncentiveInitData: ProfitSharingIncentiveInitDataState = {
  "companyId": "2",
  "id": "XX-000123",
  "type": ProfitSharingIncentiveTypeEnum.incentive,
  "legalFramework": ProfitSharingIncentiveLegalFrameworkEnum.normal,
  "defaultAffectations": [
    {
      "planId": 2001,
      "planName": "PEE",
      "planType": "PEE",
      "planFullName": "PEE SUPER",
      "isCustomPlan": false,
      "compartments": [
        {
          "code": "PER C1",
          "label": "Versements volontaires déductibles",
          "managements": [
            {
              "isFictive": false,
              "id": "PERECO-C1-L",
              "code": "PERECO C1 L",
              "label": "Gestion Libre",
              "totalAmount": 2000,
              "supports": [
                {
                  "supportIsin": "QS0002105TJ2",
                  "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                  "supportType": "FONDS",
                  "hasNoFundSheet": false,
                  "supportIdNoee": "1042",
                  "riskLevelSRRI": "6",
                  "riskLevelSRI": "5",
                  "performanceValue": 19.9222694560339,
                  'source': 'UNKNOWN',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 60,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 18.8706,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "09/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": true,
                    "ThresholdTriggerValidityDuration": 3,
                    "ThresholdTriggerValidityDate": "2023-01-21T00:00:00+01:00",
                    "CategorieClassification": "Actions internationales",
                    "DefaultAmount": 678.89945
                  },
                  "filterProperties": {},
                  "isISR": false
                },
                {
                  "supportIsin": "QS0002105TY1",
                  "supportName": "Multipar Monétaire Sélection Classique",
                  "supportType": "FONDS",
                  "hasNoFundSheet": true,
                  "supportIdNoee": "1039",
                  'source': 'UNKNOWN',
                  "riskLevelSRRI": "6",
                  "riskLevelSRI": "5",
                  "performanceValue": -0.387212073073828,
                  "amount": {
                    "amount": 1000,
                    "addedValue": 40,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 11.0915,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "11/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": false,
                    "CategorieClassification": "Monétaire",
                    "DefaultAmount": 89.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                }
              ],
              "order": 4,
              "isFree": true,
              "filterProperties": {
                "TaxOut": true,
                "VVL": true,
                "VVP": true
              },
              "advancedProperties": {
                "HasContribution": false
              }
            },
          ],
          "order": 3
        }
      ],
      "filterProperties": {
        "VVL": true,
        "VVP": true,
        "TaxOut": true,
        "TaxIn": true,
        "RetirementOnly": true,
        "Abondement": true,
        "Invested": true,
        "ThresholdTrigger": false,
        "StockReallocation": true,
        "PartialReallocation": true,
        "FlowReallocation": false,
        "OadReallocation": false,
        "Contribution": true,
        "HideInformationVvInf25": true,
      },
      "advancedProperties": {
        "ContributionConsumed": 25,
        "ContributionLimit": 5100,
        "HasContribution": false
      },
      "colors": [
        "#F3B351"
      ],
      "planFamily": "ES",
      "label": "​​Plan d'Epargne Retraite COllectif​",
      "shortLabel": "PERECO",
      "horizon": "Retraite"
    },
    {
      "planId": 2002,
      "planName": "PERECO",
      "planType": "PERECO",
      "planFullName": "PERECO SUPER",
      "isCustomPlan": false,
      "compartments": [
        {
          "code": "PER C1",
          "label": "Versements volontaires déductibles",
          "managements": [
            {
              "isFictive": false,
              "id": "PERECO-C1-L",
              "code": "PERECO C1 L",
              "label": "Gestion Libre",
              "totalAmount": 2000,
              "supports": [
                {
                  "supportIsin": "QS0002105TJ2",
                  "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                  "supportType": "FONDS",
                  "hasNoFundSheet": false,
                  "supportIdNoee": "1042",
                  "riskLevelSRRI": "6",
                  "riskLevelSRI": "5",
                  'source': 'Morningstar',
                  "performanceValue": 19.9222694560339,
                  "amount": {
                    "amount": 1000,
                    "addedValue": 60,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 18.8706,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "09/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": true,
                    "ThresholdTriggerValidityDuration": 3,
                    "ThresholdTriggerValidityDate": "2023-01-21T00:00:00+01:00",
                    "CategorieClassification": "Actions internationales",
                    "DefaultAmount": 289.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                },
                {
                  "supportIsin": "QS0002105TY1",
                  "supportName": "Multipar Monétaire Sélection Classique",
                  "supportType": "FONDS",
                  "hasNoFundSheet": true,
                  "supportIdNoee": "1039",
                  "riskLevelSRRI": "1",
                  "riskLevelSRI": "2",
                  "performanceValue": -0.387212073073828,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 40,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 11.0915,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "11/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": false,
                    "CategorieClassification": "Monétaire",
                    "DefaultAmount": 39.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                }
              ],
              "order": 4,
              "isFree": true,
              "filterProperties": {
                "TaxOut": true,
                "VVL": true,
                "VVP": true
              },
              "advancedProperties": {
                "HasContribution": false
              }
            },
            {
              "isFictive": false,
              "id": "PERECO-C1-L",
              "code": "PERECO C1 L",
              "label": "Gestion Piloté",
              "totalAmount": 2000,
              "supports": [
                {
                  "supportIsin": "QS0002105TJ2",
                  "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                  "supportType": "FONDS",
                  "hasNoFundSheet": false,
                  "supportIdNoee": "1042",
                  "riskLevelSRRI": "6",
                  "riskLevelSRI": "5",
                  "performanceValue": 19.9222694560339,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 60,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": true,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 18.8706,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "09/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": true,
                    "ThresholdTriggerValidityDuration": 3,
                    "ThresholdTriggerValidityDate": "2023-01-21T00:00:00+01:00",
                    "CategorieClassification": "Actions internationales",
                    "DefaultAmount": 13.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                },
                {
                  "supportIsin": "QS0002105TY1",
                  "supportName": "Multipar Monétaire Sélection Classique",
                  "supportType": "FONDS",
                  "hasNoFundSheet": true,
                  "supportIdNoee": "1039",
                  "riskLevelSRRI": "1",
                  "riskLevelSRI": "2",
                  "performanceValue": -0.387212073073828,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 40,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 11.0915,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "11/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": false,
                    "CategorieClassification": "Monétaire"
                  },
                  "filterProperties": {},
                  "isISR": false
                }
              ],
              "order": 4,
              "isFree": false,
              "filterProperties": {
                "TaxOut": true,
                "VVL": true,
                "VVP": true
              },
              "advancedProperties": {
                "HasContribution": false
              }
            }
          ],
          "order": 3
        }
      ],
      "filterProperties": {
        "VVL": true,
        "VVP": true,
        "TaxOut": true,
        "TaxIn": true,
        "RetirementOnly": true,
        "Abondement": true,
        "Invested": true,
        "ThresholdTrigger": false,
        "StockReallocation": true,
        "PartialReallocation": true,
        "FlowReallocation": false,
        "OadReallocation": false,
        "Contribution": true,
        "HideInformationVvInf25": true,
      },
      "advancedProperties": {
        "ContributionConsumed": 25,
        "ContributionLimit": 5100,
        "HasContribution": false
      },
      "colors": [
        "#F3B351"
      ],
      "planFamily": "ES",
      "label": "​​Plan d'Epargne Retraite COllectif​",
      "shortLabel": "PERECO",
      "horizon": "Retraite"
    },
    {
      "planId": 2002,
      "planName": "PERCO I",
      "planType": "PERCOI",
      "planFullName": "PERECO SUPER",
      "isCustomPlan": false,
      "compartments": [
        {
          "code": "PER C1",
          "label": "Versements volontaires déductibles",
          "managements": [
            {
              "isFictive": false,
              "id": "PERECO-C1-L",
              "code": "PERECO C1 L",
              "label": "Gestion Piloté",
              "totalAmount": 2000,
              "supports": [
                {
                  "supportIsin": "QS0002105TJ2",
                  "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                  "supportType": "FONDS",
                  "hasNoFundSheet": false,
                  "supportIdNoee": "1042",
                  "riskLevelSRRI": "6",
                  "riskLevelSRI": "5",
                  "performanceValue": 19.9222694560339,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 60,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": true,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 18.8706,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "09/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": true,
                    "ThresholdTriggerValidityDuration": 3,
                    "ThresholdTriggerValidityDate": "2023-01-21T00:00:00+01:00",
                    "CategorieClassification": "Actions internationales",
                    "DefaultAmount": 13.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                },
                {
                  "supportIsin": "QS0002105TY1",
                  "supportName": "Multipar Monétaire Sélection Classique",
                  "supportType": "FONDS",
                  "hasNoFundSheet": true,
                  "supportIdNoee": "1039",
                  "riskLevelSRRI": "1",
                  "riskLevelSRI": "2",
                  "performanceValue": -0.387212073073828,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 40,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 11.0915,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "11/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": false,
                    "CategorieClassification": "Monétaire"
                  },
                  "filterProperties": {},
                  "isISR": false
                }
              ],
              "order": 4,
              "isFree": false,
              "filterProperties": {
                "TaxOut": true,
                "VVL": true,
                "VVP": true
              },
              "advancedProperties": {
                "HasContribution": false
              }
            }
          ],
          "order": 3
        }
      ],
      "filterProperties": {
        "VVL": true,
        "VVP": true,
        "TaxOut": true,
        "TaxIn": true,
        "RetirementOnly": true,
        "Abondement": true,
        "Invested": true,
        "ThresholdTrigger": false,
        "StockReallocation": true,
        "PartialReallocation": true,
        "FlowReallocation": false,
        "OadReallocation": false,
        "Contribution": true,
        "HideInformationVvInf25": true,
      },
      "advancedProperties": {
        "ContributionConsumed": 25,
        "ContributionLimit": 5100,
        "HasContribution": false
      },
      "colors": [
        "#F3B351"
      ],
      "planFamily": "ES",
      "label": "​​Plan d'Epargne Retraite COllectif​",
      "shortLabel": "PERECO",
      "horizon": "Retraite"
    }
  ],
  "derogatoryAffectations": [
    {
      "planId": 2001,
      "planName": "PEE",
      "planType": "PEE",
      "planFullName": "PERECO SUPER",
      "isCustomPlan": false,
      "compartments": [
        {
          "code": "PER C1",
          "label": "Versements volontaires déductibles",
          "managements": [
            {
              "isFictive": false,
              "id": "PERECO-C1-L",
              "code": "PERECO C1 L",
              "label": "Gestion Libre",
              "totalAmount": 2000,
              "supports": [
                {
                  "supportIsin": "QS0002105TJ2",
                  "supportName": "BNP PARIBAS PERSPECTIVES 2037-2039 I",
                  "supportType": "FONDS",
                  "hasNoFundSheet": false,
                  "supportIdNoee": "1042",
                  "riskLevelSRRI": "6",
                  "riskLevelSRI": "5",
                  "performanceValue": 19.9222694560339,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 60,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 12.1706,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "09/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": true,
                    "ThresholdTriggerValidityDuration": 3,
                    "ThresholdTriggerValidityDate": "2023-01-21T00:00:00+01:00",
                    "CategorieClassification": "Actions internationales",
                    "DefaultAmount": 89.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                },
                {
                  "supportIsin": "QS0002105TY1",
                  "supportName": "Multipar Monétaire Sélection Classique",
                  "supportType": "FONDS",
                  "hasNoFundSheet": true,
                  "supportIdNoee": "1039",
                  "riskLevelSRRI": "1",
                  "riskLevelSRI": "2",
                  "performanceValue": -0.387212073073828,
                  'source': 'Morningstar',
                  "amount": {
                    "amount": 1000,
                    "addedValue": 40,
                    "numberOfUnits": 967
                  },
                  "rate": null,
                  "supportDetailsUrl": null,
                  "legalStatus": "FCP",
                  "isMaster": false,
                  "hasContribution": false,
                  "netAssetValue": {
                    "amount": 9.8755,
                    "currency": "EUR",
                    "dateValue": "2020-09-17T00:00:00+02:00"
                  },
                  "plans": null,
                  "hasVersement": true,
                  "hasInteressement": true,
                  "hasParticipation": true,
                  "nextNavDate": "11/02/2018 00:00:00 +01:00",
                  "advancedProperties": {
                    "HasThresholdTrigger": false,
                    "CategorieClassification": "Monétaire",
                    "DefaultAmount": 89.25476
                  },
                  "filterProperties": {},
                  "isISR": false
                }
              ],
              "order": 4,
              "isFree": true,
              "filterProperties": {
                "TaxOut": true,
                "VVL": true,
                "VVP": true
              },
              "advancedProperties": {
                "HasContribution": false,
                "DefaultAmount": 2000
              }
            },
          ],
          "order": 3
        }
      ],
      "filterProperties": {
        "VVL": true,
        "VVP": true,
        "TaxOut": true,
        "TaxIn": true,
        "RetirementOnly": true,
        "Abondement": true,
        "Invested": true,
        "ThresholdTrigger": false,
        "StockReallocation": true,
        "PartialReallocation": true,
        "FlowReallocation": false,
        "OadReallocation": false,
        "Contribution": true,
        "HideInformationVvInf25": true,
      },
      "advancedProperties": {
        "ContributionConsumed": 25,
        "ContributionLimit": 5100,
        "HasContribution": false
      },
      "colors": [
        "#F3B351"
      ],
      "planFamily": "ES",
      "label": "​​Plan d'Epargne Retraite COllectif​",
      "shortLabel": "PERECO",
      "horizon": "Retraite"
    },
  ],
  "deadlineDate": "2022-02-10T01:00:00+01:00",
  "fiscalYearStartDate": "2022-02-01T01:00:00+01:00",
  "fiscalYearEndDate": "2023-02-01T01:00:00+01:00",
  "totalAmount": 2004.78,
  "netShareAmount": 975.24,
  "derogatoryAmount": 120.6798,
  "minimumAmount": 250.76,
  "possibleContribution": true,
  "possibleChoices": [
    ProfitSharingIncentiveAllowedAffectationTypeEnum.REFUND,
    ProfitSharingIncentiveAllowedAffectationTypeEnum.REINVEST
  ],
  "paymentMethod": ProfitSharingIncentivePaymentModeTypeEnum.RIB,
  "saverDetails": {
    "fullAddress": {
      "additionalAddress_1": "Rue de tolbiac",
      "additionalAddress_2": null,
      "additionalAddress_3": null,
      "street": null,
      "country": "France",
      "zipcode": "75000",
      "city": "Paris",
      "recipient": null
    },
    "bankAccountDetail": {
      "domiciliation": null,
      "titulaire": null,
      "rum": "123",
      "ics": "1234",
      "iban": "FR*********************0VER",
      "bic": "RGFIFRPP",
      "type": "VIREMENT"
    }
  },
  "availableContributionAmount": 1945.0,
  "suggestedContributionAmount": 4977.5,
  "isContributionTargetingOK": true
}

export const mockProfitSharingIncentiveRepartition: ProfitSharingIncentiveRepartitionPlansState[] = [
  {
    "planId": 2001,
    "planName": "PEE",
    "planType": "PEE",
    "planFullName": "PERECO SUPER",
    "isCustomPlan": false,
    "compartments": [
      {
        "code": "PEE",
        "label": "PEE",
        "managements": [
          {
            "isFictive": false,
            "id": "PEE-AMPLISSIM",
            "code": "PEE AMPLISSIM",
            "label": "PEE AMPLISSIM",
            "totalAmount": 14672.1257197,
            "supports": [
              {
                "supportIsin": "FR0014000AL1",
                "supportName": "AM LABEL HARMONIE SOLID ESR-F)- morningstar",
                "supportType": "FONDS",
                "supportIdNoee": "28025",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "3",
                "performanceValue": -4.61690978614924,
                'source': 'Morningstar',
                "amount": {
                  "amount": 10778.1202546,
                  "addedValue": 1174.1202546,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 203.43,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "hasNoFundSheet": true,
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": true,
                  "ThresholdTriggerValidityDuration": 3,
                  "ThresholdTriggerValidityDate": "2023-01-11T00:00:00+01:00",
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "QS0002906129",
                "supportName": "BNPP SUPPORT 3",
                "supportType": "FONDS",
                "supportIdNoee": "10403",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "3",
                "performanceValue": 0,
                "amount": {
                  "amount": 1000,
                  "addedValue": 100,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FME",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 47.79,
                  "currency": "EUR",
                  "dateValue": "2018-02-07T00:00:00+01:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "nextNavDate": "09/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": true,
                  "ThresholdTriggerValidityDuration": 3,
                  "ThresholdTriggerValidityDate": "2023-01-11T00:00:00+01:00",
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "FR0013508793",
                "supportName": "BNPP SUPPORT 1",
                "supportType": "FONDS",
                "supportIdNoee": "28031",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "2",
                "performanceValue": 0,
                "amount": {
                  "amount": 1000,
                  "addedValue": 100.01,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "",
                "isMaster": false,
                "hasContribution": false,
                'source': 'Morningstar',
                "netAssetValue": {
                  "amount": 18,
                  "currency": "EUR",
                  "dateValue": "2020-08-26T22:00:00+00:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "hasNoFundSheet": true,
                "nextNavDate": "22/09/2022 22:00:00 +00:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "FR0010716217",
                "supportName": "BNPP SUPPORT 1",
                "supportType": "FONDS",
                "supportIdNoee": "28013",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "3",
                "performanceValue": -2.93863439930875,
                "amount": {
                  "amount": 1000,
                  "addedValue": 100,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "netAssetValue": {
                  "amount": 135.28,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "FR0010776807",
                "supportName": "Amundi Label Actions Euroland ESR - Hottinguer Patrimoine morninstarg",
                "supportType": "FONDS",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "5",
                "performanceValue": 19.9222694560339,
                "amount": {
                  "amount": 450.0054651,
                  "addedValue": 100.01,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": true,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "09/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "LU0992629237",
                "supportName": "CamGestion Club Patrimoine Classic - Carmignac Pf Green Gold Morninstar",
                "supportType": "FONDS",
                "supportIdNoee": "FR0007085436",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "2",
                "performanceValue": 0,
                'source': 'Morningstar',
                "amount": {
                  "amount": 444,
                  "addedValue": 4,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "",
                "isMaster": false,
                "hasContribution": true,
                "hasNoFundSheet": true,
                "netAssetValue": {
                  "amount": 18,
                  "currency": "EUR",
                  "dateValue": "2020-08-26T22:00:00+00:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "22/09/2022 22:00:00 +00:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              }
            ],
            "order": 0,
            "isFree": true,
            "filterProperties": {
              "Contribution": true,
              "FlowReallocation": false,
              "PartialReallocation": true,
              "RetirementOnly": false,
              "StockReallocation": true,
              "TaxIn": true,
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasContribution": true
            }
          }
        ],
        "order": 1,
      }
    ],
    "filterProperties": {
      "VVL": true,
      "VVP": true,
      "TaxOut": false,
      "TaxIn": true,
      "RetirementOnly": false,
      "Abondement": true,
      "Invested": true,
      "ThresholdTrigger": false,
      "StockReallocation": true,
      "PartialReallocation": true,
      "FlowReallocation": false,
      "OadReallocation": false,
      "Contribution": true
    },
    "advancedProperties": {
      "HasContribution": true,
      "ContributionConsumed": 350,
      "ContributionLimit": 675
    },
    "colors": [
      "#5B8AB5"
    ],
    "planFamily": "ES",
    "label": "​Plan d'Epargne Entreprise",
    "shortLabel": "PEE",
    "horizon": "MoyenTerme"
  },
  {
    "planId": 2002,
    "planName": "PERECO",
    "planType": "PERECO",
    "planFullName": "PERECO SUPER",
    "isCustomPlan": false,
    "compartments": [
      {
        "code": "PERCO",
        "label": "Epargne salariale",
        "managements": [
          {
            "isFictive": false,
            "id": "PERECO-C2-P",
            "code": "PERECO C2 P",
            "label": "Gestion Pilotée",
            "totalAmount": 14635.23,
            "supports": [
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "2",
                "performanceValue": -0.387212073073828,
                "amount": {
                  "amount": 8395,
                  "addedValue": 145.25,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "5",
                "performanceValue": 19.9222694560339,
                "amount": {
                  "amount": 6240.23,
                  "addedValue": 91.4,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": true,
                "hasContribution": false,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "09/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": true,
                  "ThresholdTriggerValidityDuration": 3,
                  "ThresholdTriggerValidityDate": "2023-01-11T00:00:00+01:00",
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              }
            ],
            "order": 2,
            "isFree": false,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {}
          },
          {
            "isFictive": false,
            "id": "PERECO-C2-L",
            "code": "PERECO C2 L",
            "label": "Gestion Libre",
            "totalAmount": 2000,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "5",
                "performanceValue": 19.9222694560339,
                'source': 'Morningstar',
                "amount": {
                  "amount": 1000,
                  "addedValue": 60,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "hasNoFundSheet": true,
                "nextNavDate": "09/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": true,
                  "ThresholdTriggerValidityDuration": 3,
                  "ThresholdTriggerValidityDate": "2023-01-11T00:00:00+01:00",
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "2",
                "performanceValue": -0.387212073073828,
                "amount": {
                  "amount": 1000,
                  "addedValue": 40,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              }
            ],
            "order": 4,
            "isFree": true,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {}
          }
        ],
        "order": 2
      },
    ],
    "filterProperties": {
      "VVL": true,
      "VVP": true,
      "TaxOut": true,
      "TaxIn": true,
      "RetirementOnly": true,
      "Abondement": true,
      "Invested": true,
      "ThresholdTrigger": false,
      "StockReallocation": true,
      "PartialReallocation": true,
      "FlowReallocation": false,
      "OadReallocation": false,
      "Contribution": true,
      "HideInformationVvInf25": true,
    },
    "advancedProperties": {
      "HasContribution": true,
      "ContributionConsumed": 675,
      "ContributionLimit": 675
    },
    "colors": [
      "#F3B351"
    ],
    "planFamily": "ES",
    "label": "​​Plan d'Epargne Retraite COllectif​",
    "shortLabel": "PERECO",
    "horizon": "Retraite"
  },
  {
    "planId": 2501,
    "planName": "PERO",
    "planType": "PERO",
    "planFullName": "PERO SUPER",
    "isCustomPlan": false,
    "compartments": [
      {
        "code": "1",
        "label": "Versements volontaires déductibles",
        "managements": [
          {
            "isFictive": false,
            "id": "10001",
            "code": "Gestion à Horizon",
            "label": "Gestion à Horizon",
            "totalAmount": 1500,
            "supports": [
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "MULTIPAR MONETAIRE SELECTION",
                "supportType": "FONDS",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "2",
                "performanceValue": -0.387212073073828,
                "amount": {
                  "amount": 1000,
                  "addedValue": 0,
                  "numberOfUnits": 0
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": false,
                "hasParticipation": false,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              },
              {
                "supportIsin": "FR0010839274",
                "supportName": "BNP Paribas Perspectives 2028-2030",
                "supportType": "FONDS",
                "supportIdNoee": "25039",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "3",
                "performanceValue": 6.2425252426233,
                "amount": {
                  "amount": 500,
                  "addedValue": 0,
                  "numberOfUnits": 0
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 29.5929,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": false,
                "hasParticipation": false,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              }
            ],
            "order": 0,
            "isFree": false,
            "filterProperties": {
              "TaxOut": true,
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {}
          },
          {
            "isFictive": false,
            "id": "10004",
            "code": "Gestion Libre C1",
            "label": "Gestion Libre C1",
            "totalAmount": 1000,
            "supports": [
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "MULTIPAR MONETAIRE SELECTION",
                "supportType": "FONDS",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "2",
                "performanceValue": -0.387212073073828,
                "amount": {
                  "amount": 1000,
                  "addedValue": 0,
                  "numberOfUnits": 0
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": false,
                "hasParticipation": false,
                "hasNoFundSheet": true,
                'source': 'Morningstar',
                "nextNavDate": "11/02/2018 00:00:00 +01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {
                  "StockReallocation": true
                },
                "isISR": false
              }
            ],
            "order": 0,
            "isFree": true,
            "filterProperties": {
              "TaxOut": true,
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {}
          }
        ],
        "order": 3
      },
    ],
    "filterProperties": {
      "VVL": true,
      "VVP": true,
      "TaxOut": true,
      "TaxIn": true,
      "RetirementOnly": true,
      "Abondement": false,
      "Invested": true,
      "ThresholdTrigger": false,
      "StockReallocation": true,
      "PartialReallocation": false,
      "FlowReallocation": true,
      "OadReallocation": false,
      "Contribution": false,
    },
    "advancedProperties": {},
    "colors": [
      "#F28B2D"
    ],
    "planFamily": "RC",
    "label": "​PER Obligatoire",
    "shortLabel": "PERO",
    "horizon": "Retraite"
  }
];

export const mockProfitSharingIncentiveRepartitionIncorrectDrivingData: ProfitSharingIncentiveRepartitionPlansState[] = [
  {
    "planId": 2001,
    "planName": "PEE",
    "planType": "PEE",
    "planFullName": "PEE SUPER",
    "isCustomPlan": false,
    "compartments": [
      {
        "code": "PEE",
        "label": "",
        "managements": [
          {
            "id": "PEE-AMPLISSIM",
            "code": "PEE AMPLISSIM",
            "label": "",
            "totalAmount": 14672.1257197,
            "supports": [
              {
                "supportIsin": "QS0002906129",
                "supportName": "BNPP SUPPORT 3",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "EXTERNAL",
                "supportIdNoee": "10403",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "",
                "performanceValue": 0,
                "amount": {
                  "amount": 1000,
                  "addedValue": 100,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FME",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 47.79,
                  "currency": "EUR",
                  "dateValue": "2018-02-07T00:00:00+01:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "LU0992629237",
                "supportName": "CamGestion Club Patrimoine Classic - Carmignac Pf Green Gold Morninstar",
                "supportType": "FONDS",
                "hasNoFundSheet": true,
                "source": "",
                "supportIdNoee": "FR0007085436",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "",
                "performanceValue": 0,
                "amount": {
                  "amount": 444,
                  "addedValue": 4,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18,
                  "currency": "EUR",
                  "dateValue": "2020-08-26T22:00:00+00:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2022-09-22T22:00:00+00:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0013276532",
                "supportName": "CamGestion Euroblig Privilege D",
                "supportType": "FONDS",
                "hasNoFundSheet": true,
                "source": "",
                "supportIdNoee": "FR0013276532",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "",
                "performanceValue": 0,
                "amount": {
                  "amount": 0,
                  "addedValue": 0,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18,
                  "currency": "EUR",
                  "dateValue": "2020-08-26T22:00:00+00:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2022-09-22T22:00:00+00:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0014000AL1",
                "supportName": "AM LABEL HARMONIE SOLID ESR-F)- morningstar",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "28025",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "3",
                "performanceValue": -4.61690978614924,
                "amount": {
                  "amount": 10778.1202546,
                  "addedValue": 1174.1202546,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 203.43,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0010776807",
                "supportName": "Amundi Label Actions Euroland ESR - Hottinguer Patrimoine morninstarg",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 450.0054651,
                  "addedValue": 100.01,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0013508793",
                "supportName": "BNPP SUPPORT 1",
                "supportType": "FONDS",
                "hasNoFundSheet": true,
                "source": "",
                "supportIdNoee": "28031",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "",
                "performanceValue": 0,
                "amount": {
                  "amount": 1000,
                  "addedValue": 100.01,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18,
                  "currency": "EUR",
                  "dateValue": "2020-08-26T22:00:00+00:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2022-09-22T22:00:00+00:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0010716217",
                "supportName": "BNPP SUPPORT 1",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "28013",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "2",
                "performanceValue": -2.93863439930875,
                "amount": {
                  "amount": 1000,
                  "addedValue": 100,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 135.28,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0010715326",
                "supportName": "BNPP SUPPORT 1",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "28014",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": -8.51115717595405,
                "amount": {
                  "amount": 0,
                  "addedValue": 0,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 151.22,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 0,
            "isFree": true,
            "filterProperties": {
              "Contribution": true,
              "FlowReallocation": false,
              "OadReallocation": true,
              "PartialReallocation": true,
              "RetirementOnly": false,
              "StockReallocation": true,
              "TaxIn": true,
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": true,
              "HasContribution": false
            },
            "isFictive": true
          }
        ],
        "order": 1,
        "isFictive": true
      }
    ],
    "filterProperties": {
      "VVL": true,
      "VVP": true,
      "TaxOut": false,
      "TaxIn": true,
      "RetirementOnly": false,
      "Abondement": true,
      "Invested": true,
      "ThresholdTrigger": false,
      "StockReallocation": true,
      "PartialReallocation": true,
      "FlowReallocation": false,
      "OadReallocation": true,
      "Contribution": true
    },
    "advancedProperties": {
      "ContributionConsumed": 150,
      "ContributionLimit": 4000,
      "HasContribution": false
    },
    "colors": [
      "#5B8AB5"
    ],
    "planFamily": "ES",
    "label": "​Plan d'Epargne Entreprise",
    "shortLabel": "PEE",
    "horizon": "MoyenTerme"
  },
  {
    "planId": 2002,
    "planName": "PERECO",
    "planType": "PERCO",
    "planFullName": "PERCO SUPER",
    "isCustomPlan": false,
    "compartments": [
      {
        "code": "PERCO",
        "label": "",
        "managements": [
          {
            "id": "PERECO-C2-P",
            "code": "PERECO C2 P",
            "label": "Gestion Pilotée",
            "totalAmount": 14635.23,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 6240.23,
                  "addedValue": 91.4,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "1",
                "performanceValue": 0.392431165575058,
                "amount": {
                  "amount": 8395,
                  "addedValue": 145.25,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0010997866",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "28025",
                "riskLevelSRRI": "4",
                "riskLevelSRI": "3",
                "performanceValue": -4.61690978614924,
                "amount": {
                  "amount": 0,
                  "addedValue": 0,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "SICAV",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 203.43,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Non applicable"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "FR0010668145",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": true,
                "source": "",
                "supportIdNoee": "FR0010668145",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "",
                "performanceValue": 0,
                "amount": {
                  "amount": 0,
                  "addedValue": 0,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18,
                  "currency": "EUR",
                  "dateValue": "2020-08-26T22:00:00+00:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "",
                "advancedProperties": {
                  "HasThresholdTrigger": false
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 2,
            "isFree": false,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": false,
              "HasContribution": false
            },
            "isFictive": false
          },
          {
            "id": "PERECO-C2-L",
            "code": "PERECO C2 L",
            "label": "Gestion Lssssibre",
            "totalAmount": 2000,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 1000,
                  "addedValue": 60,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "1",
                "performanceValue": 0.392431165575058,
                "amount": {
                  "amount": 1000,
                  "addedValue": 40,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 4,
            "isFree": true,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": true,
              "HasContribution": false
            },
            "isFictive": false
          }
        ],
        "order": 0,
        "isFictive": false
      },
      {
        "code": "PER C1 bis",
        "label": "",
        "managements": [
          {
            "id": "PERECO-C1B-P",
            "code": "PERECO C1BIS P",
            "label": "Gestion Pilotéesss",
            "totalAmount": 2000,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 1000,
                  "addedValue": 60,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "1",
                "performanceValue": 0.392431165575058,
                "amount": {
                  "amount": 1000,
                  "addedValue": 40,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 2,
            "isFree": false,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": true,
              "HasContribution": false
            },
            "isFictive": false
          },
          {
            "id": "PERECO-C1B-L",
            "code": "PERECO C1BIS L",
            "label": "Gestion Libre",
            "totalAmount": 2000,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 1000,
                  "addedValue": 60,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "1",
                "performanceValue": 0.392431165575058,
                "amount": {
                  "amount": 1000,
                  "addedValue": 40,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 4,
            "isFree": true,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": true,
              "HasContribution": false
            },
            "isFictive": false
          }
        ],
        "order": 0,
        "isFictive": false
      },
      {
        "code": "PER C1",
        "label": "",
        "managements": [
          {
            "id": "PERECO-C1-L",
            "code": "PERECO C1 L",
            "label": "Gestion Libre",
            "totalAmount": 2000,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 1000,
                  "addedValue": 60,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "1",
                "performanceValue": 0.392431165575058,
                "amount": {
                  "amount": 1000,
                  "addedValue": 40,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 4,
            "isFree": true,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": true,
              "HasContribution": false
            },
            "isFictive": false
          },
          {
            "id": "PERECO-C1-P",
            "code": "PERECO C1 P",
            "label": "Gestion Pilotée",
            "totalAmount": 2000,
            "supports": [
              {
                "supportIsin": "QS0002105TJ2",
                "supportName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1042",
                "riskLevelSRRI": "6",
                "riskLevelSRI": "4",
                "performanceValue": 8.47332132561054,
                "amount": {
                  "amount": 1000,
                  "addedValue": 60,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 18.8706,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-09T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Actions internationales"
                },
                "filterProperties": {},
                "isISR": false
              },
              {
                "supportIsin": "QS0002105TY1",
                "supportName": "Multipar Monétaire Sélection Classique",
                "supportType": "FONDS",
                "hasNoFundSheet": false,
                "source": "BNPP",
                "supportIdNoee": "1039",
                "riskLevelSRRI": "1",
                "riskLevelSRI": "1",
                "performanceValue": 0.392431165575058,
                "amount": {
                  "amount": 1000,
                  "addedValue": 40,
                  "numberOfUnits": 967
                },
                "rate": null,
                "supportDetailsUrl": null,
                "legalStatus": "FCP",
                "isMaster": false,
                "hasContribution": false,
                "netAssetValue": {
                  "amount": 11.0915,
                  "currency": "EUR",
                  "dateValue": "2020-09-17T00:00:00+02:00"
                },
                "plans": null,
                "hasVersement": true,
                "hasInteressement": true,
                "hasParticipation": true,
                "nextNavDate": "2018-02-11T00:00:00+01:00",
                "advancedProperties": {
                  "HasThresholdTrigger": false,
                  "CategorieClassification": "Monétaire"
                },
                "filterProperties": {},
                "isISR": false
              }
            ],
            "order": 2,
            "isFree": false,
            "filterProperties": {
              "VVL": true,
              "VVP": true
            },
            "advancedProperties": {
              "HasIncorrectDrivingData": true,
              "HasContribution": false
            },
            "isFictive": false
          }
        ],
        "order": 0,
        "isFictive": false
      }
    ],
    "filterProperties": {
      "VVL": true,
      "VVP": true,
      "TaxOut": true,
      "TaxIn": true,
      "RetirementOnly": true,
      "Abondement": true,
      "Invested": true,
      "ThresholdTrigger": false,
      "StockReallocation": true,
      "PartialReallocation": true,
      "FlowReallocation": false,
      "OadReallocation": false,
      "Contribution": true,
      "HideInformationVvInf25": true,
    },
    "advancedProperties": {
      "ContributionConsumed": 25,
      "ContributionLimit": 5100,
      "HasContribution": false
    },
    "colors": [
      "#F3B351"
    ],
    "planFamily": "ES",
    "label": "​​Plan d'Epargne Retraite COllectif​",
    "shortLabel": "PERECO",
    "horizon": "Retraite"
  }
]
