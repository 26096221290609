import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedCompany } from '@modules/dashboard/selectors';
import { getLoadingState } from '@modules/funds/selectors';
import {
  getContactInfosRequest,
  getProfileUserInfosRequest,
  postContactFormRequest,
  resetContactFormState
} from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import {
  getContactEmailInfos,
  getContactIsloading,
  getContactPhoneInfos,
  postContactIsLoading,
  contactFormPopinType,
  contactFormMail,
  getProfileUserInfos
} from '@modules/profile/selectors';
import {
  ContactFormPopinContentTypeEnum,
  emailInfosType,
  phoneInfosType
} from '@modules/profile/types';

interface HelpContactPageContextType {
  onContactInfos: () => void;
  onGetProfileInfos: () => void;
  submitContactForm: (message: string, object: string) => void;
  resetContactForm: () => void;
  phoneData: phoneInfosType;
  emailData: emailInfosType;
  isLoading: boolean;
  isPostFormLoading: boolean;
  emailContactFormIsVisible: boolean;
  selectedIndex?: number;
  toggle: () => void;
  selectContactInfo: (number: number) => void;
  popinFormType: ContactFormPopinContentTypeEnum | undefined;
  userEmail: string;
  email: string;
  isES?: boolean;
  isRC?: boolean;
}

export const HelpContactPageContext = createContext<HelpContactPageContextType | null>(null);

export const useHelpContactContext = (): HelpContactPageContextType => {
  const context = useContext(HelpContactPageContext);
  if (!context) {
    throw new Error('useHelpContactContext must be used within HelpContactPageProvider');
  }
  return context;
};

const HelpContactPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const phoneData = useSelector(getContactPhoneInfos);
  const emailData = useSelector(getContactEmailInfos);
  const isLoading = useSelector(getContactIsloading);
  const isPostFormLoading = useSelector(postContactIsLoading);
  const popinFormType = useSelector(contactFormPopinType);
  const email = useSelector(contactFormMail);
  const profileUserInfos = useSelector(getProfileUserInfos);
  const userEmail = profileUserInfos.mail || '';
  const isES = useSelector(getSelectedCompany)?.isES;
  const isRC = useSelector(getSelectedCompany)?.isRC;
  const [emailContactFormIsVisible, setEmailContactFormIsVisible] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const isLoadingProfile = useSelector(
    getLoadingState(ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST)
  );
  const onGetProfileInfos = useCallback(() => dispatch(getProfileUserInfosRequest()), [dispatch]);
  const onContactInfos = useCallback(() => dispatch(getContactInfosRequest()), [dispatch]);
  const resetContactForm = useCallback(() => dispatch(resetContactFormState()), [dispatch]);
  const submitContactForm = useCallback(
    (message, object) => dispatch(postContactFormRequest(message, object)),
    [dispatch]
  );
  const toggle = useCallback(() => {
    setEmailContactFormIsVisible(prev => !prev);
  }, [setEmailContactFormIsVisible]);
  const selectContactInfo = useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );
  const value: HelpContactPageContextType = {
    onContactInfos,
    onGetProfileInfos,
    submitContactForm,
    resetContactForm,
    selectContactInfo,
    selectedIndex,
    phoneData,
    emailData,
    isLoading: !!isLoadingProfile || isLoading,
    isPostFormLoading,
    isES,
    isRC,
    toggle,
    emailContactFormIsVisible,
    popinFormType,
    email,
    userEmail
  };

  return (
    <HelpContactPageContext.Provider value={value}>{children}</HelpContactPageContext.Provider>
  );
};

export default HelpContactPageProvider;
