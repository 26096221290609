import React, { useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { FontIcon, StatusTag, Text } from '@components/index';
import { FontWeight, useTheme } from '@ere-uilib/styles';
import { StatusTagTypesEnum } from '@ere-uilib/molecules/tags/StatusTag/sharedInterfaces';
import { ProfileInformationsEditCardStyles } from "@ere-uilib/molecules/cards/ProfileInformationsContent/useStyles";
import { useStyles } from "./useStyles";

interface Description {
  value: string | null;
  weight?: FontWeight;
  isDisabled?: boolean;
  testId?: string;
}

interface Tag {
  label: string | null | undefined;
  type: StatusTagTypesEnum | null | undefined;
  testId?: string;
}

interface ProfileInformationsEditCardProps {
  descriptions: Description[] | null;
  title: string;
  tag?: Tag;
  isEditable?: boolean;
  onEditPress?: () => void;
  editTestId?: string;
}

type Props = ProfileInformationsEditCardProps & ProfileInformationsEditCardStyles;

export const ProfileInformationsContent: React.FC<Props> = ({
  title,
  descriptions,
  isEditable = false,
  tag = {},
  onEditPress,
  containerStyle,
  firstLinesStyle,
  statusTagStyle,
  editTestId,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const renderDescriptions = useCallback(() => {
    return (descriptions || []).map(
      description =>
        !!description.value && (
          <Text
            style={[description.isDisabled && styles.disabled]}
            testId={description.testId ? description.testId : `${editTestId}_adress`}
            variant='t3'
            weight={description.weight || 'regular'}>
            {description.value}
          </Text>
        )
    );
  }, [descriptions, styles]);

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.firstLineContainerStyle}>
        <Text
          style={[styles.firstLines, firstLinesStyle]}
          variant="t3"
          weight="light">
          {title}
        </Text>
        {isEditable && (
          <TouchableOpacity
            testID={editTestId}
            accessibilityLabel={editTestId}
            onPress={onEditPress}>
            <FontIcon
              color={theme?.colors.basics.primary.c500}
              name="editer"
              size={theme?.metrics.iconSizes.mplus}
            />
          </TouchableOpacity>
        )}
      </View>
      {!!tag.type && !!tag.label && (
        <StatusTag
          label={tag.label}
          testId={tag.testId}
          displayIcon={false}
          type={tag.type}
          containerStyle={statusTagStyle}
        />
      )}
      {renderDescriptions()}
    </View>
  );
};
