import React from 'react';

import { locator } from '@constants/locator';
import { SimpleButton } from '@ere-uilib/molecules';

import { EmailCardContent } from './EmailCardContent';
import { useDictionary } from './useDictionary';
import { useStyles } from './useStyles';

interface Props {
  isES?: boolean;
  directRelationship: boolean;
  onPress: () => void;
}

export const EmailCardContentEs: React.FC<Props> = ({
  isES = false,
  directRelationship,
  onPress
}) => {
  const styles = useStyles();
  const dictionary = useDictionary();
  return (
    <>
      {isES && directRelationship && (
        <EmailCardContent
          subTitle={dictionary.mailEsContactSubTitle}
          testId={locator._profile._help_contact._email_es_card}
          title={dictionary.mailEsContactTitle}>
          <SimpleButton
            containerStyle={styles.containerStyle}
            onPress={onPress}
            size="large"
            testId="dashboard_logout"
            title={dictionary.contactMailPageButtonES}
          />
        </EmailCardContent>
      )}
    </>
  );
};
