import React, { FC } from 'react';

import { useTranslation } from '@ere-uilib/index';
import { Select } from '@ere-uilib/molecules';

type SelectProps = React.ComponentProps<typeof Select>;

import { useStyles } from './useStyles';

interface FormSelectProps extends Omit<SelectProps, 'disabled' | 'errored' | 'selectTestId'> {
  isDisabled?: boolean;
  testId: string;
  hasError: boolean;
}

export const FormSelect: FC<FormSelectProps> = ({
  selectedItem,
  onSelectItem,
  testId,
  errorMessage,
  hasError,
  isDisabled = false,
  ...props
}) => {
  const { formatMessage } = useTranslation();

  const styles = useStyles();

  return (
    <Select
      containerStyle={styles.container}
      disabled={isDisabled}
      errored={hasError}
      errorMessage={errorMessage}
      isAbsolutePositionDisabled
      onSelectItem={onSelectItem}
      selectedItem={selectedItem}
      selectTestId={testId}
      titleStyle={styles.title}
      {...props}
    />
  );
};
