import { useSelector } from 'react-redux';

import { OperationFamilyEnum } from '@constants/savings';
import { getSelectedCompany } from '@modules/dashboard/selectors';
import { getOperationDetails } from '@modules/savings/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';
import { getDiffDate } from '@utils/getDiffDate';

import { findTrueExternalPlan } from '../../../utils';

export const useData = () => {
  const selectedCompany = useSelector(getSelectedCompany);
  const applicationSettings = useSelector(getApplicationSettings);
  const {
    source,
    cancelable: isCancelable = false,
    label = '',
    type = '',
    family: operationFamily,
    details,
    brutAmount,
    destination,
    ...other
  } = useSelector(getOperationDetails) ?? {}

  const hasTrueExternalPlan = findTrueExternalPlan(destination?.plans);
  const deadline = source?.bulletinOption?.deadlineDate || '';
  const remainingDays = getDiffDate(deadline);
  const isAmountHidden = !brutAmount;

  const isIncentive =
    operationFamily === OperationFamilyEnum.PROFITSHARING ||
    operationFamily === OperationFamilyEnum.INCENTIVE;
  const isArbitration = operationFamily === OperationFamilyEnum.ARBITRATION;
  const isRefund = operationFamily === OperationFamilyEnum.REFUND;
  const isInstallment = operationFamily === OperationFamilyEnum.INSTALLMENT;
  const isCapitalizedDividend = operationFamily === OperationFamilyEnum.CAPITALIZED_DIVIDEND;

  return {
    source,
    destination,
    hasTrueExternalPlan,
    isCancelable,
    deadline,
    remainingDays,
    originTitleLabelCode: label || type,
    isAmountHidden,
    isIncentive,
    isArbitration,
    isRefund,
    isInstallment,
    operationFamily,
    isCapitalizedDividend,
    applicationSettings,
    ...selectedCompany,
    ...details,
    ...other
  }
}
