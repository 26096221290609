import { useNavigation } from "@react-navigation/native"
import React, { useCallback } from "react"
import { View } from "react-native"

import { Modal, NotificationIcon, NotificationIconTypeEnum, Title } from "@ere-uilib/atoms"
import { ModalFrame, SimpleButton } from "@ere-uilib/molecules"

import { useModalData } from "./hooks"
import { ConfirmModalComponentProps } from "./interface"
import { useStylees } from "./styles"

const ConfirmModalComponent = ({ 
  isPage, 
  closeModalAction, 
  goBackAction, 
  modalVisible, 
  confirmButton,
  cancelButton,
  headerTitle,
  descriptionText }: ConfirmModalComponentProps) =>
  {
  const styles = useStylees()
  const navigation = useNavigation();
  const { cancelButtonLabel, confirmButtonLabel, label, title, onModalClose } =
    useModalData();
  const onAbortPress = useCallback(() => {
    closeModalAction? closeModalAction() : onModalClose()
  }, [onModalClose, closeModalAction]);
  const onResumePress = useCallback(() => {
    if (isPage) {
      navigation.goBack()
    } else {
      goBackAction?.()
    }
  }, [navigation, isPage, goBackAction])

  const ModalView = isPage ? View : Modal

  return (
    <ModalView
      overlayStyle={styles.container}
      style={styles.container}
      visible={modalVisible}>
      <ModalFrame>
        <View style={styles.modalIconContainer}>
          <NotificationIcon type={NotificationIconTypeEnum.WARNING} />
        </View>
        <View style={styles.modalTextContainer}>
          <Title
            style={styles.modalTitle}
            variant="t6">
            {headerTitle || title}
          </Title>
          <Title
            style={styles.modalText}
            variant="t7"
            weight="light">
            {descriptionText || label}
          </Title>
        </View>
        <View>
          <SimpleButton
            containerStyle={styles.modalFirstActionStyle}
            design="outlined"
            onPress={onAbortPress}
            title={confirmButton || confirmButtonLabel}
          />
          <SimpleButton
            onPress={onResumePress}
            title={cancelButton || cancelButtonLabel}
          />
        </View>
      </ModalFrame>
    </ModalView>
  )

}
export default ConfirmModalComponent