import * as Yup from 'yup';

import { PlanFamillyList } from '@constants/common';
import { AddressDetail } from '@modules/common/types';

import { FormValues } from '../types';
import { FieldError, label } from './label';

const NO_SPECIAL_CHAR_REGEX = /^[^;&%#@]*$/;

export const fieldsMaxLength : Record<PlanFamillyList, Record<string, number>> = {
  [PlanFamillyList.RC]: {
    additionalAddress_1: 38,
    additionalAddress_3: 38,
    street: 38,
    zipcode: 5,
    city: 32
  },
  [PlanFamillyList.ES]: {
    additionalAddress_1: 255,
    additionalAddress_3: 255,
    street: 255,
    zipcode: 255,
    city: 255
  }
}

export const getValidationSchemaBy = (
  planFamily: PlanFamillyList
): Yup.ObjectSchema<FormValues, Yup.AnyObject, {}, ''> => {
  const getError = getErrorBy(planFamily);
  const fieldsMaxLengthList = fieldsMaxLength[planFamily];
  if (planFamily === PlanFamillyList.RC) {
    return Yup.object({
      additionalAddress_1: Yup.string()
          .matches(NO_SPECIAL_CHAR_REGEX, getError('additionalAddress_1', 'regex'))
          .max(fieldsMaxLengthList.additionalAddress_1),
      additionalAddress_3: Yup.string()
          .matches(NO_SPECIAL_CHAR_REGEX, getError('additionalAddress_3', 'regex'))
          .max(fieldsMaxLengthList.additionalAddress_3),
      street: Yup.string()
          .max(fieldsMaxLengthList.street)
          .matches(NO_SPECIAL_CHAR_REGEX, getError('street', 'regex'))
          .required(getError('street', 'required')),
      zipcode: Yup.string()
          .max(fieldsMaxLengthList.zipcode, getError('zipcode', 'max'))
          .required(getError('zipcode', 'required')),
      city: Yup.string()
          .max(fieldsMaxLengthList.city)
          .matches(NO_SPECIAL_CHAR_REGEX, getError('city', 'regex'))
          .required(getError('city', 'required')),
      country: Yup.object().shape({
        value: Yup.string().required(getError('country', 'required')),
        label: Yup.string().required(),
        defaultLabel: Yup.string().required()
      })
    });
  }


  return Yup.object({
    additionalAddress_1: Yup.string().max(fieldsMaxLengthList.additionalAddress_1),
    additionalAddress_3: Yup.string().max(fieldsMaxLengthList.additionalAddress_3),
    street: Yup.string().max(fieldsMaxLengthList.street).required(getError('street', 'required')),
    zipcode: Yup.string().max(fieldsMaxLengthList.zipcode).required(getError('zipcode', 'required')),
    city: Yup.string().max(fieldsMaxLengthList.city).required(getError('city', 'required')),
    country: Yup.object().shape({
      value: Yup.string().required(getError('country', 'required')),
      label: Yup.string().required(),
      defaultLabel: Yup.string().required()
    })
  });
};

export const getInitialValuesFrom = (address: AddressDetail | null | undefined): FormValues => ({
  additionalAddress_1: address?.additionalAddress_1 || '',
  additionalAddress_3: address?.additionalAddress_3 || '',
  street: address?.street || '',
  zipcode: address?.zipcode || '',
  city: address?.city || '',
  country: {
    label: address?.country || '',
    value: address?.countryCode || 'FR',
    defaultLabel: address?.country?.toUpperCase() || 'FRANCE'
  }
});

export const getTitleBy = (planFamily: PlanFamillyList) => (field: keyof FormValues) =>
  label[planFamily]?.field?.[field]?.title || label.default?.field?.[field]?.title;

export const getErrorBy =
  (planFamily: PlanFamillyList) => (field: keyof FormValues, error: keyof FieldError) =>
    label[planFamily]?.field?.[field]?.error?.[error] ||
    label.default?.field?.[field]?.error?.[error];
