import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';

import { BankDetailsAction, BankDetailsActionsType } from './actions/actionsTypes';
import { BankDetailsDocument, BankDetailsState, PopinContentTypeEnum } from './types';

const initialDocumentData: BankDetailsDocument = {
  documentId: undefined,
  file: undefined,
  isLoading: false,
  hasError: false
};

const initialState: BankDetailsState = {
  bankData: {
    es: null,
    rc: null
  },
  initData: {
    journeyId: null,
    contractId: null,
    fileMaxSize: 0,
    totalFileMaxSize: 0
  },
  document: {},
  error: null,
  selectedPlanFamily: undefined,
  selectedPlanId: undefined,
  selectedOTPChannel: undefined,
  selectedCountry: null,
  selectedAccountType: null,
  quickSignUrl: null,
  registerRules: {
    shouldUploadDocByDoc: false,
    withQuicksignOTP: false
  },
  updateFinishedStatus: undefined,
  documentMandatRc: undefined
};

export default function BankDetailsReducer(
  state: BankDetailsState = initialState,
  action: BankDetailsAction & ProfileActionsType
): BankDetailsState {
  switch (action.type) {
    case BankDetailsActionsType.SET_SELECTED_PLAN_FAMILY:
      return {
        ...state,
        selectedPlanFamily: action.selectedPlanFamily
      };

    case BankDetailsActionsType.SET_SELECTED_PLAN_ID:
      return {
        ...state,
        selectedPlanId: action.selectedPlanId
      };

    case BankDetailsActionsType.SET_SELECTED_DOMICILIATION_COUNTRY:
      return {
        ...state,
        selectedCountry: action.selectedCountry
      };
    case BankDetailsActionsType.SET_REGISTER_RULES:
      return {
        ...state,
        registerRules: action.registerRules
      };
    case BankDetailsActionsType.SET_UPDATE_FINISHED:
      return {
        ...state,
        updateFinishedStatus: action.updateFinished
      };
    case BankDetailsActionsType.REGISTER_BANK_DATA_SUCCESS:
      return {
        ...state,
        quickSignUrl: action.quickSignUrl
      };
    case BankDetailsActionsType.SET_SELECTED_ACCOUNT_TYPE:
      return {
        ...state,
        document: {},
        selectedAccountType: action.accountType
      };
    case BankDetailsActionsType.GET_BANK_DATA_SUCCESS:
      return {
        ...state,
        bankData: action.bankData
      };
    case BankDetailsActionsType.INIT_BANK_DATA_SUCCESS:
      return {
        ...state,
        initData: action.initData,
        document: action.isRc ? state.document : initialState.document
      };
    case BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_REQUEST:
      return {
        ...state,
        error: null,
        document: {
          ...state.document,
          [action.documentKey]: {
            ...initialDocumentData,
            isLoading: true
          }
        }
      };
    case BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_SUCCESS:
      return {
        ...state,
        document: {
          ...state.document,
          [action.documentKey]: {
            ...initialDocumentData,
            documentId: action.documentId,
            file: action.file
          }
        }
      };
    case BankDetailsActionsType.SIGN_MANDAT_RC_REQUEST:
      return {
        ...state,
        selectedOTPChannel: action.selectedOTPChannel
      };
    case BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_SUCCESS:
      return {
        ...state,
        documentMandatRc: action.documentBlob
      };
    case BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_REQUEST:
      return {
        ...state,
        error: null,
        document: {
          ...state.document,
          [action.documentKey]: {
            ...state.document[action.documentKey],
            isLoading: true
          }
        }
      };
    case BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_SUCCESS:
    case BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_FAILURE:
      delete state.document?.[action.documentKey];
      return {
        ...state,
        document: {
          ...state.document
        }
      };
    case ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_SUCCESS as any:
      return {
        ...state
      };
    case BankDetailsActionsType.SIGN_MANDAT_RC_FAILURE:
    case BankDetailsActionsType.REGISTER_BANK_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        updateFinishedStatus: !!action.error.message ? PopinContentTypeEnum.UNKNOWN : undefined
      };
    case BankDetailsActionsType.GET_BANK_DATA_FAILURE:
    case BankDetailsActionsType.INIT_BANK_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_FAILURE:
      return {
        ...state,
        document: {
          ...state.document,
          [action.documentKey]: {
            ...state.document[action.documentKey],
            isLoading: false,
            hasError: true
          }
        }
      };
    case BankDetailsActionsType.REGISTER_BANK_DATA_REQUEST:
      return state;
    case BankDetailsActionsType.INIT_BANK_DATA_REQUEST:
      return {
        ...state,
        error: null
      };
    case BankDetailsActionsType.GET_BANK_DATA_REQUEST:
    case BankDetailsActionsType.CLEAR_BANK_DATA:
      return initialState;
    default:
      return state;
  }
}
