import Clipboard from '@react-native-clipboard/clipboard';
import React, { useCallback, useState } from 'react';
import { Platform, Pressable } from 'react-native';

import { Hoverable, SVGLocalLoader } from '@ere-uilib/atoms';
import { Text } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { PlatformOS } from '@modules/common/types';

import { useStyles } from './useStyles';
interface Props {
  text: string;
  copyTooltip: string;
  copiedTooltip: string;
}

export const ClipboardButton: React.FC<Props> = ({ text, copiedTooltip, copyTooltip }) => {
  const styles = useStyles();
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const copyText = useCallback(async () => {
    await Clipboard.setString(text);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 2000);
  }, [text]);
  return (
    <>
      <Hoverable alwaysShowHover={true}>
        {(isHoverable: boolean) =>
          !showCopied ? (
            <Pressable
              onPress={() => copyText()}
              style={styles.copyIconContainer}>
              <SVGLocalLoader
                containerStyle={styles.copyIcon}
                name={IconEnum.CLIPBOARD_COPY}
              />
              {isHoverable && Platform.OS === PlatformOS.WEB && (
                <Text
                  style={styles.toolTip}
                  variant="t5">
                  {copyTooltip}
                </Text>
              )}
            </Pressable>
          ) : (
            <Pressable style={styles.copyIconContainer}>
              <SVGLocalLoader
                containerStyle={styles.copyIcon}
                name={IconEnum.CLIPBOARD_COPIED}
              />
              {isHoverable && Platform.OS === PlatformOS.WEB && (
                <Text
                  style={styles.toolTip}
                  variant="t5">
                  {copiedTooltip}
                </Text>
              )}
            </Pressable>
          )
        }
      </Hoverable>
    </>
  );
};
