import { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';

export const useDictionary = () => {
  const { formatMessage, getMessageRaw } = useTranslation();

  const dictionary = useMemo(() => {
    return {
      ContactPhoneIcon: formatMessage({ id: 'ContactPhoneIcon' }),
      ContactMailingAddress: formatMessage({ id: 'ContactMailingAddressIcon' }),
      ContactMailIcon: formatMessage({ id: 'ContactMailIcon' })
    };
  }, [formatMessage]);

  return dictionary;
};
