import React, { FC } from "react";

import { useTranslation } from "@components/ERE-UILib";
import { OperationTotalSubHeader } from "@ere-uilib/molecules";
import {
    useProfitSharringRepartitionContext
} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context";
import { label } from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/label";

export const SubHeader: FC = () => {
    const { isPercentage, remainingInvestedPercent, remainingInvestedAmount,
            hasStartedInvest, setDisplayTypeValue, displayTypeValue } = useProfitSharringRepartitionContext();
    const { formatMessage } = useTranslation();
    const { title, subTitle } = label.header.investAmount;
    const mainTitle = hasStartedInvest ? title.startInvested : title.toInvest;

    return (
        <OperationTotalSubHeader
            defaultSelectSwitch={displayTypeValue}
            isAmountPercent={isPercentage}
            isSwitchEnabled={true}
            mainAmount={isPercentage ?
                remainingInvestedPercent
                : remainingInvestedAmount}
            mainTitle={formatMessage({ id: mainTitle })}
            onSelectSwitchChanged={setDisplayTypeValue}
            secondaryAmount={isPercentage ?
                remainingInvestedAmount
                : remainingInvestedPercent}
            secondaryTitle={formatMessage({ id: subTitle })}
        />
    );
}
