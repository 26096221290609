import { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';

export const useDictionary = () => {
  const { formatMessage } = useTranslation();

  const dictionary = useMemo(() => {
    return {
      contactFormPagePopinProfilePageButton: formatMessage({
        id: 'ContactFormPagePopinProfilePageButton'
      }),
      contactFormPagePopinConfirmationMessage: formatMessage({
        id: 'ContactFormPagePopinConfirmationMessage'
      }),
      contactFormPagePopinConfirmationTitle: formatMessage({
        id: 'ContactFormPagePopinConfirmationTitle'
      }),
      contactFormPagePopinFormPageButton: formatMessage({
        id: 'ContactFormPagePopinFormPageButton'
      }),
      contactFormPagePopinErrorTitle: formatMessage({
        id: 'ContactFormPagePopinErrorTitle'
      }),
      contactFormPagePopinErrorMessage: formatMessage({
        id: 'ContactFormPagePopinErrorMessage'
      })
    };
  }, [formatMessage]);

  return dictionary;
};
