import { ContactFormPopinContentTypeEnum } from "@modules/profile/types";

import { useDictionary } from './useDictionary';

export const useErrorAndSucessPopPin = (onPressReturnToFormButton, onPressReturnToProfilButton) => {
    const dictionary = useDictionary();

    const PopinContentTypeInfos = {
        [ContactFormPopinContentTypeEnum.ERROR]: {
            loaderName: 'installmentError',
            title: dictionary.contactFormPagePopinErrorTitle,
            subTitle: dictionary.contactFormPagePopinErrorMessage,
            returnAction: onPressReturnToFormButton,
            returnActionLabel: dictionary.contactFormPagePopinFormPageButton
        },
        [ContactFormPopinContentTypeEnum.SUCCESS]: {
            loaderName: 'sendEmail',
            title: dictionary.contactFormPagePopinConfirmationTitle,
            subTitle: dictionary.contactFormPagePopinConfirmationMessage,
            returnAction: onPressReturnToProfilButton,
            returnActionLabel: dictionary.contactFormPagePopinProfilePageButton
        },
        ["DEFAULT"]: {
            loaderName: '',
            title: '',
            subTitle: '',
            returnAction: () => {},
            returnActionLabel: ''
        }
    };
    return PopinContentTypeInfos;
}
