import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { EstimatedContribution } from '@ere-uilib/organisms/EstimatedContribution';
import {
  createUseStyles,
  useTheme,
  ThemeType,
  useScreenSizes,
  FontWeight
} from '@ere-uilib/styles';
import { ParagraphSizeTypes } from '@ere-uilib/styles/theme/themeFolder/fonts';
import { useTranslation } from '@ere-uilib/translations';

interface Props extends Styles {
  title?: string | null;
  hideManagementAmount?: boolean;
  hideManagementOnlyAmount?: boolean;
  amount?: number;
  hasContribution?: boolean;
  contribution?: number;
  containerStyle?: StyleProp<ViewStyle>;
  percentage?: number;
  variant?: keyof ParagraphSizeTypes | undefined;
  fontWeight?: FontWeight | undefined;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  rowLabelsContainerStyle?: StyleProp<ViewStyle>;
  contributionLabelStyle?: StyleProp<TextStyle>;
  percentageText?: StyleProp<TextStyle>;
  cardTitle?: StyleProp<TextStyle>;
};

type StylesContext = { theme: ThemeType; isWeb: boolean };

export const SynthesisManagementRow: React.FC<Props> = ({
  containerStyle,
  title,
  hideManagementAmount,
  hideManagementOnlyAmount,
  amount,
  hasContribution,
  contribution,
  percentage,
  variant = 't3',
  fontWeight = 'bold'
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const isWeb = !(isMobile || isTablet);
  const styles = useStyles({ theme, isWeb }, { containerStyle });
  const { formatCurrencyNumber, formatPercentNumberWithPlusMinus } = useTranslation();

  return (
    <View style={styles.containerStyle}>
      <Text
        style={styles.cardTitle}
        variant={variant}
        weight={fontWeight}>
        {title}
      </Text>
      {!hideManagementAmount && (
        <View style={styles.rowLabelsContainerStyle}>
          {!hideManagementOnlyAmount && amount !== undefined && (
            <Text variant={variant}>{amount && formatCurrencyNumber({ value: amount || 0 })}</Text>
          )}
          {percentage !== undefined && (
            <Text
              style={styles.percentageText}
              variant={variant}>
              {formatPercentNumberWithPlusMinus({ value: percentage })}
            </Text>
          )}
          {hasContribution && (contribution || 0) > 0 && (
            <EstimatedContribution contribution={contribution} />
          )}
        </View>
      )}
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      flexDirection: 'row',
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s,
      paddingTop: context?.theme.metrics.spacing.xm,
      paddingBottom: context?.theme.metrics.spacing.xm,
      borderTopWidth: 1,
      borderTopColor: context?.theme.colors.basics.grey.c200
    },
    style?.containerStyle
  ],
  rowLabelsContainerStyle: [
    {
      alignItems: 'flex-end'
    },
    style?.rowLabelsContainerStyle
  ],
  contributionLabelStyle: [
    {
      color: context?.theme.colors.basics.primary.c500
    },
    style?.contributionLabelStyle
  ],
  percentageText: [{ color: context?.theme.colors.basics.grey.c400 }, style?.percentageText],
  cardTitle: [
    {
      flex: 1
    },
    style?.cardTitle
  ]
});

const useStyles = createUseStyles(getStyles);
