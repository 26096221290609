import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import { mockOperationDetailsInteressment } from '@__mocks__/mockOperationDetails';
import { mockRepartition } from '@__mocks__/mockRepartition';
import { OperationDetailsTypeEnum, ProfitSharingIncentiveTypeEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { catchApiExceptions } from '@modules/apiAuthorization';
import {
  getOCDRequest,
  getRecentOperationsRequest
} from '@modules/dashboard/actions/dashboardActions';
import { getSavingsPlans, getUsedCompanyId } from '@modules/dashboard/selectors';
import { SavingsPlansState } from '@modules/dashboard/types';
import { initNewsFeedList } from '@modules/history/actions/historyActions';
import { runManager } from '@modules/moduleManager';
import * as actionSavings from '@modules/savings/actions/savingsActions';
import * as actionSavingsTypes from '@modules/savings/actions/savingsActionsTypes';
import { SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';
import { getOperationDetails as getOperationSelector } from '@modules/savings/selectors';
import {
  getOperationsHistoryFilterRoules,
  getSavingsHistoryFilters
} from '@modules/savings/selectors';
import { getMobileMenuListRequest } from '@modules/settings/actions/settingsActions';
import { RootNavigation } from '@navigation/RootNavigation';

import {
  fetchAvailabilitiesDetail,
  fetchAvailabilitiesDetailByPlan,
  fetchAvailabilitiesFunds,
  fetchAvailabilitiesFundsByPlan,
  fetchInstallments,
  fetchOnGoingVVPHistory,
  fetchOperationCancel,
  fetchOperationDetails,
  fetchOperationsHistory,
  fetchOperationsHistoryByPlan,
  fetchRepartitions,
  fetchRepartitionsByPlan,
  fetchSavingsHistory,
  fetchSavingsHistoryByPlan
} from './services';
import { authenticatedCall } from '../apiAuthorization';
import { FilterRoulesState, OperationDetailsCancelResponseType } from './types';

function* getRepartitions(action: actionSavingsTypes.GetRepartitionsRequestAction): any {
  const response = yield authenticatedCall(fetchRepartitions, action.companyId);
  // Use For Mock Data
  //   yield delay(1300)
  //   const response = {
  //   data: mockRepartition
  //  }

  yield put(actionSavings.getRepartitionsSuccess(response?.data));
}

function* getRepartitionsByPlan(
  action: actionSavingsTypes.GetRepartitionsByPlanRequestAction
): any {
  const savingPlans = yield select(getSavingsPlans);
  const selectedPlanId = yield savingPlans[action.index].planId;
  if (selectedPlanId === 'Account') {
    return;
  }
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchRepartitionsByPlan, companyId, selectedPlanId);
  // comment to use mock
  // yield delay(1500)
  // const response = {
  //   data: mockRepartition
  // }

  yield put(actionSavings.getRepartitionsByPlanSuccess(response?.data, selectedPlanId));
}

function* getOperationsHistoryByDateRange(companyId: string, year: string): any {
  const response = yield call(fetchOperationsHistory, companyId, year);
  return yield put(actionSavings.getHistorySuccess(response.data));
}

function* getOperationsHistoryByPlanAndByDateRange(
  companyId: string,
  selectedPlanId: string,
  year: string
): any {
  const response = yield call(fetchOperationsHistoryByPlan, companyId, selectedPlanId, year);
  return yield put(actionSavings.getHistoryByPlanSuccess(response.data, selectedPlanId));
}

function* getOperationsHistory(action: actionSavingsTypes.GetOperationsHistoryRequestAction): any {
  if (action.filterRoules) {
    yield put(actionSavings.applyOperationsHistoryFilterRequest(action.filterRoules));
  }
  const dateRangefromFilterToApply: FilterRoulesState = yield select(
    getOperationsHistoryFilterRoules
  );
  yield all(
    dateRangefromFilterToApply.DateRange.map((year: number) => {
      return call(getOperationsHistoryByDateRange, action.companyId, year.toString());
    })
  );
}
function* getOperationsHistoryByPlan(
  action: actionSavingsTypes.GetOperationsHistoryByPlanRequestAction
): any {
  if (action.filterRoules) {
    yield put(actionSavings.applyOperationsHistoryFilterRequest(action.filterRoules));
  }
  const companyId = yield select(getUsedCompanyId);
  const savingPlans = yield select(getSavingsPlans);
  const selectedPlanId = yield savingPlans[action.index].planId;
  if (selectedPlanId === 'Account') {
    return yield put(actionSavings.getHistoryRequest(companyId));
  }
  // call all plans in range date
  const dateRangefromFilterToApply: FilterRoulesState = yield select(
    getOperationsHistoryFilterRoules
  );
  yield all(
    dateRangefromFilterToApply.DateRange.map((year: number) => {
      return call(
        getOperationsHistoryByPlanAndByDateRange,
        companyId,
        selectedPlanId,
        year.toString()
      );
    })
  );
}

function* getAvailabilitiesDetail(action: actionSavingsTypes.GetAvailabilitiesDetailRequest): any {
  const response = yield call(fetchAvailabilitiesDetail, action.companyId);
  yield put(actionSavings.getAvailabilitiesDetailSuccess(response.data));
}

function* getAvailabilitiesDetailByPlan(
  action: actionSavingsTypes.GetAvailabilitiesDetailByPlanRequest
): any {
  const savingPlans = yield select(getSavingsPlans);
  const selectedPlanId = yield savingPlans[action.index].planId;
  if (selectedPlanId === 'Account') {
    return;
  }
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchAvailabilitiesDetailByPlan, companyId, selectedPlanId);
  yield put(actionSavings.getAvailabilitiesDetailByPlanSuccess(response.data, selectedPlanId));
}

function* getAvailabilitiesFunds(action: actionSavingsTypes.GetAvailabilitiesFundsRequest): any {
  const response = yield call(fetchAvailabilitiesFunds, action.companyId);
  yield put(actionSavings.getAvailabilitiesFundsSuccess(response.data));
}

function* getAvailabilitiesFundsByPlan(
  action: actionSavingsTypes.GetAvailabilitiesFundsByPlanRequest
): any {
  const savingPlans = yield select(getSavingsPlans);
  const selectedPlanId = yield savingPlans[action.index].planId;
  if (selectedPlanId === 'Account') {
    return;
  }
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchAvailabilitiesFundsByPlan, companyId, selectedPlanId);
  yield put(actionSavings.getAvailabilitiesFundsByPlanSuccess(response.data, selectedPlanId));
}

function* getInstallments(action: actionSavingsTypes.GetInstallmentsRequestAction): any {
  const companyId = yield select(getUsedCompanyId);
  const res = yield authenticatedCall(fetchInstallments, companyId, action.planId, action.year);
  yield put(actionSavings.getInstallmentsSuccess(res.data));
}

function* getSavingsHistoryByPlan(
  action: actionSavingsTypes.GetSavingsHistoryByPlanRequestAction
): any {
  const filters = yield select(getSavingsHistoryFilters);
  const period = filters?.period;
  const companyId = yield select(getUsedCompanyId);
  const savingPlans = yield select(getSavingsPlans);
  const selectedPlanId = yield savingPlans[action.index].planId;
  if (selectedPlanId === 'Account') {
    return;
  }

  const response = yield authenticatedCall(
    fetchSavingsHistoryByPlan,
    companyId,
    selectedPlanId,
    period
  );

  yield put(actionSavings.getSavingsHistoryByPlanSuccess(response.data, selectedPlanId)); //res.data
}

function* getHistorySavings(): any {
  const filters = yield select(getSavingsHistoryFilters);
  const period = filters?.period;
  const companyId = yield select(getUsedCompanyId);

  const response = yield authenticatedCall(fetchSavingsHistory, companyId, period);
  yield put(actionSavings.getSavingsHistorySuccess(response.data));
}

function* getRepartitionsSagas() {
  yield takeLatest(
    SavingsActionsType.GET_REPARTITIONS_REQUEST,
    runManager(getRepartitions, SavingsActionsType.GET_REPARTITIONS_FAILURE)
  );
}

function* getRepartitionsByPlanSagas() {
  yield takeLatest(
    SavingsActionsType.GET_REPARTITIONS_BY_PLAN_REQUEST,
    runManager(getRepartitionsByPlan, SavingsActionsType.GET_REPARTITIONS_FAILURE)
  );
}

function* getOperationsHistorySagas() {
  yield takeLatest(
    SavingsActionsType.GET_OPERATIONS_HISTORY_REQUEST,
    runManager(getOperationsHistory, SavingsActionsType.GET_OPERATIONS_HISTORY_FAILURE)
  );
}

function* getOperationsHistoryByPlanSagas() {
  yield takeLatest(
    SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_REQUEST,
    runManager(
      getOperationsHistoryByPlan,
      SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_FAILURE
    )
  );
}

function* getAvailabilitiesDetailSagas() {
  yield takeLatest(
    SavingsActionsType.GET_AVAILABILITIES_DETAILS_REQUEST,
    runManager(getAvailabilitiesDetail, SavingsActionsType.GET_AVAILABILITIES_DETAILS_FAILURE)
  );
}

function* getAvailabilitiesDetailByPlanSagas() {
  yield takeLatest(
    SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_REQUEST,
    runManager(
      getAvailabilitiesDetailByPlan,
      SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_FAILURE
    )
  );
}

function* getAvailabilitiesFundsSagas() {
  yield takeLatest(
    SavingsActionsType.GET_AVAILABILITIES_FUNDS_REQUEST,
    runManager(getAvailabilitiesFunds, SavingsActionsType.GET_AVAILABILITIES_FUNDS_FAILURE)
  );
}

function* getAvailabilitiesFundsByPlanSagas() {
  yield takeLatest(
    SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_REQUEST,
    runManager(
      getAvailabilitiesFundsByPlan,
      SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_FAILURE
    )
  );
}

function* getOperationDetails(action: actionSavingsTypes.GetOperationDetailsRequestAction): any {
  const companyId = yield select(getUsedCompanyId);
  const operationId = action.operationId;

  // comment to use mock
  const response = yield call(fetchOperationDetails, companyId, operationId);

  // uncomment to use mock
  // yield delay(600);
  // const response = {
  //  data: mockOperationDetailsInteressment
  // }

  yield put(actionSavings.getOperationDetailsSuccess(response?.data));
}
function* getOperationDetailsSagas() {
  yield takeLatest(
    SavingsActionsType.GET_OPERATION_DETAILS_REQUEST,
    runManager(getOperationDetails, SavingsActionsType.GET_OPERATION_DETAILS_FAILURE)
  );
}

function* cancelOperation(action: actionSavingsTypes.CancelOperationRequestAction): any {
  const companyId = yield select(getUsedCompanyId);
  const operationId = action.operationId;

  const response: { data: OperationDetailsCancelResponseType } = yield call(
    fetchOperationCancel,
    operationId
  );

  if (response?.data?.isSucceeded) {
    yield put(actionSavings.cancelOperationSuccess());
    yield put(actionSavings.InitSavingListings());
    yield put(getOCDRequest());
    yield put(initNewsFeedList());
    yield put(getMobileMenuListRequest(companyId));
    yield put(getRecentOperationsRequest(companyId));
    RootNavigation?.replace(RouteNames.OperationCancelSuccess);
  } else {
    yield catchApiExceptions(
      SavingsActionsType.CANCEL_OPERATION_FAILURE,
      new Error(response?.data?.errorMessage),
      action
    );
  }
}
function* setOperatioCancelSagas() {
  yield takeLatest(
    SavingsActionsType.CANCEL_OPERATION_REQUEST,
    runManager(cancelOperation, SavingsActionsType.CANCEL_OPERATION_FAILURE)
  );
}

// MODIFY operation
export function* modifyOperation(action: actionSavingsTypes.ModifyOperationRequestAction): any {
  const companyId = yield select(getUsedCompanyId);
  const operationDetails = yield select(getOperationSelector);
  const operationId = operationDetails?.identifier || '';

  const response: { data: OperationDetailsCancelResponseType } = yield call(
    fetchOperationCancel,
    operationId
  );

  if (response?.data?.isSucceeded) {
    yield put(actionSavings.modifyOperationSuccess());
    yield put(actionSavings.InitSavingListings());
    yield put(getOCDRequest());
    yield put(initNewsFeedList());
    yield put(getMobileMenuListRequest(companyId));
    yield put(getRecentOperationsRequest(companyId));
    const { type, legalFramework } = operationDetails?.source?.bulletinOption ?? {};
    const transactionId = operationDetails?.identifier;
    const isProfitSharing = type === OperationDetailsTypeEnum.PROFITSHARING;
    RootNavigation.replace(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveDescription,
      params: {
        type: isProfitSharing
          ? ProfitSharingIncentiveTypeEnum.profitsharing
          : ProfitSharingIncentiveTypeEnum.incentive,
        cadre: legalFramework,
        transactionId
      }
    });
  } else {
    yield catchApiExceptions(
      SavingsActionsType.MODIFY_OPERATION_FAILURE,
      new Error(response?.data?.errorMessage),
      action
    );
  }
}
function* setOperatioModifySagas() {
  yield takeLatest(
    SavingsActionsType.MODIFY_OPERATION_REQUEST,
    runManager(modifyOperation, SavingsActionsType.MODIFY_OPERATION_FAILURE)
  );
}

export function* getOnGoingVVPHistory(
  action: actionSavingsTypes.GetOnGoingVVPHistoryRequestAction
): any {
  const companyId = yield select(getUsedCompanyId);
  const savingPlans = yield select(getSavingsPlans);
  const selectedPlanId = yield savingPlans[action.selectedPlan].planId;
  let response;

  if (selectedPlanId === 'Account') {
    response = yield authenticatedCall(fetchOnGoingVVPHistory, companyId);
  } else {
    response = yield authenticatedCall(fetchOnGoingVVPHistory, companyId, selectedPlanId);
  }

  yield put(actionSavings.getOnGoindVVPHistorySuccess(response.data));
}

export function* getOnGoingVVPHistorySagas() {
  yield takeLatest(
    SavingsActionsType.GET_ONGOING_VVP_HISTORY_REQUEST,
    runManager(getOnGoingVVPHistory, SavingsActionsType.GET_ONGOING_VVP_HISTORY_FAILURE)
  );
}

function* getInstallmentsSagas() {
  yield takeLatest(
    SavingsActionsType.GET_INSTALLMENTS_REQUEST,
    runManager(getInstallments, SavingsActionsType.GET_INSTALLMENTS_FAILURE)
  );
}

function* getSavingsHistoryByPlanSagas() {
  yield takeLatest(
    SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_REQUEST,
    runManager(getSavingsHistoryByPlan, SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_FAILURE)
  );
}

function* getSavingsHistorySagas() {
  yield takeLatest(
    SavingsActionsType.GET_SAVINGS_HISTORY_REQUEST,
    runManager(getHistorySavings, SavingsActionsType.GET_SAVINGS_HISTORY_FAILURE)
  );
}

function* clearCacheAfterOperationDone(
  action: actionSavingsTypes.ClearCacheAfterOperationDoneAction
) {
  yield put(actionSavings.InitSavingReducer());
  yield put(action.actionType);

  if (action.planId && action.selectedNavigationTab) {
    const savingPlans: SavingsPlansState[] = yield select(getSavingsPlans);
    const selectedPlanIndex = savingPlans.findIndex(
      saving => saving.planId === String(action.planId)
    );
    yield put(actionSavings.RedirectToSavings(selectedPlanIndex, action.selectedNavigationTab));
    return RootNavigation?.navigate(action.stack, {
      screen: action.screen,
      params: {
        screen: RouteNames.Savings
      }
    });
  }

  return RootNavigation.navigate(action.stack, {
    screen: action.screen
  });
}

function* clearCacheAfterOperationDoneSagas() {
  yield takeLatest(
    SavingsActionsType.CLEAR_CACHE_AFTER_OPERATION_DONE,
    runManager(clearCacheAfterOperationDone, SavingsActionsType.GET_SAVINGS_HISTORY_FAILURE)
  );
}

export function* SavingsSaga() {
  yield all([
    fork(getRepartitionsSagas),
    fork(getOperationsHistorySagas),
    fork(getRepartitionsByPlanSagas),
    fork(getOperationsHistoryByPlanSagas),
    fork(getAvailabilitiesDetailSagas),
    fork(getAvailabilitiesDetailByPlanSagas),
    fork(getAvailabilitiesFundsSagas),
    fork(getAvailabilitiesFundsByPlanSagas),
    fork(getInstallmentsSagas),
    fork(getSavingsHistoryByPlanSagas),
    fork(getSavingsHistorySagas),
    fork(getOnGoingVVPHistorySagas),
    fork(clearCacheAfterOperationDoneSagas),
    fork(getOperationDetailsSagas),
    fork(setOperatioCancelSagas),
    fork(setOperatioModifySagas)
  ]);
}
