import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { useState } from 'react';
import { Platform, StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  Paragraph,
  Title,
  LegalMenuFooterConnected,
  OfflinePageLayout,
  SimpleButton,
  TextField,
  useScreenSizes
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { ApplicationSettingsState } from '@modules/settings/types';
import { useLogoNavigation } from '@utils/index';
import { locator } from '@constants/locator';

export interface Props {
  onForgetPasswordBeginProcess: (email: string) => void;
  isApiLoading: boolean;
  appSettings: ApplicationSettingsState;
}

type StylesProps = {
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  mobileContentContainerStyle?: StyleProp<ViewStyle>;
  container?: StyleProp<ViewStyle>;
  buttonContainer?: StyleProp<ViewStyle>;
  inputContainer?: StyleProp<ViewStyle>;
  button?: StyleProp<ViewStyle>;
  paragraph?: StyleProp<TextStyle>;
  errorTextStyle?: StyleProp<TextStyle>;
};
interface StylesContext {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
}

export const ForgetPasswordProcessBeginPage: React.FC<Props> = ({
  onForgetPasswordBeginProcess,
  isApiLoading,
  appSettings
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const route: any = useRoute();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const [email, setEmail] = useState('');
  const [disable, setDisable] = useState(true);
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();
  const emailRegex = new RegExp(appSettings?.EmailFormat || '');
  const navigation = useNavigation();
  const onHelpPressed = () => {
    navigation?.navigate(RouteNames.Support);
  };

  const handleSubmit = () => {
    if (!disable) {
      onForgetPasswordBeginProcess(email);
    }
  };

  const renderActions = () => {
    return (
      <>
        <View style={styles.buttonContainer}>
          <SimpleButton
            containerStyle={styles.button}
            design="outlined"
            onPress={onHelpPressed}
            title={formatMessage({ id: 'choose-pwd_button-reverse' })}
            testId={locator._forgot_password._problem_with_email}
          />
          <SimpleButton
            containerStyle={styles.button}
            disabled={disable || isApiLoading}
            loading={isApiLoading}
            onPress={() => onForgetPasswordBeginProcess(email)}
            title={formatMessage({ id: 'forgot-pwd_button' })}
            testId={locator._forgot_password._send_email_to_reset}
          />
        </View>
        {(isMobile || isTablet) && <LegalMenuFooterConnected />}
      </>
    );
  };
  const canGoBack = navigation?.canGoBack();
  const onClose = () => {
    if (canGoBack) {
      navigation?.goBack();
    }
  };
  const renderMenuLegalFooter = ()=>{
    return <LegalMenuFooterConnected isWhite={true} />;
  }

  return (
    <OfflinePageLayout
      closeButtonAction={Platform.OS === 'web' ? logoNavigateTo : onClose}
      onLogoPress={logoNavigateTo}
      renderDesktopCardBelowContent={renderMenuLegalFooter}
      renderMobileStickyGradientFooterContent={renderActions}>
      <View style={styles.contentStyle}>
          <Title
            testId={locator._forgot_password._title}
            variant="t3"
            weight="bold">
            {formatMessage({ id: 'forgot-pwd_title' })}
          </Title>
          <Paragraph
            testId={locator._forgot_password._paragraph}
            style={styles.paragraph}
            variant="t2">
            {formatMessage({ id: 'forgot-pwd_content' })}
          </Paragraph>
          <TextField
            testId={locator._forgot_password._email}
            containerStyle={styles.inputContainer}
            inputProps={{
              placeholder: 'Adresse e-mail',
              value: email,
              onChangeText: emailValue => {
                setEmail(emailValue);
                setDisable(!emailRegex.test(emailValue));
              }
            }}
            onSubmit={handleSubmit}
          />
      </View>
    </OfflinePageLayout>
  );
};
const getStyles = (context?: StylesContext, style?: StylesProps): StylesProps => ({
  contentStyle: [
    {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    !(context?.isMobile || context?.isTablet) && {
      paddingLeft: 214,
      paddingRight: 200
    },
    context?.isMobile && {
      alignItems: 'center',
      paddingTop: context.theme.metrics.spacing.xxbig
    },
    context?.isTablet && {
      alignItems: 'center',
      paddingTop: context.theme.metrics.spacing.xhuge,
      paddingLeft: context.theme.metrics.spacing.huge
    },
    style?.contentStyle
  ],
  container: [
    !(context?.isMobile || context?.isTablet)
      ? {
          marginTop: context?.theme.metrics.spacing.l,
          maxWidth: 250
        }
      : {
          marginTop: context?.theme.metrics.spacing.l,
          width: '100%'
        }
  ],
  paragraph: {
    marginTop: 40,
    marginBottom: 24
  },
  errorTextStyle: [
    {
      color: context?.theme.colors.basics.message.error.c500,
      marginBottom: context?.theme.metrics.spacing.l
    }
  ],
  button: [
    !(context?.isMobile || context?.isTablet)
      ? {
          alignSelf: 'flex-start',
          marginTop: context?.theme.metrics.spacing.l,
          marginRight: context?.theme.metrics.spacing.s
        }
      : {
          marginTop: context?.theme.metrics.spacing.m,
          marginRight: context?.theme.metrics.spacing.s
        }
  ],
  buttonContainer: [
    {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: context?.isMobile || context?.isTablet ? 'center' : 'flex-start'
    }
  ],
  inputContainer: [
    {
      width: '100%'
    }
  ]
});

const useStyles = createUseStyles(getStyles);
