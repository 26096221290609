import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles( displayFiletTitle: boolean, withoutHorizontalMargin: boolean ) {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const generateStyles = useMemo(() => StyleSheet.create({
    filetContainer: {
      flex: 1,
      marginRight: withoutHorizontalMargin? theme.metrics.spacing.xs : theme.metrics.spacing.xm,
    },
    fromContent: {
      marginLeft: withoutHorizontalMargin? 0 : (displayFiletTitle ? theme.metrics.spacing.xm: theme.metrics.spacing.xs),
      width: '100%',
      marginTop: isDesktop ? theme.metrics.spacing.m : 0,
    },
    filetText: {
      marginBottom: theme.metrics.spacing.xm,
    },
    filetSubContainer: {
      marginTop: isDesktop ?
        theme?.metrics.spacing.l :
        theme?.metrics.spacing.m
    },
    header: {
      borderTopRightRadius: theme.metrics.borderRadius.xs,
      borderBottomRightRadius: theme.metrics.borderRadius.xs,
      borderBottomLeftRadius: theme.metrics.borderRadius.none,
      borderTopLeftRadius: theme.metrics.borderRadius.none,
    },
    headerExpanded: {
      borderBottomRightRadius: theme.metrics.borderRadius.none,
    }
  }), [isDesktop, theme]);

  return generateStyles;
}
