import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "@components/ERE-UILib";
import { ProfitSharingIncentiveTypeEnum } from "@constants/ProfitSharingIncentive";
import { getProfitSharingIncentiveInitDataState } from "@modules/profit-sharing-incentive/selectors";

export const useDictionary = () => {
    const { formatMessage, formatDate } = useTranslation();
    const initData = useSelector(getProfitSharingIncentiveInitDataState);
    const { type } = initData ?? {};
    const deadlineDate = useMemo(() => initData?.deadlineDate, [initData]);

    const generatePIOriginStepperTitleLabelCode = useMemo(() => {
        let PIOriginStepperTitleLabelCode = '';
        switch (type) {
            case ProfitSharingIncentiveTypeEnum.incentive:
                PIOriginStepperTitleLabelCode = 'PIDescriptionMonInteressementTitre';
                break;
            default:
                PIOriginStepperTitleLabelCode = 'PIDescriptionMaParticipationTitre';
                break;
        }
        return PIOriginStepperTitleLabelCode;
    }, [type]);

    const generateDefaultAffectationsDescription = useMemo(() => {
        let PIDefaultAffectationsDescriptionLabelCode = 'PIAffectationdefautCardParticipationDescription';
        if (type === ProfitSharingIncentiveTypeEnum.incentive) {
            PIDefaultAffectationsDescriptionLabelCode = 'PIAffectationdefautCardInteressementDescription';
        }

        const PIDefaultAffectationsDescription = formatMessage({
            id: PIDefaultAffectationsDescriptionLabelCode,
            values: {
                Date_max_affectation: formatDate({ value: deadlineDate })
            }
        });
        return PIDefaultAffectationsDescription;
    }, [deadlineDate, formatDate, formatMessage, type]);

    const generateDefaultAffectationTitle = useMemo(() => {
        let PIDefaultAffectationsTitle = formatMessage({
            id: 'PIAffectationdefautTotalInvestParticipationTitre'
        });

        if (type === ProfitSharingIncentiveTypeEnum.incentive) {
            PIDefaultAffectationsTitle = formatMessage({
                id: 'PIAffectationdefautTotalInvestInteressementTitre'
            });
        }
        return PIDefaultAffectationsTitle;

    }, [formatMessage, type]);

    return {
        generatePIOriginStepperTitleLabelCode,
        generateDefaultAffectationsDescription,
        generateDefaultAffectationTitle
    };
}
