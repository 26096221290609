import React from 'react';
import { View } from 'react-native';

import { TextField } from '@ere-uilib/molecules';

import { useStyles } from './useStyles';

interface FormFieldProps<T> {
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  values: T;
  propertyName: keyof T;
  testId: string;
  placeholder: string;
  errorMessage?: string;
  hasError?: boolean;
  maxSize?: number;
}

export const FormField = <T,>({
  handleChange,
  values,
  setFieldValue,
  handleBlur,
  propertyName,
  testId,
  placeholder,
  hasError = false,
  errorMessage,
  maxSize
}: FormFieldProps<T>) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <TextField
        errored={hasError}
        errorMessage={errorMessage}
        inputProps={{
          onChangeText: handleChange(propertyName),
          value: `${values[propertyName]}`,
          placeholder
        }}
        labelOnTop={true}
        onBlur={handleBlur(propertyName)}
        onCleanField={() => setFieldValue(propertyName, '')}
        rightIconsStyle={styles.rightIconStyle}
        testId={testId}
        maxLength={maxSize}
      />
    </View>
  );
};
