export interface FundsErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface SharePerfItem {
  name: string;
  type: string;
  value: number;
  date: string;
}

export interface SharePriceHistoricItem {
  date: string;
  value: number;
  valueF: string;
}

export enum FundSourceEnum {
  BNPP = 'BNPP', // FUNDMART
  EXTERNAL = 'EXTERNAL',
  MORNING_STAR = 'MORNING_STAR',
  UNKNOWN = 'UNKNOWN'
}

export interface Event {
  startDate: string;
  endDate: string;
  texte: string;
}

export interface FundsPerformanceState {
  name: string | null;
  classification: string | null;
  periodicite: string | null;
  volatility: number;
  risque: string | null;
  isSolidaire: boolean;
  isISR: boolean;
  events: Event[] | null;
  sharePerf: SharePerfItem[] | null;
  benchPerf: SharePerfItem[] | null;
  benchIndex: string | null;
  managementFirm: string | null;
  shareName: string | null;
  dureePlacement: string | null;
  sharePriceHisto: SharePriceHistoricItem[] | null;
  benchHisto: SharePriceHistoricItem[] | null;
  source: FundSourceEnum | null;
  isin: string;
  isBNPP: boolean;
  perfYTD: number | null;
  isMorningStarFund: boolean;
  underlyingFundCode: string | null;
  benchName: string | null;
  hideGraph: boolean;
  zoneGeograph: string | null;
  currency: string | null;
  productId: number | null;
  code: string | null;
  gpsShareCode: string | null;
  secId: string | null;
  value: string;
}

export interface FundsDocumentsState {
  type: string;
  datePublish: string;
  format: string;
  url: string;
  source: string | null;
  urlform: string | null;
  productId: number | null;
  isin: string | null;
  code: string | null;
  gpsShareCode: string | null;
  value: string;
  isBNPP: boolean;
  prio?: number;
}

export interface FundRepartitionSupportPlan {
  planName: string;
  totalInvestedAmount: number;
  totalNumberOfUnits: number;
  hasContribution: boolean;
  hasVersement: boolean;
  hasInteressement: boolean;
  hasParticipation: boolean;
}

export interface FundRepartitionSupportsState {
  supportIsin: string;
  supportName: string;
  supportType: string;
  supportIdNoee: string;
  riskLevel: string;
  performanceValue: number;
  amount: {
    amount: number;
    addedValue: number;
    numberOfUnits: number;
  };
  supportDetailsUrl: string;
  legalStatus: string;
  isMaster: true;
  hasContribution: true;
  netAssetValue: {
    amount: number;
    currency: string;
    dateValue: string;
  };
  plans: FundRepartitionSupportPlan[];
  hasVersement: boolean;
  hasInteressement: boolean;
  hasParticipation: boolean;
}

export interface OverviewState {
  name: string;
  managementFirm: string;
  type: string | null;
  formeJuridique: string;
  classification: string;
  deDroit: string;
  dateLancement: string;
  periodiciteValorisation: string;
  sfdrArticle: string;
  amfCategory: string;
  dureePlacement: string | null;
  perfYTD: number;
  risqueSRI: string | null;
  risqueSRRI: string | null;
  narrativeRisks: string[];
  objectifGestion: string;
  friendlyUrl: string | null;
  volatility: number;
  dateLastVL: string;
  lastVL: number;
  dateNextVL: string;
  hideGraph: boolean;
  isSolidaire: boolean;
  isISR: boolean;
  zoneGeograph: string | null;
  isDeleted: boolean;
  underlyingFundCode: string | null;
  underlyingFundLastVL: number | null;
  underlyingFundLastVLDate: string | null;
  currency: string | null;
  volatilityS: string;
  productId: number | null;
  isin: string;
  sharePriceHisto?: FundsPerformanceState['sharePriceHisto'];
  code: string;
  gpsShareCode: string;
  value: string;
  isBNPP: boolean;
  source?: FundSourceEnum | null;
  isMorningStarFund: boolean;
}

export enum DownloadResultTypeEnum {
  API_ERROR = 'API_ERROR',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  SUCCESS = 'SUCCESS'
}
export interface FundsState {
  funds: OverviewState | undefined;
  fundsRepartitionSupport?: FundRepartitionSupportsState;
  fundsPerformance: FundsPerformanceState | undefined;
  fundsDocumentsList: FundsDocumentsState[] | undefined;

  multiFundsOverviews: OverviewState[] | undefined;
  multiFundsPerformances: FundsPerformanceState[] | undefined;
  multiFundsRepartitionSupports: FundRepartitionSupportsState[] | undefined;
  compareMode: boolean;

  selectedNavigation?: string;
  innerNavigations: string[];
  error: FundsErrorState;
  downloadResult: DownloadResultTypeEnum | undefined;
}
