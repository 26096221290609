import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        cardContainerStyle: {
          borderRadius: theme.metrics.borderRadius.xs,
          paddingVertical: theme.metrics.spacing.xm,
          paddingHorizontal: theme.metrics.spacing.s,
          shadowColor: 'rgba(96, 97, 112, 0.2)',
          shadowOffset: {
            width: 0,
            height: 3
          },
          shadowOpacity: 1,
          shadowRadius: 10,
          elevation: 6,
          alignItems: 'center'
        },
        cardChildrenStyle: {
          alignItems: 'center',
          gap: theme.metrics.spacing.xm
        }
      }),
    [theme]
  );

  return styles;
}
