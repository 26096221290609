interface RegexProps {
  NUMERIC: RegExp;
  EMAIL: RegExp;
  DIGIT: RegExp;
  MAJOR: RegExp;
  MINOR: RegExp;
  SPECIAL: RegExp;
  PASSWORD: RegExp;
  URL: RegExp;
  ISVALIDPATH: RegExp;
  HREFURL: RegExp;
}

const REGEX: RegexProps = {
  NUMERIC: /[^0-9]/g,
  EMAIL: /\S+@\S+\.\S+/,
  DIGIT: /(?=.*[0-9])/,
  MAJOR: /(?=.*[A-Z])/,
  MINOR: /(?=.*[a-z])/,
  SPECIAL: /(?=.*\W)/,
  PASSWORD: /^(?=.{8,15}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
  ISVALIDPATH: /^\/|(\/[a-zA-Z0-9_-]+)+$/,
  URL: /^(http(s?)s?:\/\/)[\w.-]+(\/[\w%\-\/.]*)?([\w%\-\.]+(?:\.pdf)?)?$/,
  HREFURL: /href="\//
};

export { REGEX };
