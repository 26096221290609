import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Linking, Platform, View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  TableLoader,
  Title,
  useTranslation
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { PlatformOS } from '@modules/common/types';

import { CourierCardContent, PhoneCardContent } from './components';
import { EmailCardContentEs, EmailCardContentRc } from './components/EmailCardContent';
import { EmailContentModal } from './components/EmailContactForm/EmailContactForm';
import { NavigationButtons } from './components/NavigationButtons';
import { useHelpContactContext } from './HelpContactsPageProvider';
import { useDictionary } from './useDictionary';
import { useStyles } from './useStyles';

export const HelpContactsPageComponent: React.FC = () => {
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const navigation = useNavigation();
  const {
    onContactInfos,
    onGetProfileInfos,
    phoneData,
    emailData,
    isES,
    isRC,
    isLoading,
    toggle,
    selectedIndex,
    email
  } = useHelpContactContext();
  const dictionary = useDictionary(phoneData?.category, phoneData.description);

  useEffect(() => {
    onContactInfos();
    onGetProfileInfos();
  }, [onContactInfos, onGetProfileInfos]);

  const RenderHeader = useCallback(() => {
    const closeFunction = () => {
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.ProfileStack,
        params: {
          screen: RouteNames.Profile
        }
      });
    };

    return (
      <NavigationHeader
        displayBackButton
        displayCloseButton
        onClose={closeFunction}
        onGoBack={closeFunction}
        title={formatMessage({ id: 'TermsetFrontProfileMenuAideContacts' })}
      />
    );
  }, [navigation, formatMessage]);

  const onOpenMailFormPopin = useCallback(() => {
    toggle();
  }, [toggle]);

  const handleEmailRcClick = useCallback(async (mail: string) => {
    if (Platform.OS !== PlatformOS.WEB) {
      const url = `mailto:${mail}`;
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
      } else {
        console.warn(`Don't know how to open this URL: ${url}`);
      }
    }
  }, []);

  const renderEmailContent = useMemo(() => {
    return (
      selectedIndex === 2 && (
        <>
          <EmailCardContentEs
            directRelationship={emailData.enabled}
            isES={isES}
            onPress={onOpenMailFormPopin}
          />
          <EmailCardContentRc
            isRC={isRC}
            onPress={() => handleEmailRcClick(email)}
          />
          <EmailContentModal />
        </>
      )
    );
  }, [
    selectedIndex,
    emailData.enabled,
    isES,
    onOpenMailFormPopin,
    isRC,
    handleEmailRcClick,
    email
  ]);
  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.containerStyle}
        renderHeader={RenderHeader}>
        {isLoading ? (
          <TableLoader />
        ) : (
          <>
            <Title
              aria-level={2}
              variant={'t3'}>
              {dictionary.contactPageTitle}
            </Title>
            <View style={styles.containerStyle}>
              <NavigationButtons phoneEnabled={phoneData.enabled} />
            </View>
            {selectedIndex === 0 && (
              <PhoneCardContent
                contactPhoneInformation={dictionary.contactPhoneInformation}
                contactPhoneNotice={dictionary.contactPhoneNotice}
                description={dictionary.descriptionPhone}
                link={dictionary.contactPhonePageInformationLink}
                linkDescription={dictionary.linkDescription}
                phoneNumber={phoneData.phoneNumber}
                testId={locator._profile._help_contact._phone_card}
                title={dictionary.titlePhone}
              />
            )}
            {selectedIndex === 1 && (
              <>
                {isES && (
                  <CourierCardContent
                    address={dictionary.addressES}
                    subTitle={dictionary.subTitleCourrierES}
                    testId={locator._profile._help_contact._postal_es_card}
                    title={dictionary.titleCourrierES}
                  />
                )}
                {isRC && (
                  <CourierCardContent
                    address={dictionary.addressRC}
                    subTitle={dictionary.subTitleCourrierRC}
                    testId={locator._profile._help_contact._postal_rc_card}
                    title={dictionary.titleCourrierRC}
                  />
                )}
              </>
            )}
            {renderEmailContent}
          </>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
