import dayjs from 'dayjs';

export const getDiffDate = (endDate: string, startDate?: string) => {
  const date1 = dayjs(endDate);
  const date2 = startDate ? dayjs(startDate) : dayjs();
  const diffDays = date1.diff(date2, 'day');
  return Math.abs(diffDays);
};
export const getTimesTamp = () => {
  return dayjs().valueOf();
};
