import * as actions from '@modules/savings/actions/savingsActionsTypes';
import { SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';
import {
  AvailabilitiesDetailState,
  AvailabilitiesFundsState,
  FilterRoulesState,
  Installments,
  OnGoingVVP,
  OperationDetails,
  OperationsHistoryState,
  RepartitionFiltersState,
  RepartitionsState,
  SavingsErrorState,
  SavingsHistoryFiltersState,
  SavingsHistoryState
} from '@modules/savings/types';

export function getRepartitionsRequest(
  companyId: string,
  repartitionFilters?: RepartitionFiltersState
): actions.GetRepartitionsRequestAction {
  return {
    type: SavingsActionsType.GET_REPARTITIONS_REQUEST,
    companyId,
    repartitionFilters
  };
}

export function getRepartitionsSuccess(
  repartitions: RepartitionsState[]
): actions.GetRepartitionsSuccessAction {
  return {
    type: SavingsActionsType.GET_REPARTITIONS_SUCCESS,
    repartitions
  };
}

export function getRepartitionsFailure(
  error: SavingsErrorState
): actions.GetRepartitionsFailureAction {
  return {
    type: SavingsActionsType.GET_REPARTITIONS_FAILURE,
    error
  };
}

export function getRepartitionsByPlanRequest(
  index: number,
  repartitionFilters?: RepartitionFiltersState
): actions.GetRepartitionsByPlanRequestAction {
  return {
    type: SavingsActionsType.GET_REPARTITIONS_BY_PLAN_REQUEST,
    index,
    repartitionFilters
  };
}

export function getRepartitionsByPlanSuccess(
  repartitions: RepartitionsState[],
  planId: string
): actions.GetRepartitionsByPlanAction {
  return {
    type: SavingsActionsType.GET_REPARTITIONS_BY_PLAN_SUCCESS,
    repartitions,
    planId
  };
}
/// repartition filter

export function setRepartitionFilter(
  repartitionFilters: RepartitionFiltersState
): actions.SetRepartitionFilter {
  return {
    type: SavingsActionsType.SET_REPARTITION_FILTER,
    repartitionFilters
  };
}

/// availabilities detail

export function getAvailabilitiesDetailRequest(
  companyId: string
): actions.GetAvailabilitiesDetailRequest {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_REQUEST,
    companyId
  };
}

export function getAvailabilitiesDetailSuccess(
  availabilitiesDetail: AvailabilitiesDetailState
): actions.GetAvailabilitiesDetailSuccess {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_SUCCESS,
    availabilitiesDetail
  };
}

export function getAvailabilitiesDetailFailure(
  error: SavingsErrorState
): actions.GetAvailabilitiesDetailFailure {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_FAILURE,
    error
  };
}

// availabilities detail by plan

export function getAvailabilitiesByPlanDetailRequest(
  index: number
): actions.GetAvailabilitiesDetailByPlanRequest {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_REQUEST,
    index
  };
}

export function getAvailabilitiesDetailByPlanSuccess(
  availabilitiesDetail: AvailabilitiesDetailState,
  planId: string
): actions.GetAvailabilitiesDetailByPlanSuccess {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_SUCCESS,
    availabilitiesDetail,
    planId
  };
}

export function getAvailabilitiesDetailByPlanFailure(
  error: SavingsErrorState
): actions.GetOperationsHistoryByPlanFailureAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_FAILURE,
    error
  };
}

// Availabilities Fund

export function getAvailabilitiesFundsRequest(
  companyId: string
): actions.GetAvailabilitiesFundsRequest {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_REQUEST,
    companyId
  };
}

export function getAvailabilitiesFundsSuccess(
  availabilitiesFunds: AvailabilitiesFundsState[]
): actions.GetAvailabilitiesFundsSuccess {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_SUCCESS,
    availabilitiesFunds
  };
}

export function getAvailabilitiesFundsFailure(
  error: SavingsErrorState
): actions.GetAvailabilitiesFundsFailure {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_FAILURE,
    error
  };
}

// availabilities funds by plan

export function getAvailabilitiesFundsByPlanRequest(
  index: number
): actions.GetAvailabilitiesFundsByPlanRequest {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_REQUEST,
    index
  };
}

export function getAvailabilitiesFundsByPlanSuccess(
  availabilitiesFunds: AvailabilitiesFundsState[],
  planId: string
): actions.GetAvailabilitiesFundsByPlanSuccess {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_SUCCESS,
    availabilitiesFunds,
    planId
  };
}

export function getAvailabilitiesFundsByPlanFailure(
  error: SavingsErrorState
): actions.GetAvailabilitiesFundsByPlanFailure {
  return {
    type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_FAILURE,
    error
  };
}

/// operations history

export function getHistoryRequest(
  companyId: string,
  filterRoules?: FilterRoulesState
): actions.GetOperationsHistoryRequestAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_REQUEST,
    companyId,
    filterRoules
  };
}

export function getHistorySuccess(
  operationsHistory: OperationsHistoryState[]
): actions.GetOperationsHistorySuccessAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_SUCCESS,
    operationsHistory
  };
}

export function getHistoryFailure(
  error: SavingsErrorState
): actions.GetOperationsHistoryFailureAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_FAILURE,
    error
  };
}

/// operations history by plan

export function getHistoryByPlanRequest(
  index: number,
  filterRoules?: FilterRoulesState
): actions.GetOperationsHistoryByPlanRequestAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_REQUEST,
    index,
    filterRoules
  };
}

export function getHistoryByPlanSuccess(
  operationsHistory: OperationsHistoryState[], // à voir
  planId: string
): actions.GetOperationsHistoryByPlanSuccessAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_SUCCESS,
    operationsHistory,
    planId
  };
}

export function getHistoryByPlanFailure(
  error: SavingsErrorState
): actions.GetOperationsHistoryByPlanFailureAction {
  return {
    type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_FAILURE,
    error
  };
}

/// operations history filter

export function applyOperationsHistoryFilterRequest(
  filterRoules: FilterRoulesState
): actions.ApplyOperationsHistoryFilterRequest {
  return {
    type: SavingsActionsType.APPLY_OPERATIONS_HISTORY_FILTER_REQUEST,
    filterRoules
  };
}

//RedirectionAction

export function RedirectToSavings(
  selectedPlan: number,
  selectedTab: string
): actions.GetSelectedPlanAndNavigation {
  return {
    type: SavingsActionsType.GET_SELECTED_PLAN_AND_NAVIGATION,
    selectedTab,
    selectedPlan
  };
}

export function getInstallmentsRequest(
  year: string,
  planId: string | undefined
): actions.GetInstallmentsRequestAction {
  return {
    type: SavingsActionsType.GET_INSTALLMENTS_REQUEST,
    year,
    planId
  };
}

export function getInstallmentsSuccess(
  installments: Installments
): actions.GetInstallmentsSuccessAction {
  return {
    type: SavingsActionsType.GET_INSTALLMENTS_SUCCESS,
    installments
  };
}

export function getInstallmentsFailure(
  // todo
  error: any
): actions.GetInstallmentsFailureAction {
  return {
    type: SavingsActionsType.GET_INSTALLMENTS_FAILURE,
    error
  };
}

// Saving History by plan

export function getSavingsHistoryByPlanRequest(
  index: number
): actions.GetSavingsHistoryByPlanRequestAction {
  return {
    type: SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_REQUEST,
    index
  };
}

export function getSavingsHistoryByPlanSuccess(
  savingsHistory: SavingsHistoryState[],
  planId: string
): actions.GetSavingsHistoryByPlanSuccessAction {
  return {
    type: SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_SUCCESS,
    savingsHistory,
    planId
  };
}

export function getSavingsHistoryByPlanFailure(
  error: any
): actions.GetSavingsHistoryByPlanFailureAction {
  return {
    type: SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_FAILURE,
    error
  };
}

// Savings History

export function getSavingsHistoryRequest(): actions.GetSavingsHistoryRequestAction {
  return {
    type: SavingsActionsType.GET_SAVINGS_HISTORY_REQUEST
  };
}

export function getSavingsHistorySuccess(
  savingsHistory: SavingsHistoryState[]
): actions.GetSavingsHistorySuccessAction {
  return {
    type: SavingsActionsType.GET_SAVINGS_HISTORY_SUCCESS,
    savingsHistory
  };
}

export function getSavingsHistoryFailure(
  error: SavingsErrorState
): actions.GetSavingsHistoryFailureAction {
  return {
    type: SavingsActionsType.GET_SAVINGS_HISTORY_FAILURE,
    error
  };
}

// savings history filter

export function applySavingsHistoryFilterRequest(
  savingsHistoryFilters: SavingsHistoryFiltersState
): actions.ApplySavingsHistoryFilterRequest {
  return {
    type: SavingsActionsType.APPLY_SAVINGS_HISTORY_FILTER_REQUEST,
    savingsHistoryFilters
  };
}

// operation details

export function getOperationDetailsRequest(
  operationId: string
): actions.GetOperationDetailsRequestAction {
  return {
    type: SavingsActionsType.GET_OPERATION_DETAILS_REQUEST,
    operationId
  };
}

export function getOperationDetailsSuccess(
  operationDetails: OperationDetails
): actions.GetOperationDetailsSuccessAction {
  return {
    type: SavingsActionsType.GET_OPERATION_DETAILS_SUCCESS,
    operationDetails
  };
}

export function getOperationDetailsFailure(
  error: SavingsErrorState
): actions.GetOperationDetailsFailureAction {
  return {
    type: SavingsActionsType.GET_OPERATION_DETAILS_FAILURE,
    error
  };
}

// operation details

export function cancelOperationRequest(operationId: string): actions.CancelOperationRequestAction {
  return {
    type: SavingsActionsType.CANCEL_OPERATION_REQUEST,
    operationId
  };
}

export function cancelOperationSuccess(): actions.CancelOperationSuccessAction {
  return {
    type: SavingsActionsType.CANCEL_OPERATION_SUCCESS
  };
}

export function cancelOperationFailure(
  error: SavingsErrorState
): actions.CancelOperationFailureAction {
  return {
    type: SavingsActionsType.CANCEL_OPERATION_FAILURE,
    error
  };
}

// modify operation

export function modifyOperationRequest(): actions.ModifyOperationRequestAction {
  return {
    type: SavingsActionsType.MODIFY_OPERATION_REQUEST
  };
}

export function modifyOperationSuccess(): actions.ModifyOperationSuccessAction {
  return {
    type: SavingsActionsType.MODIFY_OPERATION_SUCCESS
  };
}

export function modifyOperationFailure(
  error: SavingsErrorState
): actions.ModifyOperationFailureAction {
  return {
    type: SavingsActionsType.MODIFY_OPERATION_FAILURE,
    error
  };
}

// on going vvp history

export function getOnGoindVVPHistoryRequest(
  selectedPlan: number
): actions.GetOnGoingVVPHistoryRequestAction {
  return {
    type: SavingsActionsType.GET_ONGOING_VVP_HISTORY_REQUEST,
    selectedPlan
  };
}

// on going vvp history

export function getOnGoindVVPHistorySuccess(
  onGoingVVP: OnGoingVVP[]
): actions.GetOnGoingVVPHistorySuccessAction {
  return {
    type: SavingsActionsType.GET_ONGOING_VVP_HISTORY_SUCCESS,
    onGoingVVP
  };
}

// on going vvp history

export function setIsDetailsVvpPopin(isOpened: boolean): actions.SetIsDetailsVvpPopin {
  return {
    type: SavingsActionsType.SET_IS_DETAILS_VVP_POPIN,
    isOpened
  };
}
// set going vvp delete modal visible state

export function setOnGoingVVPDeleteModalVisibleState(
  visible: boolean
): actions.SetOnGoingVVPDeleteModalVisibleStateAction {
  return {
    type: SavingsActionsType.SET_ONGOING_VVP_DELETE_MODAL_VISIBLE_STATE,
    visible
  };
}

export function InitSavingReducer(): actions.InitSavingReducerAction {
  return {
    type: SavingsActionsType.INIT_SAVINGS_REDUCER
  };
}

export function InitSavingListings(): actions.InitSavingListingsAction {
  return {
    type: SavingsActionsType.INIT_SAVINGS_LISTINGS
  };
}

export type ClearCacheAfterOperationDoneType = {
  selectedNavigationTab?: string;
  planId?: string;
  stack: any;
  screen: any;
  actionType: any;
};

export function ClearCacheAfterOperationDone(
  cacheData: ClearCacheAfterOperationDoneType | undefined
): actions.ClearCacheAfterOperationDoneAction {
  return {
    type: SavingsActionsType.CLEAR_CACHE_AFTER_OPERATION_DONE,
    selectedNavigationTab: cacheData?.selectedNavigationTab,
    planId: cacheData?.planId,
    stack: cacheData?.stack,
    screen: cacheData?.screen,
    actionType: cacheData?.actionType
  };
}
