import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/index';

import {
  ContributionInput,
  ProfitSharingIncentiveAmountValidationParamsType,
  ProfitSharingIncentiveAssigmentAmountsType,
  ProfitSharingIncentiveCalculatedContributionState,
  ProfitSharingIncentiveCalculContributionParamsType,
  ProfitSharingIncentiveErrorState,
  ProfitSharingIncentiveInitDataState,
  ProfitSharingIncentiveRepartitionPlansState
} from '../types';
import * as actions from './profitSharingIncentiveActionsTypes';
import { ProfitSharingIncentiveActionsType } from './profitSharingIncentiveActionsTypes';

export function getProfitSharingIncentiveInitDataRequest(
  type: ProfitSharingIncentiveTypeEnum,
  legalFramework: ProfitSharingIncentiveLegalFrameworkEnum,
  transactionId?: string
): actions.GetProfitSharingIncentiveInitDataRequestAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST,
    profitSharingIncentiveType: type,
    profitSharingIncentiveLegalFramework: legalFramework,
    transactionId
  };
}

export function getProfitSharingIncentiveInitDataSuccess(
  initData: ProfitSharingIncentiveInitDataState
): actions.GetProfitSharingIncentiveInitDataSuccessAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_SUCCESS,
    initData
  };
}

export function getProfitSharingIncentiveInitDataFailure(
  error: ProfitSharingIncentiveErrorState
): actions.GetProfitSharingIncentiveInitDataFailureAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_FAILURE,
    error,
  };
}

// set assigment Amounts
export function setProfitSharingIncentiveAssigmentAllRefundAmounts(
  amounts: ProfitSharingIncentiveAssigmentAmountsType
): actions.SetProfitSharingIncentiveAssigmentAllRefundAmountsAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_ASSIGMENT_ALLREFUND_AMOUNTS,
    amounts
  };
}

// get repartition for profit-sharing-incentive
export function getProfitSharingIncentiveRepartitionRequest(
  amounts: ProfitSharingIncentiveAssigmentAmountsType
): actions.GetProfitSharingIncentiveRepartitionRequestAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST,
    amounts
  };
}

export function getProfitSharingIncentiveRepartitionSuccess({
  plans,
  amounts,
}: {
  plans: ProfitSharingIncentiveRepartitionPlansState[]
  amounts: ProfitSharingIncentiveAssigmentAmountsType
}): actions.GetProfitSharingIncentiveRepartitionSuccessAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_SUCCESS,
    plans,
    amounts
  };
}

export function getProfitSharingIncentiveRepartitionFailure(
  error: ProfitSharingIncentiveErrorState
): actions.GetProfitSharingIncentiveRepartitionFailureAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_FAILURE,
    error,
  };
}

// set repartition amount
export function setProfitSharingIncentiveRepartitionAmountRequest(
  params: ProfitSharingIncentiveAmountValidationParamsType
): actions.SetProfitSharingIncentiveRepartitionAmountRequestAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST,
    params,
  };
}

export function setProfitSharingIncentiveRepartitionAmountSuccess(
  {
    calculatedContribution,
    profitSharingIncentiveAmountValidationParams,
  }: {
    calculatedContribution: ProfitSharingIncentiveCalculatedContributionState[] | null | undefined
    profitSharingIncentiveAmountValidationParams: ProfitSharingIncentiveAmountValidationParamsType | undefined
  }
): actions.SetProfitSharingIncentiveRepartitionAmountSuccessAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_SUCCESS,
    calculatedContribution,
    profitSharingIncentiveAmountValidationParams,
  };
}

export function setProfitSharingIncentiveRepartitionAmountFailure(
  error: ProfitSharingIncentiveErrorState,
  params?: ProfitSharingIncentiveAmountValidationParamsType
): actions.SetProfitSharingIncentiveRepartitionAmountFailureAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_FAILURE,
    error,
    params,
  };
}

export function setProfitSharingIncentiveRepartitionAmountModalVisibleState(
  state: boolean,
): actions.SetProfitSharingIncentiveRepartitionAmountModalVisibleStateAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE,
    state,
  };
}

export function setProfitSharingIncentiveRepartitionRetirementValidationModalVisibleState(
  state: boolean,
): actions.SetProfitSharingIncentiveRepartitionRetirementValidationModalVisibleStateAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_RETIREMENT_VALIDATION_MODAL_VISIBLE_STATE,
    state,
  };
}
export function ValidateProfitSharingIncentiveRetirementAmountModal(): actions.ValidateProfitSharingIncentiveRetirementAmountModalAction {
  return {
    type: ProfitSharingIncentiveActionsType.PARTICIPATION_PROFITSHARING_VALIDATE_RETIREMENT_AMOUNT_MODAL,
  };
}
export function CancelProfitSharingIncentiveRetirementAmountModal(): actions.CancelProfitSharingIncentiveRetirementAmountModalAction {
  return {
    type: ProfitSharingIncentiveActionsType.PARTICIPATION_PROFITSHARING_CANCEL_RETIREMENT_AMOUNT_MODAL,
  };
}

// contribution
export function getCalculatedContributionDataRequest(
  params: ProfitSharingIncentiveCalculContributionParamsType
): actions.GetProfitSharingIncentiveCalculatedContributionRequestAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST,
    params,
  };
};

export function getCalculatedContributionDataSuccess({
  contributionData,
  params
}: {
  contributionData: ProfitSharingIncentiveCalculatedContributionState[],
  params: ProfitSharingIncentiveCalculContributionParamsType
}): actions.GetProfitSharingIncentiveCalculatedContributionSuccessAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_SUCCESS,
    contributionData,
    params
  };
}

export function getCalculatedContributionDataFailure(
  error: ProfitSharingIncentiveErrorState,
): actions.GetProfitSharingIncentiveCalculatedContributionFailureAction {
  return {
    type: ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_FAILURE,
    error,
  };
}
export function resetProfitSharingIncentiveCalculatedContributionAction(
): actions.ResetProfitSharingIncentiveCalculatedContributionAction {
  return {
    type: ProfitSharingIncentiveActionsType.RESET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION,
  };
}

// submit operation
export function sendProfitSharingIncentiveSubmitAllocationRequest(
): actions.SendProfitSharingIncentiveSubmitAllocationRequestAction {
  return {
    type: ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST,
  };
}

export function sendProfitSharingIncentiveSubmitAllocationSuccess(
): actions.SendProfitSharingIncentiveSubmitAllocationSuccessAction {
  return {
    type: ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_SUCCESS,
  };
}

export function sendProfitSharingIncentiveSubmitAllocationFailure(
  error: ProfitSharingIncentiveErrorState | string,
): actions.SendProfitSharingIncentiveSubmitAllocationFailureAction {
  return {
    type: ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_FAILURE,
    error,
  };
}
export function setEstimateContributionInput(
  payload: ContributionInput[],
): actions.SetEstimateContributionInputAction {
  return {
    type: ProfitSharingIncentiveActionsType.SET_ESTIMATE_CONTRIBUTION_INPUT,
    payload,
  };
}

export function clearProfitSharingIncentiveData(): actions.ClearProfitSharingIncentiveDataAction {
  return {
    type: ProfitSharingIncentiveActionsType.CLEAR_PROFIT_SHARING_INCENTIVE_DATA,
  };
}
