import { getTimesTamp } from './getDiffDate';

export const downloadFile = (blobContent: Blob, fileExtension: string) => {
  const blob = new Blob([blobContent], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const urlObject = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = urlObject;
  const timestamp = getTimesTamp();

  link.download = `${timestamp}.${fileExtension}`;
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(urlObject);
};
