import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';

import {
  Modal,
  ModalFrame,
  Title,
  Text,
  useScreenSizes,
  SimpleButton,
  useTranslation
} from '@components/index';
import { locator } from '@constants/locator';
import { Select, TextFieldMessage } from '@ere-uilib/molecules';
import ConfirmModalComponent from '@pages/Common/ConfirmModalComponent/ConfirmModalComponent';

import { ContactFormErrorAndSuccessPopin } from './ErrorAndSucessPopPin';
import { useDictionary } from './useDictionary';
import { useStyles } from './useStyle';
import { useHelpContactContext } from '../../HelpContactsPageProvider';

export const EmailContentModal: React.FC = () => {
  const {
    emailContactFormIsVisible,
    toggle,
    emailData,
    isPostFormLoading,
    submitContactForm,
    resetContactForm,
    popinFormType,
    userEmail
  } = useHelpContactContext();
  const [message, setMessage] = useState<string>('');
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);

  const [selectValue, setSelectValue] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >(undefined);

  const { formatMessage } = useTranslation();
  const dictionary = useDictionary();
  const navigation = useNavigation();
  const styles = useStyles();
  const { isDesktop, isTablet } = useScreenSizes();
  const maxLength = 2000;

  const handleCloseExitModal = useCallback(() => {
    setMessage('');
    setSelectValue(undefined);
    setConfirmModalVisible(prev => !prev);
    toggle();
  }, [confirmModalVisible]);

  const handleCloseSuccesModal = useCallback(() => {
    resetContactForm();
    if (Platform.OS === 'web') {
      window.history.back();
    } else {
      navigation.goBack();
    }
  }, [navigation, resetContactForm]);

  const handleCloseErrorModal = useCallback(() => {
    resetContactForm();
  }, [resetContactForm]);

  const handleCancelExitModal = useCallback(() => {
    setConfirmModalVisible(prev => !prev);
  }, [setConfirmModalVisible]);

  const handleCloseMessageModal = useCallback(() => {
    message.length != 0 || selectValue != undefined
      ? setConfirmModalVisible(!confirmModalVisible)
      : toggle();
  }, [confirmModalVisible, selectValue, message]);

  const onMessageChange = useCallback((msg: string) => {
    setMessage(msg);
  }, []);

  const onSubmit = useCallback(() => {
    submitContactForm(message, selectValue?.value || '');
  }, [submitContactForm, message, selectValue]);

  const selectList = emailData.objects
    .map(obj => {
      return { value: obj, label: formatMessage({ id: obj }) };
    })
    .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));

  return (
    <Modal visible={emailContactFormIsVisible}>
      <ConfirmModalComponent
        cancelButton={dictionary.popinCancelButton}
        closeModalAction={handleCloseExitModal}
        confirmButton={dictionary.popinConfirmButton}
        descriptionText={dictionary.popinDescriptionText}
        goBackAction={handleCancelExitModal}
        headerTitle={dictionary.popinHeaderTitle}
        isPage={false}
        modalVisible={confirmModalVisible}
      />
      <ModalFrame
        desktopPosition={isDesktop || isTablet ? 'center' : 'fullHeight'}
        maxDesktopWidth={980}
        mobilePosition="full"
        onClose={handleCloseMessageModal}
        title={dictionary.contactFormPageHeader}>
        <ScrollView contentContainerStyle={styles.scrollViewMainContainer}>
          <View style={styles.titleContainer}>
            <Title
              testId={locator._profile._help_contact._email_form_title}
              variant={'t4'}>
              {dictionary.contactFormPageTitle1}
            </Title>
            <Text
              variant="t3"
              weight="light">
              {dictionary.contactFormPageTitle}
            </Text>
            <Text
              variant="t4"
              weight="light">
              {dictionary.contactFormPageDescriptionES}
            </Text>
          </View>
          <Text
            variant="t4"
            weight="light">
            {dictionary.contactFormPageMandatoryFields}
          </Text>
          <Select
            defaultTitle={dictionary.defaultTitleSelect}
            items={selectList}
            onSelectItem={(item: any) => {
              setSelectValue(item);
              if (item?.value === 'QT_REL_DEMANDE') {
                setMessage(dictionary.relDemandeMessage);
              }
            }}
            placeholder={dictionary.placeHolderSelect}
            selectedItem={selectValue}
            selectTestId={locator._profile._help_contact._email_form_select}
          />
          <TextFieldMessage
            containerStyle={styles.textAreaContainer}
            errored={message.length > maxLength}
            errorMessage={dictionary.errorMessage}
            inputProps={{
              helpText: '',
              placeholder: dictionary.placeHolderInput,
              value: message || '',
              onChangeText: onMessageChange,
              multiline: true,
              numberOfLines: 8
            }}
            labelOnTop
            testId={locator._profile._help_contact._email_form_description}
            wordCounter={maxLength}
          />
          <View style={styles.sendButtonContainer}>
            <SimpleButton
              containerStyle={styles.sendButton}
              disabled={
                message.trim().length === 0 ||
                selectValue === undefined ||
                message.length > maxLength
              }
              loading={isPostFormLoading}
              onPress={() => onSubmit()}
              size="large"
              testId={locator._profile._help_contact._email_form_send_button}
              title={dictionary.contactFormPageValidationButton}
            />
          </View>
          <ContactFormErrorAndSuccessPopin
            description={userEmail}
            onPressReturnToFormButton={handleCloseErrorModal}
            onPressReturnToProfilButton={handleCloseSuccesModal}
            popinFormType={popinFormType}
          />
        </ScrollView>
      </ModalFrame>
    </Modal>
  );
};
