import { PlanFamillyList } from '@constants/common';
import { FileData } from '@modules/common/types';
import { AddressDetailDto } from '@modules/profile/service/types';
import { AddressItem } from '@modules/profile/types';

import { FormCountry, FormValues } from './types';

export const getAddress = (addresses: AddressItem[] | null, planFamily: PlanFamillyList) =>
  (addresses || []).find(address => address.type === planFamily);

export const sortCountries = (countries: FormCountry[] | null) =>
  (countries || []).sort(
    (a, b) =>
      (a.value === 'FR' && -1) ||
      (b.value === 'FR' && 1) ||
      a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
  );

export const checkFile = (
  file: FileData,
  fileMaxSize: number,
  allowedExtensions: string[] = []
) => {
  const isValidSize = file.size <= fileMaxSize;
  const isValidExtension = allowedExtensions.includes(file.type);
  return isValidSize && isValidExtension;
};

export const mapFormValuesToDto = ({ country, ...rest }: FormValues): AddressDetailDto => ({
  countryCode: country?.value,
  country: country?.defaultLabel,
  ...rest
});
