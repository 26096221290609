import { useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native"
import { useTheme } from "@ere-uilib/styles";


export interface ProfileInformationsEditCardStyles {
    containerStyle?: StyleProp<ViewStyle>;
    firstLineContainerStyle?: StyleProp<ViewStyle>;
    firstLinesStyle?: StyleProp<TextStyle>;
    statusTagStyle?: StyleProp<TextStyle>;
    separator?: StyleProp<ViewStyle>;
}

export const useStyles = () => {
    const theme = useTheme();
    const styles = useMemo(() => StyleSheet.create({
        container: {
          gap: theme.metrics.spacing.s
        },
        firstLineContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        firstLines: {
            textAlign: 'left',
            flex: 1
        },
        disabled: {
            color: theme.colors.basics.greyTransparent.c200
        }
    }), [theme]);

    return styles;
}
