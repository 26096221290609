import React from 'react';
import { ViewStyle, StyleProp, TextStyle, Text, View } from 'react-native';

import { FontIcon } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme, createUseStyles, ThemeType } from '@ere-uilib/styles';

import { StatusTagTypesEnum } from './sharedInterfaces';

interface StatusTagStyles {
  containerStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
}

interface StatusTagProps extends StatusTagStyles {
  type: string
  label: string
  displayIcon?: boolean
  testId?: string
}

type StylesContext = {
  theme: ThemeType,
  displayVariables: StatusDisplayVariablesInterface
};

interface StatusDisplayVariablesListInterface {
  [key: string]: StatusDisplayVariablesInterface
}
interface StatusDisplayVariablesInterface {
  color?: string,
  iconName?: string
  textColor?: string,
  iconColor?: string,
  borderWidth?: number,
  borderColor?: string,
  backgroundColor?: string,
  iconSize?: number,
  textMargin?: number,
  fontSize?: number,
  paddingLeft?: number,
  paddingRight?:number,
  paddingBottom?:number,
  paddingTop?: number
}

export const StatusTag = ({
                            type,
                            label,
                            displayIcon= false,
                            containerStyle,
                            testId
                          }: StatusTagProps) => {
  const theme = useTheme();
  const statusDisplayVariables: StatusDisplayVariablesListInterface = {
    [StatusTagTypesEnum.TO_INFORM]: {
      textColor: theme.colors.basics.message.error.c500,
      fontSize: theme?.fonts.fontSize.title.t7,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    [StatusTagTypesEnum.DEFAULT]: {
      color: theme.colors.operationState.inProgress.c500,
      iconName: IconEnum.WAITING,
      iconSize: theme.metrics.iconSizes.xxs,
    },
    [StatusTagTypesEnum.IN_PROGRESS]: {
      color: theme.colors.basics.white,
      textColor: theme.colors.operationState.inProgress.c600,
      backgroundColor: theme.colors.background.blueLight.c100,
      iconSize: theme.metrics.iconSizes.xxs,
      iconName: IconEnum.WAITING,
    },
    [StatusTagTypesEnum.TERMINE]: {
      color: theme.colors.basics.white,
      iconSize: theme.metrics.iconSizes.xxs,
      textColor: theme.colors.operationState.success.c500,
      backgroundColor: theme.colors.background.greenBackground.c600,
      iconName: IconEnum.CHECK,
    },
    [StatusTagTypesEnum.ECHEC]: {
      iconSize: theme.metrics.iconSizes.xxs,
      textColor: theme.colors.operationState.failed.c500,
      backgroundColor: theme.colors.background.error.c100,
      iconName: IconEnum.CLOSE,
    },
    [StatusTagTypesEnum.EN_ATTENTE]: {
      textColor: theme.colors.operationState.pending.c700,
      backgroundColor: theme.colors.background.pending.c100,
      iconName: IconEnum.PAUSE,
    },
    [StatusTagTypesEnum.EN_ATTENTE_OPERATION]: {
      color: theme.colors.basics.white,
      textColor: theme.colors.text.disabled.c500,
      borderWidth: 1,
      borderColor: theme.colors.text.disabled.c500,
      iconName: IconEnum.PAUSE,
      iconSize: theme.metrics.iconSizes.xxs,
      textMargin: theme.metrics.spacing.xs,
      backgroundColor: theme.colors.background.greenBackground.c600,
    },
    [StatusTagTypesEnum.ANNULE]: {
      color: theme.colors.operationState.canceled.c500,
      iconName: IconEnum.CLOSE,
      textColor: theme.colors.text.disabled.c500,
      iconColor: theme.colors.basics.grey.c400,
      backgroundColor: theme.colors.basics.grey.c200,
    },
  };
  const displayVariables = statusDisplayVariables[type] || statusDisplayVariables.default;
  const styles = useStyles(
      { theme, displayVariables },
      { containerStyle }
  );
  return (
      <View
          style={[styles.containerStyle, {
            backgroundColor: displayVariables.backgroundColor,
            borderWidth: 0,
          }]}>
        {
            displayIcon && displayVariables.iconName &&  <FontIcon
                name={displayVariables.iconName}
                style={[styles.iconStyle, { fontSize: displayVariables.iconSize }]} />
        }
        <Text testID={testId} style={styles.textStyle}>
          {label}
        </Text>
      </View>
  );
};
const getStyles = (
    context?: StylesContext,
    style?: StatusTagStyles,
): StatusTagStyles => ({
  containerStyle: [
    {
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: context?.displayVariables.paddingLeft ?? 5,
      paddingRight: context?.displayVariables.paddingRight ?? 5,
      paddingTop: context?.displayVariables.paddingTop ?? 3,
      paddingBottom: context?.displayVariables.paddingBottom ?? 4,
      borderRadius: 2,
      backgroundColor: context?.displayVariables.backgroundColor,
    },
    style?.containerStyle,
  ],
  textStyle:
      {
        color:context?.displayVariables.textColor,
        fontSize: context?.displayVariables.fontSize || context?.theme.fonts.fontSize.title.t8
      },
  iconStyle: [
    {
      fontSize: context?.theme.metrics.iconSizes.xxxs,
      marginRight: 3,
      color: context?.displayVariables.textColor || context?.theme.colors.basics.white,
    },
    style?.iconStyle,
  ],
});
const useStyles = createUseStyles(getStyles);
