import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  SimpleButton,
  useScreenSizes,
  useTranslation
} from '@components/index';
import { InputMode } from '@modules/common/constants';

import { useStyles } from './useStyles';
import { ProfileEditAddressHeader } from '../components';
import { FormField, FormSelect, FormTitle } from './components';
import { fieldsMaxLength, getTitleBy } from './form';
import { useData } from './useData';
import { FormValues } from '../types';
import { label } from './label';

export const ProfileEditAddressFormPage: React.FC = () => {
  const { formatMessage } = useTranslation();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles();

  const { planFamily, stepper, countries, inputMode, isAddressUpdateLoading } = useData();

  const { handleChange, errors, touched, handleBlur, values, setFieldValue, handleSubmit } =
    useFormikContext<FormValues>();

  const getTitle = useCallback(getTitleBy(planFamily), [planFamily]);
  const fieldsMaxLengthList = fieldsMaxLength[planFamily];

  const renderBottomAction = useCallback(
    () => (
      <SimpleButton
        containerStyle={styles.continueButtonContainer}
        design="solid"
        loading={isAddressUpdateLoading}
        onPress={() => handleSubmit()}
        size="large"
        testId={'PostalAddressFormSubmitButton'}
        title={formatMessage({
          id: label[planFamily]?.button?.next || label.default?.button?.next,
          defaultMessage: ''
        })}
      />
    ),
    [
      formatMessage,
      handleSubmit,
      isAddressUpdateLoading,
      planFamily,
      styles.continueButtonContainer
    ]
  );

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomAction}>
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.select({ ios: -35, android: -25 })}>
        <ColumnsContainer
          hasRenderRightDesktopColumn={false}
          leftColumnStyle={styles.containerStyle}
          renderHeader={() => <ProfileEditAddressHeader stepper={stepper} />}>
          <FormTitle inputMode={inputMode || InputMode.DATA} />
          <FormField
            errorMessage={formatMessage({ id: errors.additionalAddress_1, defaultMessage: '' })}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.additionalAddress_1 && !!touched.additionalAddress_1}
            placeholder={formatMessage({ id: getTitle('additionalAddress_1'), defaultMessage: '' })}
            propertyName={'additionalAddress_1'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormAdditionalReceiverField'}
            values={values}
            maxSize={fieldsMaxLengthList.additionalAddress_1}
          />
          <FormField
            errorMessage={formatMessage({ id: errors.street, defaultMessage: '' })}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.street && !!touched.street}
            placeholder={formatMessage({ id: getTitle('street'), defaultMessage: '' })}
            propertyName={'street'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormStreetField'}
            values={values}
            maxSize={fieldsMaxLengthList.street}
          />
          <FormField
            errorMessage={formatMessage({ id: errors.additionalAddress_3, defaultMessage: '' })}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.additionalAddress_3 && !!touched.additionalAddress_3}
            placeholder={formatMessage({ id: getTitle('additionalAddress_3'), defaultMessage: '' })}
            propertyName={'additionalAddress_3'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormAdditionalAddressField'}
            values={values}
            maxSize={fieldsMaxLengthList.additionalAddress_3}
          />
          <FormField<FormValues>
            errorMessage={formatMessage({ id: errors.zipcode, defaultMessage: '' })}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.zipcode && !!touched.zipcode}
            placeholder={formatMessage({ id: getTitle('zipcode'), defaultMessage: '' })}
            propertyName={'zipcode'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormZipCodeField'}
            values={values}
          />
          <FormField
            errorMessage={formatMessage({ id: errors.city, defaultMessage: '' })}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.city && !!touched.city}
            placeholder={formatMessage({ id: getTitle('city'), defaultMessage: '' })}
            propertyName={'city'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormCityField'}
            values={values}
            maxSize={fieldsMaxLengthList.city}
          />
          <FormSelect
            errorMessage={formatMessage({ id: errors.country?.value, defaultMessage: '' })}
            hasError={!!errors.country && !!touched.country}
            items={countries}
            onSelectItem={item => setFieldValue('country', item)}
            placeholder={formatMessage({ id: getTitle('country'), defaultMessage: '' })}
            selectedItem={values.country}
            testId={'PostalAddressFormCountryField'}
          />
          {isDesktop && renderBottomAction()}
        </ColumnsContainer>
      </KeyboardAvoidingView>
    </DashboardPageWrapperConnected>
  );
};
