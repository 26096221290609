import {
    ProfitSharingIncentiveErrorState,
    ProfitSharingIncentiveSubmitAllocationResponseType
} from "@modules/profit-sharing-incentive/types";

export const convertToErrorState = (profitSharingIncentiveSubmitAllocationResponse:  ProfitSharingIncentiveSubmitAllocationResponseType):  ProfitSharingIncentiveErrorState => {
    const {code, message, innerMessage, demandId} = profitSharingIncentiveSubmitAllocationResponse || {};
    return {
    code: code || "",
    message: message || "",
    correlationId: demandId,
    innerMessage: innerMessage || ""
};
};
