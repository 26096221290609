import React, { useCallback, useMemo, useState } from 'react';
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  TextInput,
  TextInputFocusEventData,
  TouchableOpacity,
  View
} from 'react-native';
import { useMaskedInputProps } from 'react-native-mask-input';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { colors } from '@ere-uilib/styles/theme/themeFolder';

import { IconsSize, TextFieldProps } from './interface';
import { getStyles } from './styles';

export const TextFieldMessage: React.FC<React.PropsWithChildren<TextFieldProps>> = ({
  borderOnValue = false,
  errored = false,
  code = false,
  innerRef,
  labelOnTop,
  inputProps,
  containerStyle,
  labelStyle,
  inputStyle,
  rightIconsStyle,
  isDisabled,
  wordCounter,
  password,
  isPasswordVisible = true,
  setIsPasswordVisible,
  onKeyPress,
  onSubmit,
  onCleanField,
  leftIcon,
  regexPattern,
  style,
  onFocus,
  onBlur,
  passwordIconColor = colors.basics.primary.c500,
  cleanIconColor = colors.text.disabled.c500,
  placeholderTextColor = colors.basics.grey.c500,
  regexError,
  testId,
  errorMessage,
  errorMessageStyle
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();
  const isLabelOnTop = labelOnTop && (!!inputProps?.value || isFocused);
  const isButtonCleanFieldVisible = onCleanField && !!inputProps?.value;
  const isMobile = useScreenSizes().isMobile;
  const [leftIconsSize, setLeftIconsSize] = useState<IconsSize>({ width: 0, height: 0 });
  const [rightIconsSize, setRightIconsSize] = useState<IconsSize>({ width: 0, height: 0 });

  const styles = useStyles(
    {
      code,
      value: !!(borderOnValue && inputProps?.value),
      isLabelOnTop,
      errored,
      isFocused,
      password,
      theme,
      isMobile,
      leftIconsSize,
      rightIconsSize,
      isDisabled
    },
    { containerStyle, inputStyle, labelStyle, rightIconsStyle, style, errorMessageStyle }
  );

  const onLeftIconLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const { width, height } = nativeEvent.layout;
    setLeftIconsSize({ width, height });
  };

  const checkPattern = useCallback(
    (value: string) => {
      let pattern;

      if (regexPattern) {
        pattern = regexPattern;
      } else if (inputProps?.keyboardType === 'numeric') {
        pattern = /^[0-9,;.+-]+$/;
      } else {
        return true;
      }

      const regex = new RegExp(pattern);

      return regex.test(value);
    },
    [inputProps, regexPattern]
  );

  const handleChangeText = useCallback(
    (value: string) => {
      if (value && !checkPattern(value)) {
        regexError?.(true);
      } else {
        regexError?.(false);
      }
      inputProps?.onChangeText && inputProps?.onChangeText(value);
    },
    [checkPattern, inputProps]
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onBlur?.(e);
      setIsFocused(false);
    },
    [onBlur]
  );

  const keyboardType = useMemo(() => {
    if (inputProps?.keyboardType) {
      return inputProps?.keyboardType;
    }

    if (code) {
      return 'numeric';
    }

    return 'default';
  }, [inputProps, code]);

  const { onChangeText, value } = useMaskedInputProps({
    ...inputProps,
    onChangeText: (_, unmasked) => handleChangeText(unmasked)
  });

  return (
    <View>
      <View style={[styles.containerStyle, styles.inputContainerStyle]}>
        <View
          pointerEvents={isDisabled ? 'none' : 'auto'}
          style={styles.style}>
          {isLabelOnTop && (
            <Text
              style={styles.labelStyle}
              variant="t3">
              {inputProps?.placeholder}
            </Text>
          )}
          {leftIcon && (
            <View
              onLayout={onLeftIconLayout}
              style={styles.leftIconsStyle}>
              <FontIcon
                color={theme.colors.basics.primary.c500}
                name={leftIcon}
                size={theme.metrics.iconSizes.m}
              />
            </View>
          )}
        </View>
        <TextInput
          accessibilityLabel={testId}
          editable={!isDisabled}
          selectTextOnFocus={false}
          testID={testId}
          {...inputProps}
          keyboardType={keyboardType}
          onBlur={handleBlur}
          onChangeText={onChangeText}
          onFocus={e => {
            onFocus?.(e);
            setIsFocused(true);
          }}
          onKeyPress={onKeyPress}
          onSubmitEditing={onSubmit}
          placeholder={
            isLabelOnTop
              ? inputProps?.helpText
              : password
                ? inputProps?.placeholder
                  ? inputProps?.placeholder
                  : 'Mot de passe'
                : code
                  ? ''
                  : inputProps?.placeholder
          }
          placeholderTextColor={placeholderTextColor}
          ref={innerRef}
          secureTextEntry={!isPasswordVisible}
          style={styles.inputStyle}
          value={value}
        />
        {(password || onCleanField) && (
          <View
            onLayout={({ nativeEvent }) => {
              const { width, height } = nativeEvent.layout;
              setRightIconsSize({ width, height });
            }}
            style={styles.rightIconsStyle}>
            {isButtonCleanFieldVisible && (
              <TouchableOpacity
                onPress={onCleanField}
                style={styles.cleanIconStyle}>
                <FontIcon
                  color={cleanIconColor}
                  name="effacer"
                  size={theme.metrics.iconSizes.m}
                />
              </TouchableOpacity>
            )}
            {password && (
              <TouchableOpacity onPress={setIsPasswordVisible}>
                {isPasswordVisible ? (
                  <FontIcon
                    color={passwordIconColor}
                    name="voir"
                    size={theme.metrics.iconSizes.m}
                  />
                ) : (
                  <FontIcon
                    color={passwordIconColor}
                    name="cacher"
                    size={theme.metrics.iconSizes.m}
                  />
                )}
              </TouchableOpacity>
            )}
          </View>
        )}
        {wordCounter && (
          <Text style={{ textAlign: 'right', margin: 10 }}>{`${value.length}/${wordCounter}`}</Text>
        )}
      </View>
      {errored && !!errorMessage && (
        <Text
          style={styles.errorMessageStyle}
          variant="t3"
          weight="regular">
          {errorMessage}
        </Text>
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
