import React, { useCallback } from 'react';
import { View } from 'react-native';

import { FiletStepperCardWrapper, FiletStepperSimpleCard, useTheme } from '@components/index';

import { Props } from './interfaces';
import { ProfitSharingIncentiveDestinationCardType } from './sharedInterface';
import { useStyles } from './useStyles';

export const FiletStepperCardDestination: React.FC<React.PropsWithChildren<Props>> = ({
  cards,
  disableLine
}) => {
  const theme = useTheme();
  const styles = useStyles();

  //   RENDER DESTINATION CARD
  const renderDestination = useCallback(
    (card: ProfitSharingIncentiveDestinationCardType, index: number) => {
      const dispositifColor = theme.colors.basics.primary;
      const renderManagements = () => {
        return card.managements.map(management => {
          return (
            <FiletStepperSimpleCard
              amount={management.amount}
              contribution={management.contribution}
              hasContribution={management.hasContribution}
              headerColor={dispositifColor?.c100}
              hideManagementAmount={management.isFree}
              hideManagementRow={management.hideManagementRow}
              index={index}
              key={`filetStepperSimple-${index}`}
              percentage={management.percentage}
              subCards={management.subCards || undefined}
              title={management.title}
            />
          );
        });
      };
      const headerStyle =
        card.managements.length < 1 || card.isRealExternalPlan
          ? {
              borderBottomColor: dispositifColor?.c500,
              borderBottomRightRadius: theme.metrics.spacing.xs,
              backgroundColor: dispositifColor?.c500
            }
          : {
              borderBottomColor: theme.colors.basics.grey.c200,
              borderBottomRightRadius: undefined,
              backgroundColor: dispositifColor?.c500
            };

      return (
        <FiletStepperCardWrapper
          borderLeftColor={dispositifColor?.c500}
          disableIcone={index !== 0}
          disableLine={disableLine}
          headerResponseAmount={card.amount}
          headerResponseCustomContainerStyle={[styles.bankAccountHeader, headerStyle]}
          headerResponseTitle={card.planName}
          hideHeader={true}
          isDefaultExpanded={!card.isRealExternalPlan}
          isRealExternalPlan={card.isRealExternalPlan}
          planName={card.planName}
          showHeaderResponseDarkText={false}
          title={card.filetTitle || ''}>
          {!card.isRealExternalPlan && renderManagements()}
        </FiletStepperCardWrapper>
      );
    },
    [disableLine, styles, theme]
  );

  return (
    <>{cards?.map((card, index) => <View key={`filetstepper_card-${index}`}>{renderDestination(card, index)}</View>)}</>
  );
};
