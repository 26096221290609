import 'react-native-get-random-values';
import { OperationModifyPage } from '@pages/OperationDetails/OperationModifyPage';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useRef } from 'react';
import { Text } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { REACT_APP_PACKAGE_IDENTIFIER_, WEB_URL } from '@config/index';
import { RouteNames } from '@constants/navigation';
import { useTracking } from '@hooks/useTracking';
import { UserState } from '@modules/auth/types';
import { ConsentsState } from '@modules/profile/types';
import { getNavigationPathConfig } from '@navigation/getNavigationPathConfig';
import {
  ArbitrationStackNavigator,
  BottomTabNavigator,
  ForgetPassword,
  InstallmentsStackNavigator,
  OtpSecurisation,
  OtpValidation,
  ProfitSharingIncentiveNavigator,
  RefundsStackNavigator,
  RelationshipEntringProcess,
  SelfcareStackNavigator,
  DocumentSpaceStackNavigator,
  AnnuitySimulatorStackNavigator,
  RetirementStackNavigator,
  ProfileEditAddressStackNavigator
} from '@navigation/Stacks';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import { OidcCallbackRedirectSilentPage } from '@pages/auth/OidcCallbackRedirectSilentPage';
import { ConsentTransferCreditPage } from '@pages/Consent/ConsentTransferCredit';
import { ContributionModal } from '@pages/Contribution';
import {
  BackOfficeAccessPageComponent,
  BudgetInsightCallbackPage,
  BudgetInsightConnectPage,
  BudgetInsightConsentementPage,
  BudgetInsightErrorPage,
  BusinessContactPreferencesPage,
  Cgu,
  CompanyChoicePage,
  ContactPreferencesPage,
  DisponibilityTermPage,
  EndOidcSessionCallbackPage,
  EndOidcSessionPage,
  Error,
  LegalInfoPage,
  LogoutPage,
  MaintenancePage,
  NativeLoginPage,
  OidcCallbackRedirectPage,
  OidcLogoutCallbackRedirectPage,
  OperationCancelPage,
  OperationCancelSuccessPage,
  OperationDetailsPage,
  RedirectOperationPage,
  SapiendoRedirectPage,
  SupportPage,
  VVPDetailsPageContainer,
  LetterIdentifierFormPage,
  OperationsModalPage,
  ControlDataPageContainer,
  MaintenancePageContainer,
  VotAccess,
  HelpContactsPage
} from '@pages/index';
import { manageExceptionRoutes, TrackingTag } from '@utils/index';

import { AppNavigatorInterface } from './Interfaces/AppNavigatorInterface';
import { navigationRef } from './RootNavigation';
import { BankDetailsStackNavigator } from './Stacks/BankDetailsStack';

interface Props {
  consents?: ConsentsState[];
  user?: UserState;
  isATInternetEnabled: boolean;
  atInternetClientIdSession?: string | undefined;
  onSetAtInternetClientIdSession: (id: string) => void;
}

declare global {
  namespace ReactNavigation {
    // TODO : fix issue with eslint, without this comments, it's reformated badly
    /* eslint-disable */
    interface RootParamList extends AppNavigatorInterface {}
    /* eslint-enable */
  }
}

const MainStack = createStackNavigator<AppNavigatorInterface>();
export const AppNavigatorComponent = ({
  user,
  consents,
  isATInternetEnabled,
  atInternetClientIdSession,
  onSetAtInternetClientIdSession
}: Props) => {
  // set up react navitaion dev tool
  const defaultScreensOptions = useDefaultScreensOptions();
  const routeNameRef = useRef<string | undefined>();
  const isAuthenticated = (user?.access_token && user?.access_token !== '') || false;
  const CGU = consents?.find(item => item.code === 'CGU');
  const isCguAccepted = !!CGU?.isAccepted;
  const config = getNavigationPathConfig({ isAuthenticated, isCguAccepted });
  const linking: any = {
    prefixes: [`https://${WEB_URL}`, `${REACT_APP_PACKAGE_IDENTIFIER_}:`],
    config
  };
  const onGenerateTrackingToken = (tag: TrackingTag) => {
    const contentUuid = uuidv4();
    if (!atInternetClientIdSession) {
      onSetAtInternetClientIdSession(contentUuid);
      return useTracking(tag, contentUuid);
    }
    return useTracking(tag, atInternetClientIdSession);
  };

  return (
    <NavigationContainer
      fallback={<Text>Chargement...</Text>}
      independent
      linking={linking}
      onReady={() => {
        // manage all screen which are in bottomTab stack

        const currentRoute = navigationRef.getCurrentRoute();
        const currentRouteName = currentRoute ? currentRoute.name : '';
        const sentTag = manageExceptionRoutes(currentRouteName);
        sentTag && isATInternetEnabled && onGenerateTrackingToken(sentTag);
        routeNameRef.current = currentRouteName;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;

        const currentRoute = navigationRef.getCurrentRoute();
        const currentRouteName = currentRoute ? currentRoute.name : '';
        const sentTag = manageExceptionRoutes(currentRouteName);

        if (sentTag && previousRouteName !== currentRouteName && isATInternetEnabled) {
          onGenerateTrackingToken(sentTag);
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
      ref={navigationRef}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: 'transparent'
        }
      }}>
      <MainStack.Navigator
        screenOptions={{
          gestureEnabled: false,
          headerShown: false
        }}>
        {isAuthenticated && (
          <>
            {!isCguAccepted && (
              <>
                <MainStack.Screen
                  component={Cgu}
                  name={RouteNames.CguAcceptation}
                  options={defaultScreensOptions.CguAcceptation}
                />
              </>
            )}
            {isCguAccepted && (
              <>
                <MainStack.Screen
                  component={BottomTabNavigator}
                  name={RouteNames.BottomTabNavigator}
                  options={defaultScreensOptions.BottomTabNavigator}
                />
                <MainStack.Screen
                  component={CompanyChoicePage}
                  name={RouteNames.CompanyChoicePage}
                  options={defaultScreensOptions.CompanyChoicePage}
                />
                <MainStack.Screen
                  component={DisponibilityTermPage}
                  name={RouteNames.DisponibilityTermPage}
                  options={defaultScreensOptions.DisponibilityTermPage}
                />
                <MainStack.Screen
                  component={SapiendoRedirectPage}
                  name={RouteNames.SapiendoRedirectPage}
                  options={defaultScreensOptions.SapiendoRedirectPage}
                />
                <MainStack.Screen
                  component={InstallmentsStackNavigator}
                  name={RouteNames.InstallmentStack}
                  options={defaultScreensOptions.Installments}
                />
                <MainStack.Screen
                  component={RefundsStackNavigator}
                  name={RouteNames.RefundsStack}
                  options={defaultScreensOptions.Refunds}
                />
                <MainStack.Screen
                  component={ArbitrationStackNavigator}
                  name={RouteNames.ArbitrationStack}
                  options={defaultScreensOptions.Arbitration}
                />
                <MainStack.Screen
                  component={DocumentSpaceStackNavigator}
                  name={RouteNames.DocumentSpaceStack}
                  options={defaultScreensOptions.DocumentSpace}
                />
                <MainStack.Screen
                  component={OperationsModalPage}
                  name={RouteNames.OperationTypeModalPage}
                  options={defaultScreensOptions.OperationsModalPage}
                />
                <MainStack.Screen
                  component={OtpSecurisation}
                  name={RouteNames.OtpSecurisation}
                  options={defaultScreensOptions.OtpSecurisation}
                />
                <MainStack.Screen
                  component={RedirectOperationPage}
                  name={RouteNames.RedirectOperationPage}
                  options={defaultScreensOptions.RedirectOperationPage}
                />
                <MainStack.Screen
                  component={VVPDetailsPageContainer}
                  name={RouteNames.VVPDetails}
                  options={defaultScreensOptions.VVPDetails}
                />
                <MainStack.Screen
                  component={OperationDetailsPage}
                  name={RouteNames.OperationDetails}
                  options={defaultScreensOptions.OperationDetails}
                />
                <MainStack.Screen
                  component={OperationModifyPage}
                  name={RouteNames.OperationUpdate}
                  options={defaultScreensOptions.OperationUpdate}
                />
                <MainStack.Screen
                  component={OperationCancelPage}
                  name={RouteNames.OperationCancel}
                  options={defaultScreensOptions.OperationCancel}
                />
                <MainStack.Screen
                  component={OperationCancelSuccessPage}
                  name={RouteNames.OperationCancelSuccess}
                  options={defaultScreensOptions.OperationCancelSuccess}
                />
                <MainStack.Screen
                  component={BudgetInsightConsentementPage}
                  name={RouteNames.BiConsentment}
                  options={defaultScreensOptions.BiConsentment}
                />
                <MainStack.Screen
                  component={BudgetInsightErrorPage}
                  name={RouteNames.BiErrorPage}
                  options={defaultScreensOptions.BiErrorPage}
                />
                <MainStack.Screen
                  component={BudgetInsightConnectPage}
                  name={RouteNames.BiConnect}
                  options={defaultScreensOptions.BiConnect}
                />
                <MainStack.Screen
                  component={BudgetInsightCallbackPage}
                  name={RouteNames.BiCallback}
                  options={defaultScreensOptions.BiCallback}
                />
                <MainStack.Screen
                  component={ProfitSharingIncentiveNavigator}
                  name={RouteNames.ProfitSharingIncentive}
                  options={defaultScreensOptions.ProfitSharingIncentive}
                />
                <MainStack.Screen
                  component={AnnuitySimulatorStackNavigator}
                  name={RouteNames.AnnuityStack}
                  options={defaultScreensOptions.Annuity}
                />
                <MainStack.Screen
                  component={BankDetailsStackNavigator}
                  name={RouteNames.BankDetailsStack}
                  options={defaultScreensOptions.BankDetails}
                />
                <MainStack.Screen
                  component={RetirementStackNavigator}
                  name={RouteNames.RetirementStack}
                  options={defaultScreensOptions.Retirement}
                />
                <MainStack.Screen
                  component={ContributionModal}
                  name={RouteNames.ContributionModal}
                  options={defaultScreensOptions.ContributionModal}
                />
                <MainStack.Screen
                  component={ControlDataPageContainer}
                  name={RouteNames.ControlData}
                  options={defaultScreensOptions.ControlData}
                />
                <MainStack.Screen
                  component={HelpContactsPage}
                  name={RouteNames.HelpContacts}
                  options={defaultScreensOptions.HelpContacts}
                />
                <MainStack.Screen
                  component={VotAccess}
                  name={RouteNames.VotAccess}
                  options={defaultScreensOptions.VotAccess}
                />
                <MainStack.Screen
                  component={ProfileEditAddressStackNavigator}
                  name={RouteNames.ProfileEditAddress}
                  options={defaultScreensOptions.ProfileEditAddress}
                />
              </>
            )}
            <MainStack.Screen
              component={MaintenancePage}
              name={RouteNames.MaintenanceModal}
              options={defaultScreensOptions.ConfirmModal}
            />
            <MainStack.Screen
              component={ConsentTransferCreditPage}
              name={RouteNames.ConsentTransferCredit}
              options={defaultScreensOptions.ConsentTransferCredit}
            />
          </>
        )}
        <>
          {/**
           * LOGOUT PAGE MUST BE AT TOP
           */}
          <MainStack.Screen
            component={OidcLogoutCallbackRedirectPage}
            name={RouteNames.OidcLogoutCallbackRedirect}
            options={defaultScreensOptions.OidcLogoutCallbackRedirect}
          />
          <MainStack.Screen
            component={NativeLoginPage}
            name={RouteNames.NativeLoginPage}
            options={defaultScreensOptions.NativeLoginPage}
          />
          {/* TODO - this route should point to seflcareLetterLanding Page, to be restored when its ready to be used */}
          <MainStack.Screen
            component={LetterIdentifierFormPage}
            name={RouteNames.SelfcareLetterLanding}
            options={defaultScreensOptions.SelfcareLetterLanding}
          />
          <MainStack.Screen
            component={SelfcareStackNavigator}
            name={RouteNames.SelfcareStack}
            options={defaultScreensOptions.PersoneoIdentifierForm}
          />
          <MainStack.Screen
            component={LogoutPage}
            name={RouteNames.Logout}
            options={defaultScreensOptions.Logout}
          />
          <MainStack.Screen
            component={EndOidcSessionPage}
            name={RouteNames.EndOidcSessionPage}
            options={defaultScreensOptions.EndOidcSessionPage}
          />
          <MainStack.Screen
            component={EndOidcSessionCallbackPage}
            name={RouteNames.EndOidcSessionCallbackPage}
            options={defaultScreensOptions.EndOidcSessionCallbackPage}
          />
          <MainStack.Screen
            component={OidcCallbackRedirectPage}
            name={RouteNames.OidcCallbackRedirect}
            options={defaultScreensOptions.OidcCallbackRedirect}
          />
          <MainStack.Screen
            component={OidcCallbackRedirectSilentPage}
            name={RouteNames.OidcCallbackRedirectSilentPage}
            options={defaultScreensOptions.OidcCallbackRedirect}
          />
          <MainStack.Screen
            component={SupportPage}
            name={RouteNames.Support}
            options={defaultScreensOptions.Support}
          />
          <MainStack.Screen
            component={Error}
            name={RouteNames.Error}
            options={defaultScreensOptions.Error}
          />
          <MainStack.Screen
            component={BackOfficeAccessPageComponent}
            name={RouteNames.BackOfficeAccess}
            options={defaultScreensOptions.BackOfficeAccess}
          />
          <MainStack.Screen
            component={ContactPreferencesPage}
            name={RouteNames.Contact}
            options={defaultScreensOptions.ContactPreferencesPage}
          />
          <MainStack.Screen
            component={BusinessContactPreferencesPage}
            name={RouteNames.BusinessContact}
            options={defaultScreensOptions.BusinessContactPreferencesPage}
          />
        </>
        {!isAuthenticated && (
          <>
            <MainStack.Screen
              component={LegalInfoPage}
              name={RouteNames.LegalInfo}
              options={defaultScreensOptions.LegalInfoPage}
            />
            <MainStack.Screen
              component={RelationshipEntringProcess}
              name={RouteNames.RelationshipEntringProcess}
              options={defaultScreensOptions.RelationshipEntringProcess}
            />
            <MainStack.Screen
              component={ForgetPassword}
              name={RouteNames.ForgetPassword}
              options={defaultScreensOptions.ForgetPassword}
            />
            <MainStack.Screen
              component={MaintenancePageContainer}
              name={RouteNames.MaintenancePage}
              options={defaultScreensOptions.MaintenancePage}
            />
          </>
        )}
        <MainStack.Screen
          component={OtpValidation}
          name={RouteNames.OtpValidation}
          options={defaultScreensOptions.OtpValidation}
        />
      </MainStack.Navigator>
    </NavigationContainer>
  );
};
