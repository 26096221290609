import { all, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import {
  mockFundOverView,
  mockFundPerformance,
  mockFundsDocumentState,
  mockMultiFundsOverView,
  mockMultiFundsPerformance
} from '@__mocks__/index';
import { REACT_APP_ENV } from '@config/index';
import { EnvironnementNameEnum } from '@constants/index';
import { getUsedCompanyId } from '@modules/dashboard/selectors';
import * as FundsActions from '@modules/funds/actions/fundsActions';
import {
  FundsActionsType,
  GetFundOverviewRequestAction,
  GetFundPerformanceRequestAction,
  GetFundRepartitionSupportsRequestAction,
  GetFundsDocumentsListRequestAction,
  GetMultiFundsOverviewsRequestAction,
  GetMultiFundsPerformancesRequestAction,
  GetMultiFundsRepartitionSupportsRequestAction
} from '@modules/funds/actions/fundsActionsTypes';
import { runManager } from '@modules/moduleManager';
import { getFundApiUrl, getSettingFundDocOrder } from '@modules/settings/selectors';
import { downloadFile } from '@utils/downloadFile';

import {
  fetchFundOverview,
  fetchFundPerformance,
  fetchFundRepartitionSupports,
  fetchFundsDocumentsList,
  fetchMultiFundsOverviews,
  fetchMultiFundsPerformances,
  postDownloadHistory
} from './services';
import { authenticatedCall } from '../apiAuthorization';

// UAT_TEMP env cannot contact fundAPI so we need to detect this env en fake api calls with mock
const isEnvNeedFakeFundAPI = REACT_APP_ENV === EnvironnementNameEnum.UAT_TEMP;

// single fund
export function* getFunds(action: GetFundOverviewRequestAction): any {
  // overview
  yield put(FundsActions.getFundOverviewRequest(action.isinCode));

  // repartition supports
  yield put(FundsActions.getFundRepartitionSupportsRequest(action.isinCode));

  // performance call api when overview succeed
  yield put(FundsActions.getFundPerformanceRequest(action.isinCode));
  // documents call api when overview succeed
  yield put(FundsActions.getFundsDocumentsListRequest(action.isinCode));
}

function* getFundsOverview(action: GetFundPerformanceRequestAction): any {
  let response;
  // UAT_TEMP env cannot contact fundAPI so we need to detect this env en fake api calls with mock
  if (isEnvNeedFakeFundAPI) {
    yield delay(1500);
    response = {
      data: mockFundOverView
    };
  } else {
    const fundApiUrl = yield select(getFundApiUrl);
    response = yield authenticatedCall(fetchFundOverview, fundApiUrl, action.isinCode);
  }
  // prevent api empty responses to be considered as data
  const isDataValid = !(
    response.data === null ||
    (Array.isArray(response.data) && response.data?.length === 0)
  );
  const fundOverview = isDataValid ? response.data : undefined;
  yield put(FundsActions.getFundOverviewSuccess(fundOverview));
}

function* getFundsRepartitionSupports(action: GetFundRepartitionSupportsRequestAction): any {
  const companyId = yield select(getUsedCompanyId);

  const response = yield authenticatedCall(
    fetchFundRepartitionSupports,
    action.isinCode,
    companyId
  );

  yield put(
    FundsActions.getFundRepartitionSupportsSuccess(
      response?.data && response?.data.length > 0 ? response?.data[0] : undefined
    )
  );
}

function* getFundsPerformance(action: GetFundPerformanceRequestAction): any {
  let response;
  // UAT_TEMP env cannot contact fundAPI so we need to detect this env en fake api calls with mock
  if (isEnvNeedFakeFundAPI) {
    yield delay(1500);
    response = {
      data: mockFundPerformance
    };
  } else {
    const fundApiUrl = yield select(getFundApiUrl);
    response = yield authenticatedCall(fetchFundPerformance, fundApiUrl, action.isinCode);
  }
  // prevent api empty responses to be considered as data
  const isDataValid = !(
    response.data === null ||
    (Array.isArray(response.data) && response.data?.length === 0)
  );
  const fundPerformance = isDataValid ? response.data : undefined;
  yield put(FundsActions.getFundPerformanceSuccess(fundPerformance));
}

function* getFundsDocumentsList(action: GetFundsDocumentsListRequestAction): any {
  const fundDocOrderList = yield select(getSettingFundDocOrder);

  let response;
  // UAT_TEMP env cannot contact fundAPI so we need to detect this env en fake api calls with mock
  if (isEnvNeedFakeFundAPI) {
    yield delay(1500);
    response = {
      data: [
        {
          ...mockFundsDocumentState,
          type: 'KIID'
        },
        mockFundsDocumentState,
        {
          ...mockFundsDocumentState,
          type: 'KID_PRIIPS'
        },
        {
          ...mockFundsDocumentState,
          type: 'TEST_UNKOWN_TYPE'
        }
      ]
    };
  } else {
    const fundApiUrl = yield select(getFundApiUrl);
    response = yield authenticatedCall(fetchFundsDocumentsList, fundApiUrl, action.isinCode);
  }
  yield put(FundsActions.getFundsDocumentsListSuccess(response?.data, fundDocOrderList));
}

export function* getFundsSagas() {
  yield takeLatest(
    FundsActionsType.GET_FUND_REQUEST,
    runManager(getFunds, FundsActionsType.GET_FUND_FAILURE)
  );
}

function* getFundsOverviewSagas() {
  yield takeLatest(
    FundsActionsType.GET_FUND_OVERVIEW_REQUEST,
    runManager(getFundsOverview, FundsActionsType.GET_FUND_OVERVIEW_FAILURE)
  );
}

function* getFundsPerformanceSagas() {
  yield takeLatest(
    FundsActionsType.GET_FUND_PERFORMANCE_REQUEST,
    runManager(getFundsPerformance, FundsActionsType.GET_FUND_PERFORMANCE_FAILURE)
  );
}
function* getFundsDocumentsListSagas() {
  yield takeLatest(
    FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_REQUEST,
    runManager(getFundsDocumentsList, FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_FAILURE)
  );
}

// multiFund
function* getMultiFunds(action: GetMultiFundsOverviewsRequestAction): any {
  // multi performance call api when multi overview succeed

  yield put(FundsActions.GetMultiFundsOverviewsRequest(action.isinCodes));
  yield put(FundsActions.GetMultiFundsPerformancesRequest(action.isinCodes));
  yield put(FundsActions.GetMultiFundsRepartitionSupportsRequest(action.isinCodes));

  yield all([
    take(FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_SUCCESS),
    take(FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS),
    take(FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_SUCCESS)
  ]);

  yield put(FundsActions.SetCompareMode(true));
}

function* getMultiFundsOverViews(action: GetMultiFundsPerformancesRequestAction): any {
  let response;
  // UAT_TEMP env cannot contact fundAPI so we need to detect this env en fake api calls with mock
  if (isEnvNeedFakeFundAPI) {
    yield delay(1500);
    response = {
      data: mockMultiFundsOverView
    };
  } else {
    const fundApiUrl = yield select(getFundApiUrl);
    response = yield authenticatedCall(fetchMultiFundsOverviews, fundApiUrl, action.isinCodes);
  }

  yield put(FundsActions.GetMultiFundsOverviewsSuccess(response?.data));
}

function* getMultiFundsPerformances(action: GetMultiFundsPerformancesRequestAction): any {
  let response;
  // UAT_TEMP env cannot contact fundAPI so we need to detect this env en fake api calls with mock
  if (isEnvNeedFakeFundAPI) {
    yield delay(1500);
    response = {
      data: mockMultiFundsPerformance
    };
  } else {
    const fundApiUrl = yield select(getFundApiUrl);
    response = yield authenticatedCall(fetchMultiFundsPerformances, fundApiUrl, action.isinCodes);
  }

  yield put(FundsActions.GetMultiFundsPerformancesSuccess(response?.data));
}

function* getMultiFundsRepartitionSupports(
  action: GetMultiFundsRepartitionSupportsRequestAction
): any {
  const companyId = yield select(getUsedCompanyId);

  const response = yield authenticatedCall(
    fetchFundRepartitionSupports,
    action.isinCodes.join(','),
    companyId
  );
  yield put(FundsActions.GetMultiFundsRepartitionSupportsSuccess(response?.data));
}

function* downloadHistoricalNetAssetValues(action: GetMultiFundsPerformancesRequestAction): any {
  const fundApiUrl = yield select(getFundApiUrl);
  const response = yield authenticatedCall(postDownloadHistory, fundApiUrl, action.isinCodes);
  const documentBlob = response?.data ?? '';
  try {
    yield downloadFile(documentBlob, 'xlsx');
    yield put(FundsActions.postDownloadMultiFundsRepartitionSupportsSuccess());
  } catch (error) {
    yield put(FundsActions.postDownloadMultiFundsRepartitionSupportsFailure(undefined, true));
  }
}
function* getMultiFundsSagas() {
  yield takeLatest(
    FundsActionsType.GET_MULTI_FUNDS_REQUEST,
    runManager(getMultiFunds, FundsActionsType.GET_MULTI_FUNDS_FAILURE)
  );
}
function* getMultiFundsOverViewsSagas() {
  yield takeLatest(
    FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_REQUEST,
    runManager(getMultiFundsOverViews, FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_FAILURE)
  );
}
function* getMultiFundsPerformancesSagas() {
  yield takeLatest(
    FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_REQUEST,
    runManager(getMultiFundsPerformances, FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_FAILURE)
  );
}

function* getFundsRepartitionSupportsSagas() {
  yield takeLatest(
    FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_REQUEST,
    runManager(getFundsRepartitionSupports, FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_FAILURE)
  );
}

function* getMultiFundsRepartitionSupportsSagas() {
  yield takeLatest(
    FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST,
    runManager(
      getMultiFundsRepartitionSupports,
      FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE
    )
  );
}

function* downloadHistoricalNetAssetValuesSagas() {
  yield takeLatest(
    FundsActionsType.POST_DOWNLOAD_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST,
    runManager(
      downloadHistoricalNetAssetValues,
      FundsActionsType.POST_DOWNLOAD_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE
    )
  );
}

export function* FundsSaga() {
  yield all([
    fork(getFundsSagas),
    fork(getFundsRepartitionSupportsSagas),
    fork(getFundsOverviewSagas),
    fork(getFundsPerformanceSagas),
    fork(getFundsDocumentsListSagas),
    fork(getMultiFundsSagas),
    fork(getMultiFundsOverViewsSagas),
    fork(getMultiFundsPerformancesSagas),
    fork(getMultiFundsRepartitionSupportsSagas),
    fork(downloadHistoricalNetAssetValuesSagas)
  ]);
}
